import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from 'context/AuthContext'; // Adjust the import paths according to your project structure

const FinanceAdSupplierDashboard = ({ advertisementId = null }) => {
    const [formAdData, setFormAdData] = useState({
        //platform: '',
        ad_month: '',
        adcost: '',
        selectedCompany: '',
        selectedChannel: '',
    });
    const [companies, setCompanies] = useState([]);
    const [channels, setChannels] = useState([]);
    const [isUpdateMode, setIsUpdateMode] = useState(false);
    const [submitFinanceSuccess, setSubmitFinanceSuccess] = useState(false);
    const [submitFinanceError, setSubmitFinanceError] = useState(false);
    const { authToken } = useContext(AuthContext);

    useEffect(() => {
        fetchCompanies();
        if (advertisementId) {
            setIsUpdateMode(true);
            loadAdvertisementDetails(advertisementId);
        }
    }, [advertisementId]);

    const fetchCompanies = () => {
        // Replace '<API_ENDPOINT>' with your actual API endpoint for fetching companies
        const url = `${process.env.REACT_APP_BASE_API_URL}/company/`;

        fetch(url, {
            method: 'GET', // or 'POST' if required by your API
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`, // Adjust according to your auth method
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setCompanies(data); // Assuming the response is an array of company objects
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });
    };

    useEffect(() => {
        fetchCompanies();
        if (advertisementId) {
            setIsUpdateMode(true);
            loadAdvertisementDetails(advertisementId);
        }
    }, [advertisementId]);


    const loadAdvertisementDetails = async (id) => {
        // Fetch the advertisement details from the API and populate the form
        // Assume the API returns an object with advertisement details similar to the formAdData state
        const adDetails = {}; // Replace with actual API call to fetch details
        setFormAdData({
            platform: adDetails.platform,
            ad_month: adDetails.ad_month,
            adcost: adDetails.adcost,
            selectedCompany: adDetails.selectedCompany,
            selectedChannel: adDetails.selectedChannel,
        });
        // Don't forget to also load the channels for the selected company if necessary
    };

    const fetchChannelsForCompany = (companyName) => {
        // Construct the URL with the company name as a query parameter or part of the URL path, as required by your API
        // Replace '<API_ENDPOINT>' with your actual API endpoint for fetching channels based on company
        // Ensure the company name is properly encoded to be included in a URL
        const url = `${process.env.REACT_APP_BASE_API_URL}/channels/getChannelForCompany/?company_name=${encodeURIComponent(companyName)}`;

        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`, // Adjust based on your authentication method
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setChannels(data); // Assuming the response is an array of channel objects
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });
    };

    const handleAdCompanyChange = (event) => {
        const newSelectedCompany = event.target.value;
        setFormAdData(prevFormAdData => ({
            ...prevFormAdData,
            selectedCompany: newSelectedCompany
        }));

        if (newSelectedCompany) {
            fetchChannelsForCompany(newSelectedCompany);
        } else {
            setChannels([]); // Reset channels if no company is selected
        }
    };


    const handleAdInputChange = (e) => {
        const { name, value } = e.target;
        setFormAdData(prevFormAdData => ({
            ...prevFormAdData,
            [name]: value
        }));
    };

    const handleAdSubmit = async (event) => {
        event.preventDefault(); // Prevent default button action

        let bodyFormData = new FormData();
        // Append specific fields to the FormData object
        bodyFormData.append('company_name', formAdData.selectedCompany);
        bodyFormData.append('channel_name', formAdData.selectedChannel);
        // Iterate over the rest of the formAdData to append other fields
        for (let key in formAdData) {
            if (key !== 'selectedCompany' && key !== 'selectedChannel') { // Corrected the condition to &&
                bodyFormData.append(key, formAdData[key]);
            }
        }

        const apiUrl = isUpdateMode
            ? `${process.env.REACT_APP_BASE_API_URL}/advertisement/update/${advertisementId}/` // Assuming 'advertisementId' is needed for update URL
            : `${process.env.REACT_APP_BASE_API_URL}/advertisement/create_with_company_name/`;

        const method = isUpdateMode ? 'PUT' : 'POST'; // Assuming 'PUT' for updates, adjust if your API uses a different method

        try {
            const response = await fetch(apiUrl, {
                method: method,
                headers: {
                    // 'Content-Type': 'multipart/form-data' is not needed, browser sets it correctly along with the boundary
                    'Authorization': `Token ${authToken}`,
                },
                body: bodyFormData,
            });

            if (response.ok) {
                const responseData = await response.json(); // Assuming you want to do something with the response
                setSubmitFinanceSuccess(true);
                setSubmitFinanceError(false);
                // Reset form data if necessary
                setFormAdData({
                    //platform: '',
                    ad_month: '',
                    adcost: '',
                    selectedCompany: '',
                    selectedChannel: '',
                });
                // You might want to navigate the user away from the form or clear the form here
            } else {
                setSubmitFinanceSuccess(false);
                setSubmitFinanceError(true);
            }
        } catch (error) {
            console.error("Failed to submit form", error);
            setSubmitFinanceSuccess(false);
            setSubmitFinanceError(true);
        }
    };


    const handleChannelChange = (event) => {
        const { value } = event.target;
        setFormAdData(prevFormAdData => ({
            ...prevFormAdData,
            selectedChannel: value,
        }));
    };


    return (
        <div>
            <h2 className="text-lg font-bold mb-6">Add Finance Advertisement</h2>
            <form className="px-3" id="myFinanceForm" id="myFinanceForm">
                <div className="grid grid-cols-1 md:grid-cols-1 gap-4 mb-4">

                    <div className="flex gap-5 w-full">
                        <div className="items-center w-full mb-4">
                            <label htmlFor="company-select" className="block text-sm font-medium text-gray-700">Select Company *</label>
                            <select required id="company-select" value={formAdData.selectedCompany} className="mt-1 p-2 border-2 border-gray-300 w-full rounded-lg cursor-pointer" onChange={handleAdCompanyChange}>

                                <option value="">Select a Company</option>
                                {companies.map((company) => (
                                    <option value={company.name} key={company.name}>
                                        {company.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="items-center w-full mb-4">
                            {/* Channel Select Dropdown */}
                            <label htmlFor="channel-select" className="block text-sm font-medium text-gray-700">Select Channel *</label>
                            <select required id="channel-select" value={formAdData.selectedChannel} className="mt-1 p-2 border-2 border-gray-300 w-full rounded-lg cursor-pointer" onChange={handleChannelChange}>
                                <option value="">Select a Channel</option>
                                {channels.map((channel) => (
                                    <option value={channel.name} key={channel.name}>
                                        {channel.name}
                                    </option>
                                ))}
                            </select>
                        </div>




                    </div>




                    <div>
                        <label htmlFor="payment-date" className="block text-sm font-medium text-gray-700">Payment date *</label>
                        <input
                            type="date"
                            id="country"
                            name="ad_month"
                            required
                            value={formAdData.ad_month}
                            onChange={handleAdInputChange}
                            className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                            placeholder="Enter payment date"
                        />
                    </div>

                    <div>
                        <label htmlFor="ad cost" className="block text-sm font-medium text-gray-700">Ad Cost *</label>
                        <input
                            type="number"
                            id="name"
                            name="adcost"
                            value={formAdData.adcost}
                            onChange={handleAdInputChange}
                            required
                            className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                            placeholder="Enter your amount"
                        />
                    </div>
                </div>

                <div className="flex justify-end mt-4">
                    <button
                        type="button" // Change this to type="button" to prevent default form submit behavior
                        className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
                        onClick={handleAdSubmit} // Use onClick to call the submit function
                    >
                        Add Advertisements
                    </button>
                </div>
            </form>
            {submitFinanceSuccess && <div>Your form has been submitted successfully!</div>}
            {submitFinanceError && <div>Error submitting form. Please try again.</div>}
        </div>
    );
};

export default FinanceAdSupplierDashboard;