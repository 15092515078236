import { useState, useEffect, useContext } from "react";
import AuthContext from "../../../src/context/AuthContext";
import FlipkartLogo from '../../assets/svg/flipkart.svg';
import AmazonLogo from '../../assets/svg/amazon.svg';
import JiomartLogo from '../../assets/svg/jiomart.svg';
import MeeshoLogo from '../../assets/svg/meeshologo.svg';
import MyntraLogo from '../../assets/svg/myntra.svg';
import ApparioLogo from '../../assets/img/images/appario.jpg';
import HomeKitchenAccessories from '../../assets/img/images/BYB0016568.jpg';
import GardenTools from '../../assets/img/images/BYB0017109.jpg';
import PetAccessories from '../../assets/img/images/BYB0016403.jpg';

const Website = () => {
    // State to control the visibility of the modal
    const [isModalOpen, setModalOpen] = useState(false);

    // Function to open the modal
    const openModal = () => setModalOpen(true);

    // Function to close the modal
    const closeModal = () => setModalOpen(false);

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const [skuNames, setSkuNames] = useState([]);
    const { authToken } = useContext(AuthContext);

    useEffect(() => {
        // Assuming REACT_APP_BASE_API_URL is the base URL for your Django backend
        const apiUrl = `${process.env.REACT_APP_BASE_API_URL}/sku/names/`; // Adjusted to fetch SKU names
        fetch(apiUrl, {
            headers: {
                'Authorization': `Token ${authToken}`, // Assuming you're using Token authentication
            },
        })
            .then(response => response.json())
            .then(data => {
                // Assuming 'data' is an array of names since that's what our Django view returns
                const skuNames = data.map(name => ({ name })); // Transforming each name into an object if needed

                setSkuNames(skuNames); // Assuming you have a state variable called 'skuNames' for storing these
            })
            .catch(error => console.error('Fetch error:', error));
    }, [authToken]); // Dependency array ensures this effect runs whenever 'authToken' changes


    return (
        <>
            <nav className="sticky bg-white dark:bg-gray-900 fixed w-full z-20 top-0 border-b border-gray-200 dark:border-gray-600">
                <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-between items-center py-3 md:justify-start md:space-x-10">
                        <div className="flex justify-start lg:w-0 lg:flex-1">
                            <a href="https://supplier.ensologic.com/">
                                <img src="https://media.licdn.com/dms/image/D4D0BAQFqFAUkiXkfEA/company-logo_200_200/0/1690444337691?e=2147483647&v=beta&t=EyyY89lbCTDKr0dgfgPUhBn6e7QLeg2Mq8X-58aJtEo" className="h-16" alt="Beyobo Logo" />
                            </a>
                        </div>
                        <div className="-mr-2 -my-2 md:hidden">
                            <button onClick={() => setIsMobileMenuOpen(true)} type="button" className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" aria-expanded="false">
                                <span className="sr-only">Open menu</span>
                                {/* Hamburger Icon SVG */}
                                <svg id="hamburger" width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5 7H19" stroke="#000000" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M5 12L19 12" stroke="#000000" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M5 17L19 17" stroke="#000000" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </button>
                        </div>
                        <nav className="hidden md:flex space-x-10">
                            <a href="#catalogue" className="text-base font-medium text-gray-500 hover:text-gray-900">Catalogue</a>
                            <a href="#shipment" className="text-base font-medium text-gray-500 hover:text-gray-900">Shipment</a>
                            <a href="#payment" className="text-base font-medium text-gray-500 hover:text-gray-900">Payment</a>
                            <a href="#about" className="text-base font-medium text-gray-500 hover:text-gray-900">About us</a>
                        </nav>
                        <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                            <a href="https://supplier.ensologic.com/auth/sign-in">
                                <button type="button" className="whitespace-nowrap items-center text-base font-medium text-gray-500 hover:text-gray-900 flex">Log in
                                    <svg class="ml-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                </button>
                            </a>
                            <a href="https://supplier.ensologic.com/auth/onboarding/screen">
                                <button type="button" className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700">Sign up</button>
                            </a>
                        </div>
                    </div>
                </div>

                {/* Mobile menu, show/hide based on menu state. */}
                {isMobileMenuOpen && (
                    <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
                        <div className="shadow-lg ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                            <div className="pt-5 pb-6 px-5">
                                <div className="flex items-center justify-between">
                                    <div>
                                        <img src="https://media.licdn.com/dms/image/D4D0BAQFqFAUkiXkfEA/company-logo_200_200/0/1690444337691?e=2147483647&v=beta&t=EyyY89lbCTDKr0dgfgPUhBn6e7QLeg2Mq8X-58aJtEo" className="h-16" alt="Beyobo Logo" />
                                    </div>
                                    <div className="-mr-2">
                                        <button onClick={() => setIsMobileMenuOpen(false)} type="button" className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                            <span className="sr-only">Close menu</span>
                                            {/* Close Icon SVG */}
                                            <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M20.7457 3.32851C20.3552 2.93798 19.722 2.93798 19.3315 3.32851L12.0371 10.6229L4.74275 3.32851C4.35223 2.93798 3.71906 2.93798 3.32854 3.32851C2.93801 3.71903 2.93801 4.3522 3.32854 4.74272L10.6229 12.0371L3.32856 19.3314C2.93803 19.722 2.93803 20.3551 3.32856 20.7457C3.71908 21.1362 4.35225 21.1362 4.74277 20.7457L12.0371 13.4513L19.3315 20.7457C19.722 21.1362 20.3552 21.1362 20.7457 20.7457C21.1362 20.3551 21.1362 19.722 20.7457 19.3315L13.4513 12.0371L20.7457 4.74272C21.1362 4.3522 21.1362 3.71903 20.7457 3.32851Z" fill="#0F0F0F" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div className="mt-6">
                                    <nav className="grid gap-y-8">
                                        <a href="#catalogue" className="text-base font-medium text-gray-500 hover:text-gray-900">Catalogue</a>
                                        <a href="#shipment" className="text-base font-medium text-gray-500 hover:text-gray-900">Shipment</a>
                                        <a href="#payment" className="text-base font-medium text-gray-500 hover:text-gray-900">Payment</a>
                                        <a href="#about" className="text-base font-medium text-gray-500 hover:text-gray-900">About us</a>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </nav>



            <section class="bg-white dark:bg-gray-900">
                <div class="pt-8 px-4 mx-auto max-w-screen-xl text-center lg:pt-16 lg:px-12">
                    <a href="#" class="inline-flex justify-between items-center py-1 px-1 pr-4 mb-7 text-sm text-gray-700 bg-gray-100 rounded-full dark:bg-gray-800 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700" role="alert">
                        <span class="text-xs bg-gray-100 font-bold rounded-full bg-blue-700 text-white px-4 py-1.5 mr-3">New</span> <span class="text-sm font-medium  bg-gray-100">Beta Version 1.0</span>
                        <svg class="ml-2 w-5 h-5 bg-gray-100" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                    </a>
                    <h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">Your Partner For Selling Your Goods Online & Offline In India.</h1>
                    <p class="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">Here at Beyobo we focus on pro </p>
                    <div class="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
                        <a href="#" class="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-black rounded-lg hover:bg-blue-700 hover:text-white ease focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900">
                            Learn more
                            <svg class="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                        </a>
                        <div className="App">
                            {/* Trigger button */}
                            <button
                                onClick={openModal}
                                className="px-6 py-2 text-white bg-blue-500 hover:bg-blue-700 rounded"
                            >
                                Watch Video
                            </button>

                            {/* Modal Overlay */}
                            {isModalOpen && (
                                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
                                    {/* Modal Content */}
                                    <div className="relative p-5 border w-full max-w-md m-auto flex-col flex bg-white rounded-md shadow-lg">
                                        {/* Close button */}
                                        <div className="flex justify-end pb-3">
                                            <button onClick={closeModal} className="text-gray-700 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center">
                                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                                            </button>
                                        </div>
                                        {/* YouTube Video */}
                                        <iframe
                                            width="560"
                                            height="315"
                                            src="https://www.youtube.com/embed/oD9l5FAwqPI"
                                            title="YouTube video"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; allowfullscreen"
                                            className="w-full"
                                        ></iframe>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>



                <div class="px-4 mx-auto text-center bg-white lg:px-24">
                    <span class="font-semibold text-gray-400 uppercase">FEATURED IN</span>
                    <div class="grid grid-cols-6 gap-5 justify-center items-center mt-4 text-gray-500 sm:justify-between">
                        <a href="#" class="mr-5 mt-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400">
                            <img className="w-full h-12" src={AmazonLogo} alt="Amazon Logo" />
                        </a>
                        <a href="#" class="mr-5 mb-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400">
                            <img className="w-full h-12" src={FlipkartLogo} alt="Flipkart Logo" />
                        </a>
                        <a href="#" class="mr-5 mb-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400">
                            <img className="w-full h-12" src={JiomartLogo} alt="JioMart Logo" />
                        </a>
                        <a href="#" class="mr-5 mb-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400">
                            <img className="w-full h-24" src={MeeshoLogo} alt="Meesho Logo" />
                        </a>
                        <a href="#" class="mr-5 mb-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400">
                            <img className="w-full h-24" src={MyntraLogo} alt="Myntra Logo" />
                        </a>
                        <a href="#" class="mr-5 mb-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400">
                            <img className="w-24 h-auto" src={ApparioLogo} alt="Appario Logo" />
                        </a>
                    </div>
                </div>
            </section>



            <section className="w-full bg-white py-20">
                <h1 className="text-center text-4xl">Why <i><b>Beyobo</b></i>?</h1>
                <p className="text-center mx-auto px-4 py-7 sm:w-3/4 md:w-2/3 lg:w-1/2 xl:py-7">
                    Trusted by top sellers on different marketplaces like Amazon, Flipkart, Meesho, Jio Mart, etc, we help you to sell on Indian marketplaces without hassles of owning an Indian company. Beyobo is an ERP solution that integrates seamlessly with your e-commerce business.
                </p>



                <div className="flex flex-cols-3 flex-wrap gap-5 justify-center">

                    <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                        <svg class="w-7 h-7 text-gray-500 dark:text-gray-400 mb-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M18 5h-.7c.229-.467.349-.98.351-1.5a3.5 3.5 0 0 0-3.5-3.5c-1.717 0-3.215 1.2-4.331 2.481C8.4.842 6.949 0 5.5 0A3.5 3.5 0 0 0 2 3.5c.003.52.123 1.033.351 1.5H2a2 2 0 0 0-2 2v3a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V7a2 2 0 0 0-2-2ZM8.058 5H5.5a1.5 1.5 0 0 1 0-3c.9 0 2 .754 3.092 2.122-.219.337-.392.635-.534.878Zm6.1 0h-3.742c.933-1.368 2.371-3 3.739-3a1.5 1.5 0 0 1 0 3h.003ZM11 13H9v7h2v-7Zm-4 0H2v5a2 2 0 0 0 2 2h3v-7Zm6 0v7h3a2 2 0 0 0 2-2v-5h-5Z" />
                        </svg>
                        <a href="#">
                            <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">Payment Reconciliation</h5>
                        </a>
                        <p class="mb-3 font-normal text-gray-500 dark:text-gray-400">Get your product catalogue compliance checked in two steps.</p>
                    </div>



                    <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                        <svg class="w-7 h-7 text-gray-500 dark:text-gray-400 mb-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M18 5h-.7c.229-.467.349-.98.351-1.5a3.5 3.5 0 0 0-3.5-3.5c-1.717 0-3.215 1.2-4.331 2.481C8.4.842 6.949 0 5.5 0A3.5 3.5 0 0 0 2 3.5c.003.52.123 1.033.351 1.5H2a2 2 0 0 0-2 2v3a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V7a2 2 0 0 0-2-2ZM8.058 5H5.5a1.5 1.5 0 0 1 0-3c.9 0 2 .754 3.092 2.122-.219.337-.392.635-.534.878Zm6.1 0h-3.742c.933-1.368 2.371-3 3.739-3a1.5 1.5 0 0 1 0 3h.003ZM11 13H9v7h2v-7Zm-4 0H2v5a2 2 0 0 0 2 2h3v-7Zm6 0v7h3a2 2 0 0 0 2-2v-5h-5Z" />
                        </svg>
                        <a href="#">
                            <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">Brand Aggregation</h5>
                        </a>
                        <p class="mb-3 font-normal text-gray-500 dark:text-gray-400">Get your product catalogue compliance checked in two steps.</p>
                    </div>



                    <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                        <svg class="w-7 h-7 text-gray-500 dark:text-gray-400 mb-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M18 5h-.7c.229-.467.349-.98.351-1.5a3.5 3.5 0 0 0-3.5-3.5c-1.717 0-3.215 1.2-4.331 2.481C8.4.842 6.949 0 5.5 0A3.5 3.5 0 0 0 2 3.5c.003.52.123 1.033.351 1.5H2a2 2 0 0 0-2 2v3a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V7a2 2 0 0 0-2-2ZM8.058 5H5.5a1.5 1.5 0 0 1 0-3c.9 0 2 .754 3.092 2.122-.219.337-.392.635-.534.878Zm6.1 0h-3.742c.933-1.368 2.371-3 3.739-3a1.5 1.5 0 0 1 0 3h.003ZM11 13H9v7h2v-7Zm-4 0H2v5a2 2 0 0 0 2 2h3v-7Zm6 0v7h3a2 2 0 0 0 2-2v-5h-5Z" />
                        </svg>
                        <a href="#">
                            <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">Reduce Cost</h5>
                        </a>
                        <p class="mb-3 font-normal text-gray-500 dark:text-gray-400">By optimising aggregation, choosing between Sea & Air, Delivery at the nearest Port, and Packing in India</p>
                    </div>



                    <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                        <svg class="w-7 h-7 text-gray-500 dark:text-gray-400 mb-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M18 5h-.7c.229-.467.349-.98.351-1.5a3.5 3.5 0 0 0-3.5-3.5c-1.717 0-3.215 1.2-4.331 2.481C8.4.842 6.949 0 5.5 0A3.5 3.5 0 0 0 2 3.5c.003.52.123 1.033.351 1.5H2a2 2 0 0 0-2 2v3a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V7a2 2 0 0 0-2-2ZM8.058 5H5.5a1.5 1.5 0 0 1 0-3c.9 0 2 .754 3.092 2.122-.219.337-.392.635-.534.878Zm6.1 0h-3.742c.933-1.368 2.371-3 3.739-3a1.5 1.5 0 0 1 0 3h.003ZM11 13H9v7h2v-7Zm-4 0H2v5a2 2 0 0 0 2 2h3v-7Zm6 0v7h3a2 2 0 0 0 2-2v-5h-5Z" />
                        </svg>
                        <a href="#">
                            <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">Marketing & Account Management</h5>
                        </a>
                        <p class="mb-3 font-normal text-gray-500 dark:text-gray-400">Our expert team & relation with the major platform can give max sales with the least Ads.</p>
                    </div>



                    <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                        <svg class="w-7 h-7 text-gray-500 dark:text-gray-400 mb-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M18 5h-.7c.229-.467.349-.98.351-1.5a3.5 3.5 0 0 0-3.5-3.5c-1.717 0-3.215 1.2-4.331 2.481C8.4.842 6.949 0 5.5 0A3.5 3.5 0 0 0 2 3.5c.003.52.123 1.033.351 1.5H2a2 2 0 0 0-2 2v3a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V7a2 2 0 0 0-2-2ZM8.058 5H5.5a1.5 1.5 0 0 1 0-3c.9 0 2 .754 3.092 2.122-.219.337-.392.635-.534.878Zm6.1 0h-3.742c.933-1.368 2.371-3 3.739-3a1.5 1.5 0 0 1 0 3h.003ZM11 13H9v7h2v-7Zm-4 0H2v5a2 2 0 0 0 2 2h3v-7Zm6 0v7h3a2 2 0 0 0 2-2v-5h-5Z" />
                        </svg>
                        <a href="#">
                            <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">Inventory Tracking & Transparency</h5>
                        </a>
                        <p class="mb-3 font-normal text-gray-500 dark:text-gray-400">Get your product catalogue compliance checked in two steps.</p>
                    </div>



                    <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                        <svg class="w-7 h-7 text-gray-500 dark:text-gray-400 mb-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M18 5h-.7c.229-.467.349-.98.351-1.5a3.5 3.5 0 0 0-3.5-3.5c-1.717 0-3.215 1.2-4.331 2.481C8.4.842 6.949 0 5.5 0A3.5 3.5 0 0 0 2 3.5c.003.52.123 1.033.351 1.5H2a2 2 0 0 0-2 2v3a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V7a2 2 0 0 0-2-2ZM8.058 5H5.5a1.5 1.5 0 0 1 0-3c.9 0 2 .754 3.092 2.122-.219.337-.392.635-.534.878Zm6.1 0h-3.742c.933-1.368 2.371-3 3.739-3a1.5 1.5 0 0 1 0 3h.003ZM11 13H9v7h2v-7Zm-4 0H2v5a2 2 0 0 0 2 2h3v-7Zm6 0v7h3a2 2 0 0 0 2-2v-5h-5Z" />
                        </svg>
                        <a href="#">
                            <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">High Selling Products can be shown to VC Partner</h5>
                        </a>
                        <p class="mb-3 font-normal text-gray-500 dark:text-gray-400">Get your product catalogue compliance checked in two steps.</p>
                    </div>



                    <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                        <svg class="w-7 h-7 text-gray-500 dark:text-gray-400 mb-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M18 5h-.7c.229-.467.349-.98.351-1.5a3.5 3.5 0 0 0-3.5-3.5c-1.717 0-3.215 1.2-4.331 2.481C8.4.842 6.949 0 5.5 0A3.5 3.5 0 0 0 2 3.5c.003.52.123 1.033.351 1.5H2a2 2 0 0 0-2 2v3a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V7a2 2 0 0 0-2-2ZM8.058 5H5.5a1.5 1.5 0 0 1 0-3c.9 0 2 .754 3.092 2.122-.219.337-.392.635-.534.878Zm6.1 0h-3.742c.933-1.368 2.371-3 3.739-3a1.5 1.5 0 0 1 0 3h.003ZM11 13H9v7h2v-7Zm-4 0H2v5a2 2 0 0 0 2 2h3v-7Zm6 0v7h3a2 2 0 0 0 2-2v-5h-5Z" />
                        </svg>
                        <a href="#">
                            <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">Market Insights</h5>
                        </a>
                        <p class="mb-3 font-normal text-gray-500 dark:text-gray-400">Regular updates to demand in the market at what price. Estimation of Sale/Volume & competition analysis.</p>
                    </div>



                    <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                        <svg class="w-7 h-7 text-gray-500 dark:text-gray-400 mb-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M18 5h-.7c.229-.467.349-.98.351-1.5a3.5 3.5 0 0 0-3.5-3.5c-1.717 0-3.215 1.2-4.331 2.481C8.4.842 6.949 0 5.5 0A3.5 3.5 0 0 0 2 3.5c.003.52.123 1.033.351 1.5H2a2 2 0 0 0-2 2v3a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V7a2 2 0 0 0-2-2ZM8.058 5H5.5a1.5 1.5 0 0 1 0-3c.9 0 2 .754 3.092 2.122-.219.337-.392.635-.534.878Zm6.1 0h-3.742c.933-1.368 2.371-3 3.739-3a1.5 1.5 0 0 1 0 3h.003ZM11 13H9v7h2v-7Zm-4 0H2v5a2 2 0 0 0 2 2h3v-7Zm6 0v7h3a2 2 0 0 0 2-2v-5h-5Z" />
                        </svg>
                        <a href="#">
                            <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">⁠Compliance Registrations</h5>
                        </a>
                        <p class="mb-3 font-normal text-gray-500 dark:text-gray-400">BIS, Weight & Measurement, TradeMark on any other compliance registration</p>
                    </div>



                    <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                        <svg class="w-7 h-7 text-gray-500 dark:text-gray-400 mb-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M18 5h-.7c.229-.467.349-.98.351-1.5a3.5 3.5 0 0 0-3.5-3.5c-1.717 0-3.215 1.2-4.331 2.481C8.4.842 6.949 0 5.5 0A3.5 3.5 0 0 0 2 3.5c.003.52.123 1.033.351 1.5H2a2 2 0 0 0-2 2v3a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V7a2 2 0 0 0-2-2ZM8.058 5H5.5a1.5 1.5 0 0 1 0-3c.9 0 2 .754 3.092 2.122-.219.337-.392.635-.534.878Zm6.1 0h-3.742c.933-1.368 2.371-3 3.739-3a1.5 1.5 0 0 1 0 3h.003ZM11 13H9v7h2v-7Zm-4 0H2v5a2 2 0 0 0 2 2h3v-7Zm6 0v7h3a2 2 0 0 0 2-2v-5h-5Z" />
                        </svg>
                        <a href="#">
                            <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">⁠Assured Team for Best ROI.</h5>
                        </a>
                        <p class="mb-3 font-normal text-gray-500 dark:text-gray-400">Get your product catalogue compliance checked in two steps.</p>
                    </div>

                    {/*<div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">*/}
                    {/*    <svg class="w-7 h-7 text-gray-500 dark:text-gray-400 mb-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">*/}
                    {/*        <path d="M18 5h-.7c.229-.467.349-.98.351-1.5a3.5 3.5 0 0 0-3.5-3.5c-1.717 0-3.215 1.2-4.331 2.481C8.4.842 6.949 0 5.5 0A3.5 3.5 0 0 0 2 3.5c.003.52.123 1.033.351 1.5H2a2 2 0 0 0-2 2v3a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V7a2 2 0 0 0-2-2ZM8.058 5H5.5a1.5 1.5 0 0 1 0-3c.9 0 2 .754 3.092 2.122-.219.337-.392.635-.534.878Zm6.1 0h-3.742c.933-1.368 2.371-3 3.739-3a1.5 1.5 0 0 1 0 3h.003ZM11 13H9v7h2v-7Zm-4 0H2v5a2 2 0 0 0 2 2h3v-7Zm6 0v7h3a2 2 0 0 0 2-2v-5h-5Z" />*/}
                    {/*    </svg>*/}
                    {/*    <a href="#">*/}
                    {/*        <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">End to End Tracking and Transparency</h5>*/}
                    {/*    </a>*/}
                    {/*    <p class="mb-3 font-normal text-gray-500 dark:text-gray-400">Get your product catalogue compliance checked in two steps.</p>*/}
                    {/*</div>*/}

                </div>
            </section>




            <section className="w-full bg-white pb-20">
                <h1 className="text-center text-4xl py-8">Kinds of <i><b>SKUs</b></i> we list.</h1>
                {/*<p className="text-center mx-auto px-4 py-7 sm:w-3/4 md:w-2/3 lg:w-1/2 xl:py-7">*/}
                {/*    Trusted by top sellers on different marketplaces like Amazon, Flipkart, Meesho, Jio Mart, etc, we help you to sell on Indian marketplaces without hassles of owning an Indian company. Beyobo is an ERP solution that integrates seamlessly with your e-commerce business.*/}
                {/*</p>*/}

                <div className="flex flex-wrap gap-5 justify-center">
                    <div class="p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 max-w-xs">
                        <a href="#" class="flex flex-col items-center justify-center hover:text-gray-800 dark:hover:text-gray-400">
                            <img className="w-24 h-24 object-contain" src="https://cdn.ensologic.com/kingone/Black+pen-01.jpg" alt="Stylus Pen" />
                            <h5 class="mt-3 text-lg font-semibold text-center text-gray-900 dark:text-white">Mobile & Laptop Accessories</h5>
                        </a>
                    </div>

                    <div class="p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 max-w-xs">
                        <a href="#" class="flex flex-col items-center justify-center hover:text-gray-800 dark:hover:text-gray-400">
                            <img className="w-24 h-24 object-contain" src={HomeKitchenAccessories} alt="Home Kitchen Accessories" />
                            <h5 class="mt-3 text-lg font-semibold text-center text-gray-900 dark:text-white">Home & Kitchen Accessories & Furnishing</h5>
                        </a>
                    </div>

                    <div class="p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 max-w-xs">
                        <a href="#" class="flex flex-col items-center justify-center hover:text-gray-800 dark:hover:text-gray-400">
                            <img className="w-24 h-24 object-contain" src="https://cdn.ensologic.com/PINKLINE/PL-6666.jpeg" alt="Cosmetics" />
                            <h5 class="mt-3 text-lg font-semibold text-center text-gray-900 dark:text-white">Cosmetics</h5>
                        </a>
                    </div>

                    <div class="p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 max-w-xs">
                        <a href="#" class="flex flex-col items-center justify-center hover:text-gray-800 dark:hover:text-gray-400">
                            <img className="w-24 h-24 object-contain" src={GardenTools} alt="Garden Tools" />
                            <h5 class="mt-3 text-lg font-semibold text-center text-gray-900 dark:text-white">Garden Tools</h5>
                        </a>
                    </div>

                    <div class="p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 max-w-xs">
                        <a href="#" class="flex flex-col items-center justify-center hover:text-gray-800 dark:hover:text-gray-400">
                            <img className="w-24 h-24 object-contain" src={PetAccessories} alt="Pet Accessories" />
                            <h5 class="mt-3 text-lg font-semibold text-center text-gray-900 dark:text-white">Pet Accessories</h5>
                        </a>
                    </div>
                </div>
                
            </section>


            <div id="dashboard" class="flex py-16 px-6 md:px-20  items-center justify-center bg-hero overflow-hidden">
                {/*md:h-screen*/}
                <div class="flex flex-col  gap-6 md:flex-row items-center max-w-8xl">
                    <div class="w-full md:w-1/2 lg:pr-32">
                        <h2 class="text-4xl lg:text-5xl text-center md:text-left text-blue-900 leading-tight font-medium">Beyobo Dashboard</h2>
                        <h3
                            class="mt-6 md:mt-10 text-md lg:text-xl text-center md:text-left text-gray-700 font-light tracking-wider leading-relaxed">
                            Beyobo helps you with compliance check on your product catalogues and also provides certification services (BIS and WPC) to whitelist all your products in Indian marketplaces without any hassles.
                        </h3>
                        <div class="mt-10 flex flex-col sm:flex-row justify-center md:justify-start">
                            <button class="w-full sm:w-40 px-4 py-3 rounded font-semibold text-md bg-blue-500 text-white border-2 border-blue-500">Get started</button>
                        </div>
                    </div>
                    <div class="w-full md:w-1/2 flex justify-center md:justify-end">
                        <a href="https://ibb.co/NmstTHY"><img src="https://i.ibb.co/Yy3P0qj/beyobo-website-0.png" alt="beyobo-website-0" border="0" /></a>
                    </div>
                </div>
            </div>



            <div id="catalogue" class="flex px-6 md:px-20 items-center justify-center bg-white py-16 overflow-hidden">
                <div class="flex flex-col  gap-6 md:flex-row items-center max-w-8xl">
                    <div class="w-full md:w-1/2 flex justify-center md:justify-end">
                        {/*<a href="https://ibb.co/NmstTHY"><img src="https://i.ibb.co/Yy3P0qj/beyobo-website-0.png" alt="beyobo-website-0" border="0" /></a>*/}
                        <a href="https://ibb.co/R4j5833"><img src="https://i.ibb.co/Dgrqd99/beyobo-website-1.png" alt="beyobo-website-1" border="0" /></a>
                        {/*<a href="https://ibb.co/vYx0FvD"><img src="https://i.ibb.co/2N7G9Y5/beyobo-website-2.png" alt="beyobo-website-2" border="0" /></a>*/}
                        {/*<a href="https://ibb.co/NnrC7CR"><img src="https://i.ibb.co/cXw3x3q/beyobo-website-3.png" alt="beyobo-website-3" border="0" /></a>*/}
                        {/*<a href="https://ibb.co/DMQLLvW"><img src="https://i.ibb.co/s1qttNR/beyobo-website-4.png" alt="beyobo-website-4" border="0" /></a>*/}
                    </div>
                    <div class="w-full md:w-1/2 lg:pl-32">
                        <h2 class="text-4xl lg:text-5xl text-center md:text-left text-blue-900 leading-tight font-medium">Product Catalogues</h2>
                        <h3
                            class="mt-6 md:mt-10 text-md lg:text-xl text-center md:text-left text-gray-700 font-light tracking-wider leading-relaxed">
                            Beyobo helps you with compliance check on your product catalogues and also provides certification services (BIS and WPC) to whitelist all your products in Indian marketplaces without any hassles.
                        </h3>
                        <div class="mt-10 flex flex-col sm:flex-row justify-center md:justify-start">
                            <button class="w-full sm:w-40 px-4 py-3 rounded font-semibold text-md bg-blue-500 text-white border-2 border-blue-500">Get started</button>
                        </div>
                    </div>
                </div>
            </div>



            <div id="shipment" class="flex px-6 md:px-20  items-center justify-center bg-hero py-16 overflow-hidden">
                <div class="flex flex-col  gap-6 md:flex-row items-center max-w-8xl">
                    <div class="w-full md:w-1/2 lg:pr-32">
                        <h2 class="text-4xl lg:text-5xl text-center md:text-left text-blue-900 leading-tight font-medium">Shipments</h2>
                        <h3
                            class="mt-6 md:mt-10 text-md lg:text-xl text-center md:text-left text-gray-700 font-light tracking-wider leading-relaxed">
                            Beyobo helps you with compliance check on your product catalogues and also provides certification services (BIS and WPC) to whitelist all your products in Indian marketplaces without any hassles.
                        </h3>
                        <div class="mt-10 flex flex-col sm:flex-row justify-center md:justify-start">
                            <button class="w-full sm:w-40 px-4 py-3 rounded font-semibold text-md bg-blue-500 text-white border-2 border-blue-500">Get started</button>
                        </div>
                    </div>
                    <div class="w-full md:w-1/2 flex justify-center md:justify-end">
                        <a href="https://ibb.co/vYx0FvD"><img src="https://i.ibb.co/2N7G9Y5/beyobo-website-2.png" alt="beyobo-website-2" border="0" /></a>
                    </div>
                </div>
            </div>



            <div id="payment" class="flex px-6 md:px-20 items-center justify-center bg-white py-16 overflow-hidden">
                <div class="flex flex-col  gap-6 md:flex-row items-center max-w-8xl">
                    <div class="w-full md:w-1/2 flex justify-center md:justify-end">
                        <a href="https://ibb.co/NnrC7CR"><img src="https://i.ibb.co/cXw3x3q/beyobo-website-3.png" alt="beyobo-website-3" border="0" /></a>
                    </div>
                    <div class="w-full md:w-1/2 lg:pl-32">
                        <h2 class="text-4xl lg:text-5xl text-center md:text-left text-blue-900 leading-tight font-medium">Easy Payments</h2>
                        <h3
                            class="mt-6 md:mt-10 text-md lg:text-xl text-center md:text-left text-gray-700 font-light tracking-wider leading-relaxed">
                            Beyobo helps you with compliance check on your product catalogues and also provides certification services (BIS and WPC) to whitelist all your products in Indian marketplaces without any hassles.
                        </h3>
                        <div class="mt-10 flex flex-col sm:flex-row justify-center md:justify-start">
                            <button class="w-full sm:w-40 px-4 py-3 rounded font-semibold text-md bg-blue-500 text-white border-2 border-blue-500">Get started</button>
                        </div>
                    </div>
                </div>
            </div>




            <section id="about" className="w-full bg-white py-10">
                <h1 className="text-center text-4xl">About <i><b>Us</b></i></h1>
                <p className="text-center mx-auto px-4 py-7 sm:w-3/4 md:w-2/3 lg:w-1/2 xl:py-7">
                    Beyobo is an ecommerce software which is built and promoted by the company, Ensologic Pvt. Ltd. We have deep expertise in logistics, warehousing, cross-border management and e-commerce in India.
                </p>


                <div className="flex flex-cols-3 flex-wrap gap-5 justify-center">
                    <a href="https://ibb.co/CzRjG9L"><img src="https://i.ibb.co/xDxnTgZ/beyobo-website-5.png" alt="beyobo-website-5" border="0" /></a>
                </div>
            </section>



            <div class="bg-white">
                <div class="mx-auto py-24 sm:px-6 lg:px-8">
                    <div class="relative isolate overflow-hidden bg-gray-900 px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
                        <svg viewBox="0 0 1024 1024" class="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0" aria-hidden="true">
                            <circle cx="512" cy="512" r="512" fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fill-opacity="0.7" />
                            <defs>
                                <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                                    <stop stop-color="#7775D6" />
                                    <stop offset="1" stop-color="#E935C1" />
                                </radialGradient>
                            </defs>
                        </svg>
                        <div class="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
                            <h2 class="text-2xl font-bold tracking-tight text-white sm:text-3xl">Get Transparent Reconsillations - Start using our Supplier Portal Today</h2>
                            {/*<p class="mt-6 text-lg leading-8 text-gray-300">Ac euismod vel sit maecenas id pellentesque eu sed consectetur. Malesuada adipiscing sagittis vel nulla.</p>*/}
                            <div class="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
                                <a href="https://supplier.ensologic.com" class="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">Get started</a>
                                {/*<a href="#" class="text-sm font-semibold leading-6 text-white">Learn more <span aria-hidden="true">→</span></a>*/}
                            </div>
                        </div>
                        <div class="relative mt-16 h-80 lg:mt-8">
                            <img class="absolute left-0 top-0 w-[57rem] max-w-none rounded-md bg-white/5 ring-1 ring-white/10" src="https://i.ibb.co/Yy3P0qj/beyobo-website-0.png" alt="App screenshot" width="1824" height="1080" />
                        </div>
                    </div>
                </div>
            </div>




            <footer className="bg-white lg:grid lg:grid-cols-5">
                <div className="relative block h-32 lg:col-span-2 lg:h-full">
                    <img
                        src="https://ensologic.com/img/Seller-01.jpg"
                        alt=""
                        className="absolute inset-0 h-full w-full object-cover"
                    />
                </div>

                <div className="px-4 py-16 sm:px-6 lg:col-span-3 lg:px-8">
                    <div className="grid grid-cols-1 gap-8 sm:grid-cols-2">
                        <div>
                            <p className="font-medium text-gray-900">Company</p>

                            <p className="text-gray-700 transition hover:opacity-75">We at Beyobo provide, best Export, Logistics, Banking and Payment service partners like; GATI, Dart Global, CITI Bank, DBS Bank, Pingpong and Payoneer, Valuecart aims at fulfilling every other country seller’s dream of selling their products in India by taking end-to-end responsibility of imports, custom clearance, registering sellers in Indian marketplace platforms and providing the access to their seller accounts.
                            </p>
                        </div>

                        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                            <div>
                                <p className="font-medium text-gray-900">Services</p>

                                <ul className="mt-6 space-y-4 text-sm">
                                    <li>
                                        <a href="#" className="text-gray-700 transition hover:opacity-75"> Login </a>
                                    </li>

                                    <li>
                                        <a href="#" className="text-gray-700 transition hover:opacity-75"> Contact us </a>
                                    </li>
                                </ul>
                            </div>

                            <div>
                                <div>
                                    <p>
                                        <span className="text-xs uppercase tracking-wide text-gray-500"> Call us </span>

                                        <a href="#" className="block text-2xl font-medium text-gray-900 hover:opacity-75 sm:text-3xl">
                                            0123456789
                                        </a>
                                    </p>

                                    <ul className="mt-8 space-y-1 text-sm text-gray-700">
                                        <li>Monday to Friday: 10am - 5pm</li>
                                        <li>Weekend: 10am - 3pm</li>
                                    </ul>

                                    <ul className="mt-8 flex gap-6">
                                        <li>
                                            <a
                                                href="#"
                                                rel="noreferrer"
                                                target="_blank"
                                                className="text-gray-700 transition hover:opacity-75"
                                            >
                                                <span className="sr-only">Facebook</span>

                                                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                            </a>
                                        </li>

                                        <li>
                                            <a
                                                href="#"
                                                rel="noreferrer"
                                                target="_blank"
                                                className="text-gray-700 transition hover:opacity-75"
                                            >
                                                <span className="sr-only">Instagram</span>

                                                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                            </a>
                                        </li>

                                        <li>
                                            <a
                                                href="#"
                                                rel="noreferrer"
                                                target="_blank"
                                                className="text-gray-700 transition hover:opacity-75"
                                            >
                                                <span className="sr-only">Twitter</span>

                                                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                                    <path
                                                        d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"
                                                    />
                                                </svg>
                                            </a>
                                        </li>

                                        <li>
                                            <a
                                                href="#"
                                                rel="noreferrer"
                                                target="_blank"
                                                className="text-gray-700 transition hover:opacity-75"
                                            >
                                                <span className="sr-only">GitHub</span>

                                                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                            </a>
                                        </li>

                                        <li>
                                            <a
                                                href="#"
                                                rel="noreferrer"
                                                target="_blank"
                                                className="text-gray-700 transition hover:opacity-75"
                                            >
                                                <span className="sr-only">Dribbble</span>

                                                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="mt-12 border-t border-gray-100 pt-12">
                        <div className="flex flex-col sm:flex-row sm:items-center justify-between">
                            <ul className="flex flex-wrap gap-4 text-xs justify-center sm:justify-start">
                                <li>
                                    <a href="https://ensologic.com/terms-and-conditions.html" className="text-gray-500 transition hover:opacity-75"> Terms & Conditions </a>
                                </li>
                                <li>
                                    <a href="https://ensologic.com/privacy-policy.html" className="text-gray-500 transition hover:opacity-75"> Privacy Policy </a>
                                </li>
                            </ul>
                            <p className="mt-4 sm:mt-0 text-xs text-gray-500 text-center sm:text-left">
                                &copy; 2024. Ensologic. All rights reserved.
                            </p>
                        </div>
                    </div>

                </div>
            </footer>
        </>
    )
};


export default Website;





//<div>
//    <h1>SKU Names</h1>
//    <ul>
//        {skuNames.map((sku, index) => (
//            <li key={index}>{sku.name}</li>
//        ))}
//    </ul>
//</div>