import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter'
import React, { useState, useEffect, useContext, useCallback } from 'react';
import DataModal from '../../../../components/modal';
import AuthContext from '../../../../context/AuthContext';

const AdminViewsDataGrid = () => {

    const columns = [
    
      
      //{ name: 'sku_id', defaultFlex: 1, header: 'Sku Id', type: 'number', filterEditor: NumberFilter },
      { name: 'name', defaultFlex: 1, header: 'Channel Name',type:'string'},
      { name: 'platform', defaultFlex: 1, header: 'Platform',type:'string'}
      
    
      
     // {name: 'pending', defaultFlex:1,header:'Pending', type:Number,render: ({ value }) => formatCurrency(value)},
  ];



        const defaultfilter = [
          
          //{ name: 'sku_id', operator: 'gte', type: 'number' ,value: 0},
          { name: 'name', operator: 'contains', type: 'number' ,value: ''},
          { name: 'platform', operator: 'contains', type: 'number' ,value: ''}
             //{ name: 'sku_id', operator: 'gte', type: 'number' ,value: 0},
         
          //{ name: 'pending', operator: 'gte', type: 'number' ,value: 0},
  
      ];



    const [filterValue, setFilterValue] = useState(defaultfilter);
    const [dataSource, setDataSource] = useState([]);
    const { authToken } = useContext(AuthContext);

    const loadData = useCallback(() => {
        const newDataSource = () => {
            const limit = 50;
            const url = `${process.env.REACT_APP_BASE_API_URL}/channels/` + '?limit=' + limit;
            return fetch(url, {
                headers: {
                    'Authorization': `Token ${authToken}`
                }
            })
                .then(response => {
                    const totalCount = response.headers.get('X-Total-Count');
                    return response.json().then(data => {
                        return { data, count: totalCount * 1 };
                    })
                });
        }

        setDataSource(newDataSource)
    }, []);

    useEffect(() => {
        loadData();
    }, [loadData]); // Ensure loadData is stable and doesn't change unless necessary



    const gridStyle = { minHeight: 550 }


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);

    const onRowClick = useCallback((rowProps) => {
        setSelectedRowData(rowProps.data);
        setIsModalOpen(true);
    }, []);

    return (
        <div>

            <ReactDataGrid
                style={gridStyle}
                idProperty="id"
                pagination='local'
                columns={columns}
                dataSource={dataSource}
                defaultLimit={50}
                defaultFilterValue={defaultfilter}
                onFilterValueChange={setFilterValue}
                onRowClick={onRowClick}
            />

            <DataModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                data={selectedRowData}
                columns={columns}
            />
        </div>
    )
};


export default AdminViewsDataGrid;