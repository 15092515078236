import React, { createContext, useState, useEffect, useContext } from 'react';
import { AuthContext } from './AuthContext'; // or wherever your AuthContext is
// import {UserContext} from './UserContext';
// Create Context Object
export const CoreUserContext = createContext();

// Create a provider for components to consume and subscribe to changes
export const CoreUserProvider = (props) => {
    const [coreUser, setCoreUser] = useState(null); 
  

    // Fetch data from the backend
    const { authToken} = useContext(AuthContext);
    // const {user} =useContext(UserContext);
    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_API_URL}/coreuser/`,{
            headers: {
                'Authorization': `Token ${authToken}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setCoreUser(data); // Assuming the API returns an array of products
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });
    }, [authToken]);  // Rerun when user or authToken changes

    return (
        <CoreUserContext.Provider value={{ coreUser, setCoreUser}}>
            {props.children}
        </CoreUserContext.Provider>
    );

};

export default CoreUserContext;
