import React, { useState, useContext, useEffect } from 'react';
import AuthContext from "context/AuthContext";
import { useToast } from '../../../components/toast';
import { DialogContext } from '../../../components/dialog';



const ChannelForm = () => {
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [submitError, setSubmitError] = useState(false);
    const { authToken } = useContext(AuthContext);
    const [companies, setCompanies] = useState([]);
    const [skus, setSkus] = useState([]);
    const [selectedVariants, setSelectedVariants] = useState([]);
    const [displayLimit, setDisplayLimit] = useState([]);
    const { toastSuccess, toastError } = useToast();


    const [formChannelData, setFormChannelData] = useState({
        name: '',
        //platform: '',
        selectedChannel: '',
        //oms_channel_id: '',
        marketplace: '',
        selectedCompany: '',
        selectedSkus: [],
        selectedAccount: '',
    });

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_API_URL}/company/`, {
            headers: {
                'Authorization': `Token ${authToken}`
            }
        })
            .then(response => response.json())
            .then(data => {
                setCompanies(data);
            })
            .catch(error => {
                console.error('Error fetching companies:', error);
            });
    }, [authToken]);

    const fetchSkusForCompany = (companyName) => {
        fetch(`${process.env.REACT_APP_BASE_API_URL}/sku/getSkusForCompany/?company_name=${encodeURIComponent(companyName)}`, {
            headers: {
                'Authorization': `Token ${authToken}`,
            }
        })
            .then(response => response.json())
            .then(data => {
                const skusWithLabel = data.map(sku => ({
                    ...sku,
                    label: sku.name // Adjust this based on your actual data structure
                }));
                setSkus(skusWithLabel);
            })
            .catch(error => {
                console.error('Error fetching SKUs:', error);
            });
    };


    const handleChannelCompanyChange = (event) => {
        const selectedCompany = event.target.value;
        if (!selectedCompany) {
            console.error('No company selected');
            return; // Optionally show an error message or disable fetching until selection
        }

        setFormChannelData(prev => ({
            ...prev,
            selectedCompany: selectedCompany,
        }));

        fetchSkusForCompany(selectedCompany);
        fetchChannelsForCompany(selectedCompany); // Fetch channels for the selected company
    };


    const handleChannelInputChange = (event) => {
        const { name, value } = event.target;
        setFormChannelData(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSkuClick = (clickedSku) => {
        const isAlreadySelected = formChannelData.selectedSkus.some(sku => sku.id === clickedSku.id);

        if (isAlreadySelected) {
            // Remove the SKU if it's already selected
            setFormChannelData(prev => ({
                ...prev,
                selectedSkus: prev.selectedSkus.filter(sku => sku.id !== clickedSku.id),
            }));
        } else {
            // Add the SKU, ensuring the 'id' is included
            setFormChannelData(prev => ({
                ...prev,
                selectedSkus: [...prev.selectedSkus, clickedSku],
            }));
        }
    };



    const handleChannelSubmit = async (event) => {
        event.preventDefault();
        let bodyFormData = new FormData();

        // Loop through formChannelData except 'selectedSkus' and append each to FormData
        Object.entries(formChannelData).forEach(([key, value]) => {
            if (key !== 'selectedSkus') {
                bodyFormData.append(key, value);
            } else {
                // Serialize 'selectedSkus' as a JSON string and append
                bodyFormData.append(key, JSON.stringify(value.map(sku => ({
                    ...sku,
                    // Ensure we are correctly handling potential undefined 'id's
                    id: sku.id || '' // Adjust this based on how you want to handle undefined ids
                }))));
            }
        });

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/channels/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${authToken}` // Ensure this matches what your API expects
                },
                body: bodyFormData,
            });

            //if (!response.ok) {
            //    const errorData = await response.json();
            //    throw new Error(`Submission failed: ${errorData.message}`); // More detailed error message
            //}
            //setSubmitSuccess(true);
            //setSubmitError(false);
            //setFormChannelData({
            //    name: '',
            //    platform: '',
            //    oms_channel_id: '',
            //    selectedCompany: '',
            //    selectedSkus: [],
            //});


            if (!response.ok) {
                const errorData = await response.json();
                if (errorData.errors && errorData.errors.length > 0) {
                    const errorMessage = errorData.errors.map(error => error.message).join(", ");
                    toastError(`Submission failed: ${errorMessage}`);
                } else {
                    // Fallback to a general error message if no specific errors are provided
                    toastError(`Submission failed: ${errorData.message || "Unknown error"}`);
                }
                setSubmitError(true);
            } else {
                toastSuccess("Channel submission successful!");
                setSubmitSuccess(true);
                setSubmitError(false);
                setFormChannelData({
                    name: '',
                    //platform: '',
                    selectedChannel: '',
                    //oms_channel_id: '',
                    selectedCompany: '',
                    selectedSkus: [],
                });
            }

        } catch (error) {
            toastError('Submission failed');
            setSubmitSuccess(false);
            setSubmitError(true);
        }
    };

    const [accounts, setAccounts] = useState([]);
    const [channels, setChannels] = useState([]); // State for channels

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_API_URL}/channels/getChannelAccounts/`, {
            headers: {
                'Authorization': `Token ${authToken}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setAccounts(data); // Assuming the API returns an array of products
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });
    }, [authToken]);  // Rerun when user or authToken changes

    const handleChannelAccountChange = (event) => {
        const selectedAccount = event.target.value;
        setFormChannelData(prev => ({
            ...prev,
            selectedAccount: selectedAccount,
        }));
        // Do not fetch channels here; it's already handled in handleChannelCompanyChange
    };


    const handleChannelChannelsChange = (event) => {
        const selectedChannel = event.target.value;
        const selectedCompany = formChannelData.selectedCompany;
        setFormChannelData(prev => ({
            ...prev,
            selectedChannel: selectedChannel,
        }));
        fetchAccountsForChannel(selectedChannel, selectedCompany);
        // Do not fetch channels here; it's already handled in handleChannelCompanyChange
    };


    // Function to fetch accounts based on the selected channel
    const fetchAccountsForChannel = (channelName, companyName) => {
        if (!channelName) {
            console.error('Channel name is required for fetching accounts.');
            return;
        }

        fetch(`${process.env.REACT_APP_BASE_API_URL}/channels/getChannelAccounts/?channel_name=${encodeURIComponent(channelName)}&company_name=${encodeURIComponent(companyName)}`, {
            headers: {
                'Authorization': `Token ${authToken}`,
            }
        })
            .then(response => response.json())
            .then(data => {
                setAccounts(data);
            })
            .catch(error => {
                console.error('Error fetching accounts:', error);
            });
    };


    // Function to fetch channels for a selected company
    const fetchChannelsForCompany = (companyName) => {
        if (!companyName) {
            console.error('Company name is required for fetching channels.');
            return;
        }

        fetch(`${process.env.REACT_APP_BASE_API_URL}/channels/platforms/?company_name=${encodeURIComponent(companyName)}`, {
            headers: {
                'Authorization': `Token ${authToken}`,
            }
        })
            .then(response => response.json())
            .then(data => {
                const channelsWithLabel = data.map(channelArray => ({
                    name: channelArray[0], // Assuming the channel name is the first element in the array
                    label: channelArray[0]
                }));
                setChannels(channelsWithLabel);
            })
            .catch(error => {
                console.error('Error fetching channels:', error);
            });
    };


    return (
        <>
            <div>
                <form id="catalogue_variant" onSubmit={handleChannelSubmit}>


                    <div className="items-center w-full mb-4">
                        <label htmlFor="company-select" className="block text-sm font-medium text-gray-700">Select Company</label>
                        <select required id="company-select" value={formChannelData.selectedCompany} className="mt-1 p-2 border-2 border-gray-300 w-full rounded-lg cursor-pointer" onChange={handleChannelCompanyChange}>

                            <option value="">Select a Company</option>
                            {companies.map((company) => (
                                <option value={company.name} key={company.name}>
                                    {company.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="items-center w-full mb-4">
                        <label htmlFor="platform-select" className="block text-sm font-medium text-gray-700">Select Platform</label>
                        <select
                            required
                            id="platform-select"
                            value={formChannelData.selectedChannel}
                            className="mt-1 p-2 border-2 border-gray-300 w-full rounded-lg cursor-pointer"
                            onChange={handleChannelChannelsChange} // Ensure this function is correctly handling the changes
                        >
                            <option value="">Select a Platform</option>
                            {channels.map((channel) => (
                                <option value={channel.name} key={channel.name}>
                                    {channel.label}
                                </option>
                            ))}
                        </select>
                    </div>



                    <div className="items-center w-full mb-4">
                        <div className="flex items-center justify-between">
                            <label htmlFor="account-select" className="block text-sm font-medium text-gray-700">Select Account</label>
                        </div>

                        <select
                            required
                            id="account-select"
                            value={formChannelData.selectedAccount}
                            className="mt-1 p-2 border-2 border-gray-300 w-full rounded-lg cursor-pointer"
                            onChange={handleChannelAccountChange}
                        >
                            <option value="">Select an Account</option>
                            {accounts.map((account) => (
                                <option value={account.email} key={account.email}>
                                    {account.email}
                                </option>
                            ))}
                        </select>
                    </div>


                    <div className="items-center my-4">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name *</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            required
                            value={formChannelData.name}
                            onChange={handleChannelInputChange}
                            //onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                            className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                            placeholder="Enter name"
                        />
                    </div>

                    {/* SKU Selection Section */}
                    <div className="mb-4">
                        <div className="flex items-center justify-between">
                            <label className="block text-sm font-medium text-gray-700">Select SKUs</label>
                        </div>

                        <div className="mt-1 border-2 border-gray-300 w-full rounded-lg overflow-y-auto" style={{ maxHeight: '150px' }}>
                            {skus.map((sku) => (
                                <button
                                    type="button"
                                    key={sku.id}
                                    onClick={() => handleSkuClick(sku)}
                                    className={`p-2 w-full text-left ${formChannelData.selectedSkus.some(selectedSku => selectedSku.id === sku.id) ? 'bg-gray-200' : ''}`}
                                >
                                    {sku.name}
                                </button>
                            ))}
                        </div>
                    </div>

                    {/* Selected SKUs Section */}
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-3">Selected SKUs:</label>

                        <div className="flex justify-between mx-[56px] my-3">
                            <p className="block font-medium text-gray-700">SKU Name</p>
                            <p className="block font-medium text-gray-700">Listing SKU</p>
                        </div>

                        <div className="flex flex-col gap-2">
                            {formChannelData.selectedSkus.map((sku, index) => (
                                <div key={index} className="flex justify-between items-center gap-2 p-2 border rounded">
                                    {sku.name}
                                    <input
                                        type="text"
                                        placeholder="SKU details..."
                                        value={sku.listing_sku || ''}
                                        onChange={(e) => {
                                            // Create a new array with updated quantities
                                            const updatedSkus = [...formChannelData.selectedSkus];
                                            updatedSkus[index] = { ...sku, listing_sku: e.target.value };
                                            setFormChannelData(prevFormSkuData => ({
                                                ...prevFormSkuData,
                                                selectedSkus: updatedSkus
                                            }));
                                        }}
                                        className="w-1/3 border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                    />
                                </div>
                            ))}
                        </div>
                    </div>





                    <div className="flex justify-end mt-4">
                        <button
                            type="submit"
                            className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
                        >
                            Add Channel
                        </button>
                    </div>
                </form>
                {submitSuccess && <div>Your form has been submitted successfully!</div>}
                {submitError && <div>Error submitting form. Please try again.</div>}
            </div>
        </>
    );
};



const AccountSupplierDashboard = () => {
    const { authToken } = useContext(AuthContext);
    const [formAccountData, setFormAccountData] = useState({
        channel: '',
        name: '',
        email_id: '',
    });

    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [submitError, setSubmitError] = useState(false);
    const { toastSuccess, toastError } = useToast();

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormAccountData(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleAccountSubmit = async (event) => {
        event.preventDefault();
        let bodyFormData = new FormData();
        Object.entries(formAccountData).forEach(([key, value]) => {
            bodyFormData.append(key, value);
        });

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/account/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${authToken}`
                },
                body: bodyFormData,
            });

            if (!response.ok) {
                const errorData = await response.json();
                const errorMessage = errorData.errors ? errorData.errors.map(error => error.message).join(", ") : errorData.message || "Unknown error";
                toastError(`Submission failed: ${errorMessage}`);
                setSubmitError(true);
                setSubmitSuccess(false);
            } else {
                toastSuccess("Channel submission successful!");
                setSubmitSuccess(true);
                setSubmitError(false);
                setFormAccountData({
                    channel: '',
                    name: '',
                    email_id: '',
                });
            }
        } catch (error) {
            toastError(`Submission failed: ${error.toString()}`);
            setSubmitSuccess(false);
            setSubmitError(true);
        }
    };


    const { openDialog } = useContext(DialogContext);

    const openChannelFormDialog = () => {
        openDialog(<ChannelForm />, 'medium', true);
    };


    return (
        <div>
            <form onSubmit={handleAccountSubmit}>
                <div className="justify-between items-center mb-6">
                    {/* Form fields here */}
                    <div className="items-center my-4">
                        <div className="flex items-center justify-between">
                            <label className="block text-sm font-medium text-gray-700">Channel *</label>

                            <button onClick={openChannelFormDialog} className="bg-gray-500 hover:bg-brand-400 text-white font-bold py-1 px-2 rounded">
                                Add Channel
                            </button>
                        </div>

                        <input
                            type="text"
                            id="channel"
                            name="channel"
                            required
                            value={formAccountData.channel}
                            onChange={handleInputChange}
                            className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                            placeholder="Enter Channel"
                        />
                    </div>

                    <div className="items-center my-4">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name *</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            required
                            value={formAccountData.name}
                            onChange={handleInputChange}
                            className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                            placeholder="Enter name"
                        />
                    </div>

                    <div className="items-center my-4">
                        <label htmlFor="email_id" className="block text-sm font-medium text-gray-700">Email ID *</label>
                        <input
                            type="text"
                            id="email_id"
                            name="email_id"
                            required
                            value={formAccountData.email_id}
                            onChange={handleInputChange}
                            className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                            placeholder="Enter Email ID"
                        />
                    </div>
                </div>
                <div className="flex justify-end mt-4">
                    <button type="submit" className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700">
                        Add Account
                    </button>
                </div>
            </form>
            {submitSuccess && <div className="text-green-600">Your form has been submitted successfully!</div>}
            {submitError && <div className="text-red-600">Error submitting form. Please try again.</div>}
        </div>
    );
};

export default AccountSupplierDashboard;









                    //import React, { useState, useEffect, useContext } from 'react';
//import AuthContext from "context/AuthContext";
//import { useToast } from '../../../components/toast';


//const AccountSupplierDashboard = () => {
//    const { authToken } = useContext(AuthContext);
//    const [formAccountData, setFormAccountData] = useState({
//        channel: '',
//        name: '',
//        email_id: '',
//    });

//    const [submitSuccess, setSubmitSuccess] = useState(false);
//    const [submitError, setSubmitError] = useState(false);


//    const handleAccountChange = (event) => {
//        setFormAccountData(prevFormAccountData => ({
//            ...prevFormAccountData,
//            selectedCompany: event.target.value
//        }));
//    };


//    const { toastSuccess, toastError } = useToast();



//    const handleAccountSubmit = async (event) => {
//        event.preventDefault();
//        let bodyFormData = new FormData();

//        try {
//            const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/account/`, {
//                method: 'POST',
//                headers: {
//                    'Authorization': `Token ${authToken}` // Ensure this matches what your API expects
//                },
//                body: bodyFormData,
//            });


//            if (!response.ok) {
//                const errorData = await response.json();
//                if (errorData.errors && errorData.errors.length > 0) {
//                    const errorMessage = errorData.errors.map(error => error.message).join(", ");
//                    toastError(`Submission failed: ${errorMessage}`);
//                } else {
//                    // Fallback to a general error message if no specific errors are provided
//                    toastError(`Submission failed: ${errorData.message || "Unknown error"}`);
//                }
//                setSubmitError(true);
//            } else {
//                toastSuccess("Channel submission successful!");
//                setSubmitSuccess(true);
//                setSubmitError(false);
//                setFormAccountData({
//                    channel: '',
//                    name: '',
//                    email_id: '',
//                });
//            }

//        } catch (error) {
//            toastError('Submission failed');
//            setSubmitSuccess(false);
//            setSubmitError(true);
//        }
//    };

//    const handleAccountInputChange = (event) => {
//        const { name, value } = event.target;
//        setFormAccountData(prev => ({
//            ...prev,
//            [name]: value,
//        }));
//    };





//    return (
//        <div>
//            <form id="catalogue_variant" onSubmit={handleAccountSubmit}>

//                <div className="justify-between items-center mb-6">
//                    <div className="items-center my-4">
//                        <label htmlFor="channel" className="block text-sm font-medium text-gray-700">Channel *</label>
//                        <input
//                            type="text"
//                            id="channel"
//                            name="channel"
//                            required
//                            value={formAccountData.channel}
//                            onChange={handleAccountInputChange}
//                            //onChange={(e) => handleInputChange(e.target.name, e.target.value)}
//                            className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
//                            placeholder="Enter Channel"
//                        />
//                    </div>

//                    <div className="items-center my-4">
//                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name *</label>
//                        <input
//                            type="text"
//                            id="name"
//                            name="name"
//                            required
//                            value={formAccountData.name}
//                            onChange={handleAccountInputChange}
//                            //onChange={(e) => handleInputChange(e.target.name, e.target.value)}
//                            className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
//                            placeholder="Enter name"
//                        />
//                    </div>

//                    <div className="items-center my-4">
//                        <label htmlFor="email_id" className="block text-sm font-medium text-gray-700">Email ID *</label>
//                        <input
//                            type="text"
//                            id="email_id"
//                            name="email_id"
//                            required
//                            value={formAccountData.email_id}
//                            onChange={handleAccountInputChange}
//                            //onChange={(e) => handleInputChange(e.target.name, e.target.value)}
//                            className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
//                            placeholder="Enter Email ID"
//                        />
//                    </div>
//                </div>

//                <div className="flex justify-end mt-4">
//                    <button
//                        type="submit"
//                        className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
//                    >
//                        Add Account
//                    </button>
//                </div>
//            </form>

//            {submitSuccess && <div className="text-green-600">Your form has been submitted successfully!</div>}
//            {submitError && <div className="text-red-600">Error submitting form. Please try again.</div>}
//        </div>
//    );
//};

//export default AccountSupplierDashboard;




