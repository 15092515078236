import React, { useState } from 'react';
import { MdCancel, MdCheckCircle } from 'react-icons/md';
import CustomTable from 'components/customTable';
import OrderDetail from 'shared/order/orderDetail';


const ExampleParentComponent = () => {

  return (
    <>
    <OrderDetail orderId={35328}/>
    </>
  );
};

export default ExampleParentComponent;
