import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import "./CostTable.css";
import { AuthContext } from "../../../src/context/AuthContext";

const CostTable = ({ className = "" }) => {
  const [topSellingSkus, setTopSellingSkus] = useState([]);
  const { authToken } = useContext(AuthContext);
  const [leastSellingSkus, setLeastSellingSkus] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_API_URL}/amazonskuanalysis/`, {
      headers: {
        Authorization: `Token ${authToken}`,
      },
    }) // Adjust this URL based on your actual endpoint
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setTopSellingSkus(data.top_selling_skus);
        setLeastSellingSkus(data.least_selling_skus);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError(error.toString());
        setLoading(false);
      });
  }, [authToken]);

  return (
    <div className={`cost-table ${className}`}>
      <div className="frame-parent2">
        <div className="rectangle-copy-6-parent">
          <img
            className="rectangle-copy-61"
            alt=""
            src="/rectangle-copy-6.svg"
          />
          <input className="search" placeholder="Search" type="text" />
          <div className="search-icon-container">
            <img
              className="combined-shape-icon"
              alt=""
              src="/combined-shape.svg"
            />
          </div>
        </div>
        <div className="filter-button">
          <img
            className="group-11-1"
            loading="lazy"
            alt=""
            src="/group-11-1.svg"
          />
        </div>
      </div>
      <div className="box w-full rounded-lg bg-white p-5 shadow-md">
        <h3 className="mb-4 text-center text-lg font-semibold">
          Top 5 Selling SKUs on Amazon
        </h3>
        <div className="mb-4 flex justify-between font-medium">
          <span>SKU Name</span>
          <span>Total Sale</span>
        </div>
        <ul>
          {topSellingSkus.map((sku, index) => (
            <li key={index} className="flex justify-between border-b py-4">
              <span className="truncate">{sku.Sku}</span>
              <span>₹{sku.total_sales.toFixed(2)}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className="box w-full rounded-lg bg-white p-5 shadow-md">
        <h3 className="mb-4 text-center text-lg font-semibold">
          Bottom 5 Selling SKUs on Amazon
        </h3>
        <div className="mb-4 flex justify-between font-medium">
          <span>SKU Name</span>
          <span>Total Sale</span>
        </div>
        <ul>
          {leastSellingSkus.map((sku, index) => (
            <li key={index} className="flex justify-between border-b py-4">
              <span className="mr-4">{sku.Sku}</span>
              <span>₹{sku.total_sales.toFixed(2)}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

CostTable.propTypes = {
  className: PropTypes.string,
};

export default CostTable;
