import React, { useState, useEffect, useCallback, useContext } from 'react';
import useApi from "../../hooks/useApi";
import CustomTable from 'components/customTable';
import CustomSelect from "components/customSelect";
import useApiPaths from "../../hooks/useApiPath";
import { useDialog } from "components/dialog";
import { useNavigate } from 'react-router-dom';
import usePath from "hooks/usePath";
import { useToast } from "components/toast";
import AuthContext from '../../context/AuthContext';




const VariantForm = () => {
    const { authToken } = useContext(AuthContext);
    const [formVariantData, setFormVariantData] = useState({
        country: '',
        name: '',
        description: '',
        variant_code: '',
        hsn: '',
        selectedCompany: '',
    });
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [submitError, setSubmitError] = useState(false);
    const [companies, setCompanies] = useState([]);

    const handleVariantInputChange = (e) => {
        const { name, value } = e.target;
        setFormVariantData(prevFormVariantData => ({
            ...prevFormVariantData,
            [name]: value
        }));
    };

    const handleVariantCompanyChange = (event) => {
        setFormVariantData(prevFormVariantData => ({
            ...prevFormVariantData,
            selectedCompany: event.target.value
        }));
    };
    const { toastSuccess, toastError } = useToast();

    const handleVariantSubmit = async (event) => {
        event.preventDefault();

        let bodyFormData = new FormData();
        bodyFormData.append('company_name', formVariantData.selectedCompany);

        for (let key in formVariantData) {
            if (key !== 'selectedCompany') {
                bodyFormData.append(key, formVariantData[key]);
            }
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/product/create_with_company_name/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${authToken}`,
                },
                body: bodyFormData,
            });

            if (response.ok) {
                toastSuccess("Your form has been submitted successfully!");
                setSubmitSuccess(true);
                setSubmitError(false);
                setFormVariantData({
                    country: '',
                    name: '',
                    description: '',
                    variant_code: '',
                    hsn: '',
                    selectedCompany: '',
                });
            } else {
                toastError("Error submitting form. Please try again.");
                setSubmitSuccess(false);
                setSubmitError(true);
            }
        } catch (error) {
            toastError("Error submitting form. Please try again.");
            setSubmitSuccess(false);
            setSubmitError(true);
        }
    };

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_API_URL}/company/`, {
            headers: {
                'Authorization': `Token ${authToken}`
            }
        })
            .then(response => response.json())
            .then(data => {
                setCompanies(data);
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });
    }, [authToken]);

    return (
        <>
            <form id="catalogue_variant" onSubmit={handleVariantSubmit}>
                <div className="items-center w-full my-4">
                    <label htmlFor="company-select" className="block text-sm font-medium text-gray-700">Select Company *</label>
                    <select required id="company-select" value={formVariantData.selectedCompany} className="mt-1 p-2 border-2 border-gray-300 w-full rounded-lg cursor-pointer" onChange={handleVariantCompanyChange}>
                        <option value="">Select a Company</option>
                        {companies.map((company) => (
                            <option value={company.name} key={company.name}>
                                {company.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                    <div>
                        <label htmlFor="country" className="block text-sm font-medium text-gray-700">Country of origin</label>
                        <input
                            type="text"
                            id="country"
                            name="country"
                            value={formVariantData.country}
                            onChange={handleVariantInputChange}
                            className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                            placeholder="Enter your Country"
                        />
                    </div>
                    <div>
                        <label htmlFor="product name" className="block text-sm font-medium text-gray-700">Product name *</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formVariantData.name}
                            onChange={handleVariantInputChange}
                            required
                            className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                            placeholder="Enter your product name"
                        />
                    </div>
                </div>

                <div className="mb-4">
                    <label htmlFor="description" className="block text-sm font-medium text-gray-700">Product description</label>
                    <textarea
                        id="description"
                        name="description"
                        value={formVariantData.description}
                        onChange={handleVariantInputChange}
                        className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                        placeholder="Product description"
                    />
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                    <div>
                        <label htmlFor="variant_code" className="block text-sm font-medium text-gray-700">Variant code *</label>
                        <input
                            type="text"
                            id="variant_code"
                            name="variant_code"
                            value={formVariantData.variant_code}
                            onChange={handleVariantInputChange}
                            required
                            className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                            placeholder="Enter the code"
                        />
                    </div>
                    <div>
                        <label htmlFor="hsn" className="block text-sm font-medium text-gray-700">HSN Code *</label>
                        <input
                            type="number"
                            id="hsn"
                            name="hsn"
                            required
                            value={formVariantData.hsn}
                            onChange={handleVariantInputChange}
                            className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                            placeholder="Enter HSN Code"
                        />
                    </div>
                </div>

                <div className="flex justify-end mt-4">
                    <button
                        type="submit"
                        className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
                    >
                        Add Product
                    </button>
                </div>
            </form>
            {submitSuccess && <div className="text-green-600">Your form has been submitted successfully!</div>}
            {submitError && <div className="text-red-600">Error submitting form. Please try again.</div>}
        </>
    );
};



const CreatePurchaseOrder = ({ orderCreated }) => {
    const api = useApi();
    const { openDialog, closeDialog } = useDialog();
    const apiPaths = useApiPaths();
    const [warehouses, setWarehouses] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [skus, setSkus] = useState([]);
    const [companyId, setCompanyId] = useState(0);
    const [addressId, setAddressId] = useState(0);
    const [selectedVariants, setSelectedVariant] = useState([]);
    const { toastSuccess, toastInfo, toastError } = useToast();


    useEffect(() => {
        api.get(apiPaths.warehouses)
            .then(r => {
                setWarehouses(r);
            });

        api.get(apiPaths.suppliers)
            .then(r => {
                console.log(r);
                setSuppliers(r);
            });

    }, []);

    useEffect(() => {
        if (companyId > 0) {
            api.get(apiPaths.companyVariants(companyId))
                .then(r => {
                    setSkus(r);
                });
        }
    }, [companyId]);

    const handleAddressChange = useCallback((option) => {
        setAddressId(option.value);
    }, []);

    const handleSupplierChange = useCallback((option) => {
        setCompanyId(option.value);
    }, []);

    const addSelectedVariant = (variant) => {
        const isVariantPresent = selectedVariants.some(existingVariant => (existingVariant?.value ?? 0) === (variant?.value ?? -1));
        if (!isVariantPresent) {
            setSelectedVariant([...selectedVariants, { ...variant, quantity: 1 }]);
        }
    }

    const removeSelectedVariant = (variant) => {
        setSelectedVariant(selectedVariants.filter(item => item !== variant));
    };

    const handleVariantQuantityChange = (index, quantity) => {
        const updatedVariants = [...selectedVariants];
        updatedVariants[index].quantity = quantity > 0 ? Number(quantity) : "";
        setSelectedVariant(updatedVariants);
    };

    const createPurchaseOrder = () => {

        if (selectedVariants.some(v => (typeof (v?.quantity ?? 0) !== 'number') || (v?.quantity ?? 0) <= 0)) {
            alert('Item quantity cannot be less than 1');
            return;
        }

        const orderData = {
            orderDeliveryAddressId: addressId,
            companyId: companyId,
            items: selectedVariants.map(variant => ({
                skuId: variant.value,
                quantity: variant.quantity
            }))
        };

        api.post(apiPaths.createPurchaseOrder, orderData).then(r => {
            if (r.orderId > 0) {
                toastSuccess(`Purchase Order Successfully Created!!! OrderId: ${r?.orderId ?? 0}`)
                orderCreated();
                closeDialog();
            }
        })
    }



    const openVariantFormDialog = () => {
        openDialog(<VariantForm />, 'medium', true);
    };

    return (
        <>
            <label htmlFor="warehouse">Choose Delivery Address:</label>
            <CustomSelect
                name="warehouse"
                onChange={(option) => handleAddressChange(option)}
                options={warehouses?.map(w => {
                    return {
                        value: w?.address?.id ?? 0,
                        label: `${w?.name ?? ""}, ${w?.address?.address_one ?? ''}, ${w?.address?.address_two ?? ''}, ${w?.address?.city ?? ''}`,
                        customLabel: (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {`${w?.name ?? ""}, ${w?.address?.address_one ?? ''}, ${w?.address?.address_two ?? ''}, ${w?.address?.city ?? ''}`}
                            </div>
                        )
                    }
                })}
                isSearchable={true}
                className="block w-full mt-1"
            />

            <br />

            <label htmlFor="supplier">Choose Supplier:</label>
            <CustomSelect
                name="supplier"
                onChange={(option) => handleSupplierChange(option)}
                options={suppliers.map(w => {
                    return {
                        value: w?.id ?? 0,
                        label: `${w?.name ?? ''}`,
                        customLabel: (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {`${w?.name ?? ""}`}
                            </div>
                        )
                    }
                })}
                isSearchable={true}
                className="block w-full mt-1"
            />

            <br />

            

            <div className="flex items-center justify-between">
                <label className="block text-sm font-medium text-gray-700">Choose Variant *</label>
                <button onClick={openVariantFormDialog} className="bg-gray-500 hover:bg-brand-400 text-white font-bold py-1 px-2 rounded">
                    Add Variant
                </button>
            </div>

            <CustomSelect
                name="skus"
                stickOption={false}
                onChange={(option) => { addSelectedVariant(option); }}
                options={skus.filter(s => selectedVariants.findIndex(k => (k?.value ?? 0) === (s?.id ?? -1)) === -1).map(w => {
                    return {
                        value: w?.id ?? 0,
                        label: `${w?.name ?? ''} ,${w?.sku_code ?? ''}`,
                        customLabel: (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {`${w?.name ?? ''} ,${w?.sku_code ?? ''}`}
                            </div>
                        )
                    }
                })}
                isSearchable={true}
                className="block w-full mt-1"
            />


            {
                ((selectedVariants?.length ?? 0) > 0) ? (<><br />
                    <div className="flex gap-10 mr-6 justify-end mt-3">
                        <strong>Quantity</strong>
                    </div></>) :
                    (null)
            }

            {selectedVariants.map((variant, index) => (
                <div key={index} className="flex items-center my-5 bg-gray-100 p-2 rounded-lg relative">
                    <span className="flex-grow">{variant?.label ?? ''}</span>
                    <input
                        type="number"
                        value={variant?.quantity}
                        onChange={(e) => handleVariantQuantityChange(index, e.target.value)}
                        className="w-20 h-8 text-center border-2 border-gray-300 rounded focus:outline-none mx-2"
                        min="0"
                    />

                    <span
                        onClick={() => removeSelectedVariant(variant)}
                        className="absolute right-0 top-[-8px] cursor-pointer text-red-500 hover:text-red-700 text-lg">
                        <svg width="14px" height="14px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                            <g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM15.75 12C15.75 12.4142 15.4142 12.75 15 12.75H9C8.58579 12.75 8.25 12.4142 8.25 12C8.25 11.5858 8.58579 11.25 9 11.25H15C15.4142 11.25 15.75 11.5858 15.75 12Z" fill="#ff0000"></path> </g></svg>
                    </span>
                </div>
            ))}




            <div className="flex justify-end mt-4">
                <button onClick={() => { closeDialog() }} className="bg-gray-200 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-300 mr-2">
                    Cancel
                </button>
                {
                    ((selectedVariants?.length ?? 0) > 0) ? (
                        <button onClick={() => { createPurchaseOrder() }} className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700">
                            Submit
                        </button>) : (null)
                }
            </div>

        </>
    );

}

export default CreatePurchaseOrder;



/*<label htmlFor="skus">Choose Variants:</label>*/