import BeyoboNavbar from "../../../components/beyobonavbar";
import React, { useState, useEffect, useCallback } from "react";
import { TabGroup, Tab } from "../../../components/tabGroup";
import useApi from "../../../hooks/useApi";
import CustomTable from "components/customTable";
import CustomSelect from "components/customSelect";
import useApiPaths from "../../../hooks/useApiPath";
import { useDialog } from "components/dialog";
import { useNavigate } from "react-router-dom";
import usePath from "hooks/usePath";
import { useToast } from "components/toast";

const PurchaseOrderTable = ({ orderStatus, refetchTrigger }) => {
  const api = useApi();
  const paths = usePath();
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const apiPaths = useApiPaths();
  useEffect(() => {
    api.get(apiPaths.managePurchaseOrders(orderStatus)).then((r) => {
      setOrders(r);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderStatus, refetchTrigger]);

  const columns = [
    { header: "Order Id", key: "orderId" },
    { header: "Date", key: "orderDate" },
    { header: "Vendor", key: "vendor" },
  ];

  const handleRowClick = (rowData, columnKey) => {
    console.log("Column clicked:", columnKey);
    console.log("Row data:", rowData);
    navigate(`/order/${rowData.orderId}`);
  };

  return (
    <>
      <CustomTable
        data={orders}
        columns={columns}
        onRowClick={handleRowClick}
      />
    </>
  );
};

const CreatePurchaseOrder = ({ orderCreated }) => {
  const api = useApi();
  const { openDialog, closeDialog } = useDialog();
  const apiPaths = useApiPaths();
  const [warehouses, setWarehouses] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [skus, setSkus] = useState([]);
  const [companyId, setCompanyId] = useState(0);
  const [addressId, setAddressId] = useState(0);
  const [selectedVariants, setSelectedVariant] = useState([]);
  const { toastSuccess, toastInfo, toastError } = useToast();

  useEffect(() => {
    api.get(apiPaths.warehouses).then((r) => {
      setWarehouses(r);
    });

    api.get(apiPaths.suppliers).then((r) => {
      console.log(r);
      setSuppliers(r);
    });
  }, []);

  useEffect(() => {
    if (companyId > 0) {
      api.get(apiPaths.companyVariants(companyId)).then((r) => {
        setSkus(r);
      });
    }
  }, [companyId]);

  const handleAddressChange = useCallback((option) => {
    setAddressId(option.value);
  }, []);

  const handleSupplierChange = useCallback((option) => {
    setCompanyId(option.value);
  }, []);

  const addSelectedVariant = (variant) => {
    const isVariantPresent = selectedVariants.some(
      (existingVariant) =>
        (existingVariant?.value ?? 0) === (variant?.value ?? -1)
    );
    if (!isVariantPresent) {
      setSelectedVariant([...selectedVariants, { ...variant, quantity: 1 }]);
    }
  };

  const removeSelectedVariant = (variant) => {
    setSelectedVariant(selectedVariants.filter((item) => item !== variant));
  };

  const handleVariantQuantityChange = (index, quantity) => {
    const updatedVariants = [...selectedVariants];
    updatedVariants[index].quantity = quantity > 0 ? Number(quantity) : "";
    setSelectedVariant(updatedVariants);
  };

  const createPurchaseOrder = () => {
    if (
      selectedVariants.some(
        (v) => typeof (v?.quantity ?? 0) !== "number" || (v?.quantity ?? 0) <= 0
      )
    ) {
      alert("Item quantity cannot be less than 1");
      return;
    }

    const orderData = {
      orderDeliveryAddressId: addressId,
      companyId: companyId,
      items: selectedVariants.map((variant) => ({
        skuId: variant.value,
        quantity: variant.quantity,
      })),
    };

    api.post(apiPaths.createPurchaseOrder, orderData).then((r) => {
      if (r.orderId > 0) {
        toastSuccess(
          `Purchase Order Successfully Created!!! OrderId: ${r?.orderId ?? 0}`
        );
        orderCreated();
        closeDialog();
      }
    });
  };

  return (
    <>
      <label htmlFor="warehouse">Choose Delivery Address:</label>
      <CustomSelect
        name="warehouse"
        onChange={(option) => handleAddressChange(option)}
        options={warehouses?.map((w) => {
          return {
            value: w?.address?.id ?? 0,
            label: `${w?.name ?? ""}, ${w?.address?.address_one ?? ""}, ${
              w?.address?.address_two ?? ""
            }, ${w?.address?.city ?? ""}`,
            customLabel: (
              <div style={{ display: "flex", alignItems: "center" }}>
                {`${w?.name ?? ""}, ${w?.address?.address_one ?? ""}, ${
                  w?.address?.address_two ?? ""
                }, ${w?.address?.city ?? ""}`}
              </div>
            ),
          };
        })}
        isSearchable={true}
        className="mt-1 block w-full"
      />

      <br />

      <label htmlFor="supplier">Choose Supplier:</label>
      <CustomSelect
        name="supplier"
        onChange={(option) => handleSupplierChange(option)}
        options={suppliers.map((w) => {
          return {
            value: w?.id ?? 0,
            label: `${w?.name ?? ""}`,
            customLabel: (
              <div style={{ display: "flex", alignItems: "center" }}>
                {`${w?.name ?? ""}`}
              </div>
            ),
          };
        })}
        isSearchable={true}
        className="mt-1 block w-full"
      />

      <br />

      <label htmlFor="skus">Choose SKUs:</label>
      <CustomSelect
        name="skus"
        stickOption={false}
        onChange={(option) => {
          addSelectedVariant(option);
        }}
        options={skus
          .filter(
            (s) =>
              selectedVariants.findIndex(
                (k) => (k?.value ?? 0) === (s?.id ?? -1)
              ) === -1
          )
          .map((w) => {
            return {
              value: w?.id ?? 0,
              label: `${w?.name ?? ""} ,${w?.sku_code ?? ""}`,
              customLabel: (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {`${w?.name ?? ""} ,${w?.sku_code ?? ""}`}
                </div>
              ),
            };
          })}
        isSearchable={true}
        className="mt-1 block w-full"
      />

      {(selectedVariants?.length ?? 0) > 0 ? (
        <>
          <br />
          <div className="mr-6 mt-3 flex justify-end gap-10">
            <strong>Quantity</strong>
          </div>
        </>
      ) : null}

      {selectedVariants.map((variant, index) => (
        <div
          key={index}
          className="relative my-5 flex items-center rounded-lg bg-gray-100 p-2"
        >
          <span className="flex-grow">{variant?.label ?? ""}</span>
          <input
            type="number"
            value={variant?.quantity}
            onChange={(e) => handleVariantQuantityChange(index, e.target.value)}
            className="mx-2 h-8 w-20 rounded border-2 border-gray-300 text-center focus:outline-none"
            min="0"
          />

          <span
            onClick={() => removeSelectedVariant(variant)}
            className="absolute right-0 top-[-8px] cursor-pointer text-lg text-red-500 hover:text-red-700"
          >
            <svg
              width="14px"
              height="14px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM15.75 12C15.75 12.4142 15.4142 12.75 15 12.75H9C8.58579 12.75 8.25 12.4142 8.25 12C8.25 11.5858 8.58579 11.25 9 11.25H15C15.4142 11.25 15.75 11.5858 15.75 12Z"
                  fill="#ff0000"
                ></path>{" "}
              </g>
            </svg>
          </span>
        </div>
      ))}

      <div className="mt-4 flex justify-end">
        <button
          onClick={() => {
            closeDialog();
          }}
          className="mr-2 rounded-md bg-gray-200 px-4 py-2 text-gray-700 hover:bg-gray-300"
        >
          Cancel
        </button>
        {(selectedVariants?.length ?? 0) > 0 ? (
          <button
            onClick={() => {
              createPurchaseOrder();
            }}
            className="rounded-md bg-blue-600 px-4 py-2 text-white hover:bg-blue-700"
          >
            Submit
          </button>
        ) : null}
      </div>
    </>
  );
};

const Orders = () => {
  const { openDialog } = useDialog();
  const [refetchTrigger, setRefetchTrigger] = useState(0);

  return (
    <>
      {/* <BeyoboNavbar /> */}
      <div className="container mx-auto py-2" style={{ marginLeft: 220 }}>
        <div className="absolute right-4" style={{ marginTop: "-1px" }}>
          <button
            onClick={() => {
              openDialog(
                <CreatePurchaseOrder
                  orderCreated={() => {
                    setRefetchTrigger((p) => p + 1);
                  }}
                />,
                "large",
                true
              );
            }}
            className="mt-4 flex items-center rounded bg-brand-300 py-2 px-4 font-bold text-white hover:bg-brand-400"
          >
            + Create Order
          </button>
        </div>

        <TabGroup>
          <Tab label="Pending">
            <PurchaseOrderTable
              refetchTrigger={refetchTrigger}
              orderStatus={"pending"}
            ></PurchaseOrderTable>
          </Tab>
          <Tab label="Confirmed">
            <PurchaseOrderTable
              refetchTrigger={refetchTrigger}
              orderStatus={"confirmed"}
            ></PurchaseOrderTable>
          </Tab>
          <Tab label="Received">
            <PurchaseOrderTable
              refetchTrigger={refetchTrigger}
              orderStatus={"received"}
            ></PurchaseOrderTable>
          </Tab>
        </TabGroup>
      </div>
    </>
  );
};

export default Orders;
