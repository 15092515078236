import React, { useState } from 'react';
import BeyoboNavbar from "../../../components/beyobonavbar";
import MultipleDataGrid from "../../../components/multipledatagrid";
import TransactionDataGrid from 'components/transactiondatagrid';
import PayoutDataGrid from 'components/payoutdatagrid';
const PaymentScreen = () => {
    const [activeTab, setActiveTab] = useState('statements');
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <BeyoboNavbar />

            <div className="p-4 w-full">
                <div className="flex w-full justify-between my-3 items-center">

                    <div className="w-1/3">
                        <p className="text-xl font-bold">Payments</p>
                        <p className="font-light"> Manage all your payments and transactions </p>
                    </div>

                    <div className="flex gap-5 w-1/3">
                        <button
                            className={`mr-2 py-2 px-4 ${activeTab === 'statements' ? 'text-blue-500 border-b-2 border-blue-500' : 'text-gray-500'}`}
                            onClick={() => setActiveTab('statements')}
                        >
                            Payouts
                        </button>
                        <button
                            className={`py-2 px-4 ${activeTab === 'shipping' ? 'text-blue-500 border-b-2 border-blue-500' : 'text-gray-500'}`}
                            onClick={() => setActiveTab('shipping')}
                        >
                             Transactions
                        </button>
                    </div>

                    <div className="w-1/3 flex flex-col justify-between">
                        {/* Trigger Button */}
                        {/*<button*/}
                        {/*    className="bg-brand-500 hover:bg-brand-600 text-white font-bold py-2 px-4 rounded"*/}
                        {/*    onClick={() => setShowModal(true)}*/}
                        {/*>*/}
                        {/*    Download Report*/}
                        {/*</button>*/}

                        {/* Modal */}
                        {showModal && (
                            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center z-50" id="my-modal">
                                <div className="relative bg-white rounded-md shadow-lg max-w-2xl w-full">
                                    {/* Close Button */}
                                    <div className="flex justify-between p-6">

                                        <div>
                                            <h3 className="text-lg leading-6 font-bold text-gray-900 text-center">Download Report</h3>
                                            <span className="block text-sm text-gray-500 text-center">Please enter the details</span>
                                        </div>


                                        <button
                                            onClick={() => setShowModal(false)}
                                            className="text-black hover:text-gray-600 rounded-md text-sm p-1.5 ml-auto inline-flex"
                                        >
                                            X
                                        </button>
                                    </div>

                                    <hr />

                                    <div className="px-6 pb-4 pt-2">

                                        <div className="flex items-center gap-5">
                                            <div className="w-full">
                                                <label className="block text-sm font-medium text-gray-700">Report type *</label>
                                                <select className="form-select mt-1 block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-brand-500 focus:border-brand-500">
                                                    <option>Select</option>
                                                    {/* Add your options here */}
                                                </select>
                                            </div>
                                            <div className="flex gap-4 justify-center w-full">
                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700">From *</label>
                                                    <input type="date" className="form-input mt-1 block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-brand-500 focus:border-brand-500" placeholder="mm/dd/yyyy" />
                                                </div>
                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700">To *</label>
                                                    <input type="date" className="form-input mt-1 block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-brand-500 focus:border-brand-500" placeholder="mm/dd/yyyy" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <hr />

                                    <div className="flex space-x-4 justify-center py-5">
                                        <button type="button" className="px-4 py-2 bg-white text-black rounded-xl border border-gray-300" onClick={() => setShowModal(false)}>Cancel</button>
                                        <button type="submit" className="px-4 py-2 bg-brand-500 text-white rounded-xl hover:bg-brand-600 focus:outline-none">Download Report</button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                </div>


                <div className={`p-4 ${activeTab === 'statements' ? 'block' : 'hidden'}`}>

                    <div className="my-8">
                       <PayoutDataGrid></PayoutDataGrid>
                    </div>

                </div>

                <div className={`p-4 ${activeTab === 'shipping' ? 'block' : 'hidden'}`}>
                <TransactionDataGrid></TransactionDataGrid>
                </div>
            </div>

        </>
    );
};

export default PaymentScreen;
