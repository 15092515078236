import React, { useState } from 'react';


function Step3({ formData, handleFormDataChange, prevStep, handleSubmit, onSubmitSuccess }) {
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    // Wrap handleSubmit to include success modal logic
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        await handleSubmit(e); // Execute the submission logic
        onSubmitSuccess(); // Trigger success modal via prop callback
        setShowSuccessModal(true); // Optionally control a local modal if needed
    };


    

    return (
        <>
       
            <form onSubmit={handleFormSubmit}>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="user_partner_eor">
                        Partner EOR*
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="user_partner_eor"
                        name="user_partner_eor"
                        type="text"
                        placeholder="Partner EOR"
                        value={formData.user_partner_eor}
                        onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}
                        required
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="user_partner_ior">
                        Import On Record (IOR) Partner*
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="user_partner_ior"
                        name="user_partner_ior"
                        type="text"
                        placeholder="IOR Partner"
                        value={formData.user_partner_ior}
                        onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}
                        required
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="user_partner_psp">
                        Partner Service Provider (PSP)*
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="user_partner_psp"
                        name="user_partner_psp"
                        type="text"
                        placeholder="PSP"
                        value={formData.user_partner_psp}
                        onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}
                        required
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="user_partner_logistics">
                        Logistics Partner*
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="user_partner_logistics"
                        name="user_partner_logistics"
                        type="text"
                        placeholder="Logistics Partner"
                        value={formData.user_partner_logistics}
                        onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}
                        required
                    />
                </div>

                <div>
                    <button type="button" onClick={prevStep} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                        Back
                    </button>
                    <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                        Sign Up
                    </button>
                </div>
            </form>
        </>
    );
}

export default Step3;
