

const useApiPaths = () => {
  
  const warehouses = 'warehouse/warehouses/';
  const suppliers = 'company/suppliers/';
  const companyVariants = (companyId) => `sku/company/variants/?companyid=${companyId}`;
  const companyDetail = (companyId) => `company/detail/?companyid=${companyId}`
  const createPurchaseOrder = 'order/createpurchaseorder/';
  const createAddress = 'address/create/';
  const getCountries = 'country/countries/';
  const orderDetail = (orderId) => `order/detail/?orderid=${orderId}`;
  const orderSkus = (orderId) => `order/orderskus/?orderid=${orderId}`;
  const companyAddresses = (companyId) => `address/addresses/company/?companyid=${companyId}`;
  const orderItems = (orderId) => `order/orderitems/?orderid=${orderId}`;
  const orderShipment = (orderId) => `order/shipment/?orderid=${orderId}`;
  const shipmentVariants = (shipmentId) => `shipment/shipmentvariants/?shipmentid=${shipmentId}`;
  const createDraftShipment = `shipment/createdraftshipment/`;
  const downloadShipment = `shipment/downloadshipmentlabel/`;
  const downloadItem = `shipment/downloaditemlabel/`;
  const createShipment = `shipment/createshipment/`;
  const finalizeShipment = `shipment/finalizeshipment/`;
  const managePurchaseOrders = (orderStatus) => `order/purchaseorders/?status=${orderStatus}`;
  const companyPurchaseOrders = `order/companypurchaseorders/`;


  return {
    warehouses,
    suppliers,
    getCountries,
    createAddress,
    createShipment,
    companyVariants,
    companyAddresses,
    createPurchaseOrder,
    orderDetail,
    companyDetail,
    finalizeShipment,
    managePurchaseOrders,
    orderSkus,
    shipmentVariants,
    createDraftShipment,
    downloadShipment,
    downloadItem,
    orderItems,
    orderShipment,
    companyPurchaseOrders
  };
};

export default useApiPaths;
