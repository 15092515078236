

import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter'
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter'
import DateFilter from '@inovua/reactdatagrid-community/DateFilter'
import moment from 'moment'
import Button from '@inovua/reactdatagrid-community/packages/Button'
import BeyoboNavbar from "../beyobonavbar";
import React, { useState, useEffect, useContext, useCallback } from 'react';
import CatalogProductContext from '../../context/CatalogProductContext';
import { AuthContext } from '../../context/AuthContext';
import DataModal from '../modal'
import CustomTable from '../customTable'

const ShipmentDataGrid = () => {


    const columns = [
        { header: 'Shipment ID', key: 'shipment_id' },
        { header: 'Recieved Date', key: 'created_at' },
        { header: 'Ordered Quantity', key: 'ordered_quantity' },
        { header: 'Company Id', key: 'company_id' },
    ];

    const defaultfilter = [
        { name: 'shipment_id', operator: 'gte', type: 'number', value: 0 },
        { name: 'received_date', operator: 'before', type: 'date', value: 0 },
        { name: 'variant_code', operator: 'contains', type: 'string', value: '' },
        { name: 'name', operator: 'contains', type: 'string', value: '' },
        //{ name: 'sku_id', operator: 'gte', type: 'number' ,value: 0},

        //{ name: 'variant_id', operator: 'gte', type: 'number' ,value: 0},
        { name: 'ordered_quantity', operator: 'gte', type: 'number', value: 0 },

        { name: 'state', operator: 'startsWith', type: 'string', value: '' }

    ];



    const [filterValue, setFilterValue] = useState(defaultfilter);
    const [dataSource, setDataSource] = useState([]);
    const { authToken } = useContext(AuthContext);


    const [shipments, setShipments] = useState([]);

    // Fetch data from the backend
    //const { authToken } = useContext(AuthContext);
    // const {user} =useContext(UserContext);
    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_API_URL}/shipment/getcompanyshipments/`, {
            headers: {
                'Authorization': `Token ${authToken}`
          }
      })
          .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
              return response.json();
                })
            .then(data => {
                console.log('data', data)
        setShipments(data); // Assuming the API returns an array of products

    })
    .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
    });
  }, []);  //

    //const [gridData, setGridData] = useState([]); // New state for actual data array

    //const loadData = useCallback(() => {
    //    const newDataSource = () => {
    //        const limit = 50;
    //        const url = `${process.env.REACT_APP_BASE_API_URL}/shipmentvariant/` + '?limit=' + limit;
    //        return fetch(url, {
    //            headers: {
    //                'Authorization': `Token ${authToken}`
    //            }
    //        })
    //            .then(response => response.json())
    //            .then(data => {
    //                const formattedData = data.map(item => ({
    //                    ...item,
    //                    order_date: moment(item.order_date).format('DD-MM-YYYY')
    //                }));
    //                setGridData(formattedData); // Update gridData with the actual data array
    //                return { data: formattedData }; // Return the data for dataSource
    //            });
    //    };

    //    setDataSource(newDataSource); // Keep as it was, setting a function
    //}, [authToken]);


    const exportCSV = () => {
        if (!Array.isArray(shipments)) {
            console.error('Grid data is not an array');
            return;
        }

        // Process the data to format it for CSV
        const rows = shipments.map((data) =>
            columns.map((col) => {
                // Handle any necessary formatting per column
                let cellData = data[col.name];
                if (col.render) {
                    // If there's a custom render, use it for CSV data
                    const rendered = col.render({ value: cellData });
                    if (React.isValidElement(rendered)) {
                        // If rendered content is a React element, extract text content
                        cellData = rendered.props.children;
                    } else {
                        cellData = rendered;
                    }
                }
                return `"${cellData?.toString().replace(/"/g, '""') || ''}"`; // Escape quotes
            }).join(',')
        );

        const header = columns.map((col) => `"${col.header.replace(/"/g, '""')}"`).join(',');
        const csvContent = [header].concat(rows).join('\n');

        // Create a Blob for the CSV content
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        // Create a temporary link to trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.download = 'shipment-grid-data.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    //useEffect(() => {
    //    loadData();
    //}, [loadData]); // Ensure loadData is stable and doesn't change unless necessary



    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);

    const onRowClick = useCallback((rowProps) => {
        setSelectedRowData(rowProps.data);
        setIsModalOpen(true);
    }, []);



    const gridStyle = { minHeight: 550 }

    return (
        <div>

            <div className="right-4 z-50 my-3">
                <button onClick={exportCSV}
                    className="bg-brand-300 hover:bg-brand-400 text-white font-bold py-2 px-4 rounded flex items-center mt-4"
                >
                    Download
                </button>
            </div>


            <CustomTable data={shipments} columns={columns} onRowClick={() => { } } />

            

            <DataModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                data={selectedRowData}
                columns={columns}
            />
        </div>
    )
};


export default ShipmentDataGrid;




    // Simple inline modal component
    //const InlineModal = ({ isOpen, onClose, data }) => (
    //    <div className={`${isOpen ? 'flex' : 'hidden'} fixed inset-0 z-50 overflow-auto bg-smoke-light flex justify-center items-center`}>
    //        <div className="bg-white p-6 md:max-w-xl w-full rounded-lg shadow-lg">
    //            <div className="flex justify-between items-center pb-3">
    //                <div></div>
    //                <div className="cursor-pointer z-50" onClick={onClose}>
    //                    <svg className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    //                        <path d="M16.192 0L9 7.192 1.808 0 0 1.808 7.192 9 0 16.192l1.808 1.808L9 10.808l7.192 7.192 1.808-1.808L10.808 9 18 1.808z" />
    //                    </svg>
    //                </div>
    //            </div>
    //            <table className="w-full text-left border-collapse">
    //                <tbody className="text-gray-700">
    //                    <tr className="border-b">
    //                        <td className="py-2 px-4 border-r font-semibold">Name:</td>
    //                        <td className="py-2 px-4">{data?.name}</td>
    //                    </tr>
    //                    <tr className="border-b">
    //                        <td className="py-2 px-4 border-r font-semibold">Shipment ID:</td>
    //                        <td className="py-2 px-4">{data?.shipment_id}</td>
    //                    </tr>
    //                    <tr className="border-b">
    //                        <td className="py-2 px-4 border-r font-semibold">Shipment Date:</td>
    //                        <td className="py-2 px-4">{data ? moment(data.received_date).format('MM-DD-YYYY') : ''}</td>
    //                    </tr>
    //                    <tr className="border-b">
    //                        <td className="py-2 px-4 border-r font-semibold">SKU:</td>
    //                        <td className="py-2 px-4">{data?.variant_code}</td>
    //                    </tr>

    //                    <tr className="border-b">
    //                        <td className="py-2 px-4 border-r font-semibold">Ordered Quantity:</td>
    //                        <td className="py-2 px-4">{data?.ordered_quantity}</td>
    //                    </tr>
    //                    <tr className="border-b">
    //                        <td className="py-2 px-4 border-r font-semibold">State:</td>
    //                        <td className="py-2 px-4">{data?.state}</td>
    //                    </tr>
    //                    {/* Add other fields as needed */}
    //                </tbody>
    //            </table>
    //        </div>
    //    </div>
    //);



//<ReactDataGrid
//                style={gridStyle}
//                idProperty="id"
//                pagination='local'
//                columns={columns}
//                dataSource={dataSource}
//                defaultLimit={50}
//                defaultFilterValue={defaultfilter}
//                onFilterValueChange={setFilterValue}
//                onRowClick={onRowClick} // Add this line to handle row clicks
//            />
//            <InlineModal
//                isOpen={isModalOpen}
//                onClose={() => setIsModalOpen(false)}
//                data={selectedRowData}
//            />


    //{ name: 'sku_id', defaultFlex: 1, header: 'Sku Id', type: 'number', filterEditor: NumberFilter },

    //{ name: 'variant_id', defaultFlex: 1, header: 'Variant Id', type: 'number' , filterEditor: NumberFilter},


    //header: 'Age',
    //defaultWidth: 80,
    //render: ({ value }) => <span style={{ color: value < 30 ? 'lightgreen' : 'inherit' }}>{value}</span>


//{/* <Button onClick={() => loadData()} style={{marginRight: 10}}>
//  Load async data
//</Button>
//<Button
//  disabled={Array.isArray(dataSource)}
//  onClick={() => setDataSource([])}
//>Clear data</Button> */}
// {/* <div style={{ height: 80 }} >Current filterValue: {filterValue ? <code>{JSON.stringify(filterValue, null, 2)}</code>: 'none'}.</div> */}





// const dataSource = [
//     { firstName: 'John', lastName: 'Grayner', country: 'usa', age: 35, id: 0 },
//     { firstName: 'Mary', lastName: 'Stones', country: 'ca', age: 25, id: 1 },
//     { firstName: 'Robert', lastName: 'Fil', country: 'uk', age: 27, id: 2 },
//     { firstName: 'Bob', lastName: 'Fisher', country: 'usa', age: 72, id: 3 },
//     { firstName: 'Michael', lastName: 'Rogers', country: 'usa', age: 45, id: 4 },
//     { firstName: 'Hilly', lastName: 'Bobson', country: 'uk', age: 5, id: 5 },
//     { firstName: 'Mike', lastName: 'Brind', country: 'ca', age: 15, id: 6 },
//     { firstName: 'Carl', lastName: 'Phancer', country: 'ca', age: 56, id: 7 },
//     { firstName: 'Victory', lastName: 'Hope', country: 'uk', age: 52, id: 8 },        { firstName: 'John', lastName: 'Grayner', country: 'usa', age: 35, id: 0 },
//     { firstName: 'Mary', lastName: 'Stones', country: 'ca', age: 25, id: 1 },
//     { firstName: 'Robert', lastName: 'Fil', country: 'uk', age: 27, id: 2 },
//     { firstName: 'Bob', lastName: 'Fisher', country: 'usa', age: 72, id: 3 },
//     { firstName: 'Michael', lastName: 'Rogers', country: 'usa', age: 45, id: 4 },
//     { firstName: 'Hilly', lastName: 'Bobson', country: 'uk', age: 5, id: 5 },
//     { firstName: 'Mike', lastName: 'Brind', country: 'ca', age: 15, id: 6 },
//     { firstName: 'Carl', lastName: 'Phancer', country: 'ca', age: 56, id: 7 },
//     { firstName: 'Victory', lastName: 'Hope', country: 'uk', age: 52, id: 8 },        { firstName: 'John', lastName: 'Grayner', country: 'usa', age: 35, id: 0 },
//     { firstName: 'Mary', lastName: 'Stones', country: 'ca', age: 25, id: 1 },
//     { firstName: 'Robert', lastName: 'Fil', country: 'uk', age: 27, id: 2 },
//     { firstName: 'Bob', lastName: 'Fisher', country: 'usa', age: 72, id: 3 },
//     { firstName: 'Michael', lastName: 'Rogers', country: 'usa', age: 45, id: 4 },
//     { firstName: 'Hilly', lastName: 'Bobson', country: 'uk', age: 5, id: 5 },
//     { firstName: 'Mike', lastName: 'Brind', country: 'ca', age: 15, id: 6 },
//     { firstName: 'Carl', lastName: 'Phancer', country: 'ca', age: 56, id: 7 },
//     { firstName: 'Victory', lastName: 'Hope', country: 'uk', age: 52, id: 8 },        { firstName: 'John', lastName: 'Grayner', country: 'usa', age: 35, id: 0 },
//     { firstName: 'Mary', lastName: 'Stones', country: 'ca', age: 25, id: 1 },
//     { firstName: 'Robert', lastName: 'Fil', country: 'uk', age: 27, id: 2 },
//     { firstName: 'Bob', lastName: 'Fisher', country: 'usa', age: 72, id: 3 },
//     { firstName: 'Michael', lastName: 'Rogers', country: 'usa', age: 45, id: 4 },
//     { firstName: 'Hilly', lastName: 'Bobson', country: 'uk', age: 5, id: 5 },
//     { firstName: 'Mike', lastName: 'Brind', country: 'ca', age: 15, id: 6 },
//     { firstName: 'Carl', lastName: 'Phancer', country: 'ca', age: 56, id: 7 },
//     { firstName: 'Victory', lastName: 'Hope', country: 'uk', age: 52, id: 8 },        { firstName: 'John', lastName: 'Grayner', country: 'usa', age: 35, id: 0 },
//     { firstName: 'Mary', lastName: 'Stones', country: 'ca', age: 25, id: 1 },
//     { firstName: 'Robert', lastName: 'Fil', country: 'uk', age: 27, id: 2 },
//     { firstName: 'Bob', lastName: 'Fisher', country: 'usa', age: 72, id: 3 },
//     { firstName: 'Michael', lastName: 'Rogers', country: 'usa', age: 45, id: 4 },
//     { firstName: 'Hilly', lastName: 'Bobson', country: 'uk', age: 5, id: 5 },
//     { firstName: 'Mike', lastName: 'Brind', country: 'ca', age: 15, id: 6 },
//     { firstName: 'Carl', lastName: 'Phancer', country: 'ca', age: 56, id: 7 },
//     { firstName: 'Victory', lastName: 'Hope', country: 'uk', age: 52, id: 8 },        { firstName: 'John', lastName: 'Grayner', country: 'usa', age: 35, id: 0 },
//     { firstName: 'Mary', lastName: 'Stones', country: 'ca', age: 25, id: 1 },
//     { firstName: 'Robert', lastName: 'Fil', country: 'uk', age: 27, id: 2 },
//     { firstName: 'Bob', lastName: 'Fisher', country: 'usa', age: 72, id: 3 },
//     { firstName: 'Michael', lastName: 'Rogers', country: 'usa', age: 45, id: 4 },
//     { firstName: 'Hilly', lastName: 'Bobson', country: 'uk', age: 5, id: 5 },
//     { firstName: 'Mike', lastName: 'Brind', country: 'ca', age: 15, id: 6 },
//     { firstName: 'Carl', lastName: 'Phancer', country: 'ca', age: 56, id: 7 },
//     { firstName: 'Victory', lastName: 'Hope', country: 'uk', age: 52, id: 8 },        { firstName: 'John', lastName: 'Grayner', country: 'usa', age: 35, id: 0 },
//     { firstName: 'Mary', lastName: 'Stones', country: 'ca', age: 25, id: 1 },
//     { firstName: 'Robert', lastName: 'Fil', country: 'uk', age: 27, id: 2 },
//     { firstName: 'Bob', lastName: 'Fisher', country: 'usa', age: 72, id: 3 },
//     { firstName: 'Michael', lastName: 'Rogers', country: 'usa', age: 45, id: 4 },
//     { firstName: 'Hilly', lastName: 'Bobson', country: 'uk', age: 5, id: 5 },
//     { firstName: 'Mike', lastName: 'Brind', country: 'ca', age: 15, id: 6 },
//     { firstName: 'Carl', lastName: 'Phancer', country: 'ca', age: 56, id: 7 },
//     { firstName: 'Victory', lastName: 'Hope', country: 'uk', age: 52, id: 8 },        { firstName: 'John', lastName: 'Grayner', country: 'usa', age: 35, id: 0 },
//     { firstName: 'Mary', lastName: 'Stones', country: 'ca', age: 25, id: 1 },
//     { firstName: 'Robert', lastName: 'Fil', country: 'uk', age: 27, id: 2 },
//     { firstName: 'Bob', lastName: 'Fisher', country: 'usa', age: 72, id: 3 },
//     { firstName: 'Michael', lastName: 'Rogers', country: 'usa', age: 45, id: 4 },
//     { firstName: 'Hilly', lastName: 'Bobson', country: 'uk', age: 5, id: 5 },
//     { firstName: 'Mike', lastName: 'Brind', country: 'ca', age: 15, id: 6 },
//     { firstName: 'Carl', lastName: 'Phancer', country: 'ca', age: 56, id: 7 },
//     { firstName: 'Victory', lastName: 'Hope', country: 'uk', age: 52, id: 8 },        { firstName: 'John', lastName: 'Grayner', country: 'usa', age: 35, id: 0 },
//     { firstName: 'Mary', lastName: 'Stones', country: 'ca', age: 25, id: 1 },
//     { firstName: 'Robert', lastName: 'Fil', country: 'uk', age: 27, id: 2 },
//     { firstName: 'Bob', lastName: 'Fisher', country: 'usa', age: 72, id: 3 },
//     { firstName: 'Michael', lastName: 'Rogers', country: 'usa', age: 45, id: 4 },
//     { firstName: 'Hilly', lastName: 'Bobson', country: 'uk', age: 5, id: 5 },
//     { firstName: 'Mike', lastName: 'Brind', country: 'ca', age: 15, id: 6 },
//     { firstName: 'Carl', lastName: 'Phancer', country: 'ca', age: 56, id: 7 },
//     { firstName: 'Victory', lastName: 'Hope', country: 'uk', age: 52, id: 8 },        { firstName: 'John', lastName: 'Grayner', country: 'usa', age: 35, id: 0 },
//     { firstName: 'Mary', lastName: 'Stones', country: 'ca', age: 25, id: 1 },
//     { firstName: 'Robert', lastName: 'Fil', country: 'uk', age: 27, id: 2 },
//     { firstName: 'Bob', lastName: 'Fisher', country: 'usa', age: 72, id: 3 },
//     { firstName: 'Michael', lastName: 'Rogers', country: 'usa', age: 45, id: 4 },
//     { firstName: 'Hilly', lastName: 'Bobson', country: 'uk', age: 5, id: 5 },
//     { firstName: 'Mike', lastName: 'Brind', country: 'ca', age: 15, id: 6 },
//     { firstName: 'Carl', lastName: 'Phancer', country: 'ca', age: 56, id: 7 },
//     { firstName: 'Victory', lastName: 'Hope', country: 'uk', age: 52, id: 8 },        { firstName: 'John', lastName: 'Grayner', country: 'usa', age: 35, id: 0 },
//     { firstName: 'Mary', lastName: 'Stones', country: 'ca', age: 25, id: 1 },
//     { firstName: 'Robert', lastName: 'Fil', country: 'uk', age: 27, id: 2 },
//     { firstName: 'Bob', lastName: 'Fisher', country: 'usa', age: 72, id: 3 },
//     { firstName: 'Michael', lastName: 'Rogers', country: 'usa', age: 45, id: 4 },
//     { firstName: 'Hilly', lastName: 'Bobson', country: 'uk', age: 5, id: 5 },
//     { firstName: 'Mike', lastName: 'Brind', country: 'ca', age: 15, id: 6 },
//     { firstName: 'Carl', lastName: 'Phancer', country: 'ca', age: 56, id: 7 },
//     { firstName: 'Victory', lastName: 'Hope', country: 'uk', age: 52, id: 8 },        { firstName: 'John', lastName: 'Grayner', country: 'usa', age: 35, id: 0 },
//     { firstName: 'Mary', lastName: 'Stones', country: 'ca', age: 25, id: 1 },
//     { firstName: 'Robert', lastName: 'Fil', country: 'uk', age: 27, id: 2 },
//     { firstName: 'Bob', lastName: 'Fisher', country: 'usa', age: 72, id: 3 },
//     { firstName: 'Michael', lastName: 'Rogers', country: 'usa', age: 45, id: 4 },
//     { firstName: 'Hilly', lastName: 'Bobson', country: 'uk', age: 5, id: 5 },
//     { firstName: 'Mike', lastName: 'Brind', country: 'ca', age: 15, id: 6 },
//     { firstName: 'Carl', lastName: 'Phancer', country: 'ca', age: 56, id: 7 },
//     { firstName: 'Victory', lastName: 'Hope', country: 'uk', age: 52, id: 8 },        { firstName: 'John', lastName: 'Grayner', country: 'usa', age: 35, id: 0 },
//     { firstName: 'Mary', lastName: 'Stones', country: 'ca', age: 25, id: 1 },
//     { firstName: 'Robert', lastName: 'Fil', country: 'uk', age: 27, id: 2 },
//     { firstName: 'Bob', lastName: 'Fisher', country: 'usa', age: 72, id: 3 },
//     { firstName: 'Michael', lastName: 'Rogers', country: 'usa', age: 45, id: 4 },
//     { firstName: 'Hilly', lastName: 'Bobson', country: 'uk', age: 5, id: 5 },
//     { firstName: 'Mike', lastName: 'Brind', country: 'ca', age: 15, id: 6 },
//     { firstName: 'Carl', lastName: 'Phancer', country: 'ca', age: 56, id: 7 },
//     { firstName: 'Victory', lastName: 'Hope', country: 'uk', age: 52, id: 8 },        { firstName: 'John', lastName: 'Grayner', country: 'usa', age: 35, id: 0 },
//     { firstName: 'Mary', lastName: 'Stones', country: 'ca', age: 25, id: 1 },
//     { firstName: 'Robert', lastName: 'Fil', country: 'uk', age: 27, id: 2 },
//     { firstName: 'Bob', lastName: 'Fisher', country: 'usa', age: 72, id: 3 },
//     { firstName: 'Michael', lastName: 'Rogers', country: 'usa', age: 45, id: 4 },
//     { firstName: 'Hilly', lastName: 'Bobson', country: 'uk', age: 5, id: 5 },
//     { firstName: 'Mike', lastName: 'Brind', country: 'ca', age: 15, id: 6 },
//     { firstName: 'Carl', lastName: 'Phancer', country: 'ca', age: 56, id: 7 },
//     { firstName: 'Victory', lastName: 'Hope', country: 'uk', age: 52, id: 8 },        { firstName: 'John', lastName: 'Grayner', country: 'usa', age: 35, id: 0 },
//     { firstName: 'Mary', lastName: 'Stones', country: 'ca', age: 25, id: 1 },
//     { firstName: 'Robert', lastName: 'Fil', country: 'uk', age: 27, id: 2 },
//     { firstName: 'Bob', lastName: 'Fisher', country: 'usa', age: 72, id: 3 },
//     { firstName: 'Michael', lastName: 'Rogers', country: 'usa', age: 45, id: 4 },
//     { firstName: 'Hilly', lastName: 'Bobson', country: 'uk', age: 5, id: 5 },
//     { firstName: 'Mike', lastName: 'Brind', country: 'ca', age: 15, id: 6 },
//     { firstName: 'Carl', lastName: 'Phancer', country: 'ca', age: 56, id: 7 },
//     { firstName: 'Victory', lastName: 'Hope', country: 'uk', age: 52, id: 8 },        { firstName: 'John', lastName: 'Grayner', country: 'usa', age: 35, id: 0 },
//     { firstName: 'Mary', lastName: 'Stones', country: 'ca', age: 25, id: 1 },
//     { firstName: 'Robert', lastName: 'Fil', country: 'uk', age: 27, id: 2 },
//     { firstName: 'Bob', lastName: 'Fisher', country: 'usa', age: 72, id: 3 },
//     { firstName: 'Michael', lastName: 'Rogers', country: 'usa', age: 45, id: 4 },
//     { firstName: 'Hilly', lastName: 'Bobson', country: 'uk', age: 5, id: 5 },
//     { firstName: 'Mike', lastName: 'Brind', country: 'ca', age: 15, id: 6 },
//     { firstName: 'Carl', lastName: 'Phancer', country: 'ca', age: 56, id: 7 },
//     { firstName: 'Victory', lastName: 'Hope', country: 'uk', age: 52, id: 8 },        { firstName: 'John', lastName: 'Grayner', country: 'usa', age: 35, id: 0 },
//     { firstName: 'Mary', lastName: 'Stones', country: 'ca', age: 25, id: 1 },
//     { firstName: 'Robert', lastName: 'Fil', country: 'uk', age: 27, id: 2 },
//     { firstName: 'Bob', lastName: 'Fisher', country: 'usa', age: 72, id: 3 },
//     { firstName: 'Michael', lastName: 'Rogers', country: 'usa', age: 45, id: 4 },
//     { firstName: 'Hilly', lastName: 'Bobson', country: 'uk', age: 5, id: 5 },
//     { firstName: 'Mike', lastName: 'Brind', country: 'ca', age: 15, id: 6 },
//     { firstName: 'Carl', lastName: 'Phancer', country: 'ca', age: 56, id: 7 },
//     { firstName: 'Victory', lastName: 'Hope', country: 'uk', age: 52, id: 8 },
// ]

