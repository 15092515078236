import React, { useState, useEffect, useCallback, useContext } from 'react';
import moment from 'moment';
// Assuming AuthContext is already defined and exported from your context files
import { AuthContext } from '../../../../src/context/AuthContext';
import { useNavigate } from 'react-router-dom';
import useApi from 'hooks/useApi';
import CustomTable from 'components/customTable';




const SearchableOrderTable = () => {
    const api = useApi();
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        api.get(`order/companyorders/`)
            .then(r => {
                setOrders(r);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        {header: 'Id', key: 'id'},
        { header: 'Date', key: 'created_at' },
        { header: 'Order Id', key: 'order_id' },
        { header: 'Platform', key: 'platform' }
    ];


    return (
        <>
            <CustomTable data={orders} columns={columns} />
        </>
    );

}


function SearchableTable() {
    const { authToken } = useContext(AuthContext);
    const [gridData, setGridData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(25); // Set initial items per page
    const [isLoading, setIsLoading] = useState(false);

    const [searchQuery, setSearchQuery] = useState(''); // Added search query state

    //const [isModalOpen, setIsModalOpen] = useState(false);
    //const [modalContent, setModalContent] = useState('');
    const navigate = useNavigate();

    const loadData = useCallback(() => {
        setIsLoading(true);
        const offset = (currentPage - 1) * itemsPerPage;
        const url = `${process.env.REACT_APP_BASE_API_URL}/order/?limit=${itemsPerPage}&offset=${offset}`;

        fetch(url, {
            headers: {
                'Authorization': `Token ${authToken}`,
            },
        })
            .then(response => response.json())
            .then(data => {
                const formattedData = data.map(item => ({
                    ...item,
                    order_date: moment(item.order_date).format('DD-MM-YYYY'),
                    SaleAmount: formatCurrency(item.SaleAmount),
                }));
                setGridData(formattedData);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    }, [authToken, currentPage, itemsPerPage]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    // Function to handle search query change
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredData = searchQuery
        ? gridData.filter((item) =>
            Object.values(item).some(
                (value) =>
                    typeof value === "string" &&
                    value.toLowerCase().includes(searchQuery.toLowerCase())
            )
        )
        : gridData;

    function formatCurrency(value) {
        let formattedValue = Number.isInteger(value) ? value.toLocaleString('en-IN') : parseFloat(value).toFixed(2);
        return `₹${formattedValue}`;
    }

    //const handleApprove = (orderId, event) => {
    //    event.stopPropagation(); // Prevent event from bubbling to the row click handler
    //    setIsModalOpen(true);
    //    setModalContent(`Congratulations you have approved order ID ${orderId}.`);
    //};

    //const handleReject = (orderId, event) => {
    //    event.stopPropagation(); // Prevent event from bubbling to the row click handler
    //    console.log("Rejected order ID:", orderId);
    //};


    // Pagination Component Integration
    const totalItems = 1000; // Assuming total items count
    const itemsPerPageOptions = [10, 25, 50, 100]; // Available options for items per page

    const Pagination = ({ totalItems, paginate }) => {
        const lastPage = Math.ceil(totalItems / itemsPerPage);
        const [inputPage, setInputPage] = useState(currentPage);

        useEffect(() => {
            setInputPage(currentPage); // Update input field when current page changes
        }, [currentPage]);

        const handlePrevious = () => {
            setCurrentPage(current => Math.max(1, current - 1));
        };

        const handleNext = () => {
            setCurrentPage(current => Math.min(lastPage, current + 1));
        };

        const handlePageInputChange = (event) => {
            setInputPage(event.target.value); // Update inputPage state to reflect user input
        };

        const handlePageInputSubmit = (event) => {
            event.preventDefault(); // Prevent the form from submitting traditionally
            let page = parseInt(inputPage, 10);
            if (!isNaN(page) && page >= 1 && page <= lastPage) {
                setCurrentPage(page); // Update current page if the input is a valid page number
            } else {
                setInputPage(currentPage); // Reset input field to current page if input is invalid
            }
        };

        const handleItemsPerPageChange = (event) => {
            setItemsPerPage(Number(event.target.value));
            setCurrentPage(1); // Reset to first page when items per page changes
        };

        return (
            <div className="flex justify-between items-center mb-4">
                <form onSubmit={handlePageInputSubmit} className="flex items-center">
                    <button
                        onClick={handlePrevious}
                        disabled={currentPage === 1}
                        type="button" // Ensure clicking this doesn't submit the form
                        className="px-4 py-2 mx-1 text-sm bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                        &larr; Previous
                    </button>
                    <input
                        type="text"
                        value={inputPage}
                        onChange={handlePageInputChange}
                        className="mx-2 text-sm px-2 py-1 border rounded-md w-16 text-center"
                        aria-label="Page number"
                    />
                    <span className="mx-2 text-sm">of {lastPage}</span>
                    <button
                        onClick={handleNext}
                        disabled={currentPage === lastPage}
                        type="button" // Ensure clicking this doesn't submit the form
                        className="px-4 py-2 mx-1 text-sm bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                        Next &rarr;
                    </button>
                </form>
                <div className="flex items-center">
                    <label htmlFor="itemsPerPage" className="mr-2 text-sm">Items per page:</label>
                    <select
                        id="itemsPerPage"
                        value={itemsPerPage}
                        onChange={handleItemsPerPageChange}
                        className="px-2 py-1 text-sm border rounded-md"
                    >
                        {itemsPerPageOptions.map((number) => (
                            <option key={number} value={number}>
                                {number}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        );
    };


    return (
        <div className="container mx-auto">
            <div className="py-8">
                {isLoading ? (
                    <div className="flex justify-center items-center">
                        <div aria-label="Loading..." role="status" class="flex items-center space-x-2">
                            <svg class="h-12 w-12 animate-spin stroke-gray-500" viewBox="0 0 256 256">
                                <line x1="128" y1="32" x2="128" y2="64" stroke-linecap="round" stroke-linejoin="round" stroke-width="24"></line>
                                <line x1="195.9" y1="60.1" x2="173.3" y2="82.7" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="24"></line>
                                <line x1="224" y1="128" x2="192" y2="128" stroke-linecap="round" stroke-linejoin="round" stroke-width="24">
                                </line>
                                <line x1="195.9" y1="195.9" x2="173.3" y2="173.3" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="24"></line>
                                <line x1="128" y1="224" x2="128" y2="192" stroke-linecap="round" stroke-linejoin="round" stroke-width="24">
                                </line>
                                <line x1="60.1" y1="195.9" x2="82.7" y2="173.3" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="24"></line>
                                <line x1="32" y1="128" x2="64" y2="128" stroke-linecap="round" stroke-linejoin="round" stroke-width="24"></line>
                                <line x1="60.1" y1="60.1" x2="82.7" y2="82.7" stroke-linecap="round" stroke-linejoin="round" stroke-width="24">
                                </line>
                            </svg>
                            <span class="text-2xl font-medium text-gray-500">Loading data...</span>
                        </div>
                    </div>
                ) : (
                        <>
                            <div class="flex items-center border rounded-md overflow-hidden w-full mb-8">
                                <button type="submit" class="py-2 px-6">
                                    <svg class="w-7 h-7 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                                </button>
                                <input
                                    type="text"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    placeholder="Search your orders..."
                                    className="px-4 py-2 w-full"
                                />
                            </div>


                        <Pagination
                            totalItems={totalItems}
                            itemsPerPage={itemsPerPageOptions}
                            paginate={loadData}
                        />
                        <div className="shadow overflow-hidden rounded border-b border-gray-200">
                            <table className="min-w-full bg-white">
                                <thead className="bg-gray-800 text-white">
                                    <tr>
                                        <th className="text-left py-3 px-4 uppercase font-semibold text-sm">ID</th>
                                        <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Date</th>
                                        {/* <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Marketplace</th> */}
                                        <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Sku Name</th>
                                        <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Order ID</th>
                                        <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Quantity</th>
                                        <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Status</th>
                                        <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Total Amount Sold</th>
                                        {/*<th className="text-left py-3 px-4 uppercase font-semibold text-sm">Actions</th>*/}
                                    </tr>
                                </thead>
                                <tbody className="text-gray-700">
                                        {filteredData
                                        .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                                        .map((item, index) => (
                                            <tr key={index} onClick={() => navigate(`/order/${item.id}`)} className="cursor-pointer">
                                                <td className="text-left py-3 px-4">{item.id}</td>
                                                <td className="text-left py-3 px-4">{item.order_date}</td>
                                                {/* <td className="text-left py-3 px-4">{item.platform}</td> */}
                                                <td className="text-left py-3 px-4">{item.sku_name}</td>
                                                <td className="text-left py-3 px-4">{item.order_id}</td>
                                                <td className="text-left py-3 px-4">{item.quantity}</td>
                                                <td className="text-left py-3 px-4">{item.Order_Item_Status}</td>
                                                <td className="text-left py-3 px-4">{item.SaleAmount}</td>
                                                {/*<td className="text-left py-3 px-4 grid gap-[5px]">*/}
                                                {/*     <button*/}
                                                {/*        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"*/}
                                                {/*        onClick={(e) => handleApprove(item.order_id, e)}*/}
                                                {/*    >*/}
                                                {/*        Approve*/}
                                                {/*    </button>*/}
                                                {/*    <button*/}
                                                {/*        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"*/}
                                                {/*        onClick={(e) => handleReject(item.order_id, e)}*/}
                                                {/*    >*/}
                                                {/*        Reject*/}
                                                {/*    </button> */}
                                                {/*</td>*/}
                                            </tr>
                                        ))}
                                </tbody>


                            </table>
                        </div>
                    </>
                )}
                {/*{isModalOpen && (*/}
                {/*    <div className="fixed z-10 inset-0 overflow-y-auto">*/}
                {/*        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">*/}
                {/*            <div className="fixed inset-0 transition-opacity">*/}
                {/*                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>*/}
                {/*            </div>*/}
                {/*            <span className="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>*/}
                {/*            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">*/}
                {/*                <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">*/}
                {/*                    <div>{modalContent}</div>*/}
                {/*                </div>*/}
                {/*                <div className="px-4 py-3 sm:px-6 flex gap-5">*/}
                {/*                    <button className="w-full inline-flex justify-center rounded-md border border-transparent px-4 py-2 bg-blue-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">*/}
                {/*                        Create Shipment*/}
                {/*                    </button>*/}

                {/*                    <button onClick={() => setIsModalOpen(false)} className="w-full inline-flex justify-center rounded-md border border-transparent px-4 py-2 bg-red-500 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">*/}
                {/*                        Do It Later!*/}
                {/*                    </button>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*)}*/}
            </div>
        </div>
    );
}

export default SearchableOrderTable;














//import React, { useState, useEffect, useContext } from 'react';

//// Assuming AuthContext and CatalogProductContext are already defined and exported from your context files
//import { AuthContext } from '../../../../src/context/AuthContext';
//import CatalogProductContext from '../../../../src/context/CatalogProductContext';

//function SearchableTable() {
//    const { authToken } = useContext(AuthContext);
//    const { dataSource, loadData } = useContext(CatalogProductContext);
//    const [currentPage, setCurrentPage] = useState(1);
//    const [dataPerPage] = useState(10);
//    const [isModalOpen, setIsModalOpen] = useState(false);
//    const [modalContent, setModalContent] = useState('');

//    // Pagination Logic
//    const indexOfLastData = currentPage * dataPerPage;
//    const indexOfFirstData = indexOfLastData - dataPerPage;
//    const currentData = dataSource && dataSource.length > 0 ? dataSource.slice(indexOfFirstData, indexOfLastData) : [];

//    const paginate = pageNumber => setCurrentPage(pageNumber);

//    // Action Handlers
//    const handleApprove = (orderId) => {
//        setIsModalOpen(true);
//        setModalContent(`Congratulations you have approved order ID ${orderId}.`);
//    };

//    const handleReject = (orderId) => {
//        console.log("Rejected order ID:", orderId);
//    };

//    useEffect(() => {
//        if (authToken) {
//            loadData(authToken);
//        }
//    }, [authToken, loadData]);

//    // Manual CSV Export Handler
//    const exportToCSV = () => {
//        const headers = ["Date", "Marketplace", "Sku Name", "Order ID", "Quantity", "Status", "Total Amount Sold"];
//        const rows = dataSource.map(d => [d.order_date, d.platform, d.sku_name, d.order_id, d.quantity, d.Order_Item_Status, d.SaleAmount]);
//        let csvContent = "data:text/csv;charset=utf-8," + [headers.join(","), ...rows.map(e => e.join(","))].join("\n");
//        const encodedUri = encodeURI(csvContent);
//        const link = document.createElement("a");
//        link.setAttribute("href", encodedUri);
//        link.setAttribute("download", "data-grid.csv");
//        document.body.appendChild(link); // Required for FF
//        link.click();
//        document.body.removeChild(link);
//    };

//    return (
//        <div>
//            <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
//                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
//                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
//                        <tr>
//                            <th className="py-3 px-6">Date</th>
//                            <th className="py-3 px-6">Marketplace</th>
//                            <th className="py-3 px-6">Sku Name</th>
//                            <th className="py-3 px-6">Order ID</th>
//                            <th className="py-3 px-6">Quantity</th>
//                            <th className="py-3 px-6">Status</th>
//                            <th className="py-3 px-6">Total Amount Sold</th>
//                            <th className="py-3 px-6">Actions</th>
//                        </tr>
//                    </thead>
//                    <tbody>
//                        {currentData.map((data) => (
//                            <tr key={data.order_id}>
//                                <td className="py-4 px-6">{data.order_date}</td>
//                                <td className="py-4 px-6">{data.platform}</td>
//                                <td className="py-4 px-6">{data.sku_name}</td>
//                                <td className="py-4 px-6">{data.order_id}</td>
//                                <td className="py-4 px-6">{data.quantity}</td>
//                                <td className="py-4 px-6">{data.Order_Item_Status}</td>
//                                <td className="py-4 px-6">{data.SaleAmount}</td>
//                                <td className="py-4 px-6">
//                                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2" onClick={() => handleApprove(data.order_id)}>Approve</button>
//                                    <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" onClick={() => handleReject(data.order_id)}>Reject</button>
//                                </td>
//                            </tr>
//                        ))}
//                    </tbody>
//                </table>
//                <div className="py-2">
//                    {[...Array(Math.ceil(dataSource.length / dataPerPage)).keys()].map(number => (
//                        <button key={number} onClick={() => paginate(number + 1)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 m-1 rounded">{number + 1}</button>
//                    ))}
//                </div>
//            </div>

//            {isModalOpen && (
//                <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
//                    <div className="bg-white p-5 rounded-lg">
//                        <h3 className="text-lg font-medium leading-6 text-gray-900">{modalContent}</h3>
//                        <div className="mt-4">
//                            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => setIsModalOpen(false)}>Close</button>
//                        </div>
//                    </div>
//                </div>
//            )}

//            <button className="my-3 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" onClick={exportToCSV}>Download CSV</button>
//        </div>
//    );
//}

//export default SearchableTable;
