

const usePath = () => {
  
  const orderDetailPath = (orderId) => `/order/${orderId}`;
  const purchaseOrders = `/manage/purchaseorders`;


  return {
    purchaseOrders,
    orderDetailPath

  };
};

export default usePath;
