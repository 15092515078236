import React, { createContext, useState, useEffect } from 'react';

export const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
    const [username, setUserName] = useState(null);

    const getUsername = () =>{
        return localStorage?.getItem('username')??'';
    }

    useEffect(() => {
        // Replace with actual logic to retrieve stored user details/token
        const storedUserName= localStorage.getItem('username');
        if (storedUserName) {
            setUserName(storedUserName);
        }
    }, []);

    return (
        <UserContext.Provider value={{ username, setUserName,getUsername }}>
            {children}
        </UserContext.Provider>
    );
};

export default UserContext;


