import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import AuthContext from "context/AuthContext";
import * as XLSX from 'xlsx';


const OrderSupplierDashboard = () => {

    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [submitError, setSubmitError] = useState(false);
    const { authToken } = useContext(AuthContext);
    const [companies, setCompanies] = useState([]);
    const [skus, setSkus] = useState([]);
    const [selectedVariants, setSelectedVariants] = useState([]);
    const [displayLimit, setDisplayLimit] = useState([]);
    const [currentStep, setCurrentStep] = useState(1);

    const [formChannelData, setFormChannelData] = useState({
        customer_name: '',
        customer_number: '',
        customer_address: '',
        cost_price: '',
        selling_price: '',
        payment_mode: '',
        invoice_number: '',
        buyer_gst: '',
        commission_percentage: '',
        commission_price: '',
        selectedCompany: '',
        selectedSkus: [],
    });

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_API_URL}/company/`, {
            headers: {
                'Authorization': `Token ${authToken}`
            }
        })
            .then(response => response.json())
            .then(data => {
                setCompanies(data);
            })
            .catch(error => {
                console.error('Error fetching companies:', error);
            });
    }, [authToken]);

    const fetchSkusForCompany = (companyName) => {
        fetch(`${process.env.REACT_APP_BASE_API_URL}/sku/getSkusForCompany/?company_name=${encodeURIComponent(companyName)}`, {
            headers: {
                'Authorization': `Token ${authToken}`,
            }
        })
            .then(response => response.json())
            .then(data => {
                const skusWithLabel = data.map(sku => ({
                    ...sku,
                    label: sku.name // Adjust this based on your actual data structure
                }));
                setSkus(skusWithLabel);
            })
            .catch(error => {
                console.error('Error fetching SKUs:', error);
            });
    };

    const handleChannelCompanyChange = (event) => {
        const selectedCompany = event.target.value;
        setFormChannelData(prev => ({
            ...prev,
            selectedCompany: selectedCompany,
        }));
        fetchSkusForCompany(selectedCompany);
    };

    const handleChannelInputChange = (event) => {
        const { name, value } = event.target;
        setFormChannelData(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    //const handleSkuClick = (clickedSku) => {
    //    const isAlreadySelected = formChannelData.selectedSkus.some(sku => sku.id === clickedSku.id);

    //    if (isAlreadySelected) {
    //        // Remove the SKU if it's already selected
    //        setFormChannelData(prev => ({
    //            ...prev,
    //            selectedSkus: prev.selectedSkus.filter(sku => sku.id !== clickedSku.id),
    //        }));
    //    } else {
    //        // Add the SKU, ensuring the 'id' is included
    //        setFormChannelData(prev => ({
    //            ...prev,
    //            selectedSkus: [...prev.selectedSkus, clickedSku],
    //        }));
    //    }
    //};



    
    const [skuSearch, setSkuSearch] = useState(""); // State for SKU search input

    const handleSkuClick = (clickedSku) => {
        const isAlreadySelected = formChannelData.selectedSkus.some(sku => sku.id === clickedSku.id);

        if (isAlreadySelected) {
            const updatedSkus = formChannelData.selectedSkus.filter(sku => sku.id !== clickedSku.id);
            setFormChannelData(prev => ({
                ...prev,
                selectedSkus: updatedSkus
            }));
        } else {
            const updatedSkus = [...formChannelData.selectedSkus, clickedSku];
            setFormChannelData(prev => ({
                ...prev,
                selectedSkus: updatedSkus
            }));
        }
    };

    const removeSku = (skuId) => {
        const updatedSkus = formChannelData.selectedSkus.filter(sku => sku.id !== skuId);
        setFormChannelData(prev => ({ ...prev, selectedSkus: updatedSkus }));
    };

    const filteredSkus = skus.filter(sku =>
        sku.name.toLowerCase().includes(skuSearch.toLowerCase())
    );




    const handleOrderSubmit = async (event) => {
        event.preventDefault();
        let bodyFormData = new FormData();

        // Loop through formChannelData except 'selectedSkus' and append each to FormData
        Object.entries(formChannelData).forEach(([key, value]) => {
            if (key !== 'selectedSkus') {
                bodyFormData.append(key, value);
            } else {
                // Serialize 'selectedSkus' as a JSON string and append
                bodyFormData.append(key, JSON.stringify(value.map(sku => ({
                    ...sku,
                    // Ensure we are correctly handling potential undefined 'id's
                    id: sku.id || '' // Adjust this based on how you want to handle undefined ids
                }))));
            }
        });

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/customerorder/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${authToken}` // Ensure this matches what your API expects
                },
                body: bodyFormData,
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(`Submission failed: ${errorData.message}`); // More detailed error message
            }
            setSubmitSuccess(true);
            setSubmitError(false);
            setFormChannelData({
                customer_name: '',
                customer_number: '',
                customer_address: '',
                cost_price: '',
                selling_price: '',
                payment_mode: '',
                invoice_number: '',
                buyer_gst: '',
                commission_percentage: '',
                commission_price: '',
                selectedCompany: '',
                selectedSkus: [],
            });
        } catch (error) {
            console.error(error);
            setSubmitSuccess(false);
            setSubmitError(true);
        }
    };



    // Function to update additional information for a selected SKU
    const handleAdditionalInfoChange = (id, value) => {
        setFormChannelData(prev => ({
            ...prev,
            selectedSkus: prev.selectedSkus.map(sku =>
                sku.id === id ? { ...sku, additionalInfo: value } : sku
            ),
        }));
    };


    const handleShowMore = () => {
        setDisplayLimit(currentLimit => currentLimit + 10);
    };

    const handleDropdownClick = (e) => {
        e.stopPropagation(); // This prevents the onBlur event from firing when clicking inside the dropdown
    };


    const nextStep = () => {
        setCurrentStep(currentStep + 1);
    };

    const prevStep = () => {
        setCurrentStep(currentStep - 1);
    };




    const [isPopupOpen, setPopupOpen] = useState(false);
    const [step, setStep] = useState(1);
    const [selection, setSelection] = useState('');

    const openPopup = () => {
        setPopupOpen(true);
    };

    const closePopup = () => {
        setPopupOpen(false);
        setStep(1); // Reset the step to 1 when closing the modal.
    };

    const handleBulkUpload = async () => {
        // Convert items to the format expected by the backend
        const payload = {
            products: items
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/productbulk/bulk/`, { // Update the URL path accordingly
                method: 'POST',
                headers: {
                    'Authorization': `Token ${authToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                // Handle success here (e.g., showing a success message)
                alert("Bulk upload successful!");
            } else {
                // Handle request failure here (e.g., showing an error message)
                const errorData = await response.json();
                alert("Bulk upload failed: " + errorData.error);
            }
        } catch (error) {
            console.error("Bulk upload error:", error);
            alert("An error occurred during bulk upload.");
        }
    };

    const handleAddProductClick = (choice) => {
        setSelection(choice);
        setStep(2);
    };


    const [currentSteps, setCurrentSteps] = useState(1);
    const [stepsCompleted, setStepsCompleted] = useState([false, false]);

    const handleValidate = () => {
        setStepsCompleted({ ...stepsCompleted, [currentSteps - 1]: true });
        if (currentSteps < 2) setCurrentSteps(currentSteps + 1);
    };

    const handlePrevious = () => {
        setCurrentSteps(currentSteps - 1);
    };

    const isStepCompleted = (step) => {
        return stepsCompleted[step - 1];
    };


    const downloadExcelTemplate = () => {
        // Path to the file in the public directory
        //const filePath = `${process.env.PUBLIC_URL}/assets/documents/order-bulk-upload.xlsx`;
        const filePath = "https://fastupload.io/brz8NZd7rCdx4Vh/file";
        const link = document.createElement('a');
        link.href = filePath;
        link.download = 'order-bulk-upload.xlsx'; // Name of the file to download
        //document.body.appendChild(link);
        //link.click();
        //document.body.removeChild(link);
        window.open(filePath, '_blank');

    };


    const [items, setItems] = useState([]);
    const [isPopupModalOpen, setIsPopupModalOpen] = useState(false);
    const [csvFile, setCsvFile] = useState(null);

    //const fieldMappings = {
    //    // Example mapping: CSV header: Backend field name
    //    "PRODUCT NAME": "name",
    //    "PRODUCT DESCRIPTION": "description",
    //    "VARIANT CODE": "variant_code",
    //    "HSN CODE": "hsn",
    //    "ASIN CODE": "asin",
    //    "COUNTRY": "country",
    //};

    // Function to read and process the file
    const readFile = (file) => {
        const reader = new FileReader();
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
            const headers = data[0];
            const rows = data.slice(1).map((row) => {
                let rowData = {};
                row.forEach((cell, index) => {
                    // Use the fieldMappings to set the correct backend field name
                    const backendFieldName = headers[index];
                    rowData[backendFieldName] = cell;
                });
                return rowData;
            });
            setItems(rows);
        };
        reader.readAsBinaryString(file);
    };

    //const backendFieldName = fieldMappings[headers[index]] || headers[index];


    // Effect hook to process file upon selection
    useEffect(() => {
        if (csvFile) {
            readFile(csvFile);
        }
    }, [csvFile]);

    // Function to handle file selection
    const handleFileUpload = (e) => {
        const uploadedFile = e.target.files[0];
        if (uploadedFile) {
            setCsvFile(uploadedFile);
        }
    };

    // Function to toggle the popup modal visibility
    const togglePopupModal = () => {
        setIsPopupModalOpen(!isPopupModalOpen);

    };

    const renderStepContent = () => {
        switch (currentStep) {
            case 1:
                return (
                    <>
                        {/*Company and SKU Details*/}

                        <div className="flex justify-between items-center mb-6">
                            <h2 className="text-lg font-bold">Add Order</h2>


                            <div>
                                <button
                                    onClick={openPopup}
                                    className="bg-brand-300 hover:bg-brand-400 text-white font-bold py-2 px-4 rounded flex items-center mt-4"
                                >
                                    + Add Bulk Order
                                </button>
                            </div>

                            {isPopupOpen && (
                                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
                                    <div className="relative top-20 mx-auto p-5 py-[30px] border w-fit shadow-lg rounded-md bg-white">

                                        <div className="absolute top-0 right-0 pt-4 pr-4">
                                            <button
                                                onClick={closePopup}
                                                className="px-4 py-2 bg-gray-300 text-gray-900 rounded hover:bg-gray-400"
                                            >
                                                X
                                            </button>
                                        </div>

                                        <div className="mt-3">
                                            <div>
                                                {/*<h2>Add Products in Bulk Form</h2>*/}
                                                <div className="inset-0 overflow-y-auto h-fit w-full flex items-center justify-center">
                                                    <div className="w-auto m-4">
                                                        <div className="pt-4">
                                                            <h3 className="text-xl leading-6 mb-3 font-medium text-black">Add Order in bulk</h3>

                                                            {/* Step Indicator */}
                                                            {/*<div className="flex justify-between items-center p-4 w-full gap-10">*/}
                                                            {/*    {[1, 2].map(step => (*/}
                                                            {/*        <div key={step} className={`flex items-center ${currentStep >= step ? 'text-blue-500' : 'text-gray-500'}`}>*/}
                                                            {/*            <div className="rounded-full h-8 w-8 flex items-center justify-center border-2 border-blue-500">*/}
                                                            {/*                {isStepCompleted(step) ? (*/}
                                                            {/*                    <span className="text-xs">✓</span>*/}
                                                            {/*                ) : (*/}
                                                            {/*                    <span className="text-xs">{step}</span>*/}
                                                            {/*                )}*/}
                                                            {/*            </div>*/}
                                                            {/*            <span className="ml-2">{step === 1 ? 'Upload Excel File' : 'Upload Product Images'}</span>*/}
                                                            {/*        </div>*/}
                                                            {/*    ))}*/}
                                                            {/*</div>*/}

                                                            <hr />

                                                            {/* Content based on step */}
                                                            <div className="pt-5">
                                                                {/* Content based on step */}
                                                                {currentStep === 1 && (
                                                                    <div>
                                                                        <p className="text-md text-gray-700 underline">Instructions on uploading csv file</p>

                                                                        <div className="mt-4 flex justify-between p-5 items-center bg-gray-100 rounded-lg">
                                                                            <p className="text-sm text-black">1. Download the csv template.</p>
                                                                            <button
                                                                                className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none"
                                                                                type="button"
                                                                                onClick={downloadExcelTemplate} // Updated here
                                                                                style={{ transition: "all .15s ease" }}
                                                                            >
                                                                                Download Template
                                                                            </button>
                                                                        </div>

                                                                        <div className="mt-4 p-5 bg-gray-100 rounded-lg">
                                                                            <p className="text-sm text-black">2. Fill in your product details in the csv template.</p>
                                                                            <p className="text-sm text-black">Note: Do not edit the column names.</p>
                                                                        </div>

                                                                        <div className="mt-4 p-5 bg-gray-100 rounded-lg">
                                                                            <p className="text-sm text-black">3. Upload the file to Beyobo.</p>
                                                                            <div className="w-full border-brand-300 rounded-md shadow-sm bg-white p-3 border border-dashed my-2">
                                                                                <input
                                                                                    type="file"
                                                                                    name="productImage"
                                                                                    accept=".xlsx, .xls" onChange={handleFileUpload}
                                                                                    required
                                                                                    className="w-full border-gray-300 rounded-md shadow-sm file:mr-4 file:py-2 file:px-4
                file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700
                hover:file:bg-blue-100"
                                                                                />
                                                                            </div>
                                                                        </div>{/*onClick={handleCSVUpload}*/}

                                                                        <div className="flex justify-between gap-10">
                                                                            <button
                                                                                type="button"
                                                                                onClick={togglePopupModal}
                                                                                disabled={!items.length}
                                                                                className="bg-brand-400 hover:bg-brand-300 text-white active:bg-blue-600 font-bold uppercase text-xs px-6 py-3 rounded shadow hover:shadow-md outline-none focus:outline-none w-full mt-4"
                                                                                style={{ transition: "all .15s ease" }}
                                                                            >
                                                                                Show
                                                                            </button>

                                                                            <button
                                                                                className="bg-blue-600 text-white active:bg-blue-600 font-bold uppercase text-xs px-6 py-3 rounded shadow hover:bg-blue-500 outline-none focus:outline-none w-full mt-4"
                                                                                type="button"
                                                                                onClick={handleBulkUpload}
                                                                                style={{ transition: "all .15s ease" }}
                                                                            >
                                                                                Submit
                                                                            </button>
                                                                        </div>

                                                                    </div>
                                                                )}

                                                                {currentStep === 2 && (
                                                                    <div className="bg-gray-100 p-4 rounded-md">
                                                                        <p className="text-sm text-gray-500">Instructions on uploading product images</p>
                                                                        <div className="mt-4">
                                                                            <p className="text-sm text-gray-600">1. Ensure images are in JPG or PNG format.</p>
                                                                            <p className="text-sm text-gray-600">2. Upload the image files below.</p>
                                                                            <input type="file" className="text-gray-500 py-2 px-4 w-full mt-2" multiple />
                                                                        </div>
                                                                        <div className="flex justify-between mt-4">
                                                                            <button
                                                                                className="bg-gray-300 text-gray-800 active:bg-gray-500 font-bold uppercase text-xs px-6 py-3 rounded outline-none focus:outline-none"
                                                                                type="button"
                                                                                onClick={handlePrevious}
                                                                                style={{ transition: "all .15s ease" }}
                                                                            >
                                                                                Previous
                                                                            </button>
                                                                            <button
                                                                                className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-6 py-3 rounded shadow hover:shadow-md outline-none focus:outline-none"
                                                                                type="button"
                                                                                onClick={handleValidate}
                                                                                style={{ transition: "all .15s ease" }}
                                                                            >
                                                                                Finish
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {isPopupModalOpen && (
                                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center z-[1000]">
                                    <div className="bg-white rounded-lg shadow-2xl p-4 max-w-4xl w-full mx-2 my-6">
                                        <div className="flex justify-between items-center mb-4">
                                            <h2 className="text-2xl font-bold">Uploaded Products</h2>
                                            <button
                                                onClick={() => setIsPopupModalOpen(false)}
                                                className="text-gray-600 hover:text-gray-900"
                                            >
                                                <svg className="w-6 h-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path d="M6 18L18 6M6 6l12 12"></path>
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="overflow-auto">
                                            <table className="min-w-full leading-normal">
                                                <thead>
                                                    <tr>
                                                        {items[0] && Object.keys(items[0]).map((header) => (
                                                            <th
                                                                key={header}
                                                                className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                                                            >
                                                                {header}
                                                            </th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {items.map((item, index) => (
                                                        <tr key={index}>
                                                            {Object.values(item).map((val, i) => (
                                                                <td key={i} className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                                                                    {val}
                                                                </td>
                                                            ))}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="text-end mt-5">
                                            <button className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed">
                                                Confirm
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>


                        <div>
                            <div className="items-center w-full mb-4">
                                <label htmlFor="company-select" className="block text-sm font-medium text-gray-700">Select Company</label>
                                <select required id="company-select" value={formChannelData.selectedCompany} className="mt-1 p-2 border-2 border-gray-300 w-full rounded-lg cursor-pointer" onChange={handleChannelCompanyChange}>

                                    <option value="">Select a Company</option>
                                    {companies.map((company) => (
                                        <option value={company.name} key={company.name}>
                                            {company.name}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {/* SKU Selection Section */}
                            <div className="mb-4">
                                <div>
                                    <div className="flex items-center justify-between">
                                        <label className="block text-sm font-medium text-gray-700">Select SKUs</label>

                                        {/*<button onClick={openSkuFormDialog} className="bg-gray-500 hover:bg-brand-400 text-white font-bold py-1 px-2 rounded">*/}
                                        {/*    Add Sku*/}
                                        {/*</button>*/}
                                    </div>

                                    <input
                                        type="text"
                                        placeholder="Search SKUs..."
                                        value={skuSearch}
                                        onChange={e => setSkuSearch(e.target.value)}
                                        className="mt-1 p-2 border-2 border-gray-300 w-full rounded-lg"
                                    />
                                </div>

                                <div className="mt-1 border-2 border-gray-300 w-full rounded-lg overflow-y-auto" style={{ maxHeight: '150px' }}>
                                    {filteredSkus.map(sku => (
                                        <button
                                            type="button"
                                            key={sku.id}
                                            onClick={() => handleSkuClick(sku)}
                                            className={`p-2 w-full text-left ${formChannelData.selectedSkus.some(selectedSku => selectedSku.id === sku.id) ? 'bg-gray-200' : ''}`}
                                        >
                                            {sku.name}
                                        </button>
                                    ))}
                                </div>
                            </div>

                            {/* Selected SKUs Section */}
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700 mb-3">Selected SKUs:</label>
                                <div className="flex justify-between mx-[56px] my-3">
                                    <p className="block font-medium text-gray-700">SKU Name</p>
                                    <p className="block font-medium text-gray-700">Listing SKU</p>
                                </div>

                                <div className="flex flex-col gap-2">
                                    {formChannelData.selectedSkus.map((sku, index) => (
                                        <div key={index} className="flex items-center justify-between my-2 bg-white p-2 rounded-lg shadow border relative">
                                            <span>{sku.name}</span>
                                            <input
                                                type="text"
                                                placeholder="Listing SKU"
                                                value={sku.listing_sku || ''}
                                                onChange={(e) => {
                                                    const updatedSkus = formChannelData.selectedSkus.map(item =>
                                                        item.id === sku.id ? { ...item, listing_sku: e.target.value } : item
                                                    );
                                                    setFormChannelData(prev => ({ ...prev, selectedSkus: updatedSkus }));
                                                }}
                                                className="ml-4 p-1 border border-gray-300 rounded"
                                            />
                                            <span onClick={() => removeSku(sku.id)} className="absolute right-0 top-[-8px] cursor-pointer text-red-500 hover:text-red-700 text-lg">
                                                <svg width="14px" height="14px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM15.75 12C15.75 12.4142 15.4142 12.75 15 12.75H9C8.58579 12.75 8.25 12.4142 8.25 12C8.25 11.5858 8.58579 11.25 9 11.25H15C15.4142 11.25 15.75 11.5858 15.75 12Z" fill="#ff0000">
                                                    </path>
                                                </svg>
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </div>


                            {/* SKU Selection Section */}
                            {/*<div className="mb-4">*/}
                            {/*    <label className="block text-sm font-medium text-gray-700">Select SKUs</label>*/}
                            {/*    <div className="mt-1 border-2 border-gray-300 w-full rounded-lg overflow-y-auto" style={{ maxHeight: '150px' }}>*/}
                            {/*        {skus.map((sku) => (*/}
                            {/*            <button*/}
                            {/*                type="button"*/}
                            {/*                key={sku.id}*/}
                            {/*                onClick={() => handleSkuClick(sku)}*/}
                            {/*                className={`p-2 w-full text-left ${formChannelData.selectedSkus.some(selectedSku => selectedSku.id === sku.id) ? 'bg-gray-200' : ''}`}*/}
                            {/*            >*/}
                            {/*                {sku.name}*/}
                            {/*            </button>*/}
                            {/*        ))}*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/* Selected SKUs Section */}
                            {/*<div className="mb-4">*/}
                            {/*    <label className="block text-sm font-medium text-gray-700 mb-3">Selected SKUs:</label>*/}

                            {/*    <div className="flex justify-between mx-[56px] my-3">*/}
                            {/*        <p className="block font-medium text-gray-700">SKU Name</p>*/}
                            {/*        <p className="block font-medium text-gray-700">Quantity</p>*/}
                            {/*    </div>*/}

                            {/*    <div className="flex flex-col gap-2">*/}
                            {/*        {formChannelData.selectedSkus.map((sku, index) => (*/}
                            {/*            <div key={index} className="flex justify-between items-center gap-2 p-2 border rounded">*/}
                            {/*                {sku.name}*/}
                            {/*                <input*/}
                            {/*                    type="number"*/}
                            {/*                    placeholder="SKU details..."*/}
                            {/*                    value={sku.quantity || ''}*/}
                            {/*                    onChange={(e) => {*/}
                            {/*                        // Create a new array with updated quantities*/}
                            {/*                        const updatedSkus = [...formChannelData.selectedSkus];*/}
                            {/*                        updatedSkus[index] = { ...sku, quantity: e.target.value };*/}
                            {/*                        setFormChannelData(prevFormSkuData => ({*/}
                            {/*                            ...prevFormSkuData,*/}
                            {/*                            selectedSkus: updatedSkus*/}
                            {/*                        }));*/}
                            {/*                    }}*/}
                            {/*                    className="w-1/3 border-gray-300 rounded-md shadow-sm bg-white p-3 border"*/}
                            {/*                />*/}
                            {/*            </div>*/}
                            {/*        ))}*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </>
                );


            case 2:
                return (
                    <>
                        {/*Customer Details*/}
                        <div className="w-1/2">
                            <div className="items-center w-full my-4">
                                <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name *</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="customer_name"
                                    required
                                    value={formChannelData.customer_name}
                                    onChange={handleChannelInputChange}
                                    //onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                    className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                    placeholder="Enter name"
                                />
                            </div>

                            <div className="items-center w-full my-4">
                                <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">Phone Number *</label>
                                <input
                                    type="text"
                                    id="phoneNumber"
                                    name="customer_number"
                                    required
                                    value={formChannelData.customer_number}
                                    onChange={handleChannelInputChange}
                                    //onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                    className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                    placeholder="Enter Phone Number"
                                />
                            </div>

                            <div className="items-center w-full my-4">
                                <label htmlFor="address" className="block text-sm font-medium text-gray-700">Address *</label>
                                <input
                                    type="text"
                                    id="address"
                                    name="customer_address"
                                    required
                                    value={formChannelData.customer_address}
                                    onChange={handleChannelInputChange}
                                    //onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                    className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                    placeholder="Enter address"
                                />
                            </div>
                        </div>
                    </>
                );


            case 3:
                return (
                    <>
                        {/*Payment Details*/}
                        <div className="w-1/2">
                            <div className="gap-4 mb-4">
                                <label htmlFor="payment_mode" className="block text-sm font-medium text-gray-700">Payment Mode *</label>
                                <div className="mt-2">
                                    <label className="inline-flex items-center">
                                        <input
                                            type="radio"
                                            name="payment_mode"
                                            value="Cash"
                                            checked={formChannelData.payment_mode === 'Cash'}
                                            onChange={handleChannelInputChange}
                                            className="form-radio"
                                        />
                                        <span className="ml-2">Cash</span>
                                    </label>
                                    <label className="inline-flex items-center ml-6">
                                        <input
                                            type="radio"
                                            name="payment_mode"
                                            value="Online"
                                            checked={formChannelData.payment_mode === 'Online'}
                                            onChange={handleChannelInputChange}
                                            className="form-radio"
                                        />
                                        <span className="ml-2">Online</span>
                                    </label>
                                    {/*<label className="inline-flex items-center ml-6">*/}
                                    {/*    <input*/}
                                    {/*        type="radio"*/}
                                    {/*        name="payment_mode"*/}
                                    {/*        value="Others"*/}
                                    {/*        checked={formChannelData.payment_mode === 'Others'}*/}
                                    {/*        onChange={handleChannelInputChange}*/}
                                    {/*        className="form-radio"*/}
                                    {/*    />*/}
                                    {/*    <span className="ml-2">Others</span>*/}
                                    {/*</label>*/}
                                </div>
                            </div>



                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                                <div>
                                    <label htmlFor="cost_price" className="block text-sm font-medium text-gray-700">Cost Price *</label>
                                    <input
                                        type="number"
                                        id="cost_price"
                                        name="cost_price"
                                        value={formChannelData.cost_price}
                                        onChange={handleChannelInputChange}
                                        // onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        required
                                        className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                        placeholder="Enter Cost Price"
                                    />
                                </div>

                                <div>
                                    <label htmlFor="selling_price" className="block text-sm font-medium text-gray-700">Selling Price *</label>
                                    <input
                                        type="number"
                                        id="selling_price"
                                        name="selling_price"
                                        value={formChannelData.selling_price}
                                        onChange={handleChannelInputChange}
                                        // onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        required
                                        className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                        placeholder="Enter Selling Price"
                                    />
                                </div>
                            </div>



                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                                <div>
                                    <label htmlFor="invoice_number" className="block text-sm font-medium text-gray-700">Invoice Number *</label>
                                    <input
                                        type="text"
                                        id="invoice_number"
                                        name="invoice_number"
                                        value={formChannelData.invoice_number}
                                        onChange={handleChannelInputChange}
                                        // onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        required
                                        className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                        placeholder="Enter Invoice Number"
                                    />
                                </div>

                                <div>
                                    <label htmlFor="buyer_gst" className="block text-sm font-medium text-gray-700">Buyer GST *</label>
                                    <input
                                        type="text"
                                        id="buyer_gst"
                                        name="buyer_gst"
                                        value={formChannelData.buyer_gst}
                                        onChange={handleChannelInputChange}
                                        // onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        required
                                        className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                        placeholder="Enter Buyer GST"
                                    />
                                </div>
                            </div>


                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                                <div>
                                    <label htmlFor="commission_percentage" className="block text-sm font-medium text-gray-700">Commission Percentage *</label>
                                    <input
                                        type="number"
                                        id="commission_percentage"
                                        name="commission_percentage"
                                        value={formChannelData.commission_percentage}
                                        onChange={handleChannelInputChange}
                                        // onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        required
                                        className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                        placeholder="Enter Invoice Number"
                                    />
                                </div>

                                <div>
                                    <label htmlFor="commission_price" className="block text-sm font-medium text-gray-700">Commission Price *</label>
                                    <input
                                        type="number"
                                        id="commission_price"
                                        name="commission_price"
                                        value={formChannelData.commission_price}
                                        onChange={handleChannelInputChange}
                                        // onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        required
                                        className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                        placeholder="Enter Buyer GST"
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <div>
                <form id="catalogue_variant" onSubmit={handleOrderSubmit}>

                    {renderStepContent()}

                    <div className="flex w-1/2 justify-between mt-4">
                        {currentStep > 1 && (
                            <button
                                type="button"
                                className="bg-gray-600 text-white px-4 py-2 rounded-md hover:bg-gray-700"
                                onClick={() => setCurrentStep(currentStep - 1)}
                            >
                                Previous
                            </button>
                        )}

                        {currentStep < 3 ? (
                            <button
                                type="button"
                                className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
                                onClick={() => setCurrentStep(currentStep + 1)}
                            >
                                Next
                            </button>
                        ) : (
                            <button
                                type="submit"
                                className="bg-green-600 text-white px-4 py-2 rounded-md hover:bg-green-700"
                            >
                                Submit
                            </button>
                        )}
                    </div>


                    {/*<div className="flex justify-end mt-4">*/}
                    {/*    <button*/}
                    {/*        type="submit"*/}
                    {/*        className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"*/}
                    {/*    >*/}
                    {/*        Add Channel*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                </form>
                {submitSuccess && <div>Your form has been submitted successfully!</div>}
                {submitError && <div>Error submitting form. Please try again.</div>}
            </div>
        </>
    );
};

export default OrderSupplierDashboard;