import Map from "../../../components/IndiaMap/Map";
import CostTable from "../../../components/frameComponent/CostTable";
import { AuthContext } from "../../../context/AuthContext";
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import "./BusinessDashboard.css";
import { useContext, useEffect, useState } from "react";
import SplineChart from "../../../components/charts/SplineChart";

const BusinessDashboard = () => {
  const [activeTab, setActiveTab] = useState("Suppliers");
  const [openSubTabs, setOpenSubTabs] = useState({
    Suppliers: true,
    Location: false,
  });
  const [selectedPlatform, setSelectedPlatform] = useState("Amazon"); // New state for tracking dropdown selection

  const [amazontimeframe, setAmazonTimeframe] = useState("monthly");
  const [amazondata, setAmazonData] = useState([]);
  const [flipkarttimeframe, setFlipkartTimeframe] = useState("monthly");
  const [flipkartdata, setFlipkartData] = useState([]);
  const { authToken } = useContext(AuthContext);
  const [topSellingSkus, setTopSellingSkus] = useState([]);
  const [leastSellingSkus, setLeastSellingSkus] = useState([]);

  const [selectedAmazonSku, setSelectedAmazonSku] = useState("");

  const [selectedFlipkartSku, setSelectedFlipkartSku] = useState("");

  const [amazonskus, setAmazonSkus] = useState([]);
  const [flipkartskus, setFlipkartSkus] = useState([]);
  const [regionData, setRegionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [chartOptions, setChartOptions] = useState({
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "date",
    },
  });

  const [chartSeries, setChartSeries] = useState([]);

  const [selectedChart, setSelectedChart] = useState("az");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_API_URL}/amazonuniqueskus/`,
          {
            headers: {
              Authorization: `Token ${authToken}`,
            },
          }
        );
        if (!response.ok) throw new Error("Data fetch failed");
        const jsonData = await response.json();
        setAmazonSkus(jsonData);
        if (amazondata.length > 0) {
          setSelectedAmazonSku(jsonData[0]); // Automatically select the first SKU
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    fetchMapData();
  }, [authToken]);

  const fetchMapData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/statewisereturnsloss/as_list/`,
        {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      );
      if (!response.ok) throw new Error("Data fetch failed");
      const mapData = await response.json();

      const regionData = {};

      mapData.map((stateData) => {
        const { order_state, total_loss } = stateData;
        regionData[order_state] = {
          value: total_loss,
        };
      });

      setRegionData(regionData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const fetchGraphData = async () => {
      const dates = [...Array(7)].map((_, i) => {
        const d = new Date();
        d.setDate(d.getDate() - i);
        return d.toISOString().split("T")[0];
      });

      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_API_URL}/orderrevenue/orders_vs_revenue/`,
          {
            headers: {
              Authorization: `Token ${authToken}`,
            },
          }
        );
        if (!response.ok) throw new Error("Data fetch failed");
        const jsonData = await response.json();
        const categories = [],
          orders = [],
          revenue = [];
        const series = [
          {
            name: "Orders",
            data: [],
          },
          {
            name: "Revenue",
            data: [],
          },
        ];

        const options = {
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            type: "date",
            categories: [],
          },
        };
        dates.map((key) => {
          categories.push(key);

          const date = jsonData[key];
          if (date) {
            const ordersData = date[`${selectedChart}_orders`];
            const revenueData = date[`${selectedChart}_revenue`];

            orders.push(ordersData);
            revenue.push(revenueData);
          } else {
            orders.push(0);
            revenue.push(0);
          }
        });
        options["xaxis"]["categories"] = categories;
        series[0]["data"] = orders;
        series[1]["data"] = revenue;

        setChartOptions(options);
        setChartSeries(series);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchGraphData();
  }, [selectedChart]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${
            process.env.REACT_APP_BASE_API_URL
          }/amazonorderanalysis/?amazontimeframe=${amazontimeframe}&amazonsku=${encodeURIComponent(
            selectedAmazonSku
          )}`,
          {
            headers: {
              Authorization: `Token ${authToken}`,
            },
          }
        );
        if (!response.ok) throw new Error("Data fetch failed");
        const jsonData = await response.json();
        setAmazonData(jsonData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [amazontimeframe, authToken, selectedAmazonSku]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_API_URL}/flipkartuniqueskus/`,
          {
            headers: {
              Authorization: `Token ${authToken}`,
            },
          }
        );
        if (!response.ok) throw new Error("Data fetch failed");
        const jsonData = await response.json();
        setFlipkartSkus(jsonData);
        if (flipkartdata.length > 0) {
          setSelectedFlipkartSku(jsonData[0]); // Automatically select the first SKU
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [authToken]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${
            process.env.REACT_APP_BASE_API_URL
          }/flipkartorderanalysis/?flipkarttimeframe=${flipkarttimeframe}&flipkartsku=${encodeURIComponent(
            selectedFlipkartSku
          )}`,
          {
            headers: {
              Authorization: `Token ${authToken}`,
            },
          }
        );
        if (!response.ok) throw new Error("Data fetch failed");
        const jsonData = await response.json();
        setFlipkartData(jsonData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [flipkarttimeframe, authToken, selectedFlipkartSku]);

  // useEffect(() => {
  //   fetch(`${process.env.REACT_APP_BASE_API_URL}/amazonskuanalysis/`, {
  //     headers: {
  //       Authorization: `Token ${authToken}`,
  //     },
  //   }) // Adjust this URL based on your actual endpoint
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       setTopSellingSkus(data.top_selling_skus);
  //       setLeastSellingSkus(data.least_selling_skus);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //       setError(error.toString());
  //       setLoading(false);
  //     });
  // }, [authToken]);

  const renderAmazonChart = () => {
    if (amazontimeframe === "monthly") {
      return (
        <BarChart
          width={800}
          height={400}
          data={amazondata}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="period_start" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="total_amount" fill="#8884d8" />
        </BarChart>
      );
    } else {
      return (
        <LineChart
          width={800}
          height={400}
          data={amazondata}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="period_start" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="total_amount" stroke="#8884d8" />
        </LineChart>
      );
    }
  };

  const renderFlipkartChart = () => {
    if (flipkarttimeframe === "monthly") {
      return (
        <BarChart
          width={800}
          height={400}
          data={flipkartdata}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="period_start" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="total_amount" fill="#8884d8" />
        </BarChart>
      );
    } else {
      return (
        <LineChart
          width={800}
          height={400}
          data={flipkartdata}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="period_start" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="total_amount" stroke="#8884d8" />
        </LineChart>
      );
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case "Suppliers":
        return (
          <div style={{ width: "100%" }}>
            {/* New Dropdown for selecting the platform */}
            <div className="mt-5">
              <select
                value={selectedPlatform}
                onChange={(e) => setSelectedPlatform(e.target.value)}
                className="rounded-md border-2 border-gray-500 px-4 py-2"
              >
                <option value="Amazon">Amazon</option>
                <option value="Flipkart">Flipkart</option>
                {/* Add more options here as needed */}
              </select>
            </div>

            {/* Conditional rendering based on selectedPlatform */}
            {selectedPlatform === "Amazon" && (
              <div>
                <h2 className="my-5 text-2xl font-black">
                  Amazon Order Totals Chart
                </h2>

                <div className="mb-10 flex justify-between">
                  <div>
                    <label
                      htmlFor="timeframeSelect"
                      className="mb-2 block text-sm font-medium text-gray-700"
                    >
                      Select Timeframe:
                    </label>
                    <select
                      value={amazontimeframe}
                      onChange={(e) => setAmazonTimeframe(e.target.value)}
                      className="rounded-md border border-gray-300 bg-white p-3 shadow-sm"
                    >
                      <option value="monthly">Monthly</option>
                      <option value="weekly">Weekly</option>
                    </select>
                  </div>

                  {/* SKU selection moved above the chart */}
                  <div>
                    <label
                      htmlFor="skuSelect"
                      className="mb-2 block text-sm font-medium text-gray-700"
                    >
                      Select SKU:
                    </label>
                    <select
                      id="skuSelect"
                      value={selectedAmazonSku}
                      onChange={(e) => setSelectedAmazonSku(e.target.value)}
                      className="rounded-md border border-gray-300 bg-white p-3 shadow-sm"
                    >
                      <option value="All">All</option> {/* Add this line */}
                      {amazonskus.map((sku) => (
                        <option key={sku} value={sku}>
                          {sku}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {/* Now rendering the chart */}
                {renderAmazonChart()}

                <br></br>
              </div>
            )}

            {selectedPlatform === "Flipkart" && (
              <div style={{ width: "100%" }}>
                <h2 className="my-5 text-2xl font-black">
                  Flipkart Order Totals Chart
                </h2>

                <div className="mb-10 flex justify-between">
                  <div>
                    <label
                      htmlFor="timeframeSelect"
                      className="mb-2 block text-sm font-medium text-gray-700"
                    >
                      Select Timeframe:
                    </label>

                    <select
                      value={flipkarttimeframe}
                      onChange={(e) => setFlipkartTimeframe(e.target.value)}
                      className="rounded-md border border-gray-300 bg-white p-3 shadow-sm"
                    >
                      <option value="monthly">Monthly</option>
                      <option value="weekly">Weekly</option>
                    </select>
                  </div>

                  {/* SKU selection moved above the chart */}
                  <div>
                    <label
                      htmlFor="skuSelect"
                      className="mb-2 block text-sm font-medium text-gray-700"
                    >
                      Select SKU:
                    </label>

                    <select
                      id="skuSelect"
                      value={selectedFlipkartSku}
                      onChange={(e) => setSelectedFlipkartSku(e.target.value)}
                      className="rounded-md border border-gray-300 bg-white p-3 shadow-sm"
                    >
                      <option value="All">All</option> {/* Add this line */}
                      {flipkartskus.map((sku) => (
                        <option key={sku} value={sku}>
                          {sku}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {/* Now rendering the chart */}
                {renderFlipkartChart()}
              </div>
            )}
          </div>
        );
      default:
        return <div>Select a tab to view content</div>;
    }
  };

  return (
    <main className="page-header">
      <section className="header-content">
        <div className="title-content-parent">
          <div className="title-content">
            <div className="card-row-one">
              <div className="rectangle-parent">
                <img className="rectangle-icon" alt="" src="/rectangle.svg" />
                <h1 className="business-dashboard1">Business Dashboard</h1>
              </div>
              {/* <h1 className="business-dashboard2">Admin Dashboard</h1> */}
            </div>
            <nav className="metrics-container-parent">
              <div className="metrics-container">
                <div className="rectangle-group">
                  <img
                    className="rectangle-icon1"
                    alt=""
                    src="/rectangle.svg"
                  />
                  <div className="metric-title">
                    <a className="k">420k</a>
                    <div className="metric-trend">
                      <div className="trend-container">
                        <div className="trend-icons">
                          <div className="wrapper-vector">
                            <img
                              className="vector-icon"
                              alt=""
                              src="/vector.svg"
                            />
                          </div>
                          <div className="wrapper-path-33">
                            <img
                              className="path-33-icon"
                              alt=""
                              src="/path-33.svg"
                            />
                          </div>
                          <div className="wrapper-vector1">
                            <img
                              className="vector-icon1"
                              alt=""
                              src="/vector1.svg"
                            />
                          </div>
                          <div className="wrapper-vector2">
                            <img
                              className="vector-icon2"
                              alt=""
                              src="/vector2.svg"
                            />
                          </div>
                        </div>
                        <div className="wrapper-vector-parent">
                          <div className="wrapper-vector3">
                            <img
                              className="vector-icon3"
                              alt=""
                              src="/vector3.svg"
                            />
                          </div>
                          <div className="wrapper-vector4">
                            <img
                              className="vector-icon4"
                              alt=""
                              src="/vector4.svg"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="product-viewed-parent">
                    <div className="product-viewed">
                      <p className="product">Product</p>
                      <p className="viewed">viewed</p>
                    </div>
                    <div className="metric-period">
                      <div className="frame-parent">
                        <div className="period-icon-wrapper">
                          <div className="period-icon">
                            <div className="period-value">2.5%</div>
                            <div className="period-dropdown">
                              <img
                                className="path-3-icon"
                                alt=""
                                src="/path-3.svg"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="from-last-month">from last month</div>
                        <img className="path-4-icon" alt="" src="/path-4.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rectangle-container">
                <img className="rectangle-icon2" alt="" src="/rectangle.svg" />
                <div className="k-parent">
                  <a className="k1">245k</a>
                  <div className="product-shared">
                    <p className="product1">Product</p>
                    <p className="shared">shared</p>
                  </div>
                </div>
                <div className="metric-trend1">
                  <div className="wrapper-group-10">
                    <img
                      className="wrapper-group-10-child"
                      loading="lazy"
                      alt=""
                      src="/group-10.svg"
                    />
                  </div>
                  <div className="group-55-copy">
                    <div className="trend-value">1.5%</div>
                    <div className="trend-details">
                      <div className="trend-details-inner">
                        <img
                          className="frame-child"
                          alt=""
                          src="/group-56.svg"
                        />
                      </div>
                      <div className="from-last-month1">from last month</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-wrapper">
                <div className="group-div">
                  <img
                    className="rectangle-icon3"
                    alt=""
                    src="/rectangle.svg"
                  />
                  <div className="card-count">
                    <div className="counter-label">
                      <a className="k2">20k</a>
                      <div className="product-added-to-container">
                        <p className="product2">Product</p>
                        <p className="added-to-cart">added to cart</p>
                      </div>
                    </div>
                  </div>
                  <div className="month-change">
                    <div className="change-icon">
                      <div className="wrapper-group-13">
                        <img
                          className="wrapper-group-13-child"
                          loading="lazy"
                          alt=""
                          src="/group-13.svg"
                        />
                      </div>
                    </div>
                    <div className="group-55-copy-2">
                      <div className="percentage-label">
                        <div className="percentage-value">
                          <div className="percent-symbol">2.1%</div>
                          <div className="percentage-value-inner">
                            <img
                              className="frame-item"
                              alt=""
                              src="/group-561.svg"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="from-last-month2">from last month</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rectangle-copy-parent">
                <img
                  className="rectangle-copy-icon"
                  alt=""
                  src="/rectangle-copy.svg"
                />
                <div className="checkout-count">
                  <div className="k-group">
                    <a className="k3">350k</a>
                    <div className="product-checked-out-container">
                      <p className="product3">Product</p>
                      <p className="checked-out">checked out</p>
                    </div>
                  </div>
                </div>
                <div className="monthly-change">
                  <div className="wrapper-group-9">
                    <img
                      className="wrapper-group-9-child"
                      loading="lazy"
                      alt=""
                      src="/group-9.svg"
                    />
                  </div>
                  <div className="group-55-copy-4">
                    <div className="difference-value">
                      <div className="value-wrapper">
                        <div className="change-percentage">2.5%</div>
                        <div className="change-direction">
                          <img
                            className="change-direction-child"
                            alt=""
                            src="/group-56.svg"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="from-last-month3">from last month</div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
          <div className="development-content">{renderContent()}</div>
          <div className="studio-button">
            <div className="spend-values">
              <div className="portfolio-content" style={{ height: 352 }}>
                <img
                  className="rectangle-copy-2"
                  alt=""
                  src="/rectangle-copy-2.svg"
                />
                <div className="cost-breakdown">
                  <h3 className="cost-of-marketing">Cost of Marketing</h3>
                </div>
                <div className="financial-summary" style={{ marginTop: -41 }}>
                  {regionData && <Map regionData={regionData} />}
                </div>
              </div>
            </div>
            <div className="rectangle-copy-parent3">
              <img
                className="rectangle-copy-icon5"
                alt=""
                src="/rectangle-copy.svg"
              />
              <div className="chart-title-container">
                <div className="chart-title-background">
                  <div className="chart-title">
                    <h3 className="orders-vs-revenue">Orders vs Revenue</h3>
                  </div>
                  <select
                    onChange={(e) => {
                      setSelectedChart(e.target.value);
                    }}
                    className="rounded-md border border-gray-300 bg-white p-3 shadow-sm"
                  >
                    <option key="az" value="az">
                      Amazon
                    </option>
                    <option key="fk" value="fk">
                      Flipkart
                    </option>
                  </select>
                </div>
                <div className="chart-bars1">
                  {chartOptions && (
                    <SplineChart options={chartOptions} series={chartSeries} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <CostTable />
      </section>
    </main>
  );
};

export default BusinessDashboard;
