import BeyoboNavbar from "../../../components/beyobonavbar";
import React, { useState, useContext, useEffect } from 'react';
import ProductSidenav from "../payments/sidenav";
import InventorySidenav from "../payments/inventorysidenav";
import AuthContext from "../../../../src/context/AuthContext";
import ChannelSidenav from "../payments/channel";
import * as XLSX from 'xlsx';
import { useLocation, useNavigate } from 'react-router-dom';
import excelTemplate from '../../../assets/documents/variant-bulk-upload.xlsx';

const Catalogue = () => {
    /*const [activeTab, setActiveTab] = useState('statements');*/
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const [file, setFile] = useState(null); // State to hold the uploaded file

    //// handleFileUpload is triggered when the user selects a file
    //const handleFileUpload = (e) => {
    //    setFile(e.target.files[0]); // Set the selected file into state
    //}

    //const handleCSVUpload = async (e) => {
    //    e.preventDefault(); // Prevent the default form submit action

    //    if (!file) {
    //        alert("Please upload a file first!");
    //        return;
    //    }
    //}


    const navigate = useNavigate();
    const location = useLocation();

    // Function to extract the active tab from the URL path
    const getActiveTabFromPath = () => {
        // Assuming the path is "/admin/catalogue" or "/admin/catalogue/tabName"
        const pathSegments = location.pathname.split('/');
        const tabName = pathSegments[pathSegments.length - 1];
        // Default to 'products' if the path is the base path of the Catalogue or an unrecognized tab
        return tabName && (tabName === 'products' || tabName === 'inventory' || tabName === 'channels') ? tabName : 'products';
    };

    const [activeTab, setActiveTab] = useState(getActiveTabFromPath());

    useEffect(() => {
        // Update the active tab when the URL changes
        const newActiveTab = getActiveTabFromPath();
        setActiveTab(newActiveTab);
        // If the base path is accessed, redirect to the products tab
        if (location.pathname.endsWith('catalogue') || location.pathname.endsWith('catalogue/')) {
            navigate('/admin/catalogue/products', { replace: true });
        }
    }, [location, navigate]);

    const handleTabClick = (tabName) => {
        // Navigate to the new tab and update the URL
        navigate(`/admin/catalogue/${tabName}`);
    };

    const [isPopupOpen, setPopupOpen] = useState(false);
    const [step, setStep] = useState(1);
    const [selection, setSelection] = useState('');

    const openPopup = () => {
        setPopupOpen(true);
    };

    const closePopup = () => {
        setPopupOpen(false);
        setStep(1); // Reset the step to 1 when closing the modal.
    };


    const [formData, setFormData] = useState({
        country: '',
        name: '',
        description: '',

        variant_code: '',
        hsn: '',
        asin: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [submitError, setSubmitError] = useState(false);


    const { authToken } = useContext(AuthContext);
    const handleSubmit = async (e) => {
        e.preventDefault();

        let bodyFormData = new FormData();

        for (let key in formData) {
            bodyFormData.append(key, formData[key]);
        }

        try {

            const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/product/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${authToken}`,

                },
                body: bodyFormData,
            });


            const data = await response.json();

            if (response.ok) {

                setSubmitSuccess(true);
                setSubmitError(false);

                setFormData({
                    country: '',
                    name: '',
                    description: '',
                    variant_code: '',
                    hsn: '',
                    asin: '',

                });

            } else {

                setSubmitSuccess(false);
                setSubmitError(true);

            }

        } catch (error) {

            setSubmitSuccess(false);
            setSubmitError(true);

        }
    };


    //const handleBulkUpload = async () => {
    //    // Convert items to the format expected by the backend
    //    const payload = {
    //        products: items
    //    };

    //    try {
    //        const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/productbulk/bulk/`, { // Update the URL path accordingly
    //            method: 'POST',
    //            headers: {
    //                'Authorization': `Token ${authToken}`,
    //                'Content-Type': 'application/json',
    //            },
    //            body: JSON.stringify(payload),
    //        });

    //        if (response.ok) {
    //            // Handle success here (e.g., showing a success message)
    //            alert("Bulk upload successful!");
    //        } else {
    //            // Handle request failure here (e.g., showing an error message)
    //            const errorData = await response.json();
    //            alert("Bulk upload failed: " + errorData.error);
    //        }
    //    } catch (error) {
    //        console.error("Bulk upload error:", error);
    //        alert("An error occurred during bulk upload.");
    //    }
    //};

    const handleBulkUpload = async () => {
        if (!csvFile) {
            alert("Please select a file to upload.");
            return;
        }

        let formData = new FormData();
        formData.append("file", csvFile); // Ensure the 'file' key matches what your backend expects

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/productbulk/bulk/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${authToken}`,
                    // Note: We're NOT setting 'Content-Type' here. FormData will do it for us, including the boundary parameter.
                },
                body: formData,
            });

            const result = await response.json(); // Assuming your server responds with JSON
            if (response.ok) {
                alert("Bulk upload successful!");
                console.log(result); // You might want to do something with the response here
                setCsvFile(null); // Resetting the file input if needed
                setSubmitSuccess(true);
                setSubmitError(false);
            } else {
                throw new Error(`Submission failed: ${result.error || result.message || "Unknown error"}`);
            }
        } catch (error) {
            console.error("Bulk upload error:", error);
            alert(error.message);
            setSubmitSuccess(false);
            setSubmitError(true);
        }
    };

    const handleFileUpload = (event) => {
        setCsvFile(event.target.files[0]);
    };


    const handleAddProductClick = (choice) => {
        setSelection(choice);
        setStep(2);
    };


    const [currentStep, setCurrentStep] = useState(1);
    const [stepsCompleted, setStepsCompleted] = useState([false, false]);

    const handleValidate = () => {
        setStepsCompleted({ ...stepsCompleted, [currentStep - 1]: true });
        if (currentStep < 2) setCurrentStep(currentStep + 1);
    };

    const handlePrevious = () => {
        setCurrentStep(currentStep - 1);
    };

    const isStepCompleted = (step) => {
        return stepsCompleted[step - 1];
    };

    const renderStepOne = () => (
        <div>
            <h3 className="text-lg text-center leading-6 font-medium text-gray-900 my-5">
                Choose how you want to upload products
            </h3>

            {/* The button code can include an image or icon based on your design */}
            <div className="flex justify-center items-center space-x-10">
                <div className="p-4 border-2 border-dashed border-blue-300 rounded-lg text-center">
                    <button onClick={() => handleAddProductClick('single')}>
                        <svg className="mx-auto" fill="#3b82f6" width="64px" height="64px" viewBox="0 0 100.00 100.00" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="m74 42a2 2 0 0 1 2 1.85v28.15a6 6 0 0 1 -5.78 6h-40.22a6 6 0 0 1 -6-5.78v-28.22a2 2 0 0 1 1.85-2zm-15.5 8.34-.12.1-11.45 12.41-5.2-5a1.51 1.51 0 0 0 -2-.1l-.11.1-2.14 1.92a1.2 1.2 0 0 0 -.1 1.81l.1.11 7.33 6.94a3.07 3.07 0 0 0 2.14.89 2.81 2.81 0 0 0 2.13-.89l5.92-6.29.43-.44.42-.45.55-.58.21-.22.42-.44 5.62-5.93a1.54 1.54 0 0 0 .08-1.82l-.08-.1-2.14-1.92a1.51 1.51 0 0 0 -2.01-.1zm15.5-28.34a6 6 0 0 1 6 6v6a2 2 0 0 1 -2 2h-56a2 2 0 0 1 -2-2v-6a6 6 0 0 1 6-6z"></path></g></svg>
                        <div>Add Single product</div>
                        <div>Click to upload single product</div>
                    </button>
                </div>
                <div className="p-4 border-2 border-dashed border-blue-300 rounded-lg text-center">
                    <button onClick={() => handleAddProductClick('bulk')}>
                        <svg className="mx-auto" fill="#3b82f6" width="64px" height="64px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" stroke="#3b82f6"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="m47.44 61.66a1 1 0 0 1 1 .91v14.37a3.06 3.06 0 0 1 -2.87 3h-20.49a3.06 3.06 0 0 1 -3-2.88v-14.38a1 1 0 0 1 .91-1h24.5zm29.51 0a1 1 0 0 1 1 .91v14.37a3.06 3.06 0 0 1 -2.87 3h-20.49a3.06 3.06 0 0 1 -3-2.88v-14.38a1 1 0 0 1 .91-1h24.5zm-37.36 4.23-.09.11-5.82 6.32-2.63-2.55a.77.77 0 0 0 -1-.08l-.09.08-1.09 1a.62.62 0 0 0 -.07.9l.07.08 3.73 3.54a1.56 1.56 0 0 0 1.08.45 1.43 1.43 0 0 0 1.09-.45l3.14-3.32.63-.67 3.14-3.31a.78.78 0 0 0 .06-.9l-.06-.08-1.09-1a.76.76 0 0 0 -1-.12zm29.51 0-.1.11-5.82 6.32-2.64-2.55a.75.75 0 0 0 -1-.08l-.09.08-1.09 1a.62.62 0 0 0 -.07.9l.07.08 3.73 3.54a1.54 1.54 0 0 0 1.08.45 1.43 1.43 0 0 0 1.09-.45l3.14-3.32.63-.67 3.14-3.31a.78.78 0 0 0 .06-.9l-.06-.08-1.07-1.01a.76.76 0 0 0 -1-.11zm-23.43-14.41a3 3 0 0 1 2.85 2.87v3.24a1 1 0 0 1 -.84 1h-26.68a1 1 0 0 1 -.94-.9v-3.16a3 3 0 0 1 2.69-3.05h23zm31.48 0a3 3 0 0 1 2.85 2.87v3.24a1 1 0 0 1 -.84 1h-26.73a1 1 0 0 1 -1-.9v-3.16a3 3 0 0 1 2.68-3.05h23zm-15-21.29a1 1 0 0 1 1 .91v14.37a3.06 3.06 0 0 1 -2.87 3.05h-20.44a3.06 3.06 0 0 1 -3.05-2.87v-14.44a1 1 0 0 1 .9-1h24.51zm-7.85 4.22-.09.08-5.82 6.32-2.59-2.56a.76.76 0 0 0 -1-.07l-.09.07-1.08 1a.61.61 0 0 0 -.07.9l.07.08 3.72 3.53a1.56 1.56 0 0 0 1.09.45 1.43 1.43 0 0 0 1.08-.45l3.14-3.31.64-.67 3.13-3.32a.78.78 0 0 0 .06-.9l-.06-.07-1.08-1a.77.77 0 0 0 -1-.08zm7.9-14.41a3.06 3.06 0 0 1 3 2.88v3.23a1 1 0 0 1 -.91 1h-28.52a1 1 0 0 1 -1-.91v-3.14a3.06 3.06 0 0 1 2.87-3h24.56z"></path></g></svg>
                        <div>Add Products in Bulk</div>
                        <div>Click to add products in bulk</div>
                    </button>
                </div>
            </div>

        </div>
    );


    const downloadExcelTemplate = () => {
        const link = document.createElement('a');
        link.href = excelTemplate;
        link.download = 'file.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    const [items, setItems] = useState([]);
    const [isPopupModalOpen, setIsPopupModalOpen] = useState(false);
    const [csvFile, setCsvFile] = useState(null);

    const fieldMappings = {
        // Example mapping: CSV header: Backend field name
        "PRODUCT NAME": "name",
        "PRODUCT DESCRIPTION": "description",
        "VARIANT CODE": "variant_code",
        "HSN CODE": "hsn",
        "ASIN CODE": "asin",
        "COUNTRY": "country",

        // Add more mappings as necessary
    };

    // Function to read and process the file
    const readFile = (file) => {
        const reader = new FileReader();
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
            const headers = data[0];
            const rows = data.slice(1).map((row) => {
                let rowData = {};
                row.forEach((cell, index) => {
                    // Use the fieldMappings to set the correct backend field name
                    const backendFieldName = fieldMappings[headers[index]] || headers[index];
                    rowData[backendFieldName] = cell;
                });
                return rowData;
            });
            setItems(rows);
        };
        reader.readAsBinaryString(file);
    };

    // Effect hook to process file upon selection
    useEffect(() => {
        if (csvFile) {
            readFile(csvFile);
        }
    }, [csvFile]);

    //// Function to handle file selection
    //const handleFileUpload = (e) => {
    //    const uploadedFile = e.target.files[0];
    //    if (uploadedFile) {
    //        setCsvFile(uploadedFile);
    //    }
    //};

    // Function to toggle the popup modal visibility
    const togglePopupModal = () => {
        setIsPopupModalOpen(!isPopupModalOpen);
    };

    const renderStepTwo = () => {
        return (
            <div>
                {selection === 'single' && (
                    <div>
                        <h2 className="text-sm font-bold mb-6">Add single product</h2>
                        <hr />
                        <form onSubmit={handleSubmit}>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                                <div>
                                    <label className="block mb-2" htmlFor="country">Country of origin *</label>
                                    <input
                                        type="text"
                                        id="country"
                                        name="country"
                                        onChange={handleInputChange}
                                        className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                        placeholder="Enter your Country"
                                    />


                                </div>
                                <div>
                                    <label className="block mb-2" htmlFor="name">Product name *</label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        onChange={handleInputChange}
                                        required
                                        className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                        placeholder="Enter your product name"
                                    />
                                </div>
                            </div>

                            <div className="mb-4">
                                <label className="block mb-2" htmlFor="description">Product description *</label>
                                <textarea
                                    id="description"
                                    name="description"
                                    onChange={handleInputChange}
                                    required
                                    className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                    placeholder="Product description"
                                />
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                                <div>
                                    <label className="block mb-2" htmlFor="variant_code">Variant Code*</label>
                                    <input
                                        type="text"
                                        id="variant_code"
                                        name="variant_code"
                                        onChange={handleInputChange}
                                        required
                                        className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                        placeholder="Can be a combination of numbers and alphabets. Eg VC12"
                                    />
                                </div>
                                <div>
                                    <label className="block mb-2" htmlFor="hsn">Enter HSNCode</label>
                                    <input
                                        type="text"
                                        id="hsn"
                                        name="hsn"
                                        onChange={handleInputChange}
                                        className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                        placeholder="Enter only numeric value. Eg 1234"
                                    />
                                </div>
                                <div>
                                    <label className="block mb-2" htmlFor="asin">ASIN</label>
                                    <input
                                        type="text"
                                        id="asin"
                                        name="asin"
                                        onChange={handleInputChange}
                                        className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                        placeholder="Can be a combination of numbers and alphabets. Eg AS12"
                                    />
                                </div>
                            </div>

                            <div className="flex justify-end mt-4">
                                <button
                                    type="button"
                                    onClick={closeModal}
                                    className="bg-gray-200 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-300 mr-2"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
                                >
                                    Add Product
                                </button>
                            </div>
                        </form>
                        {submitSuccess && <div>Your form has been submitted successfully!</div>}
                        {submitError && <div>Error submitting form. Please try again.</div>}
                    </div>
                )}
                {selection === 'bulk' && (
                    <div>
                        {/*<h2>Add Products in Bulk Form</h2>*/}
                        <div className="inset-0 overflow-y-auto h-fit w-full flex items-center justify-center">
                            <div className="w-auto m-4">
                                <div className="pt-4">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">Add products in bulk</h3>

                                    {/* Step Indicator */}
                                    <div className="flex justify-between items-center p-4 w-full gap-10">
                                        {[1, 2].map(step => (
                                            <div key={step} className={`flex items-center ${currentStep >= step ? 'text-blue-500' : 'text-gray-500'}`}>
                                                <div className="rounded-full h-8 w-8 flex items-center justify-center border-2 border-blue-500">
                                                    {isStepCompleted(step) ? (
                                                        <span className="text-xs">✓</span>
                                                    ) : (
                                                        <span className="text-xs">{step}</span>
                                                    )}
                                                </div>
                                                <span className="ml-2">{step === 1 ? 'Upload Excel File' : 'Upload Product Images'}</span>
                                            </div>
                                        ))}
                                    </div>

                                    <hr />

                                    {/* Content based on step */}
                                    <div className="pt-5">
                                        {/* Content based on step */}
                                        {currentStep === 1 && (
                                            <div>
                                                <p className="text-xl text-black">Instructions on uploading csv file</p>

                                                <div className="mt-4 flex justify-between p-5 items-center bg-gray-100 rounded-lg">
                                                    <p className="text-sm text-black">1. Download the csv template.</p>
                                                    <button
                                                        className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none"
                                                        type="button"
                                                        onClick={downloadExcelTemplate} // Updated here
                                                        style={{ transition: "all .15s ease" }}
                                                    >
                                                        Download Template
                                                    </button>
                                                </div>

                                                <div className="mt-4 p-5 bg-gray-100 rounded-lg">
                                                    <p className="text-sm text-black">2. Fill in your product details in the csv template.</p>
                                                    <p className="text-sm text-black">Note: Do not edit the column names.</p>
                                                </div>

                                                <div className="mt-4 p-5 bg-gray-100 rounded-lg">
                                                    <p className="text-sm text-black">3. Upload the file to Beyobo.</p>
                                                    <div className="w-full border-brand-300 rounded-md shadow-sm bg-white p-3 border border-dashed my-2">
                                                        <input
                                                            type="file"
                                                            name="productImage"
                                                            accept=".xlsx, .xls" onChange={handleFileUpload}
                                                            required
                                                            className="w-full border-gray-300 rounded-md shadow-sm file:mr-4 file:py-2 file:px-4
                file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700
                hover:file:bg-blue-100"
                                                        />
                                                    </div>
                                                </div>{/*onClick={handleCSVUpload}*/}
                                                <button
                                                    onClick={togglePopupModal}
                                                    disabled={!items.length}
                                                    className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
                                                >
                                                    Show
                                                </button>

                                                <button
                                                    className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-6 py-3 rounded shadow hover:shadow-md outline-none focus:outline-none w-full mt-4"
                                                    type="button"
                                                    onClick={handleBulkUpload}
                                                    style={{ transition: "all .15s ease" }}
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        )}

                                        {currentStep === 2 && (
                                            <div className="bg-gray-100 p-4 rounded-md">
                                                <p className="text-sm text-gray-500">Instructions on uploading product images</p>
                                                <div className="mt-4">
                                                    <p className="text-sm text-gray-600">1. Ensure images are in JPG or PNG format.</p>
                                                    <p className="text-sm text-gray-600">2. Upload the image files below.</p>
                                                    <input type="file" className="text-gray-500 py-2 px-4 w-full mt-2" multiple />
                                                </div>
                                                <div className="flex justify-between mt-4">
                                                    <button
                                                        className="bg-gray-300 text-gray-800 active:bg-gray-500 font-bold uppercase text-xs px-6 py-3 rounded outline-none focus:outline-none"
                                                        type="button"
                                                        onClick={handlePrevious}
                                                        style={{ transition: "all .15s ease" }}
                                                    >
                                                        Previous
                                                    </button>
                                                    <button
                                                        className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-6 py-3 rounded shadow hover:shadow-md outline-none focus:outline-none"
                                                        type="button"
                                                        onClick={handleValidate}
                                                        style={{ transition: "all .15s ease" }}
                                                    >
                                                        Finish
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    return (
        <>
            <BeyoboNavbar />

            <div className="mx-5">
                <div className="flex justify-center p-4">
                    <button
                        className={`mr-2 py-2 px-4 ${activeTab === 'products' ? 'text-blue-500 border-b-2 border-blue-500' : 'text-gray-500'}`}
                        onClick={() => handleTabClick('products')}
                    >
                        Products
                    </button>
                    <button
                        className={`py-2 px-4 ${activeTab === 'inventory' ? 'text-blue-500 border-b-2 border-blue-500' : 'text-gray-500'}`}
                        onClick={() => handleTabClick('inventory')}
                    >
                        Inventory
                    </button>
                    <button
                        className={`py-2 px-4 ${activeTab === 'channels' ? 'text-blue-500 border-b-2 border-blue-500' : 'text-gray-500'}`}
                        onClick={() => handleTabClick('channels')}
                    >
                        Channels
                    </button>
                </div>

                <div className="right-4 absolute z-50" style={{ marginTop: '-65px' }}>
                    <button
                        onClick={openPopup}
                        className="bg-brand-300 hover:bg-brand-400 text-white font-bold py-2 px-4 rounded flex items-center mt-4"
                    >
                        + Add Products
                    </button>
                </div>




                {isPopupOpen && (
                    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
                        <div className="relative top-20 mx-auto p-5 py-[30px] border w-fit shadow-lg rounded-md bg-white">

                            <div className="absolute top-0 right-0 pt-4 pr-4">
                                <button
                                    onClick={closePopup}
                                    className="px-4 py-2 bg-gray-300 text-gray-900 rounded hover:bg-gray-400"
                                >
                                    X
                                </button>
                            </div>

                            <div className="mt-3">

                                <div className="mt-2">
                                    {step === 1 && renderStepOne()}
                                    {step === 2 && renderStepTwo()}
                                </div>
                            </div>
                        </div>
                    </div>

                )}


                {isPopupModalOpen && (
                    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center z-[1000]">
                        <div className="bg-white rounded-lg shadow-2xl p-4 max-w-4xl w-full mx-2 my-6">
                            <div className="flex justify-between items-center mb-4">
                                <h2 className="text-2xl font-bold">Uploaded Products</h2>
                                <button
                                    onClick={() => setIsPopupModalOpen(false)}
                                    className="text-gray-600 hover:text-gray-900"
                                >
                                    <svg className="w-6 h-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                        <path d="M6 18L18 6M6 6l12 12"></path>
                                    </svg>
                                </button>
                            </div>
                            <div className="overflow-auto">
                                <table className="min-w-full leading-normal">
                                    <thead>
                                        <tr>
                                            {items[0] && Object.keys(items[0]).map((header) => (
                                                <th
                                                    key={header}
                                                    className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                                                >
                                                    {header}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {items.map((item, index) => (
                                            <tr key={index}>
                                                {Object.values(item).map((val, i) => (
                                                    <td key={i} className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                                                        {val}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <div className="text-end mt-5">
                                <button className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed">
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </div>
                )}


                {/* Conditional rendering based on the active tab */}
                {activeTab === 'products' && <ProductSidenav />}
                {activeTab === 'inventory' && <InventorySidenav />}
                {activeTab === 'channels' && <ChannelSidenav />}
            </div>
        </>
    );
};

export default Catalogue;
