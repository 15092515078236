//import React from 'react';
//import 'react-data-grid/lib/styles.css';
//import DataGrid from 'react-data-grid';

//const CustomDataGrid = () => {
//    const columns = [
//        { key: 'product', name: 'Product' },
//        { key: 'sku', name: 'Product SKU Code' },
//        { key: 'country', name: 'Country of Origin' },
//        { key: 'catalogueDate', name: 'Catalogue Date' },
//        { key: 'status', name: 'Status' }
//    ];

//    const rows = [
//        { id: 1, product: "Widget", sku: "W123", country: "USA", catalogueDate: "2021-01-01", status: "Available" },
//        { id: 2, product: "Gadget", sku: "G456", country: "Germany", catalogueDate: "2021-06-15", status: "Unavailable" },
//    ];

//    return <DataGrid className="bg-white" columns={columns} rows={rows} />;
//};

//export default CustomDataGrid;



import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter'
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter'
//import DateFilter from '@inovua/reactdatagrid-community/DateFilter'
import Button from '@inovua/reactdatagrid-community/packages/Button'
import BeyoboNavbar from "../../../src/components/beyobonavbar";
import React, { useState, useEffect, useContext, useCallback } from 'react';
import CatalogProductContext from '../../../src/context/CatalogProductContext';
import { AuthContext } from '../../../src/context/AuthContext';
import DataModal from '../modal'
import CustomTable from '../customTable'
import moment from 'moment'

const CustomDataGrid = () => {




    // Fetch data from the backend




    //   const [products, setProducts] = useState([]);
    //     const [loading, setLoading] = useState(false);

    // Replace with actual token logic
    //    const products =  useContext(CatalogProductContext);
    // Define loadData inside useEffect or as a useCallback to avoid re-creating the function on every render
    // const loadData = useCallback(({ skip, limit, sortInfo }) => {
    //     setLoading(true); // Set loading to true when you start loading data
    //     const url = `${process.env.REACT_APP_BASE_API_URL}/product/?skip=${skip}&limit=${limit}${sortInfo ? '&sortInfo=' + encodeURIComponent(JSON.stringify(sortInfo)) : ''}`;

    //     return fetch(url, {
    //         headers: {
    //             'Authorization': `Token ${authToken}`
    //         }
    //     })
    //     .then(response => {
    //         if (!response.ok) {
    //             throw new Error('Network response was not ok');
    //         }
    //         const totalCount = response.headers.get('X-Total-Count');
    //         return response.json().then(data => {
    //             setProducts(data); // Set the products data
    //             setLoading(false); // Set loading to false when data is received
    //             return { data, count: parseInt(totalCount, 10) };
    //         })
    //     })
    //     .catch(error => {
    //         console.error('There was a problem with the fetch operation:', error);
    //         setLoading(false); // Ensure loading is false in case of error
    //         // Handle errors as needed
    //     });
    // }, []); // Dependencies of useCallback

    // useEffect(() => {
    //     // Trigger loadData with desired parameters
    //     loadData({skip: 0, limit: 10, sortInfo: null});
    // }, [loadData]);




    //const columns = [
    //    { name: 'name', defaultFlex: 1, header: 'Name' },

    //    { name: 'variant_code', header: 'SKUCode', defaultFlex: 1 },

    //    {
    //        name: 'hsn', header: 'HSN', defaultFlex: 1, type: 'number', filterEditor: NumberFilter
    //        //name: 'age',
    //        //header: 'Age',
    //        //defaultWidth: 80,
    //        //render: ({ value }) => <span style={{ color: value < 30 ? 'lightgreen' : 'inherit' }}>{value}</span>
    //    },

    //]

    const columns = [
        { header: 'Name', key: 'name' },
        { header: 'Variant Code', key: 'variant_code' },
        { header: 'HSN', key: 'hsn' },
    ];


    const defaultfilter = [
        { name: 'name', operator: 'startsWith', type: 'string'},
        { name: 'variant_code', operator: 'startsWith', type: 'string' },
        { name: 'hsn', operator: 'gte', type: 'number' },
        // {
        //     name: 'birthDate',
        //     operator: 'before',
        //     type: 'date',
        //     value: ''
        // },

    ];
    const [filterValue, setFilterValue] = useState(defaultfilter);
    const [dataSource, setDataSource] = useState([]);
    const { authToken } = useContext(AuthContext);

    const [gridData, setGridData] = useState([]); // New state for actual data array

    const loadData = useCallback(() => {
        const newDataSource = () => {
            const limit = 50;
            const url = `${process.env.REACT_APP_BASE_API_URL}/product/` + '?limit=' + limit;
            return fetch(url, {
                headers: {
                    'Authorization': `Token ${authToken}`
                }
            })
                .then(response => response.json())
                .then(data => {
                    const formattedData = data.map(item => ({
                        ...item,
                        order_date: moment(item.order_date).format('DD-MM-YYYY')
                    }));
                    setGridData(formattedData); // Update gridData with the actual data array
                    return { data: formattedData }; // Return the data for dataSource
                });
        };

        setDataSource(newDataSource); // Keep as it was, setting a function
    }, [authToken]);

    useEffect(() => {
        loadData();
    }, [loadData]); // Ensure loadData is stable and doesn't change unless necessary



    const gridStyle = { minHeight: 550, }


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);

    const onRowClick = useCallback((rowProps) => {
        setSelectedRowData(rowProps.data);
        setIsModalOpen(true);
    }, []);


    return (
        <div>
            <CustomTable data={gridData} columns={columns} onRowClick={() => { }} />

            {/* <Button onClick={() => loadData()} style={{marginRight: 10}}>
            Load async data
          </Button>
          <Button
            disabled={Array.isArray(dataSource)}
            onClick={() => setDataSource([])}
          >Clear data</Button> */}
            {/*<ReactDataGrid*/}
            {/*    style={gridStyle}*/}
            {/*    idProperty="id"*/}
            {/*    columns={columns}*/}
            {/*    pagination='local'*/}
            {/*    dataSource={dataSource}*/}
            {/*    defaultFilterValue={filterValue}*/}
            {/*    onFilterValueChange={setFilterValue}*/}
            {/*    onRowClick={onRowClick}*/}
            {/*/>*/}

            {/*<DataModal*/}
            {/*    isOpen={isModalOpen}*/}
            {/*    onClose={() => setIsModalOpen(false)}*/}
            {/*    data={selectedRowData}*/}
            {/*    columns={columns}*/}
            {/*/>*/}
        </div>
    )
};

export default CustomDataGrid;