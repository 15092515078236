

import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter'
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter'
import DateFilter from '@inovua/reactdatagrid-community/DateFilter'
import moment from 'moment'
import Button from '@inovua/reactdatagrid-community/packages/Button'
import BeyoboNavbar from "../beyobonavbar";
import React, { useState, useEffect, useContext, useCallback } from 'react';
import CatalogProductContext from '../../context/CatalogProductContext';
import { AuthContext } from '../../context/AuthContext';
import DataModal from '../modal'
const DashboardDataGrid = () => {

  function formatCurrency(value) {
    let formattedValue;

    // Check if the value is a whole number
    if (Number.isInteger(value)) {
        // Format without rounding for whole numbers
        formattedValue = value.toLocaleString('en-IN');
    } else {
        // Round to two decimal places if not a whole number and then format
        formattedValue = parseFloat(value).toFixed(2);
    }

    // Convert the formatted value to a string and apply Indian currency format
    return `₹${formattedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
}


    const columns = [
    
      
      //{ name: 'sku_id', defaultFlex: 1, header: 'Sku Id', type: 'number', filterEditor: NumberFilter },
      { name: 'sales', defaultFlex: 1, header: 'Sales', type: 'number' , filterEditor: NumberFilter,render: ({ value }) => formatCurrency(value)},
      { name: 'commission', defaultFlex: 1, header: 'Commission', type: 'number' , filterEditor: NumberFilter,render: ({ value }) => formatCurrency(value)},
      
    
      {name: 'tax', defaultFlex:1,header:'Tax', type:Number,render: ({ value }) => formatCurrency(value)},
      {name: 'shipment_cost', defaultFlex:1,header:'Shipment Cost', type:Number,render: ({ value }) => formatCurrency(value)},
      {name: 'ad_cost', defaultFlex:1,header:'Ad Cost', type:Number,render: ({ value }) => formatCurrency(value)},
      {name: 'unit_sold', defaultFlex:1,header:'Units Sold', type:Number},
      {name: 'order_processing_cost', defaultFlex:1,header:'Order Processing Cost', type:Number,render: ({ value }) => formatCurrency(value)},
      {name: 'payouts', defaultFlex:1,header:'Payouts', type:Number,render: ({ value }) => formatCurrency(value)},
      {name: 'pending', defaultFlex:1,header:'Pending', type:Number,render: ({ value }) => formatCurrency(value)},
     // {name: 'pending', defaultFlex:1,header:'Pending', type:Number,render: ({ value }) => formatCurrency(value)},
  ];



        const defaultfilter = [
          
          //{ name: 'sku_id', operator: 'gte', type: 'number' ,value: 0},
          { name: 'sales', operator: 'gte', type: 'number' ,value: 0},
          { name: 'commission', operator: 'gte', type: 'number' ,value: 0},
          { name: 'tax', operator: 'gte', type: 'number' ,value: 0},
          { name: 'shipment_cost', operator: 'gte', type: 'number' ,value: 0},
          { name: 'ad_cost', operator: 'gte', type: 'number' ,value: 0},
          { name: 'unit_sold', operator: 'gte', type: 'number' ,value: 0},
          { name: 'order_processing_cost', operator: 'gte', type: 'number' ,value: 0},
         
             //{ name: 'sku_id', operator: 'gte', type: 'number' ,value: 0},
          { name: 'payouts', operator: 'gte', type: 'number' ,value: 0},
          { name: 'pending', operator: 'gte', type: 'number' ,value: 0},
          //{ name: 'pending', operator: 'gte', type: 'number' ,value: 0},
  
];



    const [filterValue, setFilterValue] = useState(defaultfilter);
    const [dataSource, setDataSource] = useState([]);
    const { authToken } = useContext(AuthContext);

    const loadData = useCallback(() => {
        const newDataSource = () => {
            const limit = 50;
            const url = `${process.env.REACT_APP_BASE_API_URL}/dashboard/` + '?limit=' + limit;
            return fetch(url, {
                headers: {
                    'Authorization': `Token ${authToken}`
                }
            })
                .then(response => {
                    const totalCount = response.headers.get('X-Total-Count');
                    return response.json().then(data => {
                        return { data, count: totalCount * 1 };
                    })
                });
        }

        setDataSource(newDataSource)
    }, []);

    useEffect(() => {
        loadData();
    }, [loadData]); // Ensure loadData is stable and doesn't change unless necessary



    const gridStyle = { minHeight: 550 }


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);

    const onRowClick = useCallback((rowProps) => {
        setSelectedRowData(rowProps.data);
        setIsModalOpen(true);
    }, []);

    return (
        <div>
            {/* <Button onClick={() => loadData()} style={{marginRight: 10}}>
            Load async data
          </Button>
          <Button
            disabled={Array.isArray(dataSource)}
            onClick={() => setDataSource([])}
          >Clear data</Button> */}
            {/* <div style={{ height: 80 }} >Current filterValue: {filterValue ? <code>{JSON.stringify(filterValue, null, 2)}</code>: 'none'}.</div> */}
            <ReactDataGrid
                style={gridStyle}
                idProperty="id"
                pagination='local'
                columns={columns}
                dataSource={dataSource}
                defaultLimit={50}
                defaultFilterValue={defaultfilter}
                onFilterValueChange={setFilterValue}
                onRowClick={onRowClick}
            />

            <DataModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                data={selectedRowData}
                columns={columns}
            />
        </div>
    )
};



export default DashboardDataGrid;