import BeyoboNavbar from "../../../components/beyobonavbar";
import React, { useState, useEffect } from 'react';
import MyProfile from "./profileSidenav/myprofile";
import BankDetails from "./profileSidenav/bankdetails";

const AdminProfile = () => {
    const [activeTab, setActiveTab] = useState('My Profile');

    const activeStyle = 'bg-brand-300 text-white';
    const inactiveStyle = 'text-gray-700 hover:bg-gray-100 hover:text-gray-900';

    const getButtonClasses = (tabName) => {
        return `w-full text-left py-2 px-4 rounded-lg font-semibold flex justify-between items-center ${activeTab === tabName ? activeStyle : inactiveStyle}`;
    };

    const SidebarButton = ({ tabName, count }) => (
        <button onClick={() => setActiveTab(tabName)} className={getButtonClasses(tabName)}>
            {tabName}
        </button>
    );

    const renderContent = () => {
        switch (activeTab) {
            case 'My Profile':
                return <MyProfile></MyProfile>;

            case 'Bank & Tax details':
                return <BankDetails></BankDetails>;
            // ... other cases ...
            default:
                return <div>Select a tab to view content</div>;
        }
    };

    return (
        <>
            <BeyoboNavbar />

            <div className="flex gap-3 m-5 rounded-lg">
                <div className="bg-white p-4 h-fit w-1/4 rounded-lg">
                    <div className="flex flex-col space-y-2">
                        <SidebarButton tabName="My Profile" />
                        <SidebarButton tabName="Bank & Tax details" />
                        <SidebarButton tabName="Tutorials" />
                        <SidebarButton tabName="Rate Us" />

                        <p className="uppercase tracking-extrawide text-gray-300 text-sm font-semibold px-3 pt-10 py-3">Help & Support</p>

                        <SidebarButton tabName="FAQ's" />
                        <SidebarButton tabName="Customer Support" />
                        <SidebarButton tabName="Terms & Conditions" />
                        <SidebarButton tabName="Settings" />
                    </div>
                </div>

                <div className="bg-white h-fit w-full rounded-lg p-5">
                    {renderContent()}
                </div>
            </div>
        </>
    );
};

export default AdminProfile;
