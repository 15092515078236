import PropTypes from "prop-types";
import "./FrameComponent1.css";

const FrameComponent1 = ({ className = "" }) => {
  return (
    <div className={`path-32-copy-parent ${className}`}>
      <img
        className="path-32-copy"
        loading="lazy"
        alt=""
        src="/path-32-copy.svg"
      />
      {/* <div className="profile-content">
        <div className="profile-info">
          <div className="oval-copy-wrapper">
            <img
              className="oval-copy-icon"
              loading="lazy"
              alt=""
              src="/oval-copy@2x.png"
            />
          </div>
          <div className="frame-parent1">
            <div className="vinod-palli-wrapper">
              <b className="vinod-palli">Vinod Palli</b>
            </div>
            <div className="business-analyst">Business Analyst</div>
          </div>
        </div>
      </div> */}
      <div className="logout-content-wrapper">
        <div className="logout-content">
          <img
            className="logout-content-child"
            loading="lazy"
            alt=""
            src="/group-30.svg"
          />
          <div className="logout-wrapper">
            <div className="logout">Logout</div>
          </div>
        </div>
      </div>
    </div>
  );
};

FrameComponent1.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent1;
