import React, { useState ,useContext,useEffect,useCallback} from 'react';
import CustomDataGrid from 'components/datagrid';
import CatalogProductContext from "../../../../src/context/CatalogProductContext";
const ProductSidenav = () => {
    const [activeTab, setActiveTab] = useState('All Products');
   

  

    // Fetch data from the backend
   
  const {products}=useContext(CatalogProductContext);

    const activeStyle = 'bg-brand-300 text-white';
    const inactiveStyle = 'text-gray-700 hover:bg-gray-100 hover:text-gray-900';

    const getButtonClasses = (tabName) => {
        return `w-full text-left py-2 px-4 rounded-lg font-semibold flex justify-between items-center ${activeTab === tabName ? activeStyle : inactiveStyle}`;
    };

    const Badge = ({ count }) => (
        <span className={`badge ${count > 0 ? 'bg-blue-200' : 'bg-gray-200'} text-blue-800 text-sm font-bold py-1 px-3 rounded-full`}>
            {count}
        </span>
    );

    const SidebarButton = ({ tabName, count }) => (
        <button onClick={() => setActiveTab(tabName)} className={getButtonClasses(tabName)}>
            {tabName} <Badge count={count} />
        </button>
    );

    const renderContent = () => {
        switch (activeTab) {
            case 'All Products':
                return   <CustomDataGrid></CustomDataGrid> 
            case 'Overview':
                return <div><img className="w-1/2 h-1/2" src="https://img.freepik.com/free-vector/abstract-coming-soon-halftone-style-background-design_1017-27282.jpg" /></div>;
            case 'Under Review':
                return <div><img className="w-1/2 h-1/2" src="https://img.freepik.com/free-vector/abstract-coming-soon-halftone-style-background-design_1017-27282.jpg" /></div>;
            case 'Resend Requests':
                return <div><img className="w-1/2 h-1/2" src="https://img.freepik.com/free-vector/abstract-coming-soon-halftone-style-background-design_1017-27282.jpg" /></div>;
            case 'Not Eligible':
                return <div><img className="w-1/2 h-1/2" src="https://img.freepik.com/free-vector/abstract-coming-soon-halftone-style-background-design_1017-27282.jpg" /></div>;
            case 'Reviewed':
                return <div><img className="w-1/2 h-1/2" src="https://img.freepik.com/free-vector/abstract-coming-soon-halftone-style-background-design_1017-27282.jpg" /></div>;
            case 'Drafts':
                return <div><img className="w-1/2 h-1/2" src="https://img.freepik.com/free-vector/abstract-coming-soon-halftone-style-background-design_1017-27282.jpg" /></div>;

            // ... other cases ...
            default:
                return <div>Select a tab to view content</div>;
        }
    };

    const productCount=products.length;

    return (
        <div className="flex gap-3 w-full rounded-lg">
            <div className="bg-white p-4 h-fit w-1/4 rounded-lg">
                <div className="flex flex-col space-y-2">
                   
                    <SidebarButton tabName="All Products" count= {productCount} />
                    <SidebarButton tabName="Overview"  />
                    <SidebarButton tabName="Under Review"  />
                    <SidebarButton tabName="Resend Requests"  />
                    <SidebarButton tabName="Not Eligible"  />
                    <SidebarButton tabName="Reviewed"  />
                    <SidebarButton tabName="Drafts"  />
                </div>
            </div>

            <div className="bg-white h-fit w-full rounded-lg p-5">
                {renderContent()}
            </div>
        </div>
    );
};

export default ProductSidenav;
