

import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter'
import moment from 'moment'
import React, { useState, useEffect, useContext, useCallback } from 'react';
import CustomTable from '../../../../components/customTable';
import DataModal from '../../../../components/modal';
import AuthContext from '../../../../context/AuthContext';

const AdminShipmentDataGrid = () => {


    //const columns = [
    //    { name: 'id', defaultFlex: 1, header: ' Id', type: 'number', },
    //    { name: 'company_id', defaultFlex: 1, header: 'Company Id', type: 'number', },
    //    {
    //        name: 'received_date',
    //        header: 'Shipment Date',
    //        defaultFlex: 1,
    //        filterEditorProps: (props, { index }) => {
    //            return {
    //                dateFormat: 'DD-MM-YYYY',
    //                cancelButton: false,
    //                highlightWeekends: false,
    //                placeholder: index === 1 ? 'Created date is before...' : 'Created date is after...'
    //            }
    //        },
    //        render: ({ value, cellProps }) => {
    //            console.log('Original Value:', value); // Debugging line
    //            const formattedDate = moment(value).format('DD-MM-YYYY');
    //            console.log('Formatted Date:', formattedDate); // Debugging line
    //            return formattedDate;
    //        }
    //    },

    //    { name: 'freight_charge', defaultFlex: 1, header: 'Freight Charge', type: 'number', },
    //    { name: 'custom_duty', defaultFlex: 1, header: 'Custom Duty', type: 'number', },
    //    { name: 'conversion_rate', defaultFlex: 1, header: 'Conversion Rate', type: 'number', },
    //    { name: 'supplier_currency', defaultFlex: 1, header: 'Supplier Currency', type: 'string' },
    //    { name: 'receiver_currency', defaultFlex: 1, header: 'Supplier Currency', type: 'string' },
    //    { name: 'weight_in_kgs', defaultFlex: 1, header: 'Weight(kg)', type: 'number', },
    //    { name: 'volumetric_weight_in_kgs', defaultFlex: 1, header: 'Volumetric Weight(kgs)', type: 'number', },       
    //    /*defaultfilter: NumberFilter*/
    //    { name: 'state', defaultFlex: 1, header: 'State' }
    //];


    const columns = [
        { header: 'ID', key: 'id' },
        { header: 'Company ID', key: 'company_id' },
        { header: 'Shipment Date', key: 'received_date' },
        { header: 'Freight Charge', key: 'freight_charge' },
        { header: 'Custom Duty', key: 'custom_duty' },
        { header: 'Conversion Rate', key: 'conversion_rate' },
        { header: 'Supplier Currency', key: 'supplier_currency' },
        { header: 'Receiver Currency', key: 'receiver_currency' },
        { header: 'Weight(kgs)', key: 'weight_in_kgs' },
        { header: 'Volumetric Weight(kgs)', key: 'volumetric_weight_in_kgs' },
        { header: 'State', key: 'state' },
    ];



    //const defaultfilter = [
    //    { name: 'id', operator: 'gte', type: 'number', value: 0 },
    //    { name: 'company_id', operator: 'gte', type: 'number', value: 0 },
    //    { name: 'received_date', operator: 'before', type: 'date', value: 0 },
    //    { name: 'freight_charge', operator: 'gte', type: 'number', value: 0 },
    //    { name: 'custom_duty', operator: 'gte', type: 'number', value: 0 },
    //    { name: 'conversion_rate', operator: 'gte', type: 'number', value: 0 },
    //    { name: 'supplier_currency', operator: 'contains', type: 'string', value: '' },
    //    { name: 'receiver_currency', operator: 'contains', type: 'string', value: '' },
        
    //    //{ name: 'sku_id', operator: 'gte', type: 'number' ,value: 0},

    //    //{ name: 'variant_id', operator: 'gte', type: 'number' ,value: 0},
    //    { name: 'weight_in_kgs', operator: 'gte', type: 'number', value: 0 },
    //    { name: 'volumetric_weight_in_kgs', operator: 'gte', type: 'number', value: 0 },

    //    { name: 'state', operator: 'startsWith', type: 'string', value: '' }

    //];



    //const [filterValue, setFilterValue] = useState(defaultfilter);
    const [dataSource, setDataSource] = useState([]);
    const { authToken } = useContext(AuthContext);

    const [gridData, setGridData] = useState([]); // New state for actual data array

    const loadData = useCallback(() => {
        const newDataSource = () => {
            const limit = 50;
            const url = `${process.env.REACT_APP_BASE_API_URL}/adminshipment/complete_list/` + '?limit=' + limit;
            return fetch(url, {
                headers: {
                    'Authorization': `Token ${authToken}`
                }
            })
                .then(response => response.json())
                .then(data => {
                    const formattedData = data.map(item => ({
                        ...item,
                        order_date: moment(item.order_date).format('DD-MM-YYYY')
                    }));
                    setGridData(formattedData); // Update gridData with the actual data array
                    return { data: formattedData }; // Return the data for dataSource
                });
        };

        setDataSource(newDataSource); // Keep as it was, setting a function
    }, [authToken]);


    //const exportCSV = () => {
    //    if (!Array.isArray(gridData)) {
    //        console.error('Grid data is not an array');
    //        return;
    //    }

    //    // Process the data to format it for CSV
    //    const rows = gridData.map((data) =>
    //        columns.map((col) => {
    //            // Handle any necessary formatting per column
    //            let cellData = data[col.name];
    //            if (col.render) {
    //                // If there's a custom render, use it for CSV data
    //                const rendered = col.render({ value: cellData });
    //                if (React.isValidElement(rendered)) {
    //                    // If rendered content is a React element, extract text content
    //                    cellData = rendered.props.children;
    //                } else {
    //                    cellData = rendered;
    //                }
    //            }
    //            return `"${cellData?.toString().replace(/"/g, '""') || ''}"`; // Escape quotes
    //        }).join(',')
    //    );

    //    const header = columns.map((col) => `"${col.header.replace(/"/g, '""')}"`).join(',');
    //    const csvContent = [header].concat(rows).join('\n');

    //    // Create a Blob for the CSV content
    //    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    //    const url = URL.createObjectURL(blob);

    //    // Create a temporary link to trigger the download
    //    const link = document.createElement('a');
    //    link.href = url;
    //    link.download = 'shipment-grid-data.csv';
    //    document.body.appendChild(link);
    //    link.click();
    //    document.body.removeChild(link);
    //};


    useEffect(() => {
        loadData();
    }, [loadData]); // Ensure loadData is stable and doesn't change unless necessary


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);

    const onRowClick = useCallback((rowProps) => {
        setSelectedRowData(rowProps.data);
        setIsModalOpen(true);
    }, []);

    const gridStyle = { minHeight: 550 }


    const [selected, setSelected] = useState(null);

    const onSelectionChange = useCallback(({ selected: selectedMap, data }) => {
        const newSelected = Object.keys(selectedMap).map(id => id * 1)

        setSelected(newSelected);
    }, [])

    return (
        <div>
            <CustomTable data={gridData} columns={columns} onRowClick={() => { }} />

            {/*<div className="right-4 z-50 my-3">*/}
            {/*    <button onClick={exportCSV}*/}
            {/*        className="bg-brand-300 hover:bg-brand-400 text-white font-bold py-2 px-4 rounded flex items-center mt-4"*/}
            {/*    >*/}
            {/*        Download CSV*/}
            {/*    </button>*/}
            {/*</div>*/}

            {/*<ReactDataGrid*/}
            {/*    style={gridStyle}*/}
            {/*    idProperty="id"*/}
            {/*    pagination='local'*/}
            {/*    columns={columns}*/}
            {/*    dataSource={dataSource}*/}
            {/*    defaultLimit={50}*/}
                
            {/*    onRowClick={onRowClick} // Add this line to handle row clicks*/}
            {/*/>*/}

            {/*defaultFilterValue={defaultfilter}*/}
            {/*onFilterValueChange={setFilterValue}*/}

            {/*<p>*/}
            {/*    Selected rows: {selected == null ? 'none' : JSON.stringify(selected)}.*/}
            {/*</p>*/}

            {/*<DataModal*/}
            {/*    isOpen={isModalOpen}*/}
            {/*    onClose={() => setIsModalOpen(false)}*/}
            {/*    data={selectedRowData}*/}
            {/*    columns={columns}*/}
            {/*/>*/}
        </div>
    )
};


export default AdminShipmentDataGrid;