
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter'
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter'
import DateFilter from '@inovua/reactdatagrid-community/DateFilter'
import moment from 'moment'
import Button from '@inovua/reactdatagrid-community/packages/Button'
import BeyoboNavbar from "../beyobonavbar";
import React, { useState, useEffect, useContext, useCallback } from 'react';
import CatalogProductContext from '../../context/CatalogProductContext';
import { AuthContext } from '../../context/AuthContext';
import DataModal from '../modal'
import CustomTable from '../customTable'
const TransactionDataGrid = () => {

    function formatCurrency(value) {
        let formattedValue;

        // Check if the value is a whole number
        if (Number.isInteger(value)) {
            // Format without rounding for whole numbers
            formattedValue = value.toLocaleString('en-IN');
        } else {
            // Round to two decimal places if not a whole number and then format
            formattedValue = parseFloat(value).toFixed(2);
        }

        // Convert the formatted value to a string and apply Indian currency format
        return `₹${formattedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    }


    const columns = [
        { header: 'Platform', key: 'platform' },
        { header: 'Settlement ID', key: 'settlement_id' },
        { header: 'Type', key: 'type' },
        { header: 'Order ID', key: 'order_id' },
        { header: 'Order Sku ID', key: 'order_sku_id' },
        { header: 'Sku Name', key: 'sku_name' },
        { header: 'Narration', key: 'narration' },
        { header: 'Quantity', key: 'quantity' },
        { header: 'Fullfilment Channel', key: 'fullfilment_channel' },
        { header: 'Total', key: 'total' },
        { header: 'Settlement Date', key: 'settlement_date' },
    ];

    //const columns = [

    //    { name: 'platform', defaultFlex: 1, header: 'Marketplace', type: 'string' },
    //    { name: 'settlement_id', defaultFlex: 1, header: 'Settlement ID', type: 'string' },
    //    { name: 'type', defaultFlex: 1, header: 'Type', type: 'string' },
    //    //{ name: 'sku_id', defaultFlex: 1, header: 'Sku Id', type: 'number', filterEditor: NumberFilter },
    //    { name: 'order_id', defaultFlex: 1, header: 'Order Id', type: 'number', filterEditor: NumberFilter },
    //    { name: 'order_sku_id', defaultFlex: 1, header: 'Order Sku Id', type: 'number', filterEditor: NumberFilter },

    //    { name: 'sku_name', defaultFlex: 1, header: 'SKU Name', type: 'string' },
    //    { name: 'narration', defaultFlex: 1, header: 'Narration', type: 'string' },
    //    { name: 'quantity', defaultFlex: 1, header: 'Quantity', type: 'number', filterEditor: NumberFilter },
    //    { name: 'fullfilment_channel', defaultFlex: 1, header: 'FullFilment Channel', type: 'string' },
    //    { name: 'total', defaultFlex: 1, header: 'Total', type: 'number', filterEditor: NumberFilter, render: ({ value }) => formatCurrency(value) },
    //    {
    //        name: 'settlement_date',
    //        header: 'Settlement Date',
    //        defaultFlex: 1,

    //        filterEditorProps: (props, { index }) => {
    //            // for range and notinrange operators, the index is 1 for the after field
    //            return {
    //                dateFormat: 'DD-MM-YYY',
    //                cancelButton: false,
    //                highlightWeekends: false,
    //                placeholder: index == 1 ? 'Created date is before...' : 'Created date is after...'
    //            }
    //        },
    //        render: ({ value, cellProps }) => {
    //            return moment(value).format('DD-MM-YYYY')
    //        }
    //    },

    //];



    const defaultfilter = [

        { name: 'platform', operator: 'contains', type: 'string', value: '' },
        { name: 'settlement_id', operator: 'contains', type: 'string', value: '' },
        { name: 'order_id', operator: 'gte', type: 'number', value: 0 },
        { name: 'order_sku_id', operator: 'gte', type: 'number', value: 0 },

        { name: 'sku_name', operator: 'contains', type: 'string', value: '' },
        { name: 'narration', operator: 'contains', type: 'string', value: '' },
        { name: 'quantity', operator: 'gte', type: 'number', value: 0 },
        { name: 'fulfillment_channel', operator: 'contains', type: 'string', value: '' },
        { name: 'total', operator: 'gte', type: 'number', value: 0 },
        { name: 'settlement_at', operator: 'before', type: 'date', value: '' },


    ];



    const [filterValue, setFilterValue] = useState(defaultfilter);
    const [dataSource, setDataSource] = useState([]);
    const { authToken } = useContext(AuthContext);

    const [gridData, setGridData] = useState([]); // New state for actual data array

    const loadData = useCallback(() => {
        const newDataSource = () => {
            const limit = 50;
            const url = `${process.env.REACT_APP_BASE_API_URL}/transaction/` + '?limit=' + limit;
            return fetch(url, {
                headers: {
                    'Authorization': `Token ${authToken}`
                }
            })
                .then(response => response.json())
                .then(data => {
                    const formattedData = data.map(item => ({
                        ...item,
                        order_date: moment(item.order_date).format('DD-MM-YYYY')
                    }));
                    setGridData(formattedData); // Update gridData with the actual data array
                    return { data: formattedData }; // Return the data for dataSource
                });
        };

        setDataSource(newDataSource); // Keep as it was, setting a function
    }, [authToken]);

    useEffect(() => {
        loadData();
    }, [loadData]); // Ensure loadData is stable and doesn't change unless necessary

    const gridStyle = { minHeight: 550 }

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);

    const onRowClick = useCallback((rowProps) => {
        setSelectedRowData(rowProps.data);
        setIsModalOpen(true);
    }, []);


    const [selectedChannel, setSelectedChannel] = useState('');

    const handleChannelChange = (event) => {

        setSelectedChannel(event.target.value);
    };


    const exportFilteredCSV = () => {

        const startDate = document.getElementById('start-date').value;
        const endDate = document.getElementById('end-date').value;
        if (!startDate || !endDate || !selectedChannel) {
            alert('Please select all 3 (Channel, Start date and End date.');
            return;
        }

        // Construct the URL for your Django backend endpoint
        const url = `${process.env.REACT_APP_BASE_API_URL}/transaction/generate-report/?start_date=${startDate}&end_date=${endDate}&channel=${encodeURIComponent(selectedChannel)}`;

        fetch(url, {
            headers: {
                'Authorization': `Token ${authToken}`,
            },
        })
            .then(response => response.json())
            .then(data => {
                if (data.url) {
                    // If there's a URL, automatically trigger the download
                    const link = document.createElement('a');
                    link.href = data.url;
                    link.setAttribute('download', 'order_report.csv'); // Optional
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                } else {
                    alert('Failed to generate report: ' + (data.error || 'Unknown error'));
                }
            })
            .catch(error => console.error('Error fetching report:', error));


    };



    const exportCSV = () => {
        if (!Array.isArray(gridData)) {
            console.error('Grid data is not an array');
            return;
        }

        // Process the data to format it for CSV
        const rows = gridData.map((data) =>
            columns.map((col) => {
                // Handle any necessary formatting per column
                let cellData = data[col.name];
                if (col.name === 'total') {
                    // Remove the `₹` symbol for CSV export
                    cellData = formatCurrency(cellData).replace(/₹/g, '');
                }
                if (col.render) {
                    // If there's a custom render, use it for CSV data
                    const rendered = col.render({ value: cellData });
                    if (React.isValidElement(rendered)) {
                        // If rendered content is a React element, extract text content
                        cellData = rendered.props.children;
                    } else {
                        cellData = rendered;
                    }
                }
                return `"${cellData?.toString().replace(/"/g, '""') || ''}"`; // Escape quotes
            }).join(',')
        );

        const header = columns.map((col) => `"${col.header.replace(/"/g, '""')}"`).join(',');
        const BOM = "\uFEFF";
        const csvContent = BOM + [header].concat(rows).join('\n');

        // Create a Blob for the CSV content
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        // Create a temporary link to trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.download = 'transaction-grid-data.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    const [platforms, setPlatforms] = useState([]);
    const [selectedPlatform, setSelectedPlatform] = useState(null);

    useEffect(() => {
        const apiUrl = `${process.env.REACT_APP_BASE_API_URL}/channels/`;
        fetch(apiUrl, {
            headers: {
                'Authorization': `Token ${authToken}`,
            },
        })
            .then(response => response.json())
            .then(data => {
                const platformsMap = data.reduce((acc, channel) => {
                    acc[channel.name] = channel.platform_image;
                    return acc;
                }, {});

                const platformsArray = Object.entries(platformsMap).map(([name, logo]) => ({
                    name,
                    logo,
                }));

                setPlatforms(platformsArray);
            })
            .catch(error => console.error('Fetch error:', error));
    }, [authToken]);

    return (
        <div>


            <div className="flex justify-end my-3 -mt-[70px] mb-[50px]">
                <button onClick={exportCSV}
                    className="bg-brand-300 hover:bg-brand-400 text-white font-bold py-2 px-4 rounded flex items-center"
                    title="Download complete data grid in CSV"
                >
                    <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff" stroke-width="0.96"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12.5535 16.5061C12.4114 16.6615 12.2106 16.75 12 16.75C11.7894 16.75 11.5886 16.6615 11.4465 16.5061L7.44648 12.1311C7.16698 11.8254 7.18822 11.351 7.49392 11.0715C7.79963 10.792 8.27402 10.8132 8.55352 11.1189L11.25 14.0682V3C11.25 2.58579 11.5858 2.25 12 2.25C12.4142 2.25 12.75 2.58579 12.75 3V14.0682L15.4465 11.1189C15.726 10.8132 16.2004 10.792 16.5061 11.0715C16.8118 11.351 16.833 11.8254 16.5535 12.1311L12.5535 16.5061Z" fill="#ffffff"></path> <path d="M3.75 15C3.75 14.5858 3.41422 14.25 3 14.25C2.58579 14.25 2.25 14.5858 2.25 15V15.0549C2.24998 16.4225 2.24996 17.5248 2.36652 18.3918C2.48754 19.2919 2.74643 20.0497 3.34835 20.6516C3.95027 21.2536 4.70814 21.5125 5.60825 21.6335C6.47522 21.75 7.57754 21.75 8.94513 21.75H15.0549C16.4225 21.75 17.5248 21.75 18.3918 21.6335C19.2919 21.5125 20.0497 21.2536 20.6517 20.6516C21.2536 20.0497 21.5125 19.2919 21.6335 18.3918C21.75 17.5248 21.75 16.4225 21.75 15.0549V15C21.75 14.5858 21.4142 14.25 21 14.25C20.5858 14.25 20.25 14.5858 20.25 15C20.25 16.4354 20.2484 17.4365 20.1469 18.1919C20.0482 18.9257 19.8678 19.3142 19.591 19.591C19.3142 19.8678 18.9257 20.0482 18.1919 20.1469C17.4365 20.2484 16.4354 20.25 15 20.25H9C7.56459 20.25 6.56347 20.2484 5.80812 20.1469C5.07435 20.0482 4.68577 19.8678 4.40901 19.591C4.13225 19.3142 3.9518 18.9257 3.85315 18.1919C3.75159 17.4365 3.75 16.4354 3.75 15Z" fill="#ffffff"></path> </g></svg>
                </button>
            </div>


            <div className="flex justify-between items-center my-3">

                <div className="flex gap-5 items-center">


                    <div className="items-center">
                        <label htmlFor="start-date" className="block text-sm font-medium text-gray-700">Select platform</label>
                        <select id="channel-select" value={selectedChannel} class="mt-1 p-2 border-2 border-gray-300 rounded-lg cursor-pointer" onChange={handleChannelChange}>

                            <option value="">Select a Platform</option>
                            {platforms.map((platform) => (
                                <option value={platform.name} key={platform.name}>
                                    {platform.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="flex gap-4 items-end">
                        <div>
                            <label htmlFor="start-date" className="block text-sm font-medium text-gray-700">From</label>
                            <input
                                type="date"
                                id="start-date"
                                name="start-date"
                                className="mt-1 p-2 border-2 border-gray-300 rounded-lg cursor-pointer"
                            />
                        </div>
                        <div>
                            <label htmlFor="end-date" className="block text-sm font-medium text-gray-700">To</label>
                            <input
                                type="date"
                                id="end-date"
                                name="end-date"
                                className="mt-1 p-2 border-2 border-gray-300 rounded-lg cursor-pointer"
                            />
                        </div>

                        <div>
                            <button onClick={exportFilteredCSV}
                                className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded flex items-center"
                                title="Download selected dates data in CSV"
                            >
                                <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff" stroke-width="0.96"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12.5535 16.5061C12.4114 16.6615 12.2106 16.75 12 16.75C11.7894 16.75 11.5886 16.6615 11.4465 16.5061L7.44648 12.1311C7.16698 11.8254 7.18822 11.351 7.49392 11.0715C7.79963 10.792 8.27402 10.8132 8.55352 11.1189L11.25 14.0682V3C11.25 2.58579 11.5858 2.25 12 2.25C12.4142 2.25 12.75 2.58579 12.75 3V14.0682L15.4465 11.1189C15.726 10.8132 16.2004 10.792 16.5061 11.0715C16.8118 11.351 16.833 11.8254 16.5535 12.1311L12.5535 16.5061Z" fill="#ffffff"></path> <path d="M3.75 15C3.75 14.5858 3.41422 14.25 3 14.25C2.58579 14.25 2.25 14.5858 2.25 15V15.0549C2.24998 16.4225 2.24996 17.5248 2.36652 18.3918C2.48754 19.2919 2.74643 20.0497 3.34835 20.6516C3.95027 21.2536 4.70814 21.5125 5.60825 21.6335C6.47522 21.75 7.57754 21.75 8.94513 21.75H15.0549C16.4225 21.75 17.5248 21.75 18.3918 21.6335C19.2919 21.5125 20.0497 21.2536 20.6517 20.6516C21.2536 20.0497 21.5125 19.2919 21.6335 18.3918C21.75 17.5248 21.75 16.4225 21.75 15.0549V15C21.75 14.5858 21.4142 14.25 21 14.25C20.5858 14.25 20.25 14.5858 20.25 15C20.25 16.4354 20.2484 17.4365 20.1469 18.1919C20.0482 18.9257 19.8678 19.3142 19.591 19.591C19.3142 19.8678 18.9257 20.0482 18.1919 20.1469C17.4365 20.2484 16.4354 20.25 15 20.25H9C7.56459 20.25 6.56347 20.2484 5.80812 20.1469C5.07435 20.0482 4.68577 19.8678 4.40901 19.591C4.13225 19.3142 3.9518 18.9257 3.85315 18.1919C3.75159 17.4365 3.75 16.4354 3.75 15Z" fill="#ffffff"></path> </g></svg>
                                &nbsp;&nbsp; Report                    </button>
                        </div>
                    </div>
                </div>

            </div>


            <CustomTable data={gridData} columns={columns} onRowClick={() => { }} />


            {/* <Button onClick={() => loadData()} style={{marginRight: 10}}>
            Load async data
          </Button>
          <Button
            disabled={Array.isArray(dataSource)}
            onClick={() => setDataSource([])}
          >Clear data</Button> */}
            {/* <div style={{ height: 80 }} >Current filterValue: {filterValue ? <code>{JSON.stringify(filterValue, null, 2)}</code>: 'none'}.</div> */}
            {/*<ReactDataGrid*/}
            {/*    style={gridStyle}*/}
            {/*    idProperty="id"*/}
            {/*    pagination='local'*/}
            {/*    columns={columns}*/}
            {/*    dataSource={dataSource}*/}
            {/*    defaultLimit={50}*/}
            {/*    defaultFilterValue={defaultfilter}*/}
            {/*    onFilterValueChange={setFilterValue}*/}
            {/*    onRowClick={onRowClick}*/}
            {/*/>*/}

            {/*<DataModal*/}
            {/*    isOpen={isModalOpen}*/}
            {/*    onClose={() => setIsModalOpen(false)}*/}
            {/*    data={selectedRowData}*/}
            {/*    columns={columns}*/}
            {/*/>*/}
        </div>
    )
};

export default TransactionDataGrid;