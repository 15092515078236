import DatamapsIndia from "react-datamaps-india";

const Map = (props) => {
  const { regionData } = props;
  return (
    <DatamapsIndia
      style={{ position: "relative" }}
      regionData={regionData}
      hoverComponent={({ value }) => {
        return (
          <div>
            <div>
              {value.name} {value.value}
            </div>
          </div>
        );
      }}
      mapLayout={{
        startColor: "#b3d1ff",
        endColor: "#005ce6",
        hoverTitle: "Count",
        noDataColor: "#f5f5f5",
        borderColor: "#8D8D8D",
        hoverColor: "blue",
        hoverBorderColor: "green",
      }}
    />
  );
};

export default Map;
