import BeyoboNavbar from "../../../components/beyobonavbar";
import React, { useState, useEffect, useContext, useRef } from 'react';
import AuthContext from '../../../../src/context/AuthContext';
import CustomDataGrid from "../../../components/datagrid";
import MultipleDataGrid from "../../../components/multipledatagrid";
import CustomOrderDataGrid from "../../../components/ordersdatagrid";
import CatalogProductContext from '../../../../src/context/CatalogProductContext';
import SearchableOrderTable from "./searchableTables";
import useApi from "hooks/useApi";
import usePath from "hooks/usePath";
import { useNavigate } from "react-router-dom";
import useApiPaths from "hooks/useApiPath";
import CustomTable from "components/customTable";


const Order = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    // Fetch data from the backend

    const { products } = useContext(CatalogProductContext);

    // Calculate the current items to display
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = products.slice(indexOfFirstItem, indexOfLastItem);


    // State to keep track of the active tab
    const [activeTab, setActiveTab] = useState('Orders');

    // Handler for tab click
    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    const [isPopupOpen, setPopupOpen] = useState(false);
    const [step, setStep] = useState(1);
    const [selection, setSelection] = useState('');
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const openPopup = () => {
        setPopupOpen(true);
    };

    const [formData, setFormData] = useState({
        warehouseId: '', // For storing the selected warehouse ID
        variants: [], // To hold the variant details including SKU IDs and quantities
        // Add any other fields as necessary, for example:
        // channel_id: '', // If you're optionally including channel information
    });

    // const [shipmentvariantData, setShipmentVariantData] = useState([
    //     { name: '', ordered_quantity: '', isChecked: false, label: '' },
    // ]);



    const handleVariantChange = (name, field, value) => {
        setFormData(prevState => {
            const variants = [...prevState.variants];
            const existingVariantIndex = variants.findIndex(v => v.name === name);
            if (existingVariantIndex >= 0) {
                // Update existing variant
                variants[existingVariantIndex] = { ...variants[existingVariantIndex], [field]: value };
            } else {
                // Add new variant if not exists (optional, based on your logic)
                variants.push({ name: name, [field]: value });
            }
            return { ...prevState, variants };
        });
    };
    

    // const [selectedVariants, setSelectedVariants] = useState([]);

    // Handle changes in the form inputs
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    //const handleCheckboxChange = (index) => {
    //    setShipmentVariants(currentVariants =>
    //        currentVariants.map((variant, i) =>
    //            i === index ? { ...variant, isChecked: !variant.isChecked } : variant
    //        )
    //    );
    //};

    const closePopup = () => {
        setPopupOpen(false);
        setStep(1); // Reset the step to 1 when closing the modal.
    };


    const [shipmentVariants, setShipmentVariants] = useState([]);

    // Fetch data from the backend
    const { authToken } = useContext(AuthContext);
    // const {user} =useContext(UserContext);
    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_API_URL}/sku/`, {
            headers: {
                'Authorization': `Token ${authToken}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log(data);
                const variantsWithLabel = data.map(variant => ({
                    ...variant,
                    isChecked: false, // Existing property
                    label: variant.name, // Add label property
                    
                }));
                setShipmentVariants(variantsWithLabel);
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });
    }, [authToken]);



    //const [currentStep, setCurrentStep] = useState(1);


    //const handleNext = () => {
    //    if (currentStep < 2) setCurrentStep(currentStep + 1);
    //};

    //// Function to handle quantity change

   

    const [inputValue, setInputValue] = useState('');
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [selectedVariants, setSelectedVariants] = useState([]);
    const [displayLimit, setDisplayLimit] = useState(25);

    const handleInputChanges = (event) => {
        const value = event.target.value;
        setInputValue(value);
        filterOptions(value);
        if (!value) {
            // Reset display limit when input is cleared
            setDisplayLimit(25);
        }
    };

    const handleFocus = () => {
        filterOptions(inputValue, true);
    };

    const filterOptions = (value, isInitialFocus = false) => {
        let optionsToShow = [];
        if (value) {
            optionsToShow = shipmentVariants.filter(variant =>
                variant.label.toLowerCase().includes(value.toLowerCase())
            );
        } else if (isInitialFocus) {
            optionsToShow = shipmentVariants.slice(0, displayLimit);
        }
        setFilteredOptions(optionsToShow);
    };

   
    const handleOptionClick = (variant) => {
        setSelectedVariants(prevVariants => {
            // Check if variant is already selected
            if (prevVariants.some(v => v.name === variant.name)) {
                return prevVariants;
            }
            return [...prevVariants, { ...variant, isChecked: false }];
        });
        setInputValue('');
        setFilteredOptions([]);
    };

    const handleQuantityChange = (variantId, quantity) => {
        setSelectedVariants(prev =>
            prev.map(v => v.id === variantId ? { ...v, quantity: Number(quantity) } : v)
        );
    };


    const [highlightRange, setHighlightRange] = useState({ start: 0, end: 25 });


    const inputRef = useRef(null);

    const handleShowMoreClick = (event) => {
        event.stopPropagation(); // Prevent the event from bubbling up

        setDisplayLimit(prevLimit => {
            const newStart = prevLimit;
            const newEnd = prevLimit + 25 < shipmentVariants.length ? prevLimit + 25 : shipmentVariants.length;

            setHighlightRange({ start: newStart, end: newEnd }); // Update the highlight range

            return newEnd; // Update display limit to show more items
        });

        // Re-focus the input element after state update, as a fallback
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };


    const handleRemoveVariant = (name) => {
        setFormData(prevState => ({
            ...prevState,
            variants: prevState.variants.filter(variant => variant.name !== name)
        }));
        setSelectedVariants(prevVariants => prevVariants.filter(variant => variant.name !== name));
    };





    const [warehouses, setWarehouses] = useState([]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_API_URL}/warehouse/warehouses/`, {
            headers: {
                'Authorization': `Token ${authToken}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                const warehousesWithLabels = data.map(warehouse => ({
                    ...warehouse,
                    label: warehouse.name,
                    id: warehouse.id
                }));
                setWarehouses(warehousesWithLabels);
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });
    }, [authToken]);


    const [warehouseId, setWarehouseId] = useState('');

    // Function to handle the warehouse selection
    const handleWarehouseChange = (event) => {
      setWarehouseId(event.target.value);
    };

    const handleOpenPopup = () => setPopupOpen(true);
    const handleClosePopup = () => setPopupOpen(false);
  
    const handleSubmit = async () => {
        if (!warehouseId) {
            alert("Please select a warehouse.");
            return;
        }

        const submissionData = {
           
            warehouseId,
            variants: formData.variants.map(variant => ({
                name: variant.name,
               quantity: variant.quantity
            }))
           
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/createorder/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${authToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(submissionData),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            console.log('Order creation success:', result);
            handleClosePopup(); // Close the popup on successful submission
        } catch (error) {
            console.error('Error submitting order:', error);
        }
    };
    const renderStepOne = () => {
        return (

            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
            <div className="relative top-20 mx-auto p-5 border w-1/2 shadow-lg rounded-md bg-white">
                {/* Order Creation Form */}
                <div className="py-6">
                    <label htmlFor="warehouse">Choose Warehouse:</label>
                    <select name="warehouse" value={warehouseId} onChange={handleWarehouseChange} className="block w-full mt-1">
                        <option value="">Select a Warehouse</option>
                        {warehouses.map(warehouse => (
                            <option key={warehouse.id} value={warehouse.id}>{warehouse.label}</option>
                        ))}
                    </select>

                <div className="py-6">
                <h1 className="bold-text">Choose Skus to Order</h1>
                <br></br>

                {/* Custom Autocomplete input */}
                <input
                    type="text"
                    value={inputValue}
                    onChange={handleInputChanges}
                    placeholder="Search your variant"
                    className="w-full border-2 border-gray-300 p-2 rounded-lg" // Updated styling for the input
                />

                {/*// Inside your renderStepOne function or wherever you're rendering the options*/}
                {filteredOptions.length > 0 && (
                    <div className="options-container border border-gray-300 rounded-lg">
                        {filteredOptions.map((variant, index) => (
                            <div
                                key={index}
                                className="option p-2 hover:bg-gray-100 cursor-pointer"
                                onClick={() => handleOptionClick(variant)}
                            >
                                {variant.label}
                            </div>
                        ))}
                    </div>
                )}

                <br />

                {/* Display Selected Variants */}

                <div className="flex gap-10 mr-6 justify-end mt-3">
                    <strong>Quantity</strong>
                    
                </div>


                {selectedVariants.map((variant, index) => (
                    <div key={index} className="flex items-center my-5 bg-gray-100 p-2 rounded-lg relative">
                        <span className="flex-grow">{variant.name}</span>
                        <input
                            type="number"
                            value={formData.variants.find(v => v.name === variant.name)?.quantity || ''}
                            onChange={(e) => handleVariantChange(variant.name, 'quantity', e.target.value)}
                            className="w-20 h-8 text-center border-2 border-gray-300 rounded focus:outline-none mx-2"
                            min="0"
                        />

                        <span
                            onClick={() => handleRemoveVariant(variant.name)}
                            className="absolute right-0 top-[-8px] cursor-pointer text-red-500 hover:text-red-700 text-lg">
                            <svg width="14px" height="14px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM15.75 12C15.75 12.4142 15.4142 12.75 15 12.75H9C8.58579 12.75 8.25 12.4142 8.25 12C8.25 11.5858 8.58579 11.25 9 11.25H15C15.4142 11.25 15.75 11.5858 15.75 12Z" fill="#ff0000"></path> </g></svg>
                        </span>
                    </div>
                ))}

                    <div className="flex justify-end mt-4">
                        <button onClick={handleClosePopup} className="bg-gray-200 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-300 mr-2">
                            Cancel
                        </button>
                        <button onClick={handleSubmit} className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700">
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
        </div>
   
        );
    };



    const [userRole, setUserRole] = useState('');
    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_API_URL}/userrole/`, {
            headers: {
                'Authorization': `Token ${authToken}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                // Assuming the API returns an object with a `role` property
                setUserRole(data.role);
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });
    }, [authToken]); // Dependency on authToken

    return (
        <>
            <BeyoboNavbar></BeyoboNavbar>

            <div className="container mx-auto py-2">
                <div className="flex border-b mt-5 justify-center">
                    <button
                        className={`py-2 px-4 text-lg font-medium ${activeTab === 'Orders' ? 'border-b-2 border-indigo-500 text-indigo-600' : 'text-gray-500 hover:text-gray-600'}`}
                        onClick={() => handleTabClick('Orders')}
                    >
                        Orders
                    </button>
                    <button
                        className={`py-2 px-4 text-lg font-medium ${activeTab === 'Purchase Orders' ? 'border-b-2 border-indigo-500 text-indigo-600' : 'text-gray-500 hover:text-gray-600'}`}
                        onClick={() => handleTabClick('Purchase Orders')}
                    >
                        Purchase Orders
                    </button>
                    {/* <button
                        className={`py-2 px-4 text-lg font-medium ${activeTab === 'Pending Orders' ? 'border-b-2 border-indigo-500 text-indigo-600' : 'text-gray-500 hover:text-gray-600'}`}
                        onClick={() => handleTabClick('Pending Orders')}
                    >
                        Pending Orders
                    </button> */}
                </div>

                <div className="pt-4">
                    {activeTab === 'Orders' && (
                        <div>
                            <>

                                {userRole !== 'Admin' && (
                                    <div className="right-4 absolute z-50" style={{ marginTop: '-65px' }}>
                                        <button
                                            onClick={openPopup}
                                            className="bg-brand-300 hover:bg-brand-400 text-white font-bold py-2 px-4 rounded flex items-center mt-4"
                                        >
                                            + Create Order
                                        </button>
                                    </div>
                                )}

                                {/*<div>*/}
                                {/*    <h1>SKU Lists</h1>*/}
                                {/*    {shipmentVariants.length > 0 ? (*/}
                                {/*        <ul>*/}
                                {/*            {shipmentVariants.map(variant => (*/}
                                {/*                <li key={variant.id}>*/}
                                {/*                    {variant.label}*/}
                                {/*                </li>*/}
                                {/*            ))}*/}
                                {/*        </ul>*/}
                                {/*    ) : (*/}
                                {/*        <p>No shipment variants available.</p>*/}
                                {/*    )}*/}
                                {/*</div>*/}

                                {isPopupOpen && (
                                    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
                                        <div className="relative top-20 mx-auto p-5 py-[30px] border shadow-lg rounded-md bg-white w-1/3">

                                            <div className="absolute top-0 right-0 pt-4 pr-4">
                                                <button
                                                    onClick={closePopup}
                                                    className="px-4 py-2 bg-gray-300 text-gray-900 rounded hover:bg-gray-400"
                                                >
                                                    X
                                                </button>
                                            </div>

                                            <div className="mt-3">

                                                <div className="mt-2">
                                                    <div>
                                                        {isPopupOpen && (
                                                            <div>
                                                                <div>
                                                                    {step === 1 && renderStepOne()}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                )}
                            </>
                            <SearchableOrderTable></SearchableOrderTable>
                            {/*<CustomOrderDataGrid></CustomOrderDataGrid>*/}
                        </div>
                    )}
                    {activeTab === 'Purchase Orders' && (
                        <PurchaseOrderTable></PurchaseOrderTable>
                    )}
                    {/* {activeTab === 'Pending Orders' && (
                        <div>Pending Orders</div>
                    )} */}
                </div>
            </div>

        </>
    );
};




const PurchaseOrderTable = () => {
    const api = useApi();
    const paths = usePath();
    const navigate = useNavigate();
    const [orders, setOrders] = useState([]);
    const apiPaths = useApiPaths();
    useEffect(() => {
        api.get(apiPaths.companyPurchaseOrders)
            .then(r => {
                setOrders(r);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        { header: 'Order Id', key: 'orderId' },
        { header: 'Date', key: 'orderDate' },
        { header: 'Vendor', key: 'vendor' },
    ];

    const handleRowClick = (rowData, columnKey) => {
        console.log('Column clicked:', columnKey);
        console.log('Row data:', rowData);
        navigate(`/order/${rowData.orderId}`);
    };

    return (
        <>
            <CustomTable data={orders} columns={columns} onRowClick={handleRowClick} />
        </>
    );

}


export default Order;


//<div className="container mx-auto px-4 py-2">
//    <SearchableTable></SearchableTable>
//    <CustomOrderDataGrid></CustomOrderDataGrid>
//</div>

//<div className="m-5 rounded-lg">
//                <MultipleDataGrid></MultipleDataGrid>
//            </div>