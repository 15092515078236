import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useApi from "../hooks/useApi";

const UserRoleContext = createContext();

export const UserRoleProvider = ({ children }) => {
  // const [role, setRole] = useState(localStorage.getItem('beyoboRole'));
  // const [rolesList, setRolesList] = useState(localStorage.getItem('beyoboRoleList')?.split(',') ?? []); // Step 1: Adding a new state for roles list
  const navigate = useNavigate();
  const api = useApi();

  const checkRoles = (rolesToBeChecked) => {
    const rolesList = getRoleList();
    for (let i = 0; i < rolesList.length; i++) {
      if (rolesToBeChecked.includes(rolesList[i]))
        return true;
    }
    return false;
  }

  const getRole = () => {
    const k = localStorage.getItem('beyoboRole') ?? 'supplier';
    return k;
  }

  const getRoleList = () => {
    return localStorage.getItem('beyoboRoleList')?.split(',') ?? ['supplier']
  }

  const setRoleList = (r) => {
    if (!r || ((r?.length??0) <= 0))
      r = ['supplier'];
    localStorage.setItem('beyoboRoleList', r.join(','));
  }

  const getUserRoles = async () => {
    var r = (await api.get('coreuser/roles/')).filter(k => (k?.length ?? 0) > 0).map(k => k.toLowerCase().trim());
    setRoleList(r);
    const rolesList = getRoleList();
    console.log('repose roles', r);
    console.log('rolelist', rolesList);
    const role = getRole();
    if (rolesList.findIndex(k => k === role) > -1) { navigateToHome(); return; }
    updateRole(rolesList.length > 0 ? rolesList[0] : "supplier");
  }

  const navigateToHome = () => {
    const role = getRole();
    const path = roleHomePath(role);
    if (path.length <= 0)
      return;
    navigate(path);
  }

  const roleHomePath = (r) => {
    if (r === "supplier") {
      return '/admin/dashboard';
    }
    if (r === "manager") {
      return '/manage/managerrole'
    }
    return '';
  }

  const updateRole = (newRole) => {
    const setStorage = (newRole) => {
      localStorage.setItem('beyoboRole', newRole);
      //setRole(newRole);
    }
    newRole = newRole?.toLowerCase()?.trim() ?? '';
    const path = roleHomePath(newRole);
    if ((path?.length ?? 0) > 0) {
      setStorage(newRole);
      navigate(path);
    }
  };

  return (
    <UserRoleContext.Provider value={{ getRole, getRoleList, updateRole, getUserRoles, navigateToHome, checkRoles }}>
      {children}
    </UserRoleContext.Provider>
  );
};

export const useUserRole = () => useContext(UserRoleContext);
