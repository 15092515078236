/*import { FcGoogle } from "react-icons/fc";*/
import React, { useState, useEffect } from 'react';
import BeyoboNavbar from '../../components/beyobonavbar';


export default function OnboardingThanks() {

    return (
        <>
            <BeyoboNavbar></BeyoboNavbar>

            <div className="flex flex-row justify-between bg-white h-screen">
                {/* Left Content Section */}
                <div className="flex flex-col w-1/2 p-8">
                    <div className="mt-0 w-max lg:pt-10 px-10">
                        <img className="h-auto w-[108px]" src={'https://media.licdn.com/dms/image/D4D0BAQFqFAUkiXkfEA/company-logo_200_200/0/1690444337691?e=2147483647&v=beta&t=EyyY89lbCTDKr0dgfgPUhBn6e7QLeg2Mq8X-58aJtEo'} alt="beyobo logo" />
                    </div>

                    <img src='https://cdn-icons-png.flaticon.com/512/8832/8832108.png' alt="Section Content" className="w-[108px] self-center mt-10" />
                    <p className="text-black text-5xl text-center font-bold mt-4">Thank you for signing up with us!</p>
                    <p className="text-gray text-lg text-center mt-3">We will also share the credentials via the email address provided. You may use them to log in into Beyobo.</p>
                    <button className="px-6 py-2 bg-blue-500 mt-16 text-white rounded-lg w-fit self-center">
                        Log in now
                    </button>

                </div>

                {/* Right Image Section */}
                <div className="w-1/2">
                    <img src='https://sellercentral.jennifer-in.com/assets/images/loginleftbg.jpg' alt="Section Content" className="w-full h-full object-cover" />
                </div>
            </div>
        </>

    );
}
