
import { useContext } from 'react';
import { AuthContext } from '../../src/context/AuthContext'
import { useDialog } from 'components/dialog';
import { useToast } from 'components/toast';


const useApi = () => {
  const { getToken } = useContext(AuthContext);
  const { openDialog } = useDialog();
  const { toastSuccess,toastInfo,toastError } = useToast();

  const getHeaders = () => {
    return {
      'Authorization': `Token ${getToken()}`,
      'Content-Type': 'application/json',
    };
  };

  const httpFetch = (path, method, body = {}, headers = {}) => {
    // Construct the basic options object
    let options = {
      method: method,
      headers: { ...headers, 'Content-Type': 'application/json' },
    };
    
    // Conditionally add the body property for methods that usually have a body
    if (method !== 'GET' && method !== 'HEAD') {
      options.body = JSON.stringify(body);
    }
  
    return fetch(path, options)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .catch(error => {
        toastError(`There was a problem with the fetch operation: ${error}`);
        console.error('There was a problem with the fetch operation:', error);
        throw error;
      });
  };


  const get = (path) => {
    return fetch(`${process.env.REACT_APP_BASE_API_URL}/${path}`, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .catch(error => {
        toastError(`There was a problem with the fetch operation: ${error}`);
        console.error('There was a problem with the fetch operation:', error);
        throw error;
      });
  };

  // You can implement post, put, delete, etc., in a similar manner
  // Example POST request implementation
  const post = (path, data) => {
    return fetch(`${process.env.REACT_APP_BASE_API_URL}/${path}`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(data),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .catch(error => {
        toastError(`There was a problem with the fetch operation: ${error}`);
        console.error('There was a problem with the fetch operation:', error);
        throw error;
      });
  };

  const blobFetch = (path,downloadFileName,data) =>{
    return fetch(`${process.env.REACT_APP_BASE_API_URL}/${path}`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(data)
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = downloadFileName;
        document.body.appendChild(a); // We append the a element temporarily to the body to initiate download
        a.click();
        a.remove(); // Afterwards we remove the element again
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        toastError(`There was a problem with the fetch operation: ${error}`);
        console.error('There was a problem with the fetch operation:', error);
        throw error;
      });
  };

  return {
    get,
    post,
    httpFetch,
    blobFetch
    // Include other methods as needed
  };
};

export default useApi;
