//import React, { useState } from "react";
//import Calendar from "react-calendar";
//import Card from "components/card";
//import "react-calendar/dist/Calendar.css";
//import { MdChevronLeft, MdChevronRight } from "react-icons/md";
//import "assets/css/MiniCalendar.css";

//const MiniCalendar = () => {
//  const [value, onChange] = useState(new Date());

//  return (
//    <div>
//      <Card extra="flex w-full h-full flex-col px-3 py-3">
//        <Calendar
//          onChange={onChange}
//          value={value}
//          prevLabel={<MdChevronLeft className="ml-1 h-6 w-6 " />}
//          nextLabel={<MdChevronRight className="ml-1 h-6 w-6 " />}
//          view={"month"}
//        />
//      </Card>
//    </div>
//  );
//};

//export default MiniCalendar;


// RequireRole.jsx
//import React from 'react';
//import { useNavigate } from 'react-router-dom';
//import { useUserRole } from '../../context/UserRoleContext'; // Adjust the import path as necessary

//const RequireRole = ({ children, allowedRoles }) => {
//    const { checkRoles } = useUserRole();
//    const navigate = useNavigate();

//    React.useEffect(() => {
//        const hasAccess = checkRoles(allowedRoles);
//        if (!hasAccess) {
//            // Directly navigate to the sign-in page without showing an alert
//            navigate('/auth/sign-in');
//        }
//    }, [allowedRoles, checkRoles, navigate]);

//    // Only render children if the user has one of the allowed roles
//    return checkRoles(allowedRoles) ? children : null;
//};

//export default RequireRole;


