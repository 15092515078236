import React, { useState } from 'react';
import Select, { components } from 'react-select';

// Custom Option Component that supports HTML content
const CustomOption = (props) => (
  <components.Option {...props}>
    <div style={{
      whiteSpace: 'normal', // Allows text to wrap
      overflowWrap: 'break-word', // Ensures long words do not cause overflow
      maxWidth: '100%', // Restricts the width to prevent overflow
    }}>
      {props.data.customLabel}
    </div>
  </components.Option>
);

// Custom Select Wrapper Component
const CustomSelect = ({ options,isSearchable,isClearable, onChange, className, style,stickOption = true, ...props }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = (option) => {
    if(stickOption){
    setSelectedOption(option);
    }
    if (onChange) onChange(option);
  };

  // Calculate the width to be used by the menu portal. This could also be directly set.
  const calculatedWidth = style && style.width ? style.width : 'auto';

  // Override the styles

  // Custom styles for react-select components
  const customStyles = {
    control: (base) => ({ ...base, ...style }),
    option: (provided, state) => ({
      ...provided,
      whiteSpace: 'normal',
      overflowWrap: 'break-word',
    }),
    menuPortal: (base) => ({
      ...base,
      width: calculatedWidth,
    }),
    menu: (base) => ({
      ...base,
      position: 'relative',
    })
  };

  return (
    <Select
      value={selectedOption}
      onChange={handleChange}
      options={options}
      isClearable={(!isClearable)?true:isClearable}
      isSearchable={(!isSearchable)?true:isSearchable}
      components={{ Option: CustomOption }}
      className={className}
      styles={customStyles}
      menuPortalTarget={document.body} // Append the dropdown menu to the body element
      {...props}
    />
  );
};

export default CustomSelect;
