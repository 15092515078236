import React from 'react';
import moment from 'moment';

// Add selectedCurrency and exchangeRates to the component props
const DataModal = ({ isOpen, onClose, data, columns, selectedCurrency, exchangeRates }) => {
    const formatCurrency = (amount, currency, exchangeRates) => {
        const rate = exchangeRates[currency] || 1; // Fallback to 1 if the currency rate is not found
        const convertedValue = amount * rate;
        return `${currency} ${convertedValue.toFixed(2)}`;
    };


    return (
        <div className={`${isOpen ? 'flex' : 'hidden'} fixed inset-0 z-50 overflow-auto bg-smoke-light flex justify-center items-center`}>
            <div className="bg-white p-6 md:max-w-xl w-full rounded-lg shadow-lg">
                <div className="flex justify-between items-center pb-3">
                    <div></div>
                    <div className="cursor-pointer z-50" onClick={onClose}>
                        <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="Menu / Close_MD"> <path id="Vector" d="M18 18L12 12M12 12L6 6M12 12L18 6M12 12L6 18" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g> </g></svg>
                    </div>
                </div>
                <table className="w-full text-left border-collapse">
                    <tbody className="text-gray-700">
                        {columns.map(column => (
                            <tr key={column.name} className="border-b">
                                <td className="py-2 px-4 border-r font-semibold">{column.header}:</td>
                                <td className="py-2 px-4">
                                    {data && data[column.name]}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

            </div>
        </div>
    );
};

export default DataModal;
