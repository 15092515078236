import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import AuthContext from "context/AuthContext";


const ResumeSupplierDashboard = () => {
    const { authToken } = useContext(AuthContext);

    // State to manage the visibility of the resume upload form popup
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    // Function to open the popup
    const handleOpenPopup = (e) => {
        e.preventDefault(); // Prevent default link behavior
        setIsPopupOpen(true); // Open the popup
    };

    // Function to close the popup
    const handleClosePopup = () => setIsPopupOpen(false);

    // Initial form data state
    const [formData, setFormData] = useState({
        name: '',
        designation: '',
        notice_period_remaining: '',
        salary_expectations: '',
        joining_date: '',

    });

    // Handle form data change
    const handleFormDataChange = (name, value) => {
        setFormData({ ...formData, [name]: value });
    };


    // State to store the selected file
    const [file, setFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState('');

    // Handler for file changes
    const handleFileChange = (event) => {
        const file = event.target.files[0]; // Get the first file
        if (file) {
            setFile(file);
            setPreviewUrl(URL.createObjectURL(file));
        }
    };

    const handleResumeSubmit = async (event) => {
        event.preventDefault(); // Prevent the default form submission action

        let bodyFormData = new FormData();

        for (let key in formData) {
            bodyFormData.append(key, formData[key]);
        }

        bodyFormData.append('resume', file);

        const endpoint = `${process.env.REACT_APP_BASE_API_URL}/candidate/`;

        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${authToken}`
                },
                body: bodyFormData,
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }


        } catch (error) {
            console.error('Error:', error);
            // Handle errors, e.g., showing an error message
        }
    };


    return (
        <>
            <div>

                <div className="mt-3 bg-white p-5 rounded-lg shadow">
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="candidateName">
                                Candidate Name *
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="name"
                                name="name"
                                type="text"
                                placeholder="Candidate Name"
                                value={formData.name}
                                onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}
                                required
                            />
                        </div>

                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="designation">
                                Designation*
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="designation"
                                name="designation"
                                type="tel"
                                placeholder="Designation"
                                value={formData.designation}
                                onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}
                                required
                            />
                        </div>

                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="noticePeriod">
                                Notice Period Remaining *
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="notice_period_remaining"
                                name="notice_period_remaining"
                                type="text"
                                placeholder="Notice Period Remaining"
                                value={formData.notice_period_remaining}
                                onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}
                                required
                            />
                        </div>

                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="salaryExpected">
                                Salary Expected *
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="salary_expectations"
                                name="salary_expectations"
                                type="text"
                                placeholder="Salary Expected"
                                value={formData.salary_expectations}
                                onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}
                                required
                            />
                        </div>

                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="dateOfJoining">
                                Date of joining *
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="joining_date"
                                name="joining_date"
                                type="date"
                                placeholder="Date of joining"
                                value={formData.joining_date}
                                onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}
                                required
                            />
                        </div>

                        <div className="my-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                Upload Resume *
                            </label>
                            <div className="flex justify-center px-6 pt-5 pb-6 border-2 border-blue-500 border-dotted rounded-lg">
                                <div className="space-y-1 text-center">
                                    {/* Display preview if there's a file */}
                                    {previewUrl && (
                                        <img src={previewUrl} alt="Preview" className="mx-auto mb-3" style={{ maxWidth: '200px', maxHeight: '200px' }} />
                                    )}
                                    <div className="flex text-sm text-gray-600">
                                        <label htmlFor="file-upload" className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                            <span>Upload a file</span>
                                            <input
                                                id="file-upload"
                                                name="resume"
                                                type="file"
                                                className="sr-only"
                                                required
                                                onChange={handleFileChange}
                                                accept="image/png, image/jpeg, image/gif"
                                            />
                                        </label>
                                        <p className="pl-1">or drag and drop</p>
                                    </div>
                                    <p className="text-xs text-gray-500">
                                        PNG, JPG, GIF up to 10MB
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-5">
                            <button onClick={handleResumeSubmit} type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
                {/*<button*/}
                {/*    to="#"*/}
                {/*    onClick={handleOpenPopup}*/}
                {/*    className="text-sm bg-brand-300 text-white p-3 rounded-lg text-gray-800 dark:text-white hover:dark:text-white hover:text-brand-900 active:text-brand-900"*/}
                {/*>*/}
                {/*    Upload Resume*/}
                {/*</button>*/}
            </div>
        </>
    );
};

export default ResumeSupplierDashboard;