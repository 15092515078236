import React, { useEffect, useState, useContext } from 'react';
import AuthContext from "../../../../src/context/AuthContext";
// Adjust the import path to AuthContext according to your project structure

const ShipmentDetails = ({ shipmentId }) => {
    const { authToken } = useContext(AuthContext);
    const [shipmentDetails, setShipmentDetails] = useState(null);

    useEffect(() => {
        const fetchShipmentDetails = async () => {
            const url = `${process.env.REACT_APP_BASE_API_URL}/shipmentvariant/${shipmentId}`;
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Token ${authToken}`,
                },
            });
            const data = await response.json();
            setShipmentDetails(data);
        };

        fetchShipmentDetails();
    }, [shipmentId, authToken]);

    if (!shipmentDetails) return <p>Loading...</p>;

    return (
        <div className="p-4">
            <h2 className="text-xl font-bold">Shipment Details</h2>
            {/* Render your shipment details here */}
            <p>Name: {shipmentDetails.name}</p>
            {/* Add more details as needed */}
        </div>
    );
};

export default ShipmentDetails;
