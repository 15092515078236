import { useParams, Navigate } from 'react-router-dom';
import SignIn from 'views/auth/SignIn';
export default function Auth() {
    const { path } = useParams();

    // If the path is not 'sign-in', redirect to 'auth/sign-in'
    if (path !== 'sign-in') {
        return <Navigate to="/auth/sign-in" replace />;
    }

    // Render the SignIn component if the path is exactly 'sign-in'
    return <SignIn />;
}