import React from "react";
import { useContext } from "react";
import Dropdown from "components/dropdown";
import { Link } from "react-router-dom";
import navbarimage from "assets/img/layout/Navbar.png";
import UserContext from "../../../src/context/UserContext";
import AuthContext from "../../../src/context/AuthContext";
import { useNavigate } from 'react-router-dom';
import avatar from "assets/img/avatars/avatar4.png";
import { useState, useEffect } from "react";
import { useUserRole } from "../../context/UserRoleContext"
import useString from "../../hooks/useString"


const BeyoboNavbar = () => {
    const { getUsername } = useContext(UserContext);
    const { getRole,getRoleList, updateRole} = useUserRole();
    const { capitalizeFirstLetter } = useString();

    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('authToken'); 
        localStorage.removeItem('username');
        localStorage.removeItem('beyoboRoleList');
        localStorage.removeItem('beyoboRole')
        navigate('/auth/sign-in'); 
    };

    // State to manage the visibility of the resume upload form popup
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    // Function to open the popup
    // Function to close the popup
    const handleClosePopup = () => setIsPopupOpen(false);

    // Initial form data state
    const [formData, setFormData] = useState({
        candidateName: '',
        designation: '',
        noticePeriod: '',
        currency: '',
        salaryExpected: '',
        dateOfJoining: '',
    });

    // Handle form data change
    const handleFormDataChange = (name, value) => {
        setFormData({ ...formData, [name]: value });
    };

    // Dynamically load the gtranslate script
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://cdn.gtranslate.net/widgets/latest/float.js";
        script.defer = true;
        document.body.appendChild(script);

        window.gtranslateSettings = {
            "default_language": "en",
            "languages": ["en", "zh-CN", "zh-TW", "hi", "ja", "ko"],
            "wrapper_selector": ".gtranslate_wrapper",
            "switcher_horizontal_position": "right",
            "switcher_vertical_position": "top",
            "float_switcher_open_direction": "bottom",
            "alt_flags": { "en": "usa" }
        };

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (

        <>
            <nav className="sticky z-100 flex flex-row flex-wrap items-center justify-evenly rounded-xl bg-white/10 backdrop-blur-xl w-full">

                <div className="relative flex flex-grow items-center justify-between gap-2 pr-[12vw] bg-white px-10 py-4 shadow-xl shadow-shadow-500 w-full dark:!bg-navy-800 dark:shadow-none md:flex-grow-0 md:gap-1 xl:gap-2">

                    <div className="flex justify-around w-[55%]">

                        <div className="flex gap-10 items-center">

                            <button onClick={() => { navigate(-1); }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="feather feather-arrow-left" viewBox="0 0 24 24">
                                    <path d="M19 12H5"></path>
                                    <path d="M12 19l-7-7 7-7"></path>
                                </svg>
                            </button>


                            <img className="h-auto w-[61px]" src={'https://media.licdn.com/dms/image/D4D0BAQFqFAUkiXkfEA/company-logo_200_200/0/1690444337691?e=2147483647&v=beta&t=EyyY89lbCTDKr0dgfgPUhBn6e7QLeg2Mq8X-58aJtEo'} alt="beyobo logo" />
                        </div>


                        <div className="flex gap-10 items-center font-medium tracking-wider">

                            {
                                getRole() === "manager" ? (
                                    <>
                                        <Link
                                            to="/manage/purchaseorders"
                                            className="text-sm text-gray-800 dark:text-white hover:dark:text-white hover:text-blue-500 active:text-brand-300"
                                        >
                                            Orders
                                        </Link>
                                        <Link
                                            to="/manage/managerrole"
                                            className="text-sm text-gray-800 dark:text-white hover:dark:text-white hover:text-blue-500 active:text-brand-300"
                                        >
                                            Admin
                                        </Link>
                                    </>
                                ) : (
                                    <>
                                        <Link
                                            to="/admin/dashboard"
                                            className="text-sm text-gray-800 dark:text-white hover:dark:text-white hover:text-blue-500 active:text-brand-300"
                                        >
                                            Dashboard
                                        </Link>
                                        <Link
                                            to="/admin/catalogue/products"
                                            className="text-sm text-gray-800 dark:text-white hover:dark:text-white hover:text-blue-500 active:text-brand-300"
                                        >
                                            Catalogue
                                        </Link>
                                        <Link
                                            to="/admin/shipments"
                                            className="text-sm text-gray-800 dark:text-white hover:dark:text-white hover:text-blue-500 active:text-brand-300"
                                        >
                                            Shipments
                                        </Link>
                                        <Link
                                            to="/admin/orders"
                                            className="text-sm text-gray-800 dark:text-white hover:dark:text-white hover:text-blue-500 active:text-brand-300"
                                        >
                                            Orders
                                        </Link>
                                        <Link
                                            to="/admin/payments"
                                            className="text-sm text-gray-800 dark:text-white hover:dark:text-white hover:text-blue-500 active:text-brand-300"
                                        >
                                            Payments
                                        </Link>
                                    </>
                                )
                            }




                            {isPopupOpen && (
                                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
                                    <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                                        <div className="mt-3 text-center">
                                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                                {/* Close button */}
                                                <button onClick={handleClosePopup} className="absolute top-0 right-0 rounded-full bg-red-200 px-2 py-1 m-1">
                                                    X
                                                </button>
                                            </div>
                                            <h3 className="text-lg leading-6 font-medium text-gray-900">Upload Resume</h3>
                                            <form className="mt-2" onSubmit={(e) => e.preventDefault()}>
                                                {/* Form fields */}
                                                <div className="mb-4">
                                                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="candidateName">
                                                        Candidate Name
                                                    </label>
                                                    <input
                                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                        id="candidateName"
                                                        name="candidateName"
                                                        type="text"
                                                        placeholder="Candidate Name"
                                                        value={formData.candidateName}
                                                        onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}
                                                        required
                                                    />
                                                </div>
                                                {/* Repeat for other fields: designation, noticePeriod, etc. */}

                                                <div className="mb-4">
                                                    <button onClick={handleClosePopup} type="button" className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                                                        Close
                                                    </button>
                                                    <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                                                        Submit
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="flex items-center gap-10">
                        <div className="gtranslate_wrapper"></div>
                        <Dropdown
                            button={
                                <div className="flex items-center">
                                    <img
                                        className="h-10 w-10 rounded-full"
                                        src={avatar}
                                        alt="Elon Musk"
                                    />

                                    <div className="px-4">
                                        <div className="flex items-center gap-2">
                                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                                                {getUsername()}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            }
                            children={
                                <div className="flex w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
                                    <div className="flex flex-col p-4">
                                        {getRole() === "supplier" &&
                                            <Link
                                                to="/admin/payments"
                                                className="mt-3 text-sm text-gray-800 dark:text-white hover:dark:text-white"
                                            >
                                                Payments
                                            </Link>
                                        }
                                        {
                                            getRoleList().filter(r => r !== getRole()).map((r, i) => (<button
                                                onClick={() => { updateRole(r) }}
                                                className="mt-3 text-sm text-gray-800 dark:text-white hover:dark:text-white"
                                            >
                                                {capitalizeFirstLetter(r)}
                                            </button>))
                                        }
                                        <button
                                            onClick={handleLogout}
                                            className="mt-3 text-sm font-medium text-red-500 hover:text-red-500"
                                        >
                                            Log Out
                                        </button>
                                    </div>
                                </div>
                            }
                            classNames={"py-2 top-8 -left-[180px] w-max"}
                        />
                    </div>
                </div>
            </nav>
        </>

    );
};

export default BeyoboNavbar;



