
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter'
import React, { useState, useEffect, useContext, useCallback } from 'react';
import CustomTable from '../../../../components/customTable';
import DataModal from '../../../../components/modal';
import AuthContext from '../../../../context/AuthContext'
import moment from 'moment'

const AdminVariantDataGrid = () => {


    //const columns = [
    //    {name:'id',defaultFlex:1,header:'ID',},
    //    {name:'company_id',defaultFlex:1,header:'Company Id',},


    //    { name: 'name', defaultFlex: 1, header: 'Name' },

    //    { name: 'variant_code', header: 'SKUCode', defaultFlex: 1 },

    //    {
    //        name: 'hsn', header: 'HSN', defaultFlex: 1, type: 'number',
    //        //name: 'age',
    //        //header: 'Age',
    //        //defaultWidth: 80,
    //        //render: ({ value }) => <span style={{ color: value < 30 ? 'lightgreen' : 'inherit' }}>{value}</span>
    //    },
    //    {name:'local_inventory',defaultFlex:1,header:'Local Inventory',},
    //    {name:'FBA',defaultFlex:1,header:'FBA',},
    //    {name:'FBF',defaultFlex:1,header:'FBF',},

    //]

    //const defaultfilter = [
    //    {name: 'id',operator: 'gte',type: 'number'},
    //    {name: 'company_id',operator: 'gte',type: 'number'},
    //    { name: 'name', operator: 'startsWith', type: 'string'},
    //    { name: 'variant_code', operator: 'startsWith', type: 'string' },
    //    { name: 'hsn', operator: 'gte', type: 'number' },
    //    {name: 'local_inventory',operator: 'gte',type: 'number'},
    //    {name: 'FBA',operator: 'gte',type: 'number'},
    //    {name: 'FBF',operator: 'gte',type: 'number'},
    //    // {
    //    //     name: 'birthDate',
    //    //     operator: 'before',
    //    //     type: 'date',
    //    //     value: ''
    //    // },

    //];
    //const [filterValue, setFilterValue] = useState(defaultfilter);


    const columns = [
        { header: 'ID', key: 'id' },
        { header: 'Company ID', key: 'company_id' },
        { header: 'Name', key: 'name' },
        { header: 'Variant Code', key: 'variant_code' },
        { header: 'HSN', key: 'hsn' },
        { header: 'Local Inventory', key: 'local_inventory' },
        { header: 'FBA', key: 'FBA' },
        { header: 'FBF', key: 'FBF' },
    ];

    const [dataSource, setDataSource] = useState([]);
    const { authToken } = useContext(AuthContext);
    const [gridData, setGridData] = useState([]); // New state for actual data array

    const loadData = useCallback(() => {
        const newDataSource = () => {
            const limit = 50;
            const url = `${process.env.REACT_APP_BASE_API_URL}/product/complete_list/` + '?limit=' + limit;
            return fetch(url, {
                headers: {
                    'Authorization': `Token ${authToken}`
                }
            })
                .then(response => response.json())
                .then(data => {
                    const formattedData = data.map(item => ({
                        ...item,
                        order_date: moment(item.order_date).format('DD-MM-YYYY')
                    }));
                    setGridData(formattedData); // Update gridData with the actual data array
                    return { data: formattedData }; // Return the data for dataSource
                });
        }

        setDataSource(newDataSource)
    }, []);

    useEffect(() => {
        loadData();
    }, [loadData]); // Ensure loadData is stable and doesn't change unless necessary



    const gridStyle = { minHeight: 550, }


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);

    const onRowClick = useCallback((rowProps) => {
        setSelectedRowData(rowProps.data);
        setIsModalOpen(true);
    }, []);


    return (
        <div>
            <CustomTable data={gridData} columns={columns} onRowClick={() => { }} />

            {/*<ReactDataGrid*/}
            {/*    style={gridStyle}*/}
            {/*    idProperty="id"*/}
            {/*    columns={columns}*/}
            {/*    pagination='local'*/}
            {/*    dataSource={dataSource}*/}
            {/*    onRowClick={onRowClick}*/}
            {/*/>*/}
            {/* onFilterValueChange={setFilterValue}*/}{/*
            */}{/*    defaultFilterValue={filterValue}*/}
            {/*<DataModal*/}
            {/*    isOpen={isModalOpen}*/}
            {/*    onClose={() => setIsModalOpen(false)}*/}
            {/*    data={selectedRowData}*/}
            {/*    columns={columns}*/}
            {/*/>*/}
        </div>
    )
};

export default AdminVariantDataGrid;