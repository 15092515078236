import React, { useState,useContext } from 'react';
import Modal from 'react-modal'

// DialogContext.js
export const DialogContext = React.createContext();

export const DialogProvider = ({ children }) => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [enableCloseButton, setEnableCloseButton] = useState(true);
  const [dialogContent, setDialogContent] = useState(null);
  const [dialogSize, setDialogSize] = useState('medium'); // Default size

  const openDialog = (content, size = 'medium', enableCloseButton = true) => {
    setDialogContent(content);
    setDialogSize(size);
    setEnableCloseButton(enableCloseButton)
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
    setDialogContent(null);
    setDialogSize('medium'); // Reset to default size
  };

  return (
    <DialogContext.Provider value={{ isDialogOpen, dialogContent, dialogSize,enableCloseButton, openDialog, closeDialog }}>
      {children}
    </DialogContext.Provider>
  );
};

export const useDialog = () => useContext(DialogContext);

Modal.setAppElement('#root');
// DialogComponent.js
export const DialogComponent = () => {
  const { isDialogOpen, dialogContent, dialogSize, closeDialog, enableCloseButton } = useDialog();

  // Updated function to return fixed widths
  const dialogWidth = (size) => {
    switch (size) {
      case 'small':
        return '300px'; // Fixed width for small
      case 'large':
        return '700px'; // Fixed width for large
      default: // medium
        return '500px'; // Fixed width for medium
    }
  };

  const customStyles = {
    content: {
      top: '40%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: '1px solid #ccc',
      background: '#fff',
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      borderRadius: '4px',
      outline: 'none',
      padding: '40px 20px 20px 20px',
      width: dialogWidth(dialogSize), // Apply fixed width based on dialog size
      // Ensure the modal dialog is not too tall for the viewport
      maxHeight: '85vh', 
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
  };

  return (
    <Modal
      isOpen={isDialogOpen}
      onRequestClose={closeDialog}
      style={customStyles}
      contentLabel="Dialog Modal"
    >
      {enableCloseButton && (
        <div className="absolute top-0 right-0 pt-4 pr-4">
          <button onClick={closeDialog} className="px-4 py-2 bg-gray-300 text-gray-900 rounded hover:bg-gray-400">X</button>
        </div>
      )}
      <div className="mt-3">
        {dialogContent}
      </div>
    </Modal>
  );
};