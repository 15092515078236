import BeyoboNavbar from "../../../components/beyobonavbar";
import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
//import DashboardBox from "../../../components/dashboardbox";
//import Notification from "../../../components/notification";
import CatalogProductContext from "context/CatalogProductContext";
import AuthContext from "context/AuthContext";
import OrderContext from "context/OrderContext";
import DashboardDataGrid from "components/dashboarddatagrid";
import { useNavigate } from 'react-router-dom';

const TestingSupplierDashboard = () => {
    const [selectedAction, setSelectedAction] = useState({ action: "", context: "", item: "" });
    const { authToken } = useContext(AuthContext);

    const sectionsConfig = {
        Catalogue: ["Variants", "Company Name"],
        Channel: ["Company Name"],
        Resume: ["Resume"],
        "Finance Advertisement": ["Finance Advertisement"],
        "Finance Payouts": ["Payout Details", "Tax Information"],
        Shipments: ["Shipments", "Shipment Variants"],
        Orders: ["Order History", "Order Status"],
    };


    ///////////////////// CATALOGUE VARIANTS //////////////////////
    const [formVariantData, setFormVariantData] = useState({
        country: '',
        name: '',
        description: '',
        variant_code: '',
        hsn: '',
        asin: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormVariantData(prevFormVariantData => ({
            ...prevFormVariantData,
            [name]: value
        }));
    };

    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [submitError, setSubmitError] = useState(false);

    const handleVariantSubmit = async (e) => {
        e.preventDefault();
        const form = document.getElementById("catalogue_variant");
        let bodyFormData = new FormData(form);
        bodyFormData.append("testField", "testValue");

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/product/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${authToken}`,
                },
                body: bodyFormData,
            });

            const data = await response.json();
            if (response.ok) {
                console.log('Success:', data);
            } else {
                console.error('Error Response:', data);
            }
        } catch (error) {
            console.error('Network Error:', error);
        }
    };




    // Component mapping based on action, context, and item
    const componentMapping = {

        //////////////////////////// ADD BUTTON ACTIONS ///////////////////////////////////////

        add: {
            Catalogue: {

                /////////////////////////////// VARIANTS ////////////////////////////////////////////


                Variants: () => <>
                    <div>
                        <h2 className="text-lg font-bold mb-6">Add single product</h2>
                        <form id="catalogue_variant" onSubmit={handleVariantSubmit}>
                            {/*<div className="items-center w-full my-4">*/}
                            {/*    <label htmlFor="company-select" className="block text-sm font-medium text-gray-700">Select Company</label>*/}
                            {/*    <select id="company-select" value={selectedCompany} className="mt-1 p-2 border-2 border-gray-300 w-full rounded-lg cursor-pointer" onChange={handleCompanyChange}>*/}

                            {/*        <option value="">Select a Company</option>*/}
                            {/*        {companies.map((company) => (*/}
                            {/*            <option value={company.name} key={company.name}>*/}
                            {/*                {company.name}*/}
                            {/*            </option>*/}
                            {/*        ))}*/}
                            {/*    </select>*/}
                            {/*</div>*/}

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                                <div>
                                    <label htmlFor="country" className="block text-sm font-medium text-gray-700">Country of origin *</label>
                                    <input
                                        type="text"
                                        id="country"
                                        name="country"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                        placeholder="Enter your Country"
                                    />


                                </div>
                                <div>
                                    <label htmlFor="product name" className="block text-sm font-medium text-gray-700">Product name *</label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        required
                                        className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                        placeholder="Enter your product name"
                                    />
                                </div>
                            </div>

                            <div className="mb-4">
                                <label htmlFor="description" className="block text-sm font-medium text-gray-700">Product description *</label>
                                <textarea
                                    id="description"
                                    name="description"
                                    onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                    required
                                    className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                    placeholder="Product description"
                                />
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                                <div>
                                    <label htmlFor="variant_code" className="block text-sm font-medium text-gray-700">Variant code *</label>
                                    <input
                                        type="text"
                                        id="variant_code"
                                        name="variant_code"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        required
                                        className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                        placeholder="Enter the code"
                                    />
                                </div>
                                <div>
                                    <label htmlFor="hsn" className="block text-sm font-medium text-gray-700">Enter HSNCode *</label>
                                    <input
                                        type="text"
                                        id="hsn"
                                        name="hsn"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                        placeholder="Enter HSNCode"
                                    />
                                </div>
                                <div>
                                    <label htmlFor="asin" className="block text-sm font-medium text-gray-700">ASIN *</label>
                                    <input
                                        type="text"
                                        id="asin"
                                        name="asin"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                        placeholder="Enter the Product ASIN"
                                    />
                                </div>
                            </div>

                            <div className="flex justify-end mt-4">
                                <button
                                    type="submit"
                                    className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
                                >
                                    Add Product
                                </button>
                            </div>
                        </form>
                        {submitSuccess && <div>Your form has been submitted successfully!</div>}
                        {submitError && <div>Error submitting form. Please try again.</div>}
                    </div>
                </>,
            },
        },



        /////////////////////// CHANGE BUTTON ACTIONS ////////////////////////////////////////

        change: {
            Catalogue: {
                "Company Name": () => <></>,
                Variants: () => <></>,
            },
            // Add more specific components for other actions and contexts as needed
        },
    };

    const handleActionClick = (action, context, item) => () => {
        setSelectedAction({ action, context, item });
    };

    const renderSidebarContent = () => {
        const { action, context, item } = selectedAction;
        // Dynamically determine the component to render based on the action, context, and item
        const Component = componentMapping[action]?.[context]?.[item];
        return Component ? <Component /> : <div>Select an action</div>;
    };

    return (
        <>


            <h2 className="p-5 text-black text-2xl font-normal">Supplier_Name Dashboard</h2>

            <div className="flex flex-cols-2 w-full gap-5">
                {/* Left Sidebar Sections */}
                <div className="w-1/2 gap-5 mx-5">
                    {Object.entries(sectionsConfig).map(([context, items]) => (
                        <div key={context} className="my-5">
                            <p className="p-3 w-full bg-brand-300 text-white rounded">{context}</p>
                            <table className="w-full">
                                <tbody>
                                    {items.map(item => (
                                        <tr key={item}>
                                            <td className="w-[75%] p-3 font-semibold">{item}</td>
                                            <td className="w-[12.5%] items-center p-3">
                                                <button onClick={handleActionClick("add", context, item)} className="flex gap-2 items-center">
                                                    <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title></title> <g id="Complete"> <g data-name="add" id="add-2"> <g> <line fill="none" stroke="#0fd200" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="12" x2="12" y1="19" y2="5"></line> <line fill="none" stroke="#0fd200" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="5" x2="19" y1="12" y2="12"></line> </g> </g> </g> </g></svg>Add</button></td>
                                            <td className="w-[12.5%] items-center p-3">
                                                <button onClick={handleActionClick("change", context, item)} className="flex gap-2 items-center">
                                                    <svg width="24px" height="24px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M17.265 4.16231L19.21 5.74531C19.3978 5.9283 19.5031 6.17982 19.5015 6.44201C19.5 6.70421 19.3919 6.9545 19.202 7.13531L17.724 8.93531L12.694 15.0723C12.6069 15.1749 12.4897 15.2473 12.359 15.2793L9.75102 15.8793C9.40496 15.8936 9.10654 15.6384 9.06702 15.2943L9.18902 12.7213C9.19806 12.5899 9.25006 12.4652 9.33702 12.3663L14.15 6.50131L15.845 4.43331C16.1743 3.98505 16.7938 3.86684 17.265 4.16231Z" stroke="#ffdd00" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M5.5 18.2413C5.08579 18.2413 4.75 18.5771 4.75 18.9913C4.75 19.4056 5.08579 19.7413 5.5 19.7413V18.2413ZM19.2 19.7413C19.6142 19.7413 19.95 19.4056 19.95 18.9913C19.95 18.5771 19.6142 18.2413 19.2 18.2413V19.7413ZM14.8455 6.22062C14.6904 5.83652 14.2534 5.65082 13.8693 5.80586C13.4852 5.9609 13.2995 6.39796 13.4545 6.78206L14.8455 6.22062ZM17.8893 9.66991C18.2933 9.57863 18.5468 9.17711 18.4556 8.77308C18.3643 8.36904 17.9628 8.1155 17.5587 8.20678L17.8893 9.66991ZM5.5 19.7413H19.2V18.2413H5.5V19.7413ZM13.4545 6.78206C13.6872 7.35843 14.165 8.18012 14.8765 8.8128C15.6011 9.45718 16.633 9.95371 17.8893 9.66991L17.5587 8.20678C16.916 8.35198 16.3609 8.12551 15.8733 7.69189C15.3725 7.24656 15.0128 6.63526 14.8455 6.22062L13.4545 6.78206Z" fill="#ffdd00"></path> </g></svg>Change</button></td>
                                        </tr>))}
                                </tbody>
                            </table>
                        </div>
                    ))}
                </div>


                {/* Right Sidebar Sections */}
                <div className="w-1/2 justify-between gap-5 m-3">
                    {renderSidebarContent()}
                </div>
            </div>

        </>
    );
};

export default TestingSupplierDashboard;






///////////////////////////////////////////// CATALOGUE VARIANTS  ///////////////////////////////////////////////////////////

//const [formVData, setFormVData] = useState({
//    country: '',
//    name: '',
//    description: '',
//    variant_code: '',
//    hsn: '',
//    asin: '',
//});

//const handleInputChange = (e) => {
//    const { name, value } = e.target;
//    setFormVData(prevFormVData => ({
//        ...prevFormVData,
//        [name]: value
//    }));
//};

//const [submitSuccess, setSubmitSuccess] = useState(false);
//const [submitError, setSubmitError] = useState(false);

//const handleSubmit = async (e) => {
//    e.preventDefault();
//    const form = document.getElementById("myForm");
//    let bodyFormData = new FormData(form);
//    bodyFormData.append("testField", "testValue");

//    try {
//        const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/product/`, {
//            method: 'POST',
//            headers: {
//                'Authorization': `Token ${authToken}`,
//            },
//            body: bodyFormData,
//        });

//        const data = await response.json();
//        if (response.ok) {
//            console.log('Success:', data);
//        } else {
//            console.error('Error Response:', data);
//        }
//    } catch (error) {
//        console.error('Network Error:', error);
//    }
//};


//<div>
//    <h2 className="text-sm font-bold mb-6">Add single product</h2>
//    <hr />
//    <form id="myForm" onSubmit={handleSubmit}>
//        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
//            <div>
//                <label className="block mb-2" htmlFor="country">Country of origin *</label>
//                <input
//                    type="text"
//                    id="country"
//                    name="country"
//                    onChange={(e) => handleInputChange(e.target.name, e.target.value)}
//                    className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
//                    placeholder="Enter your Country"
//                />


//            </div>
//            <div>
//                <label className="block mb-2" htmlFor="name">Product name *</label>
//                <input
//                    type="text"
//                    id="name"
//                    name="name"
//                    onChange={(e) => handleInputChange(e.target.name, e.target.value)}
//                    required
//                    className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
//                    placeholder="Enter your product name"
//                />
//            </div>
//        </div>

//        <div className="mb-4">
//            <label className="block mb-2" htmlFor="description">Product description *</label>
//            <textarea
//                id="description"
//                name="description"
//                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
//                required
//                className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
//                placeholder="Product description"
//            />
//        </div>

//        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
//            <div>
//                <label className="block mb-2" htmlFor="variant_code">Variant Code*</label>
//                <input
//                    type="text"
//                    id="variant_code"
//                    name="variant_code"
//                    onChange={(e) => handleInputChange(e.target.name, e.target.value)}
//                    required
//                    className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
//                    placeholder="Enter the code"
//                />
//            </div>
//            <div>
//                <label className="block mb-2" htmlFor="hsn">Enter HSNCode</label>
//                <input
//                    type="text"
//                    id="hsn"
//                    name="hsn"
//                    onChange={(e) => handleInputChange(e.target.name, e.target.value)}
//                    className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
//                    placeholder="Enter HSNCode"
//                />
//            </div>
//            <div>
//                <label className="block mb-2" htmlFor="asin">ASIN</label>
//                <input
//                    type="text"
//                    id="asin"
//                    name="asin"
//                    onChange={(e) => handleInputChange(e.target.name, e.target.value)}
//                    className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
//                    placeholder="Enter the Product ASIN"
//                />
//            </div>
//        </div>

//        <div className="flex justify-end mt-4">
//            <button
//                type="submit"
//                className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
//            >
//                Add Product
//            </button>
//        </div>
//    </form>
//    {submitSuccess && <div>Your form has been submitted successfully!</div>}
//    {submitError && <div>Error submitting form. Please try again.</div>}
//</div>