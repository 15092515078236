import React, { useState, useEffect, useContext } from 'react';
import AuthContext from "context/AuthContext";

const ShipmentDetailsSupplierDashboard = () => {
    const { authToken } = useContext(AuthContext);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [submitError, setSubmitError] = useState(false);
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_API_URL}/company/`, {
            headers: {
                'Authorization': `Token ${authToken}`
            }
        })
            .then(response => response.json())
            .then(data => {
                setCompanies(data);
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });
    }, [authToken]);



    const [formShipmentVariantData, setFormShipmentVariantData] = useState({
        shipment_id: '',
        variant_id: '',
        ordered_quantity: '',
        price: '',
        dispatched_quantity: '',
        received_quantity: '',
        duty_percentage_applicable: '',
        freight_per_pc: '',
        duty_per_pc: '',
        box_charges_pc: '',
        selectedCompany: '',
    });


    const handleShipmentVariantCompanyChange = (event) => {
        setFormShipmentVariantData(prevFormShipmentVariantData => ({
            ...prevFormShipmentVariantData,
            selectedCompany: event.target.value
        }));
    };


    const handleShipmentVariantInputChange = (e) => {
        const { name, value } = e.target;
        setFormShipmentVariantData(prevFormShipmentVariantData => ({
            ...prevFormShipmentVariantData,
            [name]: value
        }));
    };

    const handleShipmentVariantSubmit = async (event) => {
        event.preventDefault();

        let bodyFormData = new FormData();
        bodyFormData.append('company_name', formShipmentVariantData.selectedCompany); // Key to match your backend expectation

        for (let key in formShipmentVariantData) {
            if (key !== 'selectedCompany') { // Exclude the 'selectedCompany' from the FormData
                bodyFormData.append(key, formShipmentVariantData[key]);
            }
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/adminshipmentvariant/create_with_company_name/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${authToken}`,
                },
                body: bodyFormData,
            });

            if (response.ok) {
                setSubmitSuccess(true);
                setSubmitError(false);
                // Reset form data if necessary
                setFormShipmentVariantData({
                    shipment_id: '',
                    variant_id: '',
                    ordered_quantity: '',
                    price: '',
                    dispatched_quantity: '',
                    received_quantity: '',
                    duty_percentage_applicable: '',
                    freight_per_pc: '',
                    duty_per_pc: '',
                    box_charges_pc: '',
                    selectedCompany: '',
                });
            } else {
                setSubmitSuccess(false);
                setSubmitError(true);
            }
        } catch (error) {
            console.error("Failed to submit form", error);
            setSubmitSuccess(false);
            setSubmitError(true);
        }
    };

    return (
        <>
            <div>
                <h2 className="text-lg font-bold mb-6">Add shipment details</h2>
                <form className="py-5 px-3" onSubmit={handleShipmentVariantSubmit}>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                        <div className="items-center w-full mb-4">
                            <label htmlFor="company-select" className="block text-sm font-medium text-gray-700">Select Company</label>
                            <select id="company-select" value={formShipmentVariantData.selectedCompany} className="mt-1 p-2 border-2 border-gray-300 w-full rounded-lg cursor-pointer" onChange={handleShipmentVariantCompanyChange}>

                                <option value="">Select a Company</option>
                                {companies.map((company) => (
                                    <option value={company.name} key={company.name}>
                                        {company.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div>
                            <label htmlFor="shipment_id" className="block text-sm font-medium text-gray-700 mb-1">Shipment ID *</label>
                            <input
                                type="number"
                                id="country"
                                name="shipment_id"
                                value={formShipmentVariantData.shipment_id}
                                onChange={handleShipmentVariantInputChange}
                                className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                placeholder="Enter your Shipment ID"
                            />
                        </div>

                        <div>
                            <label htmlFor="variant_id" className="block text-sm font-medium text-gray-700 mb-1">Variant ID *</label>
                            <input
                                type="text"
                                id="country"
                                name="variant_id"
                                value={formShipmentVariantData.variant_id}
                                onChange={handleShipmentVariantInputChange}
                                className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                placeholder="Enter your Variant ID"
                            />
                        </div>
                    </div>


                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                        <div>
                            <label htmlFor="ordered_quantity" className="block text-sm font-medium text-gray-700 mb-1">Ordered quantity *</label>
                            <input
                                type="number"
                                id="country"
                                name="ordered_quantity"
                                value={formShipmentVariantData.ordered_quantity}
                                onChange={handleShipmentVariantInputChange}
                                className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                placeholder="Enter ordered quantity"
                            />


                        </div>
                        <div>
                            <label htmlFor="price" className="block text-sm font-medium text-gray-700 mb-1">Price *</label>
                            <input
                                type="text"
                                id="name"
                                name="price"
                                value={formShipmentVariantData.price}
                                onChange={handleShipmentVariantInputChange}

                                className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                placeholder="Enter your price"
                            />
                        </div>
                        <div>
                            <label htmlFor="dispatched_quantity" className="block text-sm font-medium text-gray-700 mb-1">Dispatched quantity *</label>
                            <input
                                type="number"
                                id="name"
                                name="dispatched_quantity"
                                value={formShipmentVariantData.dispatched_quantity}
                                onChange={handleShipmentVariantInputChange}

                                className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                placeholder="Enter your Dispatched Quantity"
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                        <div>
                            <label htmlFor="recieved_quantity" className="block text-sm font-medium text-gray-700 mb-1">Recieved quantity *</label>
                            <input
                                type="number"
                                id="variant_code"
                                name="received_quantity"
                                value={formShipmentVariantData.received_quantity}
                                onChange={handleShipmentVariantInputChange}

                                className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                placeholder="Enter your Recieved Quantity"
                            />
                        </div>
                        <div>
                            <label htmlFor="duty" className="block text-sm font-medium text-gray-700 mb-1">Duty % applicable *</label>
                            <input
                                type="number"
                                id="hsn"
                                name="duty_percentage_applicable"
                                value={formShipmentVariantData.duty_percentage_applicable}
                                onChange={handleShipmentVariantInputChange}
                                className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                placeholder="Duty percentage applicable"
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                        <div>
                            <label htmlFor="freight_per_pc" className="block text-sm font-medium text-gray-700 mb-1">Freight per pc *</label>
                            <input
                                type="number"
                                id="variant_code"
                                name="freight_per_pc"
                                value={formShipmentVariantData.freight_per_pc}
                                onChange={handleShipmentVariantInputChange}

                                className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                placeholder="Freight per pc"
                            />
                        </div>
                        <div>
                            <label htmlFor="duty_per_pc" className="block text-sm font-medium text-gray-700 mb-1">Duty per pc *</label>
                            <input
                                type="number"
                                id="hsn"
                                name="duty_per_pc"
                                value={formShipmentVariantData.duty_per_pc}
                                onChange={handleShipmentVariantInputChange}
                                className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                placeholder="Duty per pc"
                            />
                        </div>
                        <div>
                            <label htmlFor="box_charges" className="block text-sm font-medium text-gray-700 mb-1">Box charges per pc *</label>
                            <input
                                type="number"
                                id="asin"
                                name="box_charges_pc"
                                value={formShipmentVariantData.box_charges_pc}
                                onChange={handleShipmentVariantInputChange}
                                className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                placeholder="Box charges per pc"
                            />
                        </div>
                    </div>

                    <div className="flex justify-end mt-4">
                        <button
                            type="submit"
                            className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
                        >
                            Add shipment variants
                        </button>
                    </div>
                </form>
                {submitSuccess && <div>Your form has been submitted successfully!</div>}
                {submitError && <div>Error submitting form. Please try again.</div>}
            </div>
        </>
    );
};

export default ShipmentDetailsSupplierDashboard;
