import React, { useState, useEffect, useContext } from 'react';
import AuthContext from "context/AuthContext";

const ShipmentsSupplierDashboard = () => {
    const { authToken } = useContext(AuthContext);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [submitError, setSubmitError] = useState(false);
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_API_URL}/company/`, {
            headers: {
                'Authorization': `Token ${authToken}`
            }
        })
            .then(response => response.json())
            .then(data => {
                setCompanies(data);
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });
    }, [authToken]);

    const [formShipmentData, setFormShipmentData] = useState({
        order_id: '',
        awb: '',
        dispatched_date: '',
        received_date: '',
        freight_charge: '',
        custom_duty: '',
        conversion_rate: '',
        supplier_currency: '',
        receiver_currency: '',
        shipper_name: '',
        state: '',
        pickup_address: '',
        delivery_address: '',
        weight_in_kgs: '',
        volumetric_weight_in_kgs: '',
        selectedCompany: '',
    });

    const handleShipmentCompanyChange = (event) => {
        setFormShipmentData(prevFormShipmentData => ({
            ...prevFormShipmentData,
            selectedCompany: event.target.value
        }));
    };

    const handleShipmentInputChange = (e) => {
        const { name, value } = e.target;
        setFormShipmentData(prevFormShipmentData => ({
            ...prevFormShipmentData,
            [name]: value
        }));
    };

    const handleShipmentSubmit = async (event) => {
        event.preventDefault();

        let bodyFormData = new FormData();
        bodyFormData.append('company_name', formShipmentData.selectedCompany); // Key to match your backend expectation

        for (let key in formShipmentData) {
            if (key !== 'selectedCompany') { // Exclude the 'selectedCompany' from the FormData
                bodyFormData.append(key, formShipmentData[key]);
            }
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/adminshipment/create_with_company_name/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${authToken}`,
                },
                body: bodyFormData,
            });

            if (response.ok) {
                setSubmitSuccess(true);
                setSubmitError(false);
                // Reset form data if necessary
                setFormShipmentData({
                    order_id: '',
                    awb: '',
                    dispatched_date: '',
                    received_date: '',
                    freight_charge: '',
                    custom_duty: '',
                    conversion_rate: '',
                    supplier_currency: '',
                    receiver_currency: '',
                    shipper_name: '',
                    state: '',
                    pickup_address: '',
                    delivery_address: '',
                    weight_in_kgs: '',
                    volumetric_weight_in_kgs: '',
                    selectedCompany: '',
                });
            } else {
                setSubmitSuccess(false);
                setSubmitError(true);
            }
        } catch (error) {
            console.error("Failed to submit form", error);
            setSubmitSuccess(false);
            setSubmitError(true);
        }
    };

    return (
        <>
            <div>
                <h2 className="text-lg font-bold mb-6">Add shipment</h2>
                <form className="py-5 px-3" onSubmit={handleShipmentSubmit}>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                        <div className="items-center w-full mb-4">
                            <label htmlFor="company-select" className="block text-sm font-medium text-gray-700 mb-1">Select Company</label>
                            <select id="company-select" value={formShipmentData.selectedCompany} className="mt-1 p-2 border-2 border-gray-300 w-full rounded-lg cursor-pointer" onChange={handleShipmentCompanyChange}>

                                <option value="">Select a Company</option>
                                {companies.map((company) => (
                                    <option value={company.name} key={company.name}>
                                        {company.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div>
                            <label htmlFor="order_id" className="block text-sm font-medium text-gray-700 mb-1">Order ID *</label>
                            <input
                                type="number"
                                id="country"
                                name="order_id"
                                value={formShipmentData.order_id}
                                onChange={handleShipmentInputChange}
                                className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                placeholder="Enter your Order ID"
                            />
                        </div>

                        <div>
                            <label htmlFor="awb" className="block text-sm font-medium text-gray-700 mb-1">AWB *</label>
                            <input
                                type="text"
                                id="country"
                                name="awb"
                                value={formShipmentData.awb}
                                onChange={handleShipmentInputChange}
                                className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                placeholder="Enter your awb"
                            />
                        </div>
                    </div>

                    <div className="flex gap-5 my-4">
                        <div className="w-1/2 mb-4">
                            <label htmlFor="dispatch_date" className="block text-sm font-medium text-gray-700 mb-1">Dispatched Date *</label>
                            <div className="flex items-center space-x-2">
                                <input
                                    type="datetime-local"
                                    id="dispatched-date"
                                    name="dispatched_date"
                                    value={formShipmentData.dispatched_date}
                                    onChange={handleShipmentInputChange}
                                    className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                />
                            </div>

                        </div>

                        <div className="w-1/2 mb-4">
                            <label htmlFor="recieve_date" className="block text-sm font-medium text-gray-700 mb-1">Recieved Date *</label>
                            <div className="flex items-center space-x-2">
                                <input
                                    type="datetime-local"
                                    id="received-date"
                                    name="received_date"
                                    value={formShipmentData.received_date}
                                    onChange={handleShipmentInputChange}
                                    className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                />
                            </div>

                        </div>
                    </div>


                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                        <div>
                            <label htmlFor="freight_charges" className="block text-sm font-medium text-gray-700 mb-1">Freight Charges *</label>
                            <input
                                type="number"
                                id="country"
                                name="freight_charge"
                                value={formShipmentData.freight_charge}
                                onChange={handleShipmentInputChange}
                                className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                placeholder="Enter Freight charge"
                            />


                        </div>
                        <div>
                            <label htmlFor="custom_duty" className="block text-sm font-medium text-gray-700 mb-1">Custom Duty *</label>
                            <input
                                type="number"
                                id="name"
                                name="custom_duty"
                                value={formShipmentData.custom_duty}
                                onChange={handleShipmentInputChange}

                                className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                placeholder="Enter Custom duty"
                            />
                        </div>
                        <div>
                            <label htmlFor="conversion_rate" className="block text-sm font-medium text-gray-700 mb-1">Conversion Rate *</label>
                            <input
                                type="number"
                                id="name"
                                name="conversion_rate"
                                value={formShipmentData.conversion_rate}
                                onChange={handleShipmentInputChange}

                                className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                placeholder="Enter your Conversion rate"
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                        <div>
                            <label htmlFor="supplier_currency" className="block text-sm font-medium text-gray-700 mb-1">Supplier Currency *</label>
                            <input
                                type="text"
                                id="variant_code"
                                name="supplier_currency"
                                value={formShipmentData.supplier_currency}
                                onChange={handleShipmentInputChange}

                                className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                placeholder="Enter Supplier currency"
                            />
                        </div>
                        <div>
                            <label htmlFor="reciever_currency" className="block text-sm font-medium text-gray-700 mb-1">Reciever Currency *</label>
                            <input
                                type="text"
                                id="hsn"
                                name="receiver_currency"
                                value={formShipmentData.receiver_currency}
                                onChange={handleShipmentInputChange}
                                className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                placeholder="Enter Receiver currency"
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                        <div>
                            <label htmlFor="shipper_name" className="block text-sm font-medium text-gray-700 mb-1">Shipper Name *</label>
                            <input
                                type="text"
                                id="variant_code"
                                name="shipper_name"
                                value={formShipmentData.shipper_name}
                                onChange={handleShipmentInputChange}

                                className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                placeholder="Enter Shipper name"
                            />
                        </div>
                        <div>
                            <label htmlFor="state" className="block text-sm font-medium text-gray-700 mb-1">State *</label>
                            <input
                                type="text"
                                id="hsn"
                                name="state"
                                value={formShipmentData.state}
                                onChange={handleShipmentInputChange}
                                className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                placeholder="Enter State"
                            />
                        </div>
                    </div>

                    <div className="mb-4">
                        <label htmlFor="pickup_address" className="block text-sm font-medium text-gray-700 mb-1">Pickup address *</label>
                        <textarea
                            id="description"
                            name="pickup_address"
                            value={formShipmentData.pickup_address}
                            onChange={handleShipmentInputChange}

                            className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                            placeholder="Pickup address"
                        />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="delivery_address" className="block text-sm font-medium text-gray-700 mb-1">Delivery address *</label>
                        <textarea
                            id="description"
                            name="delivery_address"
                            value={formShipmentData.delivery_address}
                            onChange={handleShipmentInputChange}

                            className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                            placeholder="Delivery addrerss"
                        />
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                        <div>
                            <label htmlFor="weight_in_kg" className="block text-sm font-medium text-gray-700 mb-1">Weight (in kgs) *</label>
                            <input
                                type="number"
                                id="country"
                                name="weight_in_kgs"
                                value={formShipmentData.weight_in_kgs}
                                onChange={handleShipmentInputChange}
                                className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                placeholder="Enter Weight in kgs"
                            />


                        </div>
                        <div>
                            <label htmlFor="volumetric_weight" className="block text-sm font-medium text-gray-700 mb-1">Volumetric weight (in kgs) *</label>
                            <input
                                type="number"
                                id="name"
                                name="volumetric_weight_in_kgs"
                                value={formShipmentData.volumetric_weight_in_kgs}
                                onChange={handleShipmentInputChange}

                                className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                placeholder="Enter Volumetric weight in kgs"
                            />
                        </div>
                    </div>

                    <div className="flex justify-end mt-4">
                        <button
                            type="submit"
                            className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
                        >
                            Add shipment
                        </button>
                    </div>
                </form>
                {submitSuccess && <div>Your form has been submitted successfully!</div>}
                {submitError && <div>Error submitting form. Please try again.</div>}
            </div>
        </>
    );
};

export default ShipmentsSupplierDashboard;
