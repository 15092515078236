import React, { useState, useEffect } from 'react';


// SearchableTable Component
const CustomTable = ({ data, columns, onRowClick,heading }) => {

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(15); // Set initial items per page

    const itemsPerPageOptions = [10, 25, 50, 100]; // Available options for items per page

    const Pagination = ({ totalItems, paginate }) => {
        const lastPage = Math.ceil(totalItems / itemsPerPage);
        const [inputPage, setInputPage] = useState(currentPage);

        useEffect(() => {
            setInputPage(currentPage); // Update input field when current page changes
        }, [currentPage]);

        const handlePrevious = () => {
            setCurrentPage(current => Math.max(1, current - 1));
        };

        const handleNext = () => {
            setCurrentPage(current => Math.min(lastPage, current + 1));
        };

        const handlePageInputChange = (event) => {
            setInputPage(event.target.value); // Update inputPage state to reflect user input
        };

        const handlePageInputSubmit = (event) => {
            event.preventDefault(); // Prevent the form from submitting traditionally
            let page = parseInt(inputPage, 10);
            if (!isNaN(page) && page >= 1 && page <= lastPage) {
                setCurrentPage(page); // Update current page if the input is a valid page number
            } else {
                setInputPage(currentPage); // Reset input field to current page if input is invalid
            }
        };

        const handleItemsPerPageChange = (event) => {
            setItemsPerPage(Number(event.target.value));
            setCurrentPage(1); // Reset to first page when items per page changes
        };

        return (
            <div className="flex justify-between items-center mb-4">
                <form onSubmit={handlePageInputSubmit} className="flex items-center">
                    <button
                        onClick={handlePrevious}
                        disabled={currentPage === 1}
                        type="button" // Ensure clicking this doesn't submit the form
                        className="px-4 py-2 mx-1 text-sm bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                        &larr; Previous
                    </button>
                    <input
                        type="text"
                        value={inputPage}
                        onChange={handlePageInputChange}
                        className="mx-2 text-sm px-2 py-1 border rounded-md w-16 text-center"
                        aria-label="Page number"
                    />
                    <span className="mx-2 text-sm">of {lastPage}</span>
                    <button
                        onClick={handleNext}
                        disabled={currentPage === lastPage}
                        type="button" // Ensure clicking this doesn't submit the form
                        className="px-4 py-2 mx-1 text-sm bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                        Next &rarr;
                    </button>
                </form>
                <div className="flex items-center">
                    <label htmlFor="itemsPerPage" className="mr-2 text-sm">Items per page:</label>
                    <select
                        id="itemsPerPage"
                        value={itemsPerPage}
                        onChange={handleItemsPerPageChange}
                        className="px-2 py-1 text-sm border rounded-md"
                    >
                        {itemsPerPageOptions.map((number) => (
                            <option key={number} value={number}>
                                {number}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        );
    };

    const [searchTerm, setSearchTerm] = useState('');

    // Adjusted useEffect to reset currentPage when searchTerm changes
    useEffect(() => {
        setCurrentPage(1); // Reset to first page whenever the search term changes
    }, [searchTerm]);

    // Function to filter data based on the search term
    const getFilteredData = (data, searchTerm) => {
        if (!searchTerm.trim()) return data; // Return original data if search term is empty

        return data.filter(item =>
            columns.some(column => {
                const propertyValue = getNestedProperty(item, column.key);
                return propertyValue ? propertyValue.toString().toLowerCase().includes(searchTerm.trim().toLowerCase()) : false;
            })
        );
    };

    const getNestedProperty = (object, path) => {
        return path.split('.').reduce((obj, key) => obj && obj[key], object);
    };

    const filteredData = getFilteredData(data, searchTerm);


    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);


    return (
        <div className="container mx-auto">
            {heading && <h2 className="text-xl font-semibold mb-4">{heading}</h2>}
            <div className="py-4">
                <div className="mb-4">
                    <div class="flex items-center border rounded-md overflow-hidden w-full mb-8">
                        <button type="submit" class="py-2 px-6">
                            <svg class="w-7 h-7 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                        </button>
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder="Search your data..."
                            className="px-4 py-2 w-full"
                        />
                    </div>
                </div>

                {/*    <div class="flex items-center border rounded-md overflow-hidden w-full mb-8">*/}
                {/*        <button type="submit" class="py-2 px-6">*/}
                {/*            <svg class="w-7 h-7 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>*/}
                {/*        </button>*/}
                {/*        <input*/}
                {/*            type="text"*/}
                {/*            value={searchTerm}*/}
                {/*            onChange={(e) => setSearchTerm(e.target.value)}*/}
                {/*            placeholder="Search your orders..."*/}
                {/*            className="px-4 py-2 w-full"*/}
                {/*        />*/}
                {/*    </div>*/}
                    

                {/*</div>*/}

                {filteredData.length > itemsPerPage && (
                    <Pagination
                        itemsPerPage={itemsPerPage}
                        totalItems={filteredData.length}
                        paginate={setCurrentPage}
                        currentPage={currentPage}
                    />
                )}

                <table className="min-w-full mt-3 table-auto">
                    <thead className="bg-gray-800 text-white">
                        <tr>
                            {columns.map(column => (
                                <th key={column.key} className="px-4 py-2">{column.header}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="bg-white">
                        {currentItems.map((item, index) => (
                            <tr key={index} className="text-gray-700">
                                {columns.map(column => (
                                    <td key={column.key} className="border px-4 py-2" onClick={() => onRowClick(item, column.key)}>
                                        {getNestedProperty(item, column.key)}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>

                </table>

                {/* <p className="block text-sm font-medium text-gray-900 my-3 italic">Note: Data may take few seconds to load in some browser</p> */}
            </div>
        </div>
    );
};

export default CustomTable;





//<input
//    type="text"
//    placeholder="Search..."
//    value={searchTerm}
//    onChange={(e) => setSearchTerm(e.target.value)}
//    className="border px-4 py-2"
///>



//<div className="mb-4">
//    <div className="flex items-center border rounded-md overflow-hidden w-full mb-8">
//        <input
//            type="text"
//            value={searchTerm}
//            onChange={(e) => setSearchTerm(e.target.value)}
//            placeholder="Search..."
//            className="px-4 py-2 w-full"
//        />
//    </div>
//</div>




    //// Filter data based on search term
    //useEffect(() => {
    //  const filtered = data.filter(item =>
    //    columns.some(column =>
    //      item[column.key].toString().toLowerCase().includes(searchTerm.toLowerCase().trim())
    //    )
    //  );
    //  setFilteredData(filtered);
    //  setCurrentPage(1); // Reset to first page with new search results
    //}, [searchTerm, data, columns]);



//// Pagination Component
//const Pagination = ({ itemsPerPage, totalItems, paginate, currentPage }) => {
//  const pageNumbers = [];
//  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
//    pageNumbers.push(i);
//  }

