import BeyoboNavbar from "../../../components/beyobonavbar";
import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
//import DashboardBox from "../../../components/dashboardbox";
//import Notification from "../../../components/notification";
import CatalogProductContext from "context/CatalogProductContext";
import AuthContext from "context/AuthContext";
import CoreUserContext from "context/CoreUserContext";
import OrderContext from "context/OrderContext";
import DashboardDataGrid from "components/dashboarddatagrid";
import { Link, useNavigate } from 'react-router-dom';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { evaluate } from 'mathjs';


const BeyoboDashboard = () => {
    const [currency, setCurrency] = useState('INR'); // Default currency
    const [exchangeRates, setExchangeRates] = useState({});
    const { authToken } = useContext(AuthContext);
    const [salesdata, setsalesdata] = useState([]);


    useEffect(() => {
        const fetchSalesData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/dashboard/sales_by_platform/`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Token ${authToken}`, // Assuming you use token authentication
                        'Content-Type': 'application/json',
                    },
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setsalesdata(data); // Assuming setData is the state setter for your data
            } catch (error) {
                console.error('There was a problem with the fetch operation:', error);
            }
        };

        fetchSalesData();
    }, [authToken]);

    const [userRole, setUserRole] = useState('');

    useEffect(() => {
        // Function to fetch user role
        const fetchUserRole = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/userrole/`, { // Adjust the URL to match your API endpoint
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        // Include authorization header if your API requires authentication
                        'Authorization': `Token ${authToken}`, // Replace YOUR_ACCESS_TOKEN appropriately
                    },
                });

                if (!response.ok) {
                    throw new Error(`Error: ${response.status}`); // Error handling for non-2xx responses
                }

                const data = await response.json();
                setUserRole(data.user_role); // Assuming the backend response contains `user_role` field
            } catch (error) {
                console.error('Fetching user role failed:', error);
                // Handle fetch error (e.g., network error, JSON parsing error)
            }
        };

        fetchUserRole();
    }, [authToken]); // Empty dependency array means this effect runs once on mount


    // Fetch the latest exchange rates
    useEffect(() => {
        const fetchExchangeRates = async () => {
            try {
                const response = await fetch('https://api.exchangerate-api.com/v4/latest/USD');
                const data = await response.json();
                setExchangeRates(prevRates => ({
                    ...prevRates,
                    INR: data.rates['INR'],
                    USD: data.rates['USD'], // Add USD for conversion accuracy
                    JPY: data.rates['JPY'],
                    CNY: data.rates['CNY'],
                    KRW: data.rates['KRW'],
                }));
            } catch (error) {
                console.error('Error fetching exchange rates:', error);
            }
        };

        fetchExchangeRates();

        // Set up an interval to fetch the exchange rates regularly
        const intervalId = setInterval(fetchExchangeRates, 3600000); // Update every hour

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    // Convert and format the value according to the selected currency
    const convertAndFormatValue = (value, targetCurrency, negate = false) => {
        const rate = exchangeRates[targetCurrency] || 1; // Use 1 as fallback rate
        // Negate the value if the negate parameter is true
        const adjustedValue = negate ? value * -1 : value;
        const convertedValue = adjustedValue * (rate / exchangeRates['INR']); // Convert from INR to target currency
        return new Intl.NumberFormat('en-IN', { style: 'currency', currency: targetCurrency, maximumFractionDigits: 2 }).format(convertedValue);
    };

    //const headings = data ? [
    //    { title: "Sale Amount", value: convertAndFormatValue(data[0]?.sale_amount || 0, currency) },
    //    // Add other headings here, applying convertAndFormatValue to their values
    //] : [];

    // Handle currency selection change
    const handleCurrencyChange = (e) => {
        setCurrency(e.target.value);
    };

    const [selected, setSelected] = useState('THIS YEAR');
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null); // Ref for the dropdown to track click outside
    const { products } = useContext(CatalogProductContext);
    const { orders } = useContext(OrderContext);
    const handleSelect = (item) => {
        setSelected(item);
        setShowDropdown(false);
    };




    //const {coreUser}=useContext(CoreUserContext);





    const [data, setData] = React.useState(null);

    const [channels, setChannels] = useState(null);
    const navigate = useNavigate();

    const [platforms, setPlatforms] = useState([]);
    const [selectedPlatform, setSelectedPlatform] = useState(null);

    useEffect(() => {
        const apiUrl = `${process.env.REACT_APP_BASE_API_URL}/channels/getcompanychannels/`;
        fetch(apiUrl, {
            headers: {
                'Authorization': `Token ${authToken}`,
            },
        })
            .then(response => response.json())
            .then(data => {
                // Directly use the data array, adjusting the structure as needed
                const platformsArray = data.map(channel => ({
                    name: channel.name,
                    platform: channel.platform, // Assuming you want to use platform as well
                    logo: channel.platform_image,
                }));

                setPlatforms(platformsArray);
            })
            .catch(error => console.error('Fetch error:', error));
    }, [authToken]);
    const selectPlatform = (name) => {
        setSelectedPlatform(name);
        navigate('/admin/catalogue/channels');
    };


    React.useEffect(() => {
        const apiUrl = `${process.env.REACT_APP_BASE_API_URL}/dashboard/`;
        fetch(apiUrl, {
            headers: {
                'Authorization': `Token ${authToken}`,
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('Fetched data:', data); // Debugging fetched data
                setData(data);
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });
    }, [authToken]);

    const headings = data && data[0] ? [
        { title: "Sale Amount", value: convertAndFormatValue(data[0].sale_amount || 0, currency) },
        { title: "Realisation Value", value: convertAndFormatValue(data[0].realisation_value || 0, currency) },
        { title: "Commission", value: convertAndFormatValue(-(data[0].Commission || 0), currency) },
        { title: "Tax", value: convertAndFormatValue(-(data[0].Tax || 0), currency) },
        { title: "Shipment Cost", value: convertAndFormatValue(data[0].ShipmentCost || 0, currency, true) },
        { title: "Advertisement Cost", value: data[0].AdCost ? convertAndFormatValue(data[0].AdCost, currency, true) : 'Calculation Needed' }, // Conditional formatting for AdCost
        { title: "Other Charges", value: convertAndFormatValue(data[0].other_charges || 0, currency, true) },
        { title: "Net Payout", value: convertAndFormatValue(data[0].NetPayout || 0, currency) },
        { title: "Payment Done", value: convertAndFormatValue(data[0].Payouts || 0, currency, true) },
        { title: "Balance Payment", value: convertAndFormatValue(data[0].BalancePayment || 0, currency) },
    ] : [];


    // const headings = data ? Object.keys(data).map(key => ({
    //     title: key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()), // Formatting the key as a title
    //     value: typeof data[key] === 'number'
    //       ? `₹${new Intl.NumberFormat('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(data[key])}`
    //       : data[key], // Directly use the value if it's not a number
    //   })) : [];


    // Close dropdown when clicking outside
    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);



    // const platforms = [
    //     { name: 'Amazon', logo: 'https://cdn.icon-icons.com/icons2/836/PNG/512/Amazon_icon-icons.com_66787.png' },
    //     { name: 'Flipkart', logo: 'https://sightsinplus.com/wp-content/uploads/2020/05/flipkart-logo-detail-icon.jpeg' },
    //     { name: 'Myntra', logo: 'https://bizology.in/wp-content/uploads/2023/08/Myntra.png' },
    //     { name: 'Meesho', logo: 'https://upload.wikimedia.org/wikipedia/commons/3/33/Meesho_logo.png' },
    //     { name: 'JioMart', logo: 'https://img.etimg.com/thumb/msid-83304266,width-300,height-225,imgsize-42276,resizemode-75/untitled-17.jpg' },
    //     { name: 'Appario', logo: 'https://media.licdn.com/dms/image/C510BAQEkPTIF5uvT0w/company-logo_200_200/0/1631409391926/appario_retail_private_limited_logo?e=2147483647&v=beta&t=JB7lkjiU9kbqnaa7Wf9Yh5AQnOLnI0bkNz_5hGkF8sk' },
    //     { name: 'Beyobo', logo: 'https://media.licdn.com/dms/image/D4D0BAQFqFAUkiXkfEA/company-logo_200_200/0/1690444337691?e=2147483647&v=beta&t=EyyY89lbCTDKr0dgfgPUhBn6e7QLeg2Mq8X-58aJtEo' },
    // ];

    //const [selectedPlatform, setSelectedPlatform] = useState('');


    // const selectPlatform = (platform) => {
    //     setSelectedPlatform(platform);
    //     navigate('/admin/catalogue/channels');
    // };


    // State to manage iframe visibility
    const [isIframeOpen, setIsIframeOpen] = useState(false);

    // Function to toggle iframe visibility
    const toggleIframe = () => {
        setIsIframeOpen(!isIframeOpen);
    };












    // State to manage the visibility of the resume upload form popup
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    // Function to open the popup
    const handleOpenPopup = (e) => {
        e.preventDefault(); // Prevent default link behavior
        setIsPopupOpen(true); // Open the popup
    };

    // Function to close the popup
    const handleClosePopup = () => setIsPopupOpen(false);

    // Initial form data state
    const [formData, setFormData] = useState({
        name: '',
        designation: '',
        notice_period_remaining: '',
        salary_expectations: '',
        joining_date: '',

    });

    // Handle form data change
    const handleFormDataChange = (name, value) => {
        setFormData({ ...formData, [name]: value });
    };


    // State to store the selected file
    const [file, setFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState('');

    // Handler for file changes
    const handleFileChange = (event) => {
        const file = event.target.files[0]; // Get the first file
        if (file) {
            setFile(file);
            setPreviewUrl(URL.createObjectURL(file));
        }
    };


    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent the default form submission action

        let bodyFormData = new FormData();

        for (let key in formData) {
            bodyFormData.append(key, formData[key]);
        }

        bodyFormData.append('resume', file);

        const endpoint = `${process.env.REACT_APP_BASE_API_URL}/candidate/`;

        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${authToken}`
                },
                body: bodyFormData,
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }


        } catch (error) {
            console.error('Error:', error);
            // Handle errors, e.g., showing an error message
        }
    };




    const [isOpen, setIsOpen] = useState(false);
    const [currentInput, setCurrentInput] = useState("");
    const [previousInput, setPreviousInput] = useState("");
    const [operation, setOperation] = useState(null);

    const handleNumberClick = (number) => {
        setCurrentInput(currentInput === "0" ? String(number) : currentInput + number);
    };

    const handleOperationClick = (op) => {
        if (currentInput === "") return;
        if (previousInput !== "") {
            calculate();
        }
        setOperation(op);
        setPreviousInput(currentInput);
        setCurrentInput("");
    };

    const calculate = () => {
        let calc;
        const prev = parseFloat(previousInput);
        const current = parseFloat(currentInput);
        if (isNaN(prev) || isNaN(current)) return;
        switch (operation) {
            case '+':
                calc = prev + current;
                break;
            case '-':
                calc = prev - current;
                break;
            case '*':
                calc = prev * current;
                break;
            case '/':
                calc = prev / current;
                break;
            default:
                return;
        }
        setCurrentInput(String(calc));
        setOperation(null);
        setPreviousInput("");
    };

    const handleClear = () => {
        setCurrentInput("");
        setPreviousInput("");
        setOperation(null);
    };




    const handleEqualClick = () => {
        try {
            // Assuming your state has something like 'previousInput' that holds the expression
            const result = evaluate(previousInput + operation + currentInput);
            setCurrentInput(result.toString()); // Convert the result back to a string
            setPreviousInput(''); // Clear the previous input if needed
            setOperation(''); // Clear the operation if needed
        } catch (error) {
            // Handle any errors, e.g. show an error message
            console.error("Error in calculation: ", error);
        }
    }

    const handleDeleteLastNumber = () => {
        // Check if there's something to delete
        if (currentInput.length > 0) {
            // Remove the last character from the current input
            setCurrentInput(currentInput.slice(0, -1));
        }
    }

    const handleDecimalClick = () => {
        // If there is already a decimal in the current input, don't add another
        if (currentInput.includes('.')) return;

        // If the current input is empty, add a '0' before the decimal
        if (currentInput === '') {
            setCurrentInput('0.');
        } else {
            // Otherwise, just add the decimal to the current input
            setCurrentInput(currentInput + '.');
        }
    }





    const [notes, setNotes] = useState([]);

    // Retrieve notes from local storage when the component mounts
    useEffect(() => {
        const storedNotes = localStorage.getItem('notes');
        if (storedNotes) {
            setNotes(JSON.parse(storedNotes));
        }
    }, []);

    // Save notes to local storage whenever they change
    useEffect(() => {
        localStorage.setItem('notes', JSON.stringify(notes));
    }, [notes]);

    const [newNoteContent, setNewNoteContent] = useState('');

    const addNote = () => {
        if (!newNoteContent.trim()) return;
        const newNote = { id: Date.now(), content: newNoteContent, isDone: false };
        const updatedNotes = [...notes, newNote];
        setNotes(updatedNotes);
        setNewNoteContent('');
    };

    const deleteNote = (id) => {
        const updatedNotes = notes.filter(note => note.id !== id);
        setNotes(updatedNotes);
    };

    const toggleNoteDone = (id) => {
        const updatedNotes = notes.map(note => {
            if (note.id === id) {
                return { ...note, isDone: !note.isDone };
            }
            return note;
        });
        setNotes(updatedNotes);
    };





    const [articles, setArticles] = useState([]);
    const apiKey = '5054ff982cdbcf5d1e4729469170584c';

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const response = await fetch(`https://gnews.io/api/v4/search?q=shipping+goods+services&token=${apiKey}&lang=en`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setArticles(data.articles);
            } catch (error) {
                console.error("Error fetching news:", error);
            }
        };

        fetchNews();
    }, []);

    return (
        <>
            <BeyoboNavbar />
            <div className="bg-blue-600 text-white text-center p-2 leading-none">
                <p className="text-sm md:text-base">
                    This is currently beta app. Your feedback is of utmost importance.
                </p>
            </div>

            {/* <div>
      <p>User Role: {userRole}</p>
    </div> */}




            <div>
                <div className="p-4 flex justify-end">
                    <button
                        className="group bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center overflow-hidden transition-all duration-300 ease-in-out"
                        style={{ minWidth: '56px' }} // Ensure the button has a minimum width to start with
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        <svg fill="#ffffff" width="24px" height="24px" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff">
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <path d="M480 106.667c-117.82 0-213.333 95.512-213.333 213.333v1280c0 117.82 95.512 213.333 213.333 213.333h960c117.82 0 213.333-95.512 213.333-213.333V320c0-117.82-95.512-213.333-213.333-213.333H480ZM480 0h960c176.731 0 320 143.269 320 320v1280c0 176.731-143.269 320-320 320H480c-176.731 0-320-143.269-320-320V320C160 143.269 303.269 0 480 0Zm106.667 320C527.757 320 480 367.756 480 426.667v106.666C480 592.243 527.756 640 586.667 640h746.666c58.91 0 106.667-47.756 106.667-106.667V426.667c0-58.91-47.756-106.667-106.667-106.667H586.667Zm0-106.667h746.666c117.821 0 213.334 95.513 213.334 213.334v106.666c0 117.821-95.513 213.334-213.334 213.334H586.667c-117.821 0-213.334-95.513-213.334-213.334V426.667c0-117.821 95.513-213.334 213.334-213.334ZM480 853.333h106.667c58.91 0 106.666 47.757 106.666 106.667 0 58.91-47.756 106.667-106.666 106.667H480c-58.91 0-106.667-47.757-106.667-106.667 0-58.91 47.757-106.667 106.667-106.667Zm426.667 0h106.666C1072.243 853.333 1120 901.09 1120 960c0 58.91-47.756 106.667-106.667 106.667H906.667C847.757 1066.667 800 1018.91 800 960c0-58.91 47.756-106.667 106.667-106.667Zm426.666 0H1440c58.91 0 106.667 47.757 106.667 106.667 0 58.91-47.757 106.667-106.667 106.667h-106.667c-58.91 0-106.666-47.757-106.666-106.667 0-58.91 47.756-106.667 106.666-106.667Zm-853.333 320h106.667c58.91 0 106.666 47.757 106.666 106.667 0 58.91-47.756 106.667-106.666 106.667H480c-58.91 0-106.667-47.757-106.667-106.667 0-58.91 47.757-106.667 106.667-106.667Zm426.667 0h106.666c58.91 0 106.667 47.757 106.667 106.667 0 58.91-47.756 106.667-106.667 106.667H906.667C847.757 1706.667 800 1658.91 800 1600c0-58.91 47.756-106.667 106.667-106.667Zm426.666 0H1440c58.91 0 106.667 47.757 106.667 106.667 0 58.91-47.757 106.667-106.667 106.667h-106.667c-58.91 0-106.666-47.757-106.666-106.667 0-58.91 47.756-106.667 106.666-106.667Z"></path>
                            </g>
                        </svg>
                        <span className="ml-2 group-hover:max-w-xs transition-all duration-300 ease-in-out overflow-hidden max-w-0">Calculator</span>
                    </button>

                    {isOpen && (
                        <div className="fixed z-20 inset-0 overflow-y-auto h-full w-full flex justify-center items-center">
                            <div className="bg-gray-800 text-white p-5 rounded-lg shadow-lg w-96">
                                {/* Close Button */}
                                <div className="flex justify-between mb-4">
                                    <span className="text-2xl font-light">Calculator</span>
                                    <button
                                        onClick={() => setIsOpen(false)}
                                        className="text-lg text-white"
                                    >
                                        &times;
                                    </button>
                                </div>

                                {/* Display */}
                                <div className="bg-black rounded p-4 mb-5 text-right">
                                    <div className="text-gray-500">{previousInput} {operation}</div>
                                    <div className="text-2xl">{currentInput}</div>
                                </div>

                                {/* Buttons */}
                                <div className="grid grid-cols-4 gap-4">
                                    {['7', '8', '9', 'DEL', '4', '5', '6', '+', '1', '2', '3', '-'].map((key) => (
                                        <button
                                            key={key}
                                            onClick={() => {
                                                if (key >= '0' && key <= '9') handleNumberClick(key);
                                                if (key === 'DEL') handleDeleteLastNumber();
                                                if (key === '+') handleOperationClick(key);
                                                if (key === '-') handleOperationClick(key);
                                            }}
                                            className={`${['+', '-', 'DEL'].includes(key) ? 'col-span-1 bg-gray-500 hover:bg-gray-600' :
                                                'bg-blue-600 hover:bg-blue-700'
                                                } text-white font-bold py-3 px-4 rounded focus:outline-none`}
                                        >
                                            {key}
                                        </button>
                                    ))}
                                    <button
                                        onClick={handleDecimalClick}
                                        className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-3 px-4 rounded focus:outline-none"
                                    >
                                        .
                                    </button>
                                    <button
                                        onClick={() => handleNumberClick('0')}
                                        className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded focus:outline-none"
                                    >
                                        0
                                    </button>
                                    <button
                                        onClick={() => handleOperationClick('/')}
                                        className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-3 px-4 rounded focus:outline-none"
                                    >
                                        /
                                    </button>
                                    <button
                                        onClick={() => handleOperationClick('*')}
                                        className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-3 px-4 rounded focus:outline-none"
                                    >
                                        x
                                    </button>
                                </div>
                                <div className="flex gap-4 mt-4">
                                    <button
                                        onClick={handleClear}
                                        className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-3 px-4 rounded focus:outline-none flex-grow"
                                    >
                                        RESET
                                    </button>
                                    <button
                                        onClick={handleEqualClick}
                                        className="bg-red-500 hover:bg-red-600 text-white font-bold py-3 px-4 rounded focus:outline-none flex-grow"
                                    >
                                        =
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>


                <div className="flex gap-3 mx-5">
                    <div className="w-[60%]">
                        <div className="px-4">
                            <h2 className="text-lg font-semibold mb-4">Choose your platform</h2>
                            <div className="grid grid-cols-2 md:grid-cols-5 lg:grid-cols-5 gap-4">
                                {platforms.map((platform) => (
                                    <div
                                        key={platform.name}
                                        onClick={() => selectPlatform(platform.name)}
                                        className="cursor-pointer transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg"
                                    >
                                        <div className={`flex flex-col items-center p-4 rounded-lg ${platform.isSelected ? 'bg-blue-100' : 'hover:bg-blue-50'}`}>
                                            <img src={platform.logo} alt={platform.name} className="mb-2 w-12 h-12 rounded-full object-cover shadow-sm" />
                                            <span className="text-sm font-medium text-center break-words">{platform.name}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>





                        <div className="flex gap-5 my-5 justify-center w-full">
                            <div className="transform transition duration-300 ease-in-out hover:scale-105 cursor-pointer rounded-lg bg-white hover:text-white hover:bg-brand-500 shadow-lg p-5 text-center w-44 tracking-wide border rounded-full">
                                <a href="/admin/catalogue" className="flex flex-col items-center justify-center" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <svg height="64px" width="64px" viewBox="0 0 512 512">
                                        <path fill="#FFDFB3" d="M483.364,155.826h-42.902l-26.876,53.75c-1.152,2.321-1.761,4.875-1.761,7.467v33.391c0,9.223,7.479,16.696,16.696,16.696h66.783c9.217,0,16.696-7.473,16.696-16.696v-33.391c0-2.592-0.609-5.147-1.761-7.467L483.364,155.826z" />
                                        <path fill="#FFCC99" d="M512,217.043v33.391c0,9.238-7.456,16.696-16.696,16.696h-33.391V155.826h21.481l26.827,53.76 C511.443,211.923,512,214.483,512,217.043z" />
                                        <path fill="#A6673A" d="M446.978,142.793l-12.081,24.163h54.033l-12.081-24.163c-2.827-5.658-8.609-9.228-14.936-9.228 S449.804,137.136,446.978,142.793z" />
                                        <path fill="#99522E" d="M488.959,166.957h-27.046v-33.391c6.345,0,12.133,3.562,14.916,9.238l6.565,13.023L488.959,166.957z" />
                                        <path fill="#736056" d="M461.913,512c-27.636,0-50.087-22.432-50.087-50.087v-33.391c0-9.223,7.479-16.696,16.696-16.696 h66.783c9.217,0,16.696,7.473,16.696,16.696v33.391C512,489.532,489.532,512,461.913,512z" />
                                        <path fill="#665247" d="M495.304,411.826h-33.391V512C489.516,512,512,489.516,512,461.913v-33.391 C512,419.283,504.544,411.826,495.304,411.826z" />
                                        <path fill="#3ABBE6" d="M512,445.217H411.826V233.739H512V445.217z" />
                                        <path fill="#33A6CC" d="M461.913,233.739v211.478H512V233.739H461.913z" />
                                        <path fill="#F28618" d="M495.304,100.174H361.739c-9.217,0-16.696-7.473-16.696-16.696C345.043,37.446,382.49,0,428.522,0 S512,37.446,512,83.478C512,92.701,504.521,100.174,495.304,100.174z" />
                                        <path fill="#EDF0F2" d="M150.261,0c-46.08,0-83.478,37.398-83.478,83.478v345.043c0,9.238,7.456,16.696,16.696,16.696 c0,0,10.574,0,25.824,0c37.731,0,103.847,0,103.847,0C220.94,483.283,254.665,512,294.958,512c4.785,0,9.572-0.445,14.136-1.224 c39.402-6.678,69.343-40.961,69.343-82.254V50.087C378.436,22.483,400.919,0,428.523,0H150.261z" />
                                        <path fill="#DAE1E6" d="M244.87,495.163C258.839,505.695,276.162,512,294.957,512c4.785,0,9.572-0.445,14.136-1.224 c39.402-6.678,69.343-40.961,69.343-82.254V50.087C378.435,22.483,400.918,0,428.522,0H328.348 c-46.103,0-83.478,37.375-83.478,83.478V495.163z" />
                                        <circle fill="#C9D2D9" cx="150.261" cy="116.87" r="16.696" />
                                        <path fill="#C9D2D9" d="M294.957,133.565h-77.913c-9.217,0-16.696-7.473-16.696-16.696s7.479-16.696,16.696-16.696h77.913 c9.217,0,16.696,7.473,16.696,16.696S304.174,133.565,294.957,133.565z" />
                                        <path fill="#B7C4CC" d="M294.957,100.174H244.87v33.391h50.087c9.217,0,16.696-7.473,16.696-16.696 S304.174,100.174,294.957,100.174z" />
                                        <circle fill="#C9D2D9" cx="150.261" cy="183.652" r="16.696" />
                                        <path fill="#C9D2D9" d="M294.957,200.348h-77.913c-9.217,0-16.696-7.473-16.696-16.696s7.479-16.696,16.696-16.696h77.913 c9.217,0,16.696,7.473,16.696,16.696S304.174,200.348,294.957,200.348z" />
                                        <path fill="#B7C4CC" d="M294.957,166.957H244.87v33.391h50.087c9.217,0,16.696-7.473,16.696-16.696 S304.174,166.957,294.957,166.957z" />
                                        <circle fill="#C9D2D9" cx="150.261" cy="250.435" r="16.696" />
                                        <path fill="#C9D2D9" d="M294.957,267.13h-77.913c-9.217,0-16.696-7.473-16.696-16.696s7.479-16.696,16.696-16.696h77.913 c9.217,0,16.696,7.473,16.696,16.696S304.174,267.13,294.957,267.13z" />
                                        <path fill="#B7C4CC" d="M294.957,233.739H244.87v33.391h50.087c9.217,0,16.696-7.473,16.696-16.696 S304.174,233.739,294.957,233.739z" />
                                        <circle fill="#C9D2D9" cx="150.261" cy="317.217" r="16.696" />
                                        <path fill="#C9D2D9" d="M294.957,333.913h-77.913c-9.217,0-16.696-7.473-16.696-16.696s7.479-16.696,16.696-16.696h77.913 c9.217,0,16.696,7.473,16.696,16.696S304.174,333.913,294.957,333.913z" />
                                        <path fill="#B7C4CC" d="M294.957,300.522H244.87v33.391h50.087c9.217,0,16.696-7.473,16.696-16.696 S304.174,300.522,294.957,300.522z" />
                                        <path fill="#FF9F19" d="M309.092,510.776c-4.563,0.778-9.351,1.224-14.136,1.224c-63.666,0-211.478,0-211.478,0 C37.398,512,0,474.602,0,428.522c0-8.682,6.678-15.805,15.137-16.584c0.864-0.174,212.156-0.111,213.037-0.111 c9.239,0,16.696,7.457,16.696,16.696c0,2.783,0,5.565,0,5.565C244.87,472.487,272.584,504.32,309.092,510.776z" />
                                    </svg>
                                    <p className="text-2xl font-bold mt-2">{products.length}</p>
                                    <p className="text-md font-semibold">Total Products</p>
                                </a>
                            </div>

                            <div className="transform transition duration-300 ease-in-out hover:scale-105 cursor-pointer rounded-lg bg-white hover:text-white hover:bg-brand-500 shadow-lg p-5 text-center w-44 tracking-wide border rounded-full">
                                <a href="/admin/orders" className="flex flex-col items-center justify-center" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <svg width="64px" height="64px" viewBox="0 0 48 48" version="1" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 48 48" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill="#8BC34A" d="M43,36H29V14h10.6c0.9,0,1.6,0.6,1.9,1.4L45,26v8C45,35.1,44.1,36,43,36z"></path> <path fill="#388E3C" d="M29,36H5c-1.1,0-2-0.9-2-2V9c0-1.1,0.9-2,2-2h22c1.1,0,2,0.9,2,2V36z"></path> <g fill="#37474F"> <circle cx="37" cy="36" r="5"></circle> <circle cx="13" cy="36" r="5"></circle> </g> <g fill="#78909C"> <circle cx="37" cy="36" r="2"></circle> <circle cx="13" cy="36" r="2"></circle> </g> <path fill="#37474F" d="M41,25h-7c-0.6,0-1-0.4-1-1v-7c0-0.6,0.4-1,1-1h5.3c0.4,0,0.8,0.3,0.9,0.7l1.7,5.2c0,0.1,0.1,0.2,0.1,0.3V24 C42,24.6,41.6,25,41,25z"></path> <polygon fill="#DCEDC8" points="21.8,13.8 13.9,21.7 10.2,17.9 8,20.1 13.9,26 24,15.9"></polygon> </g></svg>
                                    <p className="text-2xl font-bold mt-2">{orders.length}</p>
                                    <p className="text-md font-semibold">Total Orders</p>
                                </a>
                            </div>
                        </div>

                        <div>
                            <h2 className="text-2xl font-bold my-10 p-2">Graph of Total Sales</h2>
                            <div className="flex justify-center">
                                <div style={{ width: '700px' }}>
                                    <BarChart
                                        width={600} height={400} data={salesdata}
                                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="platform" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="total_sales" fill="#8884d8" />
                                    </BarChart>
                                </div>
                            </div>
                        </div>

                    </div>



                    <div className="w-[40%]">
                        <div className="py-4 space-y-4">
                            <h2 className="text-2xl font-bold text-gray-800">Take Notes</h2>
                            <div className="flex space-x-2">
                                <input
                                    type="text"
                                    value={newNoteContent}
                                    onChange={(e) => setNewNoteContent(e.target.value)}
                                    placeholder="Add a new note for yourself"
                                    className="flex-1 border-gray-300 rounded shadow p-2"
                                />
                                <button
                                    onClick={addNote}
                                    className="bg-blue-500 text-white rounded p-2 shadow"
                                >
                                    Add
                                </button>
                            </div>
                            <ul className="space-y-2">
                                {notes.map(note => (
                                    <li key={note.id} className={`p-2 rounded shadow ${note.isDone ? 'bg-green-100' : 'bg-white'}`}>
                                        <div className="flex justify-between items-center">
                                            <span className={`${note.isDone ? 'line-through' : ''}`}>{note.content}</span>
                                            <div className="flex space-x-2">
                                                <button onClick={() => toggleNoteDone(note.id)} className="text-xs text-green-600">Done</button>
                                                <button onClick={() => deleteNote(note.id)} className="text-xs text-red-600">Delete</button>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>



                        <div className="my-5 p-8 bg-white shadow-xl rounded-xl">
                            <div className="flex justify-between mb-8 pb-3 border-b-4 border-gray-300 items-center">
                                <h2 className="text-xl font-bold text-gray-700">Payment Information</h2>
                                <div className="relative inline-block">
                                    <select
                                        value={currency}
                                        onChange={handleCurrencyChange}
                                        className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-400 px-4 py-3 pr-8 rounded-lg shadow-sm leading-tight focus:outline-none focus:border-blue-500 transition duration-200 ease-in-out"
                                    >
                                        <option value="INR">Indian Rupee</option>
                                        <option value="USD">US Dollar</option>
                                        <option value="JPY">Japanese Yen</option>
                                        <option value="CNY">Chinese RMB</option>
                                        <option value="KRW">South Korean Won</option>
                                    </select>
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-3 text-gray-600">
                                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                    </div>
                                </div>
                            </div>

                            <div className="space-y-4">
                                {headings.map((item, index) => (
                                    <div key={index} className="flex justify-between items-center py-3 border-b border-gray-300">
                                        <span className="text-gray-700 text-lg font-medium">{item.title}</span>
                                        <span className="text-indigo-600 font-semibold">
                                            <span className="mr-1">{item.value.charAt(0)}</span>
                                            <span style={{ letterSpacing: '0.025em' }}>{item.value.slice(1)}</span>
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>

                </div>


                <button onClick={toggleIframe} style={{
                    position: 'fixed',
                    bottom: '20px',
                    right: '20px',
                    width: '60px',
                    height: '60px',
                    borderRadius: '50%',
                    backgroundColor: '#007bff',
                    color: 'white',
                    fontSize: '24px',
                    border: 'none',
                    cursor: 'pointer',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    {/* Conditional rendering based on isIframeOpen */}
                    {isIframeOpen ? (
                        // Cross sign (for closing the iframe)
                        <svg width="30px" height="30px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#ffffff">
                            <path d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z" />
                        </svg>
                    ) : (
                        // Chat icon (for opening the iframe)
                        <svg fill="#ffffff" width="30px" height="30px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 15l-4 4v-4H2a2 2 0 0 1-2-2V3c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-8zM5 7v2h2V7H5zm4 0v2h2V7H9zm4 0v2h2V7h-2z" />
                        </svg>
                    )}
                </button>

                {/* Iframe container */}
                {isIframeOpen && (
                    <div style={{
                        position: 'fixed',
                        bottom: '80px',
                        right: '20px',
                        width: '400px',
                        height: '500px',
                        border: '1px solid #ccc',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                        zIndex: 999,
                    }}>
                        <iframe
                            src="https://ai.ensologic.com"
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            title="Embedded Chat"
                            style={{ border: 'none' }}
                        ></iframe>
                    </div>
                )}

            </div>






            <div className="mx-auto p-8">
                <h2 className="text-3xl font-bold text-center mb-10">Shipping Goods and Services News</h2>
                <div className="grid md:grid-cols-3 gap-10">
                    {articles.length > 0 ? (
                        articles.map((article, index) => (
                            <div key={index} className="bg-white shadow-lg rounded-lg overflow-hidden">
                                {article.image && (
                                    <img className="w-full h-56 object-cover object-center" src={article.image} alt={article.title} />
                                )}
                                <div className="p-4">
                                    <h3 className="font-bold text-xl mb-2">{article.title}</h3>
                                    <p className="text-gray-700 mb-4">{article.description}</p>
                                    <a href={article.url} target="_blank" rel="noopener noreferrer" className="inline-block bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors">Read more</a>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className="text-center col-span-2">No news articles found.</p>
                    )}
                </div>
            </div>
        </>
    );
};

export default BeyoboDashboard;




//<div className="flex flex-cols-2 w-full">
//    <div className="w-2/3 justify-between gap-5 m-5">
//        <div className="p-4">
//            <button
//                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
//                onClick={() => setIsOpen(!isOpen)}
//            >
//                {isOpen ? 'Close Calculator' : 'Open Calculator'}
//            </button>

//            {isOpen && (
//                <div className="fixed z-20 inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
//                    <div className="relative bg-white p-5 rounded-lg shadow-lg max-w-sm">
//                        Close Button
//                        <button
//                            onClick={() => setIsOpen(false)}
//                            className="absolute top-0 right-0 text-2xl font-semibold px-2 -mt-[5px]"
//                        >
//                            &times;
//                        </button>
//                        <div className="space-y-2">
//                            <div className="bg-gray-100 p-5 text-right">
//                                {previousInput} {operation} {currentInput}
//                            </div>
//                            <div className="grid grid-cols-4 gap-2">
//                                {['7', '8', '9', '/', '4', '5', '6', '*', '1', '2', '3', '-', 'C', '0', '=', '+'].map((key) => (
//                                    <button
//                                        key={key}
//                                        onClick={() => {
//                                            if (key >= '0' && key <= '9') handleNumberClick(key);
//                                            if (['/', '*', '-', '+'].includes(key)) handleOperationClick(key);
//                                            if (key === 'C') handleClear();
//                                            if (key === '=') handleEqualClick();
//                                        }}
//                                        className="bg-gray-200 hover:bg-gray-300 text-gray-700 font-bold py-2 px-4 rounded"
//                                    >
//                                        {key}
//                                    </button>
//                                ))}
//                            </div>
//                        </div>
//                    </div>
//                </div>
//            )}
//        </div>

//        <div>
//            <div classname="justify-end">
//                <select
//                    name="options"
//                    id="options-select"
//                    class="block w-fit px-3 py-2 text-base font-normal text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
//                    onchange="handleselectchange(this)"
//                >
//                    <option value="amazon">amazon</option>
//                    <option value="flipkart">flipkart</option>
//                    <option value="meesho">meesho</option>
//                </select>
//            </div>


//            <div className="p-4">
//                <h2 className="text-lg font-semibold mb-4">Choose your platform</h2>
//                <div className="grid grid-cols-2 md:grid-cols-8 lg:grid-cols-8 gap-4">
//                    {platforms.map((platform) => (
//                        <div
//                            key={platform.name}
//                            onClick={() => selectPlatform(platform.name)}
//                        >
//                            <div className="flex flex-col items-center">
//                                <img src={platform.logo} alt={platform.name} className="mb-2 w-12 h-12 rounded-full object-cover" />
//                                <span className="text-sm font-medium break-words">{platform.name}</span>
//                            </div>
//                        </div>
//                    ))}
//                </div>
//            </div>

//            <div className="flex gap-5 justify-center w-full">
//                <div className="rounded-lg bg-white hover:text-white hover:bg-brand-500 transition p-5 text-center w-44 tracking-wide border delay-75 rounded-full">
//                    <a href="/admin/catalogue" style={{ textDecoration: 'none', color: 'inherit' }}>
//                        <p className="text-md font-normal">{products.length}</p>
//                        <p className="text-lg font-semibold">Total Products listed</p>
//                    </a>
//                </div>

//                <div className="rounded-lg bg-white hover:text-white hover:bg-brand-500 transition p-5 text-center w-44 tracking-wide border rounded-full delay-75">
//                    <a href="/admin/orders" style={{ textDecoration: 'none', color: 'inherit' }}>
//                        <p className="text-md font-normal">{orders.length}</p>
//                        <p className="text-lg font-semibold">Total Orders</p>
//                    </a>
//                </div>

//            </div>

//        </div>


//        <div className="justify-between flex my-5">
//            <div className="relative inline-block text-gray-700">
//                <select
//                    value={currency}
//                    onChange={handleCurrencyChange}
//                    className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
//                >
//                    <option value="INR">Indian Rupee</option>
//                    <option value="USD">US Dollar</option>
//                    <option value="JPY">Japanese Yen</option>
//                    <option value="CNY">Chinese RMB</option>
//                    <option value="KRW">South Korean Won</option>
//                </select>
//                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
//                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
//                </div>
//            </div>
//        </div>



//        <div className="my-5 mx-auto p-6 bg-white shadow-lg rounded-lg">
//            <h2 className="text-2xl font-bold mb-6 border-b-2 pb-2 border-gray-200">Payment Information</h2>
//            <div className="space-y-4">
//                {headings.map((item, index) => (
//                    <div key={index} className="flex justify-between items-center py-2 border-b last:border-b-0">
//                        <span className="text-gray-800 text-lg font-medium">{item.title}</span>
//                        <span className="text-blue-600 font-semibold flex items-center">
//                            <span className="mr-1">{item.value.charAt(0)}</span>
//                            <span style={{ letterSpacing: '0.025em' }}>{item.value.slice(1)}</span>
//                        </span>
//                    </div>
//                ))}
//            </div>

//            {/*<div className="mt-6 flex justify-between items-center">*/}
//            {/*    <button className="bg-blue-600 text-white font-semibold py-2 px-4 rounded hover:bg-blue-700 transition duration-150 ease-in-out">Get Invoice</button>*/}
//            {/*    <span className="flex items-center text-sm bg-blue-100 text-blue-600 py-2 px-3 rounded-full">*/}
//            {/*        Pay on delivery*/}
//            {/*        <svg className="ml-2 w-5 h-5" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">*/}
//            {/*            <path d="M18 12H6"></path>*/}
//            {/*        </svg>*/}
//            {/*    </span>*/}
//            {/*</div>*/}
//        </div>

//    </div>

//    {/* Right Sidebar Sections */}

//    <div>
//        <h2 className="text-2xl font-bold my-10 pb-2">Graph of Total Sales</h2>

//        <BarChart
//            width={500}
//            height={300}
//            data={salesdata} // Ensure 'data' is stored in your component's state
//            margin={{
//                top: 5, right: 20, left: 35, bottom: 5,
//            }}
//            z-index={50}
//        >
//            <CartesianGrid strokeDasharray="3 3" />
//            <XAxis dataKey="platform" />
//            <YAxis />
//            <Tooltip />
//            <Legend />
//            <Bar dataKey="total_sales" fill="#8884d8" />
//        </BarChart>


//    </div>

//</div>








//{/* Learning Videos Section */ }
//{/* <div className="bg-white rounded-lg shadow p-5 mb-5">
//                        <h2 className="text-xl font-semibold mb-4">Learning Videos</h2>
//                        <ul className="list-disc list-inside">
//                            <li>Payment Reconciliation Guide <span className="text-red-500">NEW</span></li>
//                            <li>Catalogue process</li>
//                            <li>Shipments in Beyobo</li>
//                            <li>Click here for more videos</li>
//                        </ul>
//                    </div> */}

//{/* Activity Section */ }
//{/* <div className="bg-white rounded-lg shadow p-5">
//                        <h2 className="text-xl font-semibold mb-4">Activity</h2>

//                        <Notification></Notification>
//                        <Notification></Notification>
//                    </div> */}





//{/* Dashboard Boxes */ }

//{/*<div className="w-full flex flex-wrap gap-5 mt-5">*/ }
//{/*    <DashboardBox*/ }
//{/*        imgSrc="https://sellercentral.jennifer-in.com/assets/images/svg/shipmentvalue.svg"*/ }
//{/*        imgAlt="Shipment Value"*/ }
//{/*        currency="INR"*/ }
//{/*        description="Total Shipment Value"*/ }
//{/*    />*/ }
//{/*    <DashboardBox*/ }
//{/*        imgSrc="https://sellercentral.jennifer-in.com/assets/images/svg/amazoncredit.svg"*/ }
//{/*        imgAlt="Total Credit"*/ }
//{/*        currency="INR"*/ }
//{/*        description="Total Credit"*/ }

//{/*    />*/ }
//{/*    */ } {/*options={['Amazon', 'Flipkart', 'Meesho']} // Providing options here*/ }
//{/*    <DashboardBox*/ }
//{/*        imgSrc="https://sellercentral.jennifer-in.com/assets/images/svg/amountrecived.svg"*/ }
//{/*        imgAlt="Total Amazon Credit"*/ }
//{/*        currency="INR"*/ }
//{/*        description="Total Amount Received"*/ }
//{/*    />*/ }
//{/*    */ } {/* Add more DashboardBox components as needed */ }
//{/*</div>*/ }





//{/*<div>*/ }
//{/*    <button*/ }
//{/*        to="#"*/ }
//{/*        onClick={handleOpenPopup}*/ }
//{/*        className="text-sm bg-brand-300 text-white p-3 rounded-lg text-gray-800 dark:text-white hover:dark:text-white hover:text-brand-900 active:text-brand-900"*/ }
//{/*    >*/ }
//{/*        Upload Resume*/ }
//{/*    </button>*/ }
//{/*</div>*/ }

//{/* Resume upload form popup */ }
//{
//    isPopupOpen && (
//        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-100" id="my-modal">
//            <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
//                <div className="mt-3">
//                    {/* Close button */}
//                    <button onClick={handleClosePopup} className="absolute top-0 right-0 rounded-full bg-red-200 px-2 py-1 m-1">
//                        X
//                    </button>
//                    <form onSubmit={(e) => e.preventDefault()}>
//                        <div className="mb-4">
//                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="candidateName">
//                                Candidate Name*
//                            </label>
//                            <input
//                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                                id="name"
//                                name="name"
//                                type="text"
//                                placeholder="Candidate Name"
//                                value={formData.name}
//                                onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}
//                                required
//                            />
//                        </div>

//                        <div className="mb-4">
//                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="designation">
//                                Designation*
//                            </label>
//                            <input
//                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                                id="designation"
//                                name="designation"
//                                type="tel"
//                                placeholder="Designation"
//                                value={formData.designation}
//                                onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}
//                                required
//                            />
//                        </div>

//                        <div className="mb-4">
//                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="noticePeriod">
//                                Notice Period Remaining*
//                            </label>
//                            <input
//                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                                id="notice_period_remaining"
//                                name="notice_period_remaining"
//                                type="text"
//                                placeholder="Notice Period Remaining"
//                                value={formData.notice_period_remaining}
//                                onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}
//                                required
//                            />
//                        </div>

//                        <div className="mb-4">
//                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="salaryExpected">
//                                Salary Expected*
//                            </label>
//                            <input
//                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                                id="salary_expectations"
//                                name="salary_expectations"
//                                type="text"
//                                placeholder="Salary Expected"
//                                value={formData.salary_expectations}
//                                onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}
//                                required
//                            />
//                        </div>

//                        <div className="mb-4">
//                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="dateOfJoining">
//                                Date of joining*
//                            </label>
//                            <input
//                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                                id="joining_date"
//                                name="joining_date"
//                                type="date"
//                                placeholder="Date of joining"
//                                value={formData.joining_date}
//                                onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}
//                                required
//                            />
//                        </div>

//                        <div className="my-4">
//                            <label className="block text-gray-700 text-sm font-bold mb-2">
//                                Upload Resume *
//                            </label>
//                            <div className="flex justify-center px-6 pt-5 pb-6 border-2 border-blue-500 border-dotted rounded-lg">
//                                <div className="space-y-1 text-center">
//                                    {/* Display preview if there's a file */}
//                                    {previewUrl && (
//                                        <img src={previewUrl} alt="Preview" className="mx-auto mb-3" style={{ maxWidth: '200px', maxHeight: '200px' }} />
//                                    )}
//                                    <div className="flex text-sm text-gray-600">
//                                        <label htmlFor="file-upload" className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
//                                            <span>Upload a file</span>
//                                            <input
//                                                id="file-upload"
//                                                name="resume"
//                                                type="file"
//                                                className="sr-only"
//                                                onChange={handleFileChange}
//                                                accept="image/png, image/jpeg, image/gif"
//                                            />
//                                        </label>
//                                        <p className="pl-1">or drag and drop</p>
//                                    </div>
//                                    <p className="text-xs text-gray-500">
//                                        PNG, JPG, GIF up to 10MB
//                                    </p>
//                                </div>
//                            </div>
//                        </div>
//                        <div className="flex gap-5">
//                            <button onClick={handleSubmit} type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
//                                Submit
//                            </button>
//                        </div>
//                    </form>
//                </div>
//            </div>
//        </div>
//    )
//}






//{/* Gross Sales Section */ }
//{/*<div className="my-5">*/ }
//{/*    <div className="bg-white rounded-lg shadow">*/ }
//{/*        <div className="flex justify-between p-5 items-center">*/ }
//{/*            <h2 className="text-xl font-semibold mb-4">Gross Sales</h2>*/ }
//{/*            <div className="col-md-3 col-xs-12 w-full md:w-1/4 px-2">*/ }
//{/*                <div className="relative" ref={dropdownRef}>*/ }
//{/*                    <button*/ }
//{/*                        className="text-left text-gray-700 w-full px-4 py-2 bg-white rounded-md border border-gray-300 shadow-sm focus:outline-none focus:ring"*/ }
//{/*                        onClick={() => setShowDropdown(!showDropdown)}*/ }
//{/*                    >*/ }
//{/*                        {selected}*/ }
//{/*                    </button>*/ }
//{/*                    {showDropdown && (*/ }
//{/*                        <div className="absolute right-0 w-full mt-2 bg-white rounded-md shadow-lg z-50">*/ }
//{/*                            {['THIS YEAR', 'THIS MONTH', 'LAST 30 DAYS', 'LAST MONTH', 'CUSTOM UPTO 180 DAYS'].map((item) => (*/ }
//{/*                                <a key={item} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => handleSelect(item)}>*/ }
//{/*                                    {item}*/ }
//{/*                                </a>*/ }
//{/*                            ))}*/ }
//{/*                        </div>*/ }
//{/*                    )}*/ }
//{/*                </div>*/ }
//{/*            </div>*/ }
//{/*        </div>*/ }

//{/*        <DashboardDataGrid></DashboardDataGrid>*/ }

//{/*        <hr className="bg-gray-300" />*/ }

//{/*        <div className="p-5"></div>*/ }
//{/*    </div>*/ }
//{/*</div>*/ }