import React, { useState,useEffect,useContext } from 'react';
import InventoryDataGrid from 'components/inventorydatagrid';
import AuthContext from "../../../../src/context/AuthContext";
const InventorySidenav = () => {
    const [activeTab, setActiveTab] = useState('All Products');
    
    const [products, setProducts] = useState([]);
  

    // Fetch data from the backend
    const { authToken} = useContext(AuthContext);
    // const {user} =useContext(UserContext);
    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_API_URL}/inventory/`,{
            headers: {
                'Authorization': `Token ${authToken}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setProducts(data); // Assuming the API returns an array of products
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });
    }, []);  // Rerun when user o
    const activeStyle = 'bg-brand-300 text-white';
    const inactiveStyle = 'text-gray-700 hover:bg-gray-100 hover:text-gray-900';

    const getButtonClasses = (tabName) => {
        return `w-full text-left py-2 px-4 rounded-lg font-semibold flex justify-between items-center ${activeTab === tabName ? activeStyle : inactiveStyle}`;
    };

    const Badge = ({ count }) => (
        <span className={`badge ${count > 0 ? 'bg-blue-200' : 'bg-gray-200'} text-blue-800 text-sm font-bold py-1 px-3 rounded-full`}>
            {count}
        </span>
    );

    const SidebarButton = ({ tabName, count }) => (
        <button onClick={() => setActiveTab(tabName)} className={getButtonClasses(tabName)}>
            {tabName} <Badge count={count} />
        </button>
    );

    const renderContent = () => {
        switch (activeTab) {
            case 'Overview':
                return <div><img className="w-1/2 h-1/2" src="https://img.freepik.com/free-vector/abstract-coming-soon-halftone-style-background-design_1017-27282.jpg" /></div>;
            case 'All Products':
                return <InventoryDataGrid></InventoryDataGrid>
            case 'Limited':
                return <div><img className="w-1/2 h-1/2" src="https://img.freepik.com/free-vector/abstract-coming-soon-halftone-style-background-design_1017-27282.jpg" /></div>;
            case 'In stock':
                return <div><img className="w-1/2 h-1/2" src="https://img.freepik.com/free-vector/abstract-coming-soon-halftone-style-background-design_1017-27282.jpg" /></div>;
            // ... other cases ...
            default:
                return <div>Select a tab to view content</div>;
        }
    };

    return (
        <div className="flex gap-3 w-full rounded-lg">
            <div className="bg-white p-4 h-fit w-1/4 rounded-lg">
                <div className="flex flex-col space-y-2">
                    <SidebarButton tabName="All Products" count={products.length} />
                    <SidebarButton tabName="OUT OF STOCK"  />
                    <SidebarButton tabName="Limited"  />
                    <SidebarButton tabName="In stock"  />
                </div>
            </div>

            <div className="bg-white h-fit w-full rounded-lg p-5">
                {renderContent()}
            </div>
        </div>
    );

};

export default InventorySidenav;
