import React, { createContext, useState } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [authToken, setAuthToken] = useState(localStorage.getItem('authToken'));
    
    const setToken = (token) => {
        localStorage.setItem('authToken', token);
        setAuthToken(token);
    };

    const getToken = () => {
        return localStorage?.getItem('authToken')??'';
    };


   

  return (
        <AuthContext.Provider value={{ authToken, setToken,getToken}}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
