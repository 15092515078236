import BeyoboNavbar from "../../../components/beyobonavbar";
import React, { useState, useContext, useEffect } from 'react';
//import axios from "axios";
import AuthContext from "../../../../src/context/AuthContext";
import comingSoonImage from '../../../assets/img/images/comingsoon.png';
import ShipmentSidenav from "../payments/shipmentsidenav";
import CatalogProductContext from "../../../../src/context/CatalogProductContext";
import { Autocomplete, TextField } from "@mui/material";

const NoShipments = () => {
    const [activeTab, setActiveTab] = useState('statements');
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [step, setStep] = useState(1);
    const [selection, setSelection] = useState('');
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const openPopup = () => {
        setPopupOpen(true);
    };

    const [formData, setFormData] = useState({
        conversion_rate: '',
        pickup_address: '',
        weight_in_kgs: '',
        volumetric_weight_in_kgs: '',
        variants: [], // This will hold the variant details
    });


    // const [shipmentvariantData, setShipmentVariantData] = useState([
    //     { name: '', ordered_quantity: '', isChecked: false, label: '' },
    // ]);



    const handleVariantChange = (name, field, value) => {
        setFormData(prevState => {
            const variants = [...prevState.variants];
            const existingVariantIndex = variants.findIndex(v => v.name === name);
            if (existingVariantIndex >= 0) {
                // Update existing variant
                variants[existingVariantIndex] = { ...variants[existingVariantIndex], [field]: value };
            } else {
                // Add new variant if not exists (optional, based on your logic)
                variants.push({ name: name, [field]: value });
            }
            return { ...prevState, variants };
        });
    };

   // const [selectedVariants, setSelectedVariants] = useState([]);

    // Handle changes in the form inputs
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    //const handleCheckboxChange = (index) => {
    //    setShipmentVariants(currentVariants =>
    //        currentVariants.map((variant, i) =>
    //            i === index ? { ...variant, isChecked: !variant.isChecked } : variant
    //        )
    //    );
    //};

    const closePopup = () => {
        setPopupOpen(false);
        setStep(1); // Reset the step to 1 when closing the modal.
    };

    const [shipmentVariants, setShipmentVariants] = useState([]);


    // Fetch data from the backend
    const { authToken } = useContext(AuthContext);
    // const {user} =useContext(UserContext);
    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_API_URL}/variantname/`, {
            headers: {
                'Authorization': `Token ${authToken}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                const variantsWithLabel = data.map(variant => ({
                    ...variant,
                    isChecked: false, // Existing property
                    label: variant.name // Add label property
                }));
                setShipmentVariants(variantsWithLabel);
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });
    }, [authToken]);



    const [currentStep, setCurrentStep] = useState(1);
    const [stepsCompleted, setStepsCompleted] = useState([false]);



    // const handleOptionClick = (variant) => {
    //     setSelectedVariants(prevVariants => {
    //         // Avoid adding duplicate variants
    //         if (prevVariants.some(v => v.name === variant.name)) {
    //             return prevVariants;
    //         }
    //         return [...prevVariants, { ...variant, isChecked: false }];
    //     });
    // };

    // // Function to handle checkbox changes for each variant
    // const handleCheckboxChange = (index) => {
    //     setSelectedVariants(currentVariants =>
    //         currentVariants.map((variant, i) =>
    //             i === index ? { ...variant, isChecked: !variant.isChecked } : variant
    //         )
    //     );
    // };


        // Combine form data and selected variants
        // const combinedData = {
        //     ...formData,
        //     variants: selectedVariants.filter(variant => variant.isChecked).map(variant => ({
        //         name: variant.name,
        //         ordered_quantity: variant.quantity,
        //         price:variant.price
        //     }))
        // };



    //const handleSubmit = async (event) => {
    //    event.preventDefault();

    //    const submissionData = {
    //        ...formData,
    //        variants: formData.variants.map(variant => ({
    //            name: variant.name,
    //            ordered_quantity: variant.quantity,
    //        }))
    //    };

    //    try {
    //        const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/shipmentvariant/`, {
    //            method: 'POST',
    //            headers: {
    //                'Authorization': `Token ${authToken}`,
    //                'Content-Type': 'application/json',
    //            },
    //            body: JSON.stringify(submissionData),
    //        });

    //        if (!response.ok) {
    //            throw new Error('Network response was not ok');
    //        }

    //        // Assuming your API returns a success response on successful submission
    //        const data = await response.json();
    //        console.log('Success:', data);

    //        // Set success state and possibly reset form or close the popup
    //        setSubmitSuccess(true);
    //        // Optional: Reset form state or close popup
    //        // setFormData({...initialFormData}); // Reset form data to initial state
    //        // setPopupOpen(false); // Close the popup if needed
    //    } catch (error) {
    //        console.error('Error:', error);
    //        setSubmitError(true); // Handle submission error
    //    }
    //};



    const handleSubmit = async (event) => {
        event.preventDefault();

        const submissionData = {
            ...formData,
            variants: formData.variants.map(variant => ({
                name: variant.name,
                ordered_quantity: variant.quantity,
                price: variant.price,
                supplier_currency: variant.currency,
            }))
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/shipmentvariant/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${authToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(submissionData),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            // Handle success
            console.log('Submission successful');
            setShowSuccessModal(true); // Show success modal
            setPopupOpen(false); // Close the "Add Variants" popup if needed
        } catch (error) {
            console.error('Error:', error);
            // Handle error (optionally show an error message)
        }
    };





    // const [shipmentVariants, setShipmentVariants] = useContext(CatalogProductContext);




    const handleNext = () => {
        if (currentStep < 2) setCurrentStep(currentStep + 1);
    };

    // Function to handle quantity change

    const handleQuantityChange = (index, newValue) => {
        setShipmentVariants(currentVariants =>
            currentVariants.map((variant, i) =>
                i === index ? { ...variant, quantity: newValue } : variant
            )
        );
    };

    const handlePriceChange = (index, newValue) => {
        setShipmentVariants(currentVariants =>
            currentVariants.map((variant, i) =>
                i === index ? { ...variant, quantity: newValue } : variant
            )
        );
    };


    // Function to move to the previous step
    const handlePrevious = () => {
        if (currentStep > 1) setCurrentStep(currentStep - 1);
    };
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [submitError, setSubmitError] = useState(false);

    const renderStepTwo = () => (
        <div>
            <div>
                <form>

                    <div>
                        <label className="block mb-2" htmlFor="name">Conversion Rate *</label>
                        <input
                            type="number"
                            id="conversion_rate"
                            name="conversion_rate"
                            onChange={handleInputChange}
                            required
                            className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                            placeholder="12.5"
                        />
                    </div>

                    <div>
                        <label className="block my-2" htmlFor="name">Pickup Address *</label>
                        <input
                            type="text"
                            id="pickup_adress"
                            name="pickup_address"
                            onChange={handleInputChange}
                            className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                            placeholder="Enter Pickup Address"
                        />
                    </div>


                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-4">
                        <div>
                            <label className="block mb-2" htmlFor="name">Weight (in kgs) *</label>
                            <input
                                type="number"
                                id="weight_in_kgs"
                                name="weight_in_kgs"
                                onChange={handleInputChange}
                                className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                placeholder="1"
                            />
                        </div>

                        <div>
                            <label className="block mb-2" htmlFor="name">Volumetric Weight (in kgs) *</label>
                            <input
                                type="number"
                                id="volumetric_weight_in_kgs"
                                name="volumetric_weight_in_kgs"
                                onChange={handleInputChange}
                                className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                placeholder="1"
                            />
                        </div>
                    </div>


                    <div className="flex justify-end mt-6">
                        <button onClick={() => setStep(1)} className="bg-gray-200 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-300 mr-2">Previous</button>
                        <button
                            type="submit"
                            className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
                            onClick={handleSubmit}
                        >
                            Submit
                        </button>
                    </div>
                </form>
                {/*{*/}
                {/*    submitSuccess && (*/}
                {/*        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">*/}
                {/*            <div className="relative top-20 mx-auto p-5 border w-1/2 shadow-lg rounded-md bg-white">*/}
                {/*                <div className="text-center">*/}
                {/*                    <h3 className="mb-4 text-lg font-bold text-green-500">Success!</h3>*/}
                {/*                    <p className="mb-4">Your shipment details have been successfully submitted.</p>*/}
                {/*                    <button*/}
                {/*                        onClick={() => {*/}
                {/*                            setSubmitSuccess(false); // Close the success message*/}
                {/*                            setPopupOpen(false); // Optionally close the popup*/}
                {/*                        }}*/}
                {/*                        className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700"*/}
                {/*                    >*/}
                {/*                        OK*/}
                {/*                    </button>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    )*/}
                {/*}*/}

                {submitError && <div>Error submitting form. Please try again.</div>}
            </div>

        </div>
    );

    const [inputValue, setInputValue] = useState('');
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [selectedVariants, setSelectedVariants] = useState([]);

    const handleInputChanges = (event) => {
        const value = event.target.value;
        setInputValue(value);
        if (value) {
            const filtered = shipmentVariants.filter(variant =>
                variant.label.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredOptions(filtered);
        } else {
            setFilteredOptions([]);
        }
    };

    const handleOptionClick = (variant) => {
        setSelectedVariants(prevVariants => {
            // Check if variant is already selected
            if (prevVariants.some(v => v.name === variant.name)) {
                return prevVariants;
            }
            return [...prevVariants, { ...variant, isChecked: false }];
        });
        setInputValue('');
        setFilteredOptions([]);
    };


    const handleRemoveVariant = (name) => {
        setFormData(prevState => ({
            ...prevState,
            variants: prevState.variants.filter(variant => variant.name !== name)
        }));
        setSelectedVariants(prevVariants => prevVariants.filter(variant => variant.name !== name));
    };


    //const handleCheckboxChange = (index) => {
    //    setSelectedVariants(currentVariants =>
    //        currentVariants.map((variant, i) =>
    //            i === index ? { ...variant, isChecked: !variant.isChecked } : variant
    //        )
    //    );
    //};


    const renderStepOne = () => {
        return (


            <div className="py-6">
                <h1 className="bold-text">Choose Variant To Ship</h1>
                <br></br>

                {/* Custom Autocomplete input */}
                <input
                    type="text"
                    value={inputValue}
                    onChange={handleInputChanges}
                    placeholder="Search your variant"
                    className="w-full border-2 border-gray-300 p-2 rounded-lg" // Updated styling for the input
                />
                {filteredOptions.length > 0 && (
                    <div className="options-container border border-gray-300 rounded-lg">
                        {filteredOptions.map((variant, index) => (
                            <div
                                key={index}
                                className="option p-2 hover:bg-gray-100 cursor-pointer"
                                onClick={() => handleOptionClick(variant)}
                            >
                                {variant.label}
                            </div>
                        ))}
                    </div>
                )}

                <br />

                {/* Display Selected Variants */}

                <div className="flex gap-10 mr-6 justify-end mt-3">
                    <strong>Quantity</strong>
                    <strong>Currency</strong>
                    <strong>Price</strong>
                </div>


                {selectedVariants.map((variant, index) => (
                    <div key={index} className="flex items-center my-5 bg-gray-100 p-2 rounded-lg relative">
                        <span className="flex-grow">{variant.name}</span>
                        <input
                            type="number"
                            value={formData.variants.find(v => v.name === variant.name)?.quantity || ''}
                            onChange={(e) => handleVariantChange(variant.name, 'quantity', e.target.value)}
                            className="w-20 h-8 text-center border-2 border-gray-300 rounded focus:outline-none mx-2"
                            min="0"
                        />
                         <input
                            type="text"
                            value={formData.variants.find(v => v.name === variant.name)?.currency || ''}
                            onChange={(e) => handleVariantChange(variant.name, 'currency', e.target.value)}
                            className="w-20 h-8 text-center border-2 border-gray-300 rounded focus:outline-none mx-2"
                            min="0"
                        />
                        <input
                            type="number"
                            value={formData.variants.find(v => v.name === variant.name)?.price || ''}
                            onChange={(e) => handleVariantChange(variant.name, 'price', e.target.value)}
                            className="w-20 h-8 text-center border-2 border-gray-300 rounded focus:outline-none"
                            min="0"
                        />
                        {/* Minus Icon for removing the variant, using Tailwind CSS for styling */}
                        <span
                            onClick={() => handleRemoveVariant(variant.name)}
                            className="absolute right-0 top-[-8px] cursor-pointer text-red-500 hover:text-red-700 text-lg">
                            <svg width="14px" height="14px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM15.75 12C15.75 12.4142 15.4142 12.75 15 12.75H9C8.58579 12.75 8.25 12.4142 8.25 12C8.25 11.5858 8.58579 11.25 9 11.25H15C15.4142 11.25 15.75 11.5858 15.75 12Z" fill="#ff0000"></path> </g></svg>
                        </span>
                    </div>
                ))}




                {/* Previous and Cancel buttons */}

                <div className="flex justify-end mt-4">
                    <button
                        type="button"
                        onClick={closePopup}
                        className="bg-gray-200 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-300 mr-2"
                    >
                        Cancel
                    </button>
                    <button onClick={() => setStep(2)}
                        type="button"
                        className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
                    >
                        Next
                    </button>
                </div>

            </div>

        );
    };


    return (
        <>
            <BeyoboNavbar></BeyoboNavbar>
            <div className="mx-5">
                <div className="flex p-4">
                    <p className="mt-3 text-2xl font-black font-bold "> Shipments </p>
                </div>

                <div className="right-4 absolute z-50" style={{ marginTop: '-65px' }}>
                    <button
                        onClick={openPopup}
                        className="bg-brand-300 hover:bg-brand-400 text-white font-bold py-2 px-4 rounded flex items-center mt-4"
                    >
                        + Add Shipment
                    </button>
                </div>



                <ShipmentSidenav></ShipmentSidenav>

            </div>


            <div className="flex flex-col items-center justify-center h-[600px]">


                {isPopupOpen && (
                    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
                        <div className="relative top-20 mx-auto p-5 py-[30px] border shadow-lg rounded-md bg-white w-1/3">

                            <div className="absolute top-0 right-0 pt-4 pr-4">
                                <button
                                    onClick={closePopup}
                                    className="px-4 py-2 bg-gray-300 text-gray-900 rounded hover:bg-gray-400"
                                >
                                    X
                                </button>
                            </div>

                            <div className="mt-3">

                                <div className="mt-2">
                                    <div>
                                        {/* ... Your existing popup/modal code ... */}
                                        {isPopupOpen && (
                                            <div>
                                                {/* ... Your existing modal content ... */}
                                                <div>
                                                    {step === 1 && renderStepOne()}
                                                    {step === 2 && renderStepTwo()}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                )}


                {/* Success Modal */}
                {showSuccessModal && (
                    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
                        <div className="relative top-20 mx-auto p-5 border w-1/2 shadow-lg rounded-md bg-white">
                            <div className="text-center">
                                <h3 className="mb-4 text-lg font-bold text-green-500">Success!</h3>
                                <p className="mb-4">Your shipment details have been successfully submitted.</p>
                                <button
                                    onClick={() => setShowSuccessModal(false)} // This closes the success modal
                                    className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700"
                                >
                                    OK
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};


export default NoShipments;




//{/* {selectedVariants.map((variant, index) => (
//                    <div key={index} className="flex items-center my-5 bg-gray-100 p-2 rounded-lg">
//                        <input
//                            type="checkbox"
//                            id="name"
//                            checked={variant.isChecked}
//                            onChange={() => handleCheckboxChange(index)}
//                            className="mr-2 h-5 w-5"
//                        />
//                        <label htmlFor= "name" className="mr-2 flex-grow">
//                            {variant.name}
//                        </label>

//                        <div className="flex gap-3">
//<input
//                            type="number"
//                            id="ordered_quantity"
//                            name="ordered_quantity"
//                            value={variant.quantity}
//                            onChange={(e) => {
//                                const newValue = e.target.value === '' ? 0 : parseInt(e.target.value);
//                                handleQuantityChange(index, newValue);
//                            }}
//                            className="w-20 h-8 text-center border-2 border-gray-300 rounded-lg focus:outline-none"
//                            min="0"
//                        />
//                        <input
//                            type="number"
//                            id="price"
//                            name="price"
//                            value={variant.price}
//                            onChange={(e) => {
//                                const newValue = e.target.value === '' ? 0 : parseInt(e.target.value);
//                                handlePriceChange(index, newValue);
//                            }}
//                            className="w-20 h-8 text-center border-2 border-gray-300 rounded-lg focus:outline-none"
//                            min="0"
//                        />
//                        </div>

//                    </div>
//                ))} */}





//{/*<div>*/ }
//{/*    <label className="block my-2" htmlFor="name">Delivery Address *</label>*/ }
//{/*    <input*/ }
//{/*        type="text"*/ }
//{/*        id="delivery_address"*/ }
//{/*        name="delivery_address"*/ }
//{/*        onChange={handleInputChange}*/ }
//{/*        className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"*/ }
//{/*        placeholder="Enter Delivery Address"*/ }
//{/*    />*/ }
//{/*</div>*/ }


//{/*<div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">*/ }

//{/*    <div>*/ }
//{/*        <label className="block mb-2" htmlFor="name">Freight Charges *</label>*/ }
//{/*        <input*/ }
//{/*            type="number"*/ }
//{/*            id="freight_charge"*/ }
//{/*            name="freight_charge"*/ }
//{/*            onChange={handleInputChange}*/ }
//{/*            required*/ }
//{/*            className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"*/ }
//{/*            placeholder="41244"*/ }
//{/*        />*/ }
//{/*    </div>*/ }
//{/*</div>*/ }



//{/*<Autocomplete*/ }
//{/*    disablePortal*/ }
//{/*    id="combo-box-demo"*/ }
//{/*    options={shipmentVariants} // Ensure this is the correct state variable*/ }
//{/*    getOptionLabel={(option) => option.label || ''} // Optional: Define how to get labels from options*/ }
//{/*    renderInput={(params) => <TextField {...params} label="Enter product name" />}*/ }
//{/*/>*/ }




//{/*<div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">*/ }
//{/*    <div>*/ }
//{/*        <label className="block mb-2" htmlFor="name">Supplier Currency *</label>*/ }
//{/*        <input*/ }
//{/*            type="text"*/ }
//{/*            id="supplier_currency"*/ }
//{/*            name="supplier_currency"*/ }
//{/*            onChange={handleInputChange}*/ }
//{/*            className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"*/ }
//{/*            placeholder="RMB"*/ }
//{/*        />*/ }
//{/*    </div>*/ }

//{/*    <div>*/ }
//{/*        <label className="block mb-2" htmlFor="name">Receiver Currency *</label>*/ }
//{/*        <input*/ }
//{/*            type="text"*/ }
//{/*            id="receiver_currency"*/ }
//{/*            name="receiver_currency"*/ }
//{/*            onChange={handleInputChange}*/ }
//{/*            className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"*/ }
//{/*            placeholder="INR"*/ }
//{/*        />*/ }
//{/*    </div>*/ }
//{/*</div>*/ }




//{
//    shipmentVariants.map((variant, index) => (
//        <div key={index} className="flex items-center mb-6 bg-gray-100 p-4 rounded-lg shadow">
//            <input
//                type="checkbox"
//                id={`variant-${variant.name}`}
//                checked={variant.isChecked}
//                name="name"
//                onChange={() => handleCheckboxChange(index)}
//                className="mr-4 h-5 w-5"
//            />
//            <label htmlFor={`variant-${variant.name}`} className="flex-1 text-lg font-medium mr-6">
//                {variant.name}
//            </label>
//            <input
//                type="number"
//                id={`ordered_quantity-${variant.name}`}
//                name="ordered_quantity"

//                value={variant.quantity}
//                onChange={(e) => {
//                    const newValue = e.target.value === '' ? 0 : parseInt(e.target.value);
//                    handleQuantityChange(index, newValue);
//                }}
//                className="w-20 h-8 text-center border-2 border-gray-300 rounded-lg focus:outline-none"
//                min="0"
//            />
//        </div>
//    ))
//}