import React, { useState, useEffect, useContext } from 'react';
import AuthContext from "context/AuthContext";

const PayoutDetailsSupplierDashboard = () => {
    const { authToken } = useContext(AuthContext);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [submitError, setSubmitError] = useState(false);
    const [companies, setCompanies] = useState([]);

    const [formPayoutData, setFormPayoutData] = useState({
        payout_date: '',
        amount: '',
        currency: '',
        commission: '',
        commission_basis: '',
        narration: '',
        status: '',
        //type:'',
        selectedCompany: '',
    });

    const handlePayoutInputChange = (e) => {
        const { name, value } = e.target;
        setFormPayoutData(prevFormPayoutData => ({
            ...prevFormPayoutData,
            [name]: value
        }));
    };

    const handlePayoutCompanyChange = (event) => {
        setFormPayoutData(prevFormPayoutData => ({
            ...prevFormPayoutData,
            selectedCompany: event.target.value
        }));
    };

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_API_URL}/company/`, {
            headers: {
                'Authorization': `Token ${authToken}`
            }
        })
            .then(response => response.json())
            .then(data => {
                setCompanies(data);
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });
    }, [authToken]);

    const handlePayoutSubmit = async (event) => {
        event.preventDefault();

        let bodyFormData = new FormData();
        bodyFormData.append('company_name', formPayoutData.selectedCompany); // Key to match your backend expectation

        for (let key in formPayoutData) {
            if (key !== 'selectedCompany') { // Exclude the 'selectedCompany' from the FormData
                bodyFormData.append(key, formPayoutData[key]);
            }
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/payout/create_with_company_name/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${authToken}`,
                },
                body: bodyFormData,
            });

            if (response.ok) {
                setSubmitSuccess(true);
                setSubmitError(false);
                // Reset form data if necessary
                setFormPayoutData({
                    payout_date: '',
                    amount: '',
                    currency: '',
                    commission: '',
                    commission_basis: '',
                    narration: '',
                    status: '',
                    //type:'',
                    selectedCompany: '',
                });
            } else {
                setSubmitSuccess(false);
                setSubmitError(true);
            }
        } catch (error) {
            console.error("Failed to submit form", error);
            setSubmitSuccess(false);
            setSubmitError(true);
        }
    };

    return (
        <>
            <div>
                <h2 className="text-lg font-bold mb-3">Add Payout</h2>
                <form className="py-5 px-3" onSubmit={handlePayoutSubmit}>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                        <div className="items-center w-full mb-4">
                            <label htmlFor="company-select" className="block text-sm font-medium text-gray-700">Select Company *</label>
                            <select required id="company-select" value={formPayoutData.selectedCompany} className="mt-1 p-2 border-2 border-gray-300 w-full rounded-lg cursor-pointer" onChange={handlePayoutCompanyChange}>

                                <option value="">Select a Company</option>
                                {companies.map((company) => (
                                    <option value={company.name} key={company.name}>
                                        {company.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                        <div>
                            <label htmlFor="payout-date" className="block text-sm font-medium mb-1 text-gray-700">Payout date *</label>
                            <input
                                type="date"
                                id="country"
                                name="payout_date"
                                value={formPayoutData.payout_date}
                                
                                onChange={handlePayoutInputChange}
                                className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                placeholder="Enter payment date"
                            />
                        </div>

                        <div>
                            <label htmlFor="amount" className="block text-sm mb-1 font-medium text-gray-700">Amount *</label>
                            <input
                                type="number"
                                id="name"
                                name="amount"
                                value={formPayoutData.amount}
                                onChange={handlePayoutInputChange}
                                
                                className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                placeholder="Enter amount"
                            />
                        </div>

                        <div>
                            <label htmlFor="currency" className="block text-sm mb-1 font-medium text-gray-700">Currency *</label>
                            <input
                                type="text"
                                id="name"
                                name="currency"
                                value={formPayoutData.currency}
                                onChange={handlePayoutInputChange}
                                
                                className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                placeholder="Enter currency"
                            />
                        </div>

                        <div>
                            <label htmlFor="commission" className="block text-sm font-medium mb-1 text-gray-700">Commission</label>
                            <input
                                type="number"
                                id="country"
                                name="commission"
                                value={formPayoutData.commission}
                                onChange={handlePayoutInputChange}
                                className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                placeholder="Enter Commission"
                            />
                        </div>

                        <div>
                            <label htmlFor="commission_basis" className="block text-sm font-medium mb-1 text-gray-700">Commission Basis *</label>
                            <input
                                type="text"
                                id="country"
                                name="commission_basis"
                                value={formPayoutData.commission_basis}
                                onChange={handlePayoutInputChange}
                                
                                className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                placeholder="Enter Commission Basis"
                            />
                        </div>

                        <div>
                            <label htmlFor="narration" className="block text-sm font-medium mb-1 text-gray-700">Narration</label>
                            <input
                                type="text"
                                id="country"
                                name="narration"
                                value={formPayoutData.narration}
                                onChange={handlePayoutInputChange}
                                className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                placeholder="Enter Narration"
                            />
                        </div>

                        {/*Status Dropdown Credited, Debited, Pending*/}
                        <div>
                            <label htmlFor="status" className="block text-sm font-medium mb-1 text-gray-700">Status</label>
                            <input
                                type="text"
                                id="country"
                                name="status"
                                value={formPayoutData.status}
                                onChange={handlePayoutInputChange}
                                className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                placeholder="Enter Status"
                            />
                        </div>

                    </div>

                    <div className="flex justify-end mt-4">
                        <button
                            type="submit"
                            className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
                        >
                            Add Payout
                        </button>
                    </div>
                </form>
                {submitSuccess && <div>Your form has been submitted successfully!</div>}
                {submitError && <div>Error submitting form. Please try again.</div>}
            </div>
        </>
    );
};

export default PayoutDetailsSupplierDashboard;

