
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter'
import moment from 'moment'
import React, { useState, useEffect, useContext, useCallback } from 'react';
import CustomTable from '../../../../components/customTable';
import DataModal from '../../../../components/modal';
import AuthContext from '../../../../context/AuthContext';

const AdminPayoutDataGrid = () => {

    // Existing states and functions...
    const [selectedCurrency, setSelectedCurrency] = useState('INR');
    // State for exchange rates
    const [exchangeRates, setExchangeRates] = useState({
        USD: 1, // Base rate, assume 1 USD = 1 USD
        INR: 0, // Indian Rupee
        RMB: 0, // Chinese Yuan
        Korean: 0, // South Korean Won
        Japanese: 0, // Japanese Yen
    });

    useEffect(() => {
        // Fetch the latest exchange rates
        const fetchExchangeRates = async () => {
            try {
                const response = await fetch('https://api.exchangerate-api.com/v4/latest/INR');
                const data = await response.json();

                // Assuming the API returns rates in the format { rates: { INR: 74.57, ... } }
                setExchangeRates(prevRates => ({
                    ...prevRates,
                    USD: data.rates['USD'],
                    RMB: data.rates['CNY'], // Often the Chinese Yuan is represented as CNY
                    Korean: data.rates['KRW'],
                    Japanese: data.rates['JPY'],
                }));
            } catch (error) {
                console.error('Error fetching exchange rates:', error);
                // Handle errors here, possibly retrying the fetch or displaying an error message
            }
        };

        fetchExchangeRates();

        // Set up an interval to fetch the exchange rates regularly
        const intervalId = setInterval(fetchExchangeRates, 3600000); // Update every hour

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);


    const currencySymbols = {
        USD: ' $ ',
        INR: ' ₹ ',
        RMB: ' ¥ ',
        Korean: ' ₩ ',
        Japanese: ' ¥ ',
    };

    // Modify formatCurrency to include dynamic currency symbols
    function formatCurrency(value, currency) {
        let rate = exchangeRates[currency] || 1; // Fallback to 1 if currency not found
        let convertedValue = value * rate;
        let formattedValue = Number.isInteger(convertedValue) ?
            convertedValue.toLocaleString('en-IN') :
            convertedValue.toFixed(2);

        // Use the currency symbol based on the selected currency
        return `${currencySymbols[currency] || '₹'}${formattedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    }

    // Helper function to update the currency for each item in the grid data
    const updateCurrencyData = (data, newCurrency) => {
        return data.map(item => {
            return {
                ...item,
                currency: newCurrency, // Update the currency field to the new currency
                amount: formatCurrency(item.amount, newCurrency), // You might need to adjust this if 'amount' holds the original amount in base currency
            };
        });
    };

    // useEffect to update the grid data currency when selected currency changes
    useEffect(() => {
        // Load your original grid data here if it's not already loaded

        // Now, update the currency data within the grid data
        const updatedData = updateCurrencyData(gridData, selectedCurrency);
        setGridData(updatedData);
    }, [selectedCurrency]);



    const columns = [
        { header: 'ID', key: 'id' },
        { header: 'Company ID', key: 'company_id' },
        { header: 'Date', key: 'payout_date' },
        { header: `Amount (${currencySymbols[selectedCurrency]})`, key: 'amount' },
        { header: 'Narration', key: 'narration' },
        { header: 'Commission', key: 'commission' },
        { header: 'Commission Basis', key: 'commission_basis' },
        { header: 'Status', key: 'status' },
    ];


    //const columns = [
    //    {name:'id',defaultFlex:1,header:'ID',type:'number',},
    //    {name:'company_id',defaultFlex:1,header:'Company ID',type:'number',},
    //    {
    //        name: 'payout_date',
    //        header: 'Date',
    //        defaultFlex: 1,
    //        filterEditorProps: (props, { index }) => {
    //            return {
    //                dateFormat: 'DD-MM-YYYY', // Updated date format
    //                cancelButton: false,
    //                highlightWeekends: false,
    //                placeholder: index == 1 ? 'Created date is before...' : 'Created date is after...'
    //            }
    //        },
    //        render: ({ value, cellProps }) => {
    //            return moment(value).format('DD-MM-YYYY') // Updated to match ShipmentDataGrid format
    //        }
    //    },
    //    {
    //        name: 'amount',
    //        defaultFlex: 1,
    //        header: `Amount (${currencySymbols[selectedCurrency]})`,
    //        type: 'number',
    //        filterEditor: NumberFilter,
    //        render: ({ value }) => formatCurrency(value, selectedCurrency)
    //    },
    //    //{
    //    //    name: 'currency',
    //    //    defaultFlex: 1,
    //    //    header: `Currency (${selectedCurrency})`,
    //    //    type: 'string',
    //    //    // Update other properties as necessary
    //    //},
    //    { name: 'narration', defaultFlex: 1, header: 'Narration', type: 'string' },
    //    //{ name: 'sku_id', defaultFlex: 1, header: 'Sku Id', type: 'number', filterEditor: NumberFilter },
    //    { name: 'commission', defaultFlex: 1, header: 'Commission', type: 'number', filterEditor: NumberFilter },
    //    { name: 'commission_basis', defaultFlex: 1, header: 'Commission Basis', type: 'string' },

    //    {
    //        name: 'status', header: 'Status', defaultFlex: 1
    //        //header: 'Age',
    //        //defaultWidth: 80,
    //        //render: ({ value }) => <span style={{ color: value < 30 ? 'lightgreen' : 'inherit' }}>{value}</span>
    //    },

    //];



    //const defaultfilter = [
    //    {name:'id',operator:'gte',type:'number',value:0},
    //    {name:'company_id',operator:'gte',type:'number',value:0},
    //    { name: 'payout_date', operator: 'before', type: 'date', value: '' },
    //    { name: 'amount', operator: 'gte', type: 'number', value: 0 },
    //    /*{ name: 'currency', operator: 'contains', type: 'string', value: '' },*/
    //    { name: 'narration', operator: 'contains', type: 'string', value: '' },
    //    //{ name: 'sku_id', operator: 'gte', type: 'number' ,value: 0},

    //    { name: 'commission', operator: 'gte', type: 'number', value: 0 },

    //    { name: 'commission_basis', operator: 'startsWith', type: 'string', value: '' },
    //    { name: 'status', operator: 'startsWith', type: 'string', value: '' }

    //];



    //const [filterValue, setFilterValue] = useState(defaultfilter);
    const [dataSource, setDataSource] = useState([]);
    const { authToken } = useContext(AuthContext);

    const [gridData, setGridData] = useState([]); // New state for actual data array

    const loadData = useCallback(() => {
        const newDataSource = () => {
            const limit = 50;
            const url = `${process.env.REACT_APP_BASE_API_URL}/payout/complete_list/` + '?limit=' + limit;
            return fetch(url, {
                headers: {
                    'Authorization': `Token ${authToken}`
                }
            })
                .then(response => response.json())
                .then(data => {
                    const formattedData = data.map(item => ({
                        ...item,
                        order_date: moment(item.order_date).format('DD-MM-YYYY')
                    }));
                    setGridData(formattedData); // Update gridData with the actual data array
                    return { data: formattedData }; // Return the data for dataSource
                });
        };

        setDataSource(newDataSource); // Keep as it was, setting a function
    }, [authToken]);

    useEffect(() => {
        loadData();
    }, [loadData]); // Ensure loadData is stable and doesn't change unless necessary



    const gridStyle = { minHeight: 550 }


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);

    const onRowClick = useCallback((rowProps) => {
        setSelectedRowData(rowProps.data);
        setIsModalOpen(true);
    }, []);



    const exportCSV = () => {
        if (!Array.isArray(gridData)) {
            console.error('Grid data is not an array');
            return;
        }

        // Process the data to format it for CSV
        const rows = gridData.map((data) =>
            columns.map((col) => {
                // Handle any necessary formatting per column
                let cellData = data[col.name];
                if (col.name === 'SaleAmount') {
                    // Remove the `₹` symbol for CSV export
                    cellData = formatCurrency(cellData).replace(/₹/g, '');
                }
                if (col.render) {
                    // If there's a custom render, use it for CSV data
                    const rendered = col.render({ value: cellData });
                    if (React.isValidElement(rendered)) {
                        // If rendered content is a React element, extract text content
                        cellData = rendered.props.children;
                    } else {
                        cellData = rendered;
                    }
                }
                return `"${cellData?.toString().replace(/"/g, '""') || ''}"`; // Escape quotes
            }).join(',')
        );

        const header = columns.map((col) => `"${col.header.replace(/"/g, '""')}"`).join(',');
        /*const csvContent = [header].concat(rows).join('\n');*/
        const BOM = "\uFEFF";
        const csvContent = BOM + [header].concat(rows).join('\n');
        // Create a Blob for the CSV content
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        // Create a temporary link to trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.download = 'payout-grid-data.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div>
            <CustomTable data={gridData} columns={columns} onRowClick={() => { }} />

            {/*<div className="flex justify-between items-center my-3">*/}
            {/*    <div className="flex gap-5 items-center">*/}
            {/*        <div>*/}
            {/*            <label htmlFor="start-date" className="block text-sm font-medium text-gray-700">Select Currency</label>*/}

            {/*            <select*/}
            {/*                value={selectedCurrency} onChange={e => setSelectedCurrency(e.target.value)}*/}
            {/*                className="mt-2 w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"*/}
            {/*            >*/}
            {/*                <option value="USD">USD</option>*/}
            {/*                <option value="INR">INR</option>*/}
            {/*                <option value="RMB">RMB</option>*/}
            {/*                <option value="Korean">KRW</option>*/}
            {/*                <option value="Japanese">JPY</option>*/}
            {/*            </select>*/}
            {/*            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">*/}
            {/*                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>*/}
            {/*            </div>*/}
            {/*        </div>*/}


            {/*        */}{/* <div className="flex gap-4 items-center">*/}{/*
            */}{/*            <div>*/}{/*
            */}{/*                <label htmlFor="start-date" className="block text-sm font-medium text-gray-700">Start Date</label>*/}{/*
            */}{/*                <input*/}{/*
            */}{/*                    type="date"*/}{/*
            */}{/*                    id="start-date"*/}{/*
            */}{/*                    name="start-date"*/}{/*
            */}{/*                    className="mt-1 p-2 border-2 border-gray-300 rounded-lg cursor-pointer"*/}{/*
            */}{/*                />*/}{/*
            */}{/*            </div>*/}{/*
            */}{/*            <div>*/}{/*
            */}{/*                <label htmlFor="end-date" className="block text-sm font-medium text-gray-700">End Date</label>*/}{/*
            */}{/*                <input*/}{/*
            */}{/*                    type="date"*/}{/*
            */}{/*                    id="end-date"*/}{/*
            */}{/*                    name="end-date"*/}{/*
            */}{/*                    className="mt-1 p-2 border-2 border-gray-300 rounded-lg cursor-pointer"*/}{/*
            */}{/*                />*/}{/*
            */}{/*            </div>*/}{/*
            */}{/*        </div> */}
            {/*    </div>*/}


            {/*    <div className="right-4 z-50 my-3">*/}
            {/*        <button onClick={exportCSV}*/}
            {/*            className="bg-brand-300 hover:bg-brand-400 text-white font-bold py-2 px-4 rounded flex items-center mt-4"*/}
            {/*        >*/}
            {/*            Download*/}
            {/*        </button>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*<ReactDataGrid*/}
            {/*    style={gridStyle}*/}
            {/*    idProperty="id"*/}
            {/*    pagination='local'*/}
            {/*    columns={columns}*/}
            {/*    dataSource={dataSource}*/}
            {/*    defaultLimit={50}*/}
                
            {/*    onRowClick={onRowClick}*/}
            {/*/>*/}

            {/*defaultFilterValue={defaultfilter}*/}
            {/*onFilterValueChange={setFilterValue}*/}

            {/*<DataModal*/}
            {/*    isOpen={isModalOpen}*/}
            {/*    onClose={() => setIsModalOpen(false)}*/}
            {/*    data={selectedRowData}*/}
            {/*    columns={columns}*/}
            {/*/>*/}
        </div>
    )
};


export default AdminPayoutDataGrid;