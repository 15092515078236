import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";

function FinancialData() {
  const [data, setData] = useState([]);
  const [financialYear, setFinancialYear] = useState("all");
  const { authToken } = useContext(AuthContext);

  useEffect(() => {
    const fetchFinancialData = async () => {
      const queryParams =
        financialYear !== "all" ? `?financial_year=${financialYear}` : "";
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/financial/${queryParams}`,
        {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      );
      if (response.ok) {
        const jsonData = await response.json();
        setData(jsonData);
      }
    };
    fetchFinancialData();
  }, [authToken, financialYear]);

  // Function to format numbers according to Indian numbering system
  const formatNumber = (number) => {
    // Check if the input is a number
    if (!isNaN(parseFloat(number)) && isFinite(number)) {
      return new Intl.NumberFormat("en-IN").format(number);
    }
    // Return the input as-is if it's not a number
    return number;
  };

  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse", // Ensures that border lines are neatly collapsed into a single line when they meet
    marginTop: "20px",
    border: "1px solid #ddd", // Adds an outer border to the table for completeness
  };

  const thStyle = {
    borderBottom: "2px solid #ddd", // Adds a bottom border to each header cell
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#f2f2f2",
    fontWeight: "bold",
    borderRight: "1px solid #ddd", // Adds a right border to separate columns
  };

  const tdStyle = {
    borderBottom: "1px solid #ddd", // Adds a bottom border to each data cell
    padding: "8px",
    borderRight: "1px solid #ddd", // Adds a right border to separate columns
  };

  const selectStyle = {
    padding: "10px",
    marginBottom: "20px",
    cursor: "pointer",
  };

  return (
    <div className="page-header">
      <select
        style={selectStyle}
        value={financialYear}
        onChange={(e) => setFinancialYear(e.target.value)}
      >
        <option value="all">All Years</option>
        <option value="2023">2023</option>
        <option value="2022">2022</option>
      </select>
      <div style={{ overflowX: "auto", width: "100%" }}>
        <table style={tableStyle}>
          <tbody>
            {data.length > 0 &&
              Object.keys(data[0]).map((key, index) => (
                <tr key={index}>
                  <th style={thStyle}>{key}</th>
                  {data.map((row, idx) => (
                    <td style={tdStyle} key={idx}>
                      {/* Check if the column title suggests a percentage value */}
                      {key.includes("%")
                        ? `${formatNumber(row[key])}%`
                        : formatNumber(row[key])}
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default FinancialData;
