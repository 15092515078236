/*import { FcGoogle } from "react-icons/fc";*/

export default function Onboarding() {
    return (
        <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">

            <div className="w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
                <img className="h-auto w-[108px]" src={'https://media.licdn.com/dms/image/D4D0BAQFqFAUkiXkfEA/company-logo_200_200/0/1690444337691?e=2147483647&v=beta&t=EyyY89lbCTDKr0dgfgPUhBn6e7QLeg2Mq8X-58aJtEo'} alt="beyobo logo" />
                
                <button className="linear mt-2 w-full rounded-full bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                    Log In
                </button>
                <div className="mt-4 text-center">
                    <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
                        Don't have an account?
                    </span>
                    <a
                        href=" "
                        className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                    >
                        Register now
                    </a>
                </div>


                <div className="mt-4 flex justify-between">
                    <p><a
                        href=" "
                        className="ml-1 text-sm font-medium dark:text-white" style={{ color: "#0083ff" }}>
                        Terms of use
                    </a></p>
                    <p>
                        <a
                        href=" "
                            className="ml-1 text-sm font-medium dark:text-white" style={{ color: "#0083ff" }}>
                        Privacy Policy
                    </a></p>

                </div>
            </div>
        </div>
    );
}
