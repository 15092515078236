import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import AuthContext from "context/AuthContext";
import * as XLSX from 'xlsx';
import excelTemplate from '../../../assets/documents/channel-bulk-upload.xlsx';
import { useToast } from '../../../components/toast';
import { DialogContext } from '../../../components/dialog';



const AccountForm = () => {
    const { authToken } = useContext(AuthContext);
    const [formAccountData, setFormAccountData] = useState({
        channel: '',
        name: '',
        email_id: '',
    });

    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [submitError, setSubmitError] = useState(false);
    const { toastSuccess, toastError } = useToast();

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormAccountData(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleAccountSubmit = async (event) => {
        event.preventDefault();
        let bodyFormData = new FormData();
        Object.entries(formAccountData).forEach(([key, value]) => {
            bodyFormData.append(key, value);
        });

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/account/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${authToken}`
                },
                body: bodyFormData,
            });

            if (!response.ok) {
                const errorData = await response.json();
                const errorMessage = errorData.errors ? errorData.errors.map(error => error.message).join(", ") : errorData.message || "Unknown error";
                toastError(`Submission failed: ${errorMessage}`);
                setSubmitError(true);
                setSubmitSuccess(false);
            } else {
                toastSuccess("Channel submission successful!");
                setSubmitSuccess(true);
                setSubmitError(false);
                setFormAccountData({
                    channel: '',
                    name: '',
                    email_id: '',
                });
            }
        } catch (error) {
            toastError(`Submission failed: ${error.toString()}`);
            setSubmitSuccess(false);
            setSubmitError(true);
        }
    };

    return (
        <div>
            <form onSubmit={handleAccountSubmit}>
                <div className="justify-between items-center mb-6">
                    {/* Form fields here */}
                    <div className="items-center my-4">
                        <label htmlFor="channel" className="block text-sm font-medium text-gray-700">Channel *</label>
                        <input
                            type="text"
                            id="channel"
                            name="channel"
                            required
                            value={formAccountData.channel}
                            onChange={handleInputChange}
                            className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                            placeholder="Enter Channel"
                        />
                    </div>

                    <div className="items-center my-4">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name *</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            required
                            value={formAccountData.name}
                            onChange={handleInputChange}
                            className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                            placeholder="Enter name"
                        />
                    </div>

                    <div className="items-center my-4">
                        <label htmlFor="email_id" className="block text-sm font-medium text-gray-700">Email ID *</label>
                        <input
                            type="text"
                            id="email_id"
                            name="email_id"
                            required
                            value={formAccountData.email_id}
                            onChange={handleInputChange}
                            className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                            placeholder="Enter Email ID"
                        />
                    </div>
                </div>
                <div className="flex justify-end mt-4">
                    <button type="submit" className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700">
                        Add Account
                    </button>
                </div>
            </form>
            {submitSuccess && <div className="text-green-600">Your form has been submitted successfully!</div>}
            {submitError && <div className="text-red-600">Error submitting form. Please try again.</div>}
        </div>
    );
};



const SkuForm = () => {
    const { authToken } = useContext(AuthContext);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [submitError, setSubmitError] = useState(false);
    const { toastSuccess, toastError } = useToast();

    // State for storing the list of companies fetched from the API
    const [companies, setCompanies] = useState([]);

    //const [channels, setChannels] = useState([]);


    // Fetch companies from the API when the component mounts


    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_API_URL}/company/`, {
            headers: {
                'Authorization': `Token ${authToken}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setCompanies(data); // Assuming the API returns an array of products
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });
    }, []);

    const handleSkuCompanyChange = (event) => {
        const selectedCompany = event.target.value;
        setFormSkuData(prevFormSkuData => ({
            ...prevFormSkuData,
            selectedCompany,
        }));
        if (selectedCompany) {
            //fetchChannelsForCompany(selectedCompany);
            fetchVariantsForCompany(selectedCompany);
        } else {
            // setChannels([]);
            setShipmentVariants([]);
        }
    };


    const [formSkuData, setFormSkuData] = useState({
        name: '',
        sku_code: '',
        selectedCompany: '',

        selectedVariants: [],
        //selectedChannel: '',
    });



    const handleSkuInputChange = (event) => {
        const { name, value } = event.target;
        setFormSkuData(prevFormSkuData => ({
            ...prevFormSkuData,
            [name]: value,
        }));
    };


    const [shipmentVariants, setShipmentVariants] = useState([]);
    const fetchVariantsForCompany = (companyName) => {
        fetch(`${process.env.REACT_APP_BASE_API_URL}/variantname/getVariantsForCompany/?company_name=${encodeURIComponent(companyName)}`, {
            headers: {
                'Authorization': `Token ${authToken}`,
            }
        })
            .then(response => {
                if (!response.ok) {
                    toastError('Network response was not ok');
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                const variantsWithLabel = data.map(variant => ({
                    ...variant,
                    label: variant.name // Adjust this based on your actual data structure
                }));
                setShipmentVariants(variantsWithLabel);
            })
            .catch(error => {
                toastError('Error fetching variants:', error);
                //console.error('Error fetching variants:', error);
                setShipmentVariants([]);
            });
    };


    //const handleVariantClick = (clickedVariant) => {
    //    const variantExists = formSkuData.selectedVariants.some(variant => variant.id === clickedVariant.id);

    //    if (variantExists) {
    //        setFormSkuData(prevFormSkuData => {
    //            const updatedVariants = prevFormSkuData.selectedVariants.filter(variant => variant.id !== clickedVariant.id);
    //            return { ...prevFormSkuData, selectedVariants: updatedVariants };
    //        });
    //    } else {
    //        setFormSkuData(prevFormSkuData => {
    //            const updatedVariants = [...prevFormSkuData.selectedVariants, { ...clickedVariant, quantity: 1 }]; // Initialize quantity here
    //            return { ...prevFormSkuData, selectedVariants: updatedVariants };
    //        });
    //    }
    //};



    const [searchQuery, setSearchQuery] = useState(""); // For capturing search input

    // Existing useEffect and other methods...

    const handleVariantClick = (clickedVariant) => {
        const variantExists = formSkuData.selectedVariants.some(variant => variant.id === clickedVariant.id);
        if (variantExists) {
            const updatedVariants = formSkuData.selectedVariants.filter(variant => variant.id !== clickedVariant.id);
            setFormSkuData(prevFormSkuData => ({
                ...prevFormSkuData,
                selectedVariants: updatedVariants
            }));
        } else {
            const updatedVariants = [...formSkuData.selectedVariants, { ...clickedVariant, quantity: 1 }];
            setFormSkuData(prevFormSkuData => ({
                ...prevFormSkuData,
                selectedVariants: updatedVariants
            }));
        }
    };

    const removeVariant = (variantId) => {
        const updatedVariants = formSkuData.selectedVariants.filter(variant => variant.id !== variantId);
        setFormSkuData(prevFormSkuData => ({
            ...prevFormSkuData,
            selectedVariants: updatedVariants
        }));
    };

    // Filter variants based on search input
    const filteredVariants = shipmentVariants.filter(variant =>
        variant.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleSkuSubmit = async (event) => {
        event.preventDefault();

        let bodyFormData = new FormData();
        // Append form data as before
        Object.entries(formSkuData).forEach(([key, value]) => {
            if (key === 'selectedVariants') {
                bodyFormData.append(key, JSON.stringify(value));
            } else {
                bodyFormData.append(key, value);
            }
        });

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/sku/`, {
                method: 'POST',
                headers: { 'Authorization': `Token ${authToken}` },
                body: bodyFormData,
            });

            if (response.ok) {
                toastSuccess("Your form has been submitted successfully!");
                setSubmitSuccess(true);
                setSubmitError(false);
                // Reset form state here if necessary
            } else {
                toastError('Submission failed');
                setSubmitSuccess(false);
                setSubmitError(true);
                /*throw new Error('Submission failed');*/
            }
        } catch (error) {
            /*console.error("Failed to submit form", error);*/
            toastError("Failed to submit form", error);
            setSubmitSuccess(false);
            setSubmitError(true);
        }
    };

    return (
        <>
            <form id="catalogue_variant" onSubmit={handleSkuSubmit}>


                <div className="items-center w-full mb-4">
                    <label htmlFor="company-select" className="block text-sm font-medium text-gray-700">Select Company *</label>
                    <select required id="company-select" value={formSkuData.selectedCompany} className="mt-1 p-2 border-2 border-gray-300 w-full rounded-lg cursor-pointer" onChange={handleSkuCompanyChange}>

                        <option value="">Select a Company</option>
                        {companies.map((company) => (
                            <option value={company.name} key={company.name}>
                                {company.name}
                            </option>
                        ))}
                    </select>
                </div>

                {/* Company select, SKU name, and code inputs... */}

                {/*<div className="mb-4">*/}
                {/*    <div className="flex items-center justify-between">*/}
                {/*        <label className="block text-sm font-medium text-gray-700">Select Variant *</label>*/}
                {/*        */}{/*<button onClick={openVariantFormDialog} className="bg-gray-500 hover:bg-brand-400 text-white font-bold py-1 px-2 rounded">*/}
                {/*        */}{/*    Add Variant*/}
                {/*        */}{/*</button>*/}
                {/*    </div>*/}

                {/*    <div className="mt-1 border-2 border-gray-300 w-full rounded-lg">*/}
                {/*        {shipmentVariants.map(variant => (*/}
                {/*            <div*/}
                {/*                key={variant.id}*/}
                {/*                onClick={() => handleVariantClick(variant)}*/}
                {/*                style={{*/}
                {/*                    backgroundColor: formSkuData.selectedVariants.some(v => v.id === variant.id) ? '#efefef' : 'transparent',*/}
                {/*                }}*/}
                {/*                className="p-2 cursor-pointer hover:bg-gray-100"*/}
                {/*            >*/}
                {/*                {variant.name}*/}
                {/*            </div>*/}
                {/*        ))}*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/* Displaying selected variants */}
                {/*<div className="items-center w-full mb-4">*/}
                {/*    <h3 className="block font-medium text-gray-700">Selected Variants:</h3>*/}

                {/*    <div className="flex justify-between mx-[56px] my-3">*/}
                {/*        <p className="block font-medium text-gray-700">Variant Name</p>*/}
                {/*        <p className="block font-medium text-gray-700">Quantity</p>*/}
                {/*    </div>*/}

                {/*    <ul className="block font-medium">*/}
                {/*        {formSkuData.selectedVariants.map((variant, index) => (*/}
                {/*            <li key={index} className="flex items-center justify-between my-2">*/}
                {/*                {variant.name}*/}
                {/*                */}{/* Quantity input field */}
                {/*                <input*/}
                {/*                    type="number"*/}
                {/*                    value={variant.quantity}*/}
                {/*                    onChange={(e) => {*/}
                {/*                        // Create a new array with updated quantities*/}
                {/*                        const updatedVariants = [...formSkuData.selectedVariants];*/}
                {/*                        updatedVariants[index] = { ...variant, quantity: e.target.value };*/}
                {/*                        setFormSkuData(prevFormSkuData => ({*/}
                {/*                            ...prevFormSkuData,*/}
                {/*                            selectedVariants: updatedVariants*/}
                {/*                        }));*/}
                {/*                    }}*/}
                {/*                    className="ml-4 p-1 border border-gray-500 rounded"*/}
                {/*                    min="1" // Prevent negative quantities*/}
                {/*                />*/}
                {/*            </li>*/}
                {/*        ))}*/}
                {/*    </ul>*/}
                {/*</div>*/}

                {/* Company select, SKU name, and code inputs... */}

                <div className="mb-4">
                    <div className="flex items-center justify-between">
                        <label className="block text-sm font-medium text-gray-700">Select Variant *</label>
                    </div>

                    {/* Search input for variants */}
                    <input
                        type="text"
                        placeholder="Search variants..."
                        value={searchQuery}
                        onChange={e => setSearchQuery(e.target.value)}
                        className="mt-1 p-2 border-2 border-gray-300 w-full rounded-lg"
                    />

                    {/* Displaying search results */}
                    <div className="mt-1 border-2 border-gray-300 w-full rounded-lg">
                        {filteredVariants.map(variant => (
                            <div
                                key={variant.id}
                                onClick={() => handleVariantClick(variant)}
                                className="p-2 cursor-pointer hover:bg-gray-100"
                                style={{
                                    backgroundColor: formSkuData.selectedVariants.some(v => v.id === variant.id) ? '#efefef' : 'transparent',
                                }}
                            >
                                {variant.name}
                            </div>
                        ))}
                    </div>
                </div>

                {/* Displaying selected variants with a remove button */}
                <div className="items-center w-full mb-4">
                    <h3 className="block font-medium text-gray-700">Selected Variants:</h3>
                    <ul className="block font-medium">
                        {formSkuData.selectedVariants.map((variant, index) => (
                            <li key={index} className="flex relative items-center justify-between my-2 bg-white p-2 rounded-lg shadow border">
                                <span>{variant.name}</span>
                                <div className="flex items-center">
                                    <input
                                        type="number"
                                        value={variant.quantity}
                                        onChange={(e) => {
                                            const updatedVariants = [...formSkuData.selectedVariants];
                                            updatedVariants[index] = { ...variant, quantity: parseInt(e.target.value, 10) };
                                            setFormSkuData(prevFormSkuData => ({
                                                ...prevFormSkuData,
                                                selectedVariants: updatedVariants
                                            }));
                                        }}
                                        className="ml-4 p-1 border border-gray-500 rounded"
                                        min="1"
                                    />

                                    <span onClick={() => removeVariant(variant.id)} class="absolute right-0 top-[-8px] cursor-pointer text-red-500 hover:text-red-700 text-lg"><svg width="14px" height="14px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM15.75 12C15.75 12.4142 15.4142 12.75 15 12.75H9C8.58579 12.75 8.25 12.4142 8.25 12C8.25 11.5858 8.58579 11.25 9 11.25H15C15.4142 11.25 15.75 11.5858 15.75 12Z" fill="#ff0000">
                                    </path> </g>
                                    </svg>
                                    </span>
                                    {/*<button*/}
                                    {/*    onClick={() => removeVariant(variant.id)}*/}
                                    {/*    className="ml-4 bg-red-500 text-white p-1 rounded-full hover:bg-red-700"*/}
                                    {/*    aria-label="Remove variant"*/}
                                    {/*>*/}
                                    {/*    x*/}
                                    {/*</button>*/}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>




                <div className="items-center w-full mb-4">
                    <label htmlFor="variant_code" className="block text-sm font-medium text-gray-700">Name *</label>
                    <input
                        type="text"
                        id="variant_code"
                        name="name"
                        value={formSkuData.name}
                        onChange={handleSkuInputChange}
                        required
                        className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                        placeholder="Enter the code"
                    />
                </div>

                <div className="items-center w-full mb-4">
                    <label htmlFor="variant_code" className="block text-sm font-medium text-gray-700">Sku code *</label>
                    <input
                        type="text"
                        id="variant_code"
                        name="sku_code"
                        value={formSkuData.sku_code}
                        onChange={handleSkuInputChange}
                        required
                        className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                        placeholder="Enter the code"
                    />
                </div>

                <div className="flex justify-end mt-4">
                    <button
                        type="submit"
                        className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
                    >
                        Add Sku
                    </button>
                </div>
            </form>
            {submitSuccess && <div>Your form has been submitted successfully!</div>}
            {submitError && <div>Error submitting form. Please try again.</div>}
        </>
    );
};





const ChannelSupplierDashboard = () => {

    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [submitError, setSubmitError] = useState(false);
    const { authToken } = useContext(AuthContext);
    const [companies, setCompanies] = useState([]);
    const [skus, setSkus] = useState([]);
    const [selectedVariants, setSelectedVariants] = useState([]);
    const [displayLimit, setDisplayLimit] = useState([]);
    const { toastSuccess, toastError } = useToast();


    const { openDialog } = useContext(DialogContext);

    const openAccountFormDialog = () => {
        openDialog(<AccountForm />, 'medium', true);
    };

    const openSkuFormDialog = () => {
        openDialog(<SkuForm />, 'medium', true);
    };

    const [formChannelData, setFormChannelData] = useState({
        name: '',
        //platform: '',
        selectedChannel: '',
        //oms_channel_id: '',
        marketplace: '',
        selectedCompany: '',
        selectedSkus: [],
        selectedAccount: '',
    });

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_API_URL}/company/`, {
            headers: {
                'Authorization': `Token ${authToken}`
            }
        })
            .then(response => response.json())
            .then(data => {
                setCompanies(data);
            })
            .catch(error => {
                console.error('Error fetching companies:', error);
            });
    }, [authToken]);

    const fetchSkusForCompany = (companyName) => {
        fetch(`${process.env.REACT_APP_BASE_API_URL}/sku/getSkusForCompany/?company_name=${encodeURIComponent(companyName)}`, {
            headers: {
                'Authorization': `Token ${authToken}`,
            }
        })
            .then(response => response.json())
            .then(data => {
                const skusWithLabel = data.map(sku => ({
                    ...sku,
                    label: sku.name // Adjust this based on your actual data structure
                }));
                setSkus(skusWithLabel);
            })
            .catch(error => {
                console.error('Error fetching SKUs:', error);
            });
    };

    //const handleChannelCompanyChange = (event) => {
    //    const selectedCompany = event.target.value;
    //    setFormChannelData(prev => ({
    //        ...prev,
    //        selectedCompany: selectedCompany,
    //    }));
    //    fetchSkusForCompany(selectedCompany);
    //};


    const handleChannelCompanyChange = (event) => {
        const selectedCompany = event.target.value;
        if (!selectedCompany) {
            console.error('No company selected');
            return; // Optionally show an error message or disable fetching until selection
        }

        setFormChannelData(prev => ({
            ...prev,
            selectedCompany: selectedCompany,
        }));

        fetchSkusForCompany(selectedCompany);
        fetchChannelsForCompany(selectedCompany); // Fetch channels for the selected company
    };


    const handleChannelInputChange = (event) => {
        const { name, value } = event.target;
        setFormChannelData(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    //const handleSkuClick = (clickedSku) => {
    //    const isAlreadySelected = formChannelData.selectedSkus.some(sku => sku.id === clickedSku.id);

    //    if (isAlreadySelected) {
    //        // Remove the SKU if it's already selected
    //        setFormChannelData(prev => ({
    //            ...prev,
    //            selectedSkus: prev.selectedSkus.filter(sku => sku.id !== clickedSku.id),
    //        }));
    //    } else {
    //        // Add the SKU, ensuring the 'id' is included
    //        setFormChannelData(prev => ({
    //            ...prev,
    //            selectedSkus: [...prev.selectedSkus, clickedSku],
    //        }));
    //    }
    //};



    const [skuSearch, setSkuSearch] = useState(""); // State for SKU search input

    const handleSkuClick = (clickedSku) => {
        const isAlreadySelected = formChannelData.selectedSkus.some(sku => sku.id === clickedSku.id);

        if (isAlreadySelected) {
            const updatedSkus = formChannelData.selectedSkus.filter(sku => sku.id !== clickedSku.id);
            setFormChannelData(prev => ({
                ...prev,
                selectedSkus: updatedSkus
            }));
        } else {
            const updatedSkus = [...formChannelData.selectedSkus, clickedSku];
            setFormChannelData(prev => ({
                ...prev,
                selectedSkus: updatedSkus
            }));
        }
    };

    const removeSku = (skuId) => {
        const updatedSkus = formChannelData.selectedSkus.filter(sku => sku.id !== skuId);
        setFormChannelData(prev => ({ ...prev, selectedSkus: updatedSkus }));
    };

    const filteredSkus = skus.filter(sku =>
        sku.name.toLowerCase().includes(skuSearch.toLowerCase())
    );



    const handleChannelSubmit = async (event) => {
        event.preventDefault();
        let bodyFormData = new FormData();
    
        // Loop through formChannelData except 'selectedSkus' and append each to FormData
        Object.entries(formChannelData).forEach(([key, value]) => {
            if (key !== 'selectedSkus') {
                bodyFormData.append(key, value);
            } else {
                // Serialize 'selectedSkus' as a JSON string and append
                bodyFormData.append(key, JSON.stringify(value.map(sku => ({
                  ...sku,
                  // Ensure we are correctly handling potential undefined 'id's
                  id: sku.id || '' // Adjust this based on how you want to handle undefined ids
                }))));
            }
        });

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/channels/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${authToken}` // Ensure this matches what your API expects
                },
                body: bodyFormData,
            });

            //if (!response.ok) {
            //    const errorData = await response.json();
            //    throw new Error(`Submission failed: ${errorData.message}`); // More detailed error message
            //}
            //setSubmitSuccess(true);
            //setSubmitError(false);
            //setFormChannelData({
            //    name: '',
            //    platform: '',
            //    oms_channel_id: '',
            //    selectedCompany: '',
            //    selectedSkus: [],
            //});


            if (!response.ok) {
                const errorData = await response.json();
                if (errorData.errors && errorData.errors.length > 0) {
                    const errorMessage = errorData.errors.map(error => error.message).join(", ");
                    toastError(`Submission failed: ${errorMessage}`);
                } else {
                    // Fallback to a general error message if no specific errors are provided
                    toastError(`Submission failed: ${errorData.message || "Unknown error"}`);
                }
                setSubmitError(true);
            } else {
                toastSuccess("Channel submission successful!");
                setSubmitSuccess(true);
                setSubmitError(false);
                setFormChannelData({
                    name: '',
                    //platform: '',
                    selectedChannel: '',
                    /*oms_channel_id: '',*/
                    selectedCompany: '',
                    selectedSkus: [],
                });
            }

        } catch (error) {
            toastError('Submission failed');
            setSubmitSuccess(false);
            setSubmitError(true);
        }
    };



    // Function to update additional information for a selected SKU
    const handleAdditionalInfoChange = (id, value) => {
        setFormChannelData(prev => ({
            ...prev,
            selectedSkus: prev.selectedSkus.map(sku =>
                sku.id === id ? { ...sku, additionalInfo: value } : sku
            ),
        }));
    };


    const handleShowMore = () => {
        setDisplayLimit(currentLimit => currentLimit + 10);
    };

    const handleDropdownClick = (e) => {
        e.stopPropagation(); // This prevents the onBlur event from firing when clicking inside the dropdown
    };




    const [isPopupOpen, setPopupOpen] = useState(false);
    const [step, setStep] = useState(1);
    const [selection, setSelection] = useState('');

    const openPopup = () => {
        setPopupOpen(true);
    };

    const closePopup = () => {
        setPopupOpen(false);
        setStep(1); // Reset the step to 1 when closing the modal.
    };

    //const handleBulkUpload = async () => {
    //    if (!csvFile) {
    //        alert("Please select a file to upload.");
    //        return;
    //    }

    //    let formData = new FormData();
    //    formData.append("file", csvFile); // Ensure the 'file' key matches what your backend expects

    //    try {
    //        const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/channels/create_channelbulk/`, {
    //            method: 'POST',
    //            headers: {
    //                'Authorization': `Token ${authToken}`,
    //                // Note: We're NOT setting 'Content-Type' here. FormData will do it for us, including the boundary parameter.
    //            },
    //            body: formData,
    //        });

    //        const result = await response.json(); // Assuming your server responds with JSON
    //        if (response.ok) {
    //            alert("Bulk upload successful!");
    //            console.log(result); // You might want to do something with the response here
    //            setCsvFile(null); // Resetting the file input if needed
    //            setSubmitSuccess(true);
    //            setSubmitError(false);
    //        } else {
    //            toastError(`Submission failed: ${result.error || result.message || "Unknown error"}`);
    //            setSubmitSuccess(false);
    //            setSubmitError(true);
    //        //    throw new Error(`Submission failed: ${result.error || result.message || "Unknown error"}`);
    //        }
    //    } catch (error) {
    //        //console.error("Bulk upload error:", error);
    //        //alert(error.message);
    //        //setSubmitSuccess(false);
    //        //setSubmitError(true);

    //        toastError("Bulk upload error:", error);
    //        setSubmitSuccess(false);
    //        setSubmitError(true);
    //    }
    //};

    const handleBulkUpload = async () => {
        if (!csvFile) {
            toastError("Please select a file to upload."); // Use toast for consistency
            return;
        }

        let formData = new FormData();
        formData.append("file", csvFile); // Ensure the 'file' key matches what your backend expects

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/channels/create_channelbulk/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${authToken}`,
                    // Note: We're NOT setting 'Content-Type' here. FormData will do it for us, including the boundary parameter.
                },
                body: formData,
            });

            const result = await response.json(); // Assuming your server responds with JSON

            if (response.ok) {
                toastSuccess("Bulk upload successful!"); // Use toastSuccess here
                console.log(result); // You might want to do something with the response here
                setCsvFile(null); // Resetting the file input if needed
                setSubmitSuccess(true);
                setSubmitError(false);
            } else {
                // Check if the errors array exists and is not empty, then concatenate all error messages
                if (result.errors && result.errors.length > 0) {
                    const errorMessage = result.errors.map(error => error.message).join(", ");
                    toastError(`Submission failed: ${errorMessage}`);
                } else {
                    toastError("Submission failed: Unknown error"); // Fallback error message
                }
                setSubmitSuccess(false);
                setSubmitError(true);
            }
        } catch (error) {
            console.error("Bulk upload error:", error);
            toastError(`Bulk upload error: ${error.message}`); // Improved error message display
            setSubmitSuccess(false);
            setSubmitError(true);
        }
    };



    const handleAddProductClick = (choice) => {
        setSelection(choice);
        setStep(2);
    };


    const [currentStep, setCurrentStep] = useState(1);
    const [stepsCompleted, setStepsCompleted] = useState([false, false]);

    const handleValidate = () => {
        setStepsCompleted({ ...stepsCompleted, [currentStep - 1]: true });
        if (currentStep < 2) setCurrentStep(currentStep + 1);
    };

    const handlePrevious = () => {
        setCurrentStep(currentStep - 1);
    };

    const isStepCompleted = (step) => {
        return stepsCompleted[step - 1];
    };


    const downloadExcelTemplate = () => {
        const link = document.createElement('a');
        link.href = excelTemplate;
        link.download = 'channel.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };



    const [items, setItems] = useState([]);
    const [isPopupModalOpen, setIsPopupModalOpen] = useState(false);
    const [csvFile, setCsvFile] = useState(null);

    //const fieldMappings = {
    //    // Example mapping: CSV header: Backend field name
    //    "PRODUCT NAME": "name",
    //    "PRODUCT DESCRIPTION": "description",
    //    "VARIANT CODE": "variant_code",
    //    "HSN CODE": "hsn",
    //    "ASIN CODE": "asin",
    //    "COUNTRY": "country",
    //};

    // Function to read and process the file
    const readFile = (file) => {
        const reader = new FileReader();
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
            const headers = data[0];
            const rows = data.slice(1).map((row) => {
                let rowData = {};
                row.forEach((cell, index) => {
                    // Use the fieldMappings to set the correct backend field name
                    const backendFieldName = headers[index];
                    rowData[backendFieldName] = cell;
                });
                return rowData;
            });
            setItems(rows);
        };
        reader.readAsBinaryString(file);
    };

    //const backendFieldName = fieldMappings[headers[index]] || headers[index];


    // Effect hook to process file upon selection
    useEffect(() => {
        if (csvFile) {
            readFile(csvFile);
        }
    }, [csvFile]);

    // Function to handle file selection
    const handleFileUpload = (e) => {
        const uploadedFile = e.target.files[0];
        if (uploadedFile) {
            setCsvFile(uploadedFile);
        }
    };

    // This might be already correctly set, just ensure it's capturing the file change event
    const handleFileChange = (event) => {
        setCsvFile(event.target.files[0]); // Assuming 'setCsvFile' updates the state with the selected file
    };


    // Function to toggle the popup modal visibility
    const togglePopupModal = () => {
        setIsPopupModalOpen(!isPopupModalOpen);

    };


    const [accounts, setAccounts] = useState([]);
    const [channels, setChannels] = useState([]); // State for channels

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_API_URL}/channels/getChannelAccounts/`, {
            headers: {
                'Authorization': `Token ${authToken}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setAccounts(data); // Assuming the API returns an array of products
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });
    }, [authToken]);  // Rerun when user or authToken changes

    const handleChannelAccountChange = (event) => {
        const selectedAccount = event.target.value;
        setFormChannelData(prev => ({
            ...prev,
            selectedAccount: selectedAccount,
        }));
        // Do not fetch channels here; it's already handled in handleChannelCompanyChange
    };


    const handleChannelChannelsChange = (event) => {
        const selectedChannel = event.target.value;
        setFormChannelData(prev => ({
            ...prev,
            selectedChannel: selectedChannel,
        }));
        fetchAccountsForChannel(selectedChannel);
        // Do not fetch channels here; it's already handled in handleChannelCompanyChange
    };


    // Function to fetch accounts based on the selected channel
    const fetchAccountsForChannel = (channelName) => {
        if (!channelName) {
            console.error('Channel name is required for fetching accounts.');
            return;
        }

        fetch(`${process.env.REACT_APP_BASE_API_URL}/channels/getChannelAccounts/?channel_name=${encodeURIComponent(channelName)}`, {
            headers: {
                'Authorization': `Token ${authToken}`,
            }
        })
            .then(response => response.json())
            .then(data => {
                setAccounts(data);
            })
            .catch(error => {
                console.error('Error fetching accounts:', error);
            });
    };


    // Function to fetch channels for a selected company
    const fetchChannelsForCompany = (companyName) => {
        if (!companyName) {
            console.error('Company name is required for fetching channels.');
            return;
        }

        fetch(`${process.env.REACT_APP_BASE_API_URL}/channels/platforms/?company_name=${encodeURIComponent(companyName)}`, {
            headers: {
                'Authorization': `Token ${authToken}`,
            }
        })
            .then(response => response.json())
            .then(data => {
                const channelsWithLabel = data.map(channelArray => ({
                    name: channelArray[0], // Assuming the channel name is the first element in the array
                    label: channelArray[0]
                }));
                setChannels(channelsWithLabel);
            })
            .catch(error => {
                console.error('Error fetching channels:', error);
            });
    };




    return (
        <>
            <div>
                <div className="flex justify-between items-center my-6">
                    <h2 className="text-lg font-bold">Add Channel</h2>


                    {/*<div>*/}
                    {/*    <button*/}
                    {/*        onClick={openPopup}*/}
                    {/*        className="bg-brand-300 hover:bg-brand-400 text-white font-bold py-2 px-4 rounded flex items-center mt-4"*/}
                    {/*    >*/}
                    {/*        + Add Bulk Channel*/}
                    {/*    </button>*/}
                    {/*</div>*/}

                    {isPopupOpen && (
                        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
                            <div className="relative top-20 mx-auto p-5 py-[30px] border w-fit shadow-lg rounded-md bg-white">

                                <div className="absolute top-0 right-0 pt-4 pr-4">
                                    <button
                                        onClick={closePopup}
                                        className="px-4 py-2 bg-gray-300 text-gray-900 rounded hover:bg-gray-400"
                                    >
                                        X
                                    </button>
                                </div>

                                <div className="mt-3">
                                    <div>
                                        {/*<h2>Add Products in Bulk Form</h2>*/}
                                        <div className="inset-0 overflow-y-auto h-fit w-full flex items-center justify-center">
                                            <div className="w-auto m-4">
                                                <div className="pt-4">
                                                    <h3 className="text-xl leading-6 mb-3 font-medium text-black">Add Channel in bulk</h3>

                                                    {/* Step Indicator */}
                                                    {/*<div className="flex justify-between items-center p-4 w-full gap-10">*/}
                                                    {/*    {[1, 2].map(step => (*/}
                                                    {/*        <div key={step} className={`flex items-center ${currentStep >= step ? 'text-blue-500' : 'text-gray-500'}`}>*/}
                                                    {/*            <div className="rounded-full h-8 w-8 flex items-center justify-center border-2 border-blue-500">*/}
                                                    {/*                {isStepCompleted(step) ? (*/}
                                                    {/*                    <span className="text-xs">✓</span>*/}
                                                    {/*                ) : (*/}
                                                    {/*                    <span className="text-xs">{step}</span>*/}
                                                    {/*                )}*/}
                                                    {/*            </div>*/}
                                                    {/*            <span className="ml-2">{step === 1 ? 'Upload Excel File' : 'Upload Product Images'}</span>*/}
                                                    {/*        </div>*/}
                                                    {/*    ))}*/}
                                                    {/*</div>*/}

                                                    <hr />

                                                    {/* Content based on step */}
                                                    <div className="pt-5">
                                                        {/* Content based on step */}
                                                        {currentStep === 1 && (
                                                            <div>
                                                                <p className="text-md text-gray-700 underline">Instructions on uploading csv file</p>

                                                                <div className="mt-4 flex justify-between p-5 items-center bg-gray-100 rounded-lg">
                                                                    <p className="text-sm text-black">1. Download the csv template.</p>
                                                                    <button
                                                                        className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none"
                                                                        type="button"
                                                                        onClick={downloadExcelTemplate} // Updated here
                                                                        style={{ transition: "all .15s ease" }}
                                                                    >
                                                                        Download Template
                                                                    </button>
                                                                </div>

                                                                <div className="mt-4 p-5 bg-gray-100 rounded-lg">
                                                                    <p className="text-sm text-black">2. Fill in your product details in the csv template.</p>
                                                                    <p className="text-sm text-black">Note: Do not edit the column names.</p>
                                                                </div>

                                                                <div className="mt-4 p-5 bg-gray-100 rounded-lg">
                                                                    <p className="text-sm text-black">3. Upload the file to Beyobo.</p>
                                                                    <div className="w-full border-brand-300 rounded-md shadow-sm bg-white p-3 border border-dashed my-2">
                                                                        <input
                                                                            type="file"
                                                                            accept=".xlsx, .xls" // Restricting file types for convenience
                                                                            onChange={handleFileChange} // Ensure this is correctly wired up
                                                                            className="w-full border-gray-300 rounded-md shadow-sm"
                                                                        />

                                                                    </div>
                                                                </div>{/*onClick={handleCSVUpload}*/}

                                                                <div className="flex justify-between gap-10">
                                                                    <button
                                                                        type="button"
                                                                        onClick={togglePopupModal}
                                                                        disabled={!items.length}
                                                                        className="bg-brand-400 hover:bg-brand-300 text-white active:bg-blue-600 font-bold uppercase text-xs px-6 py-3 rounded shadow hover:shadow-md outline-none focus:outline-none w-full mt-4"
                                                                        style={{ transition: "all .15s ease" }}
                                                                    >
                                                                        Show
                                                                    </button>

                                                                    <button
                                                                        className="bg-blue-600 text-white active:bg-blue-600 font-bold uppercase text-xs px-6 py-3 rounded shadow hover:bg-blue-500 outline-none focus:outline-none w-full mt-4"
                                                                        type="button"
                                                                        onClick={handleBulkUpload}
                                                                        style={{ transition: "all .15s ease" }}
                                                                    >
                                                                        Submit
                                                                    </button>
                                                                </div>

                                                            </div>
                                                        )}

                                                        {currentStep === 2 && (
                                                            <div className="bg-gray-100 p-4 rounded-md">
                                                                <p className="text-sm text-gray-500">Instructions on uploading product images</p>
                                                                <div className="mt-4">
                                                                    <p className="text-sm text-gray-600">1. Ensure images are in JPG or PNG format.</p>
                                                                    <p className="text-sm text-gray-600">2. Upload the image files below.</p>
                                                                    <input type="file" className="text-gray-500 py-2 px-4 w-full mt-2" multiple />
                                                                </div>
                                                                <div className="flex justify-between mt-4">
                                                                    <button
                                                                        className="bg-gray-300 text-gray-800 active:bg-gray-500 font-bold uppercase text-xs px-6 py-3 rounded outline-none focus:outline-none"
                                                                        type="button"
                                                                        onClick={handlePrevious}
                                                                        style={{ transition: "all .15s ease" }}
                                                                    >
                                                                        Previous
                                                                    </button>
                                                                    <button
                                                                        className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-6 py-3 rounded shadow hover:shadow-md outline-none focus:outline-none"
                                                                        type="button"
                                                                        onClick={handleValidate}
                                                                        style={{ transition: "all .15s ease" }}
                                                                    >
                                                                        Finish
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {isPopupModalOpen && (
                        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center z-[1000]">
                            <div className="bg-white rounded-lg shadow-2xl p-4 max-w-4xl w-full mx-2 my-6">
                                <div className="flex justify-between items-center mb-4">
                                    <h2 className="text-2xl font-bold">Uploaded Products</h2>
                                    <button
                                        onClick={() => setIsPopupModalOpen(false)}
                                        className="text-gray-600 hover:text-gray-900"
                                    >
                                        <svg className="w-6 h-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                            <path d="M6 18L18 6M6 6l12 12"></path>
                                        </svg>
                                    </button>
                                </div>
                                <div className="overflow-auto">
                                    <table className="min-w-full leading-normal">
                                        <thead>
                                            <tr>
                                                {items[0] && Object.keys(items[0]).map((header) => (
                                                    <th
                                                        key={header}
                                                        className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                                                    >
                                                        {header}
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {items.map((item, index) => (
                                                <tr key={index}>
                                                    {Object.values(item).map((val, i) => (
                                                        <td key={i} className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                                                            {val}
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="text-end mt-5">
                                    <button className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed">
                                        Confirm
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                </div>



                <form id="catalogue_variant" onSubmit={handleChannelSubmit}>

                    {/*<div>*/}
                    {/*    <label htmlFor="oms id" className="block text-sm font-medium text-gray-700">Platform *</label>*/}
                    {/*    <input*/}
                    {/*        type="text"*/}
                    {/*        id="name"*/}
                    {/*        name="platform"*/}
                    {/*        value={formChannelData.platform}*/}
                    {/*        onChange={handleChannelInputChange}*/}
                    {/*        // onChange={(e) => handleInputChange(e.target.name, e.target.value)}*/}
                    {/*        required*/}
                    {/*        className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"*/}
                    {/*        placeholder="Enter Platform"*/}
                    {/*    />*/}
                    {/*</div>*/}

                    {/*<div className="items-center w-full my-4">*/}
                    {/*    <label htmlFor="company-select" className="block text-sm font-medium text-gray-700">Select Marketplace Account</label>*/}
                    {/*    <select required id="company-select" value={formChannelData.selectedAccount} className="mt-1 p-2 border-2 border-gray-300 w-full rounded-lg cursor-pointer" onChange={handleChannelAccountChange}>*/}

                    {/*        <option value="">Select Marketplace Account</option>*/}
                    {/*        {accounts.map((account) => (*/}
                    {/*            <option value={account.email} key={account.email}>*/}
                    {/*                {account.email}*/}
                    {/*            </option>*/}
                    {/*        ))}*/}
                    {/*    </select>*/}
                    {/*</div>*/}


                    {/*<div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">*/}
                    {/*    <div>*/}
                    {/*        <label htmlFor="oms id" className="block text-sm font-medium text-gray-700">Platform *</label>*/}
                    {/*        <input*/}
                    {/*            type="text"*/}
                    {/*            id="name"*/}
                    {/*            name="platform"*/}
                    {/*            value={formChannelData.platform}*/}
                    {/*            onChange={handleChannelInputChange}*/}
                    {/*            // onChange={(e) => handleInputChange(e.target.name, e.target.value)}*/}
                    {/*            required*/}
                    {/*            className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"*/}
                    {/*            placeholder="Enter Platform"*/}
                    {/*        />*/}
                    {/*    </div>*/}

                    {/*    <div className="items-center w-full mb-4">*/}
                    {/*        <label htmlFor="company-select" className="block text-sm font-medium text-gray-700">Select Account</label>*/}
                    {/*        <select required id="company-select" value={formChannelData.selectedAccount} className="mt-1 p-2 border-2 border-gray-300 w-full rounded-lg cursor-pointer" onChange={handleChannelAccountChange}>*/}

                    {/*            <option value="">Select Account</option>*/}
                    {/*            {accounts.map((account) => (*/}
                    {/*                <option value={account.email} key={account.email}>*/}
                    {/*                    {account.email}*/}
                    {/*                </option>*/}
                    {/*            ))}*/}
                    {/*        </select>*/}
                    {/*    </div>*/}



                    {/*    */}{/*<div>*/}
                    {/*    */}{/*    <label htmlFor="oms id" className="block text-sm font-medium text-gray-700">Marketplace *</label>*/}
                    {/*    */}{/*    <input*/}
                    {/*    */}{/*        type="text"*/}
                    {/*    */}{/*        id="name"*/}
                    {/*    */}{/*        name="marketplace"*/}
                    {/*    */}{/*        value={formChannelData.marketplace}*/}
                    {/*    */}{/*        onChange={handleChannelInputChange}*/}
                    {/*    */}{/*        // onChange={(e) => handleInputChange(e.target.name, e.target.value)}*/}
                    {/*    */}{/*        required*/}
                    {/*    */}{/*        className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"*/}
                    {/*    */}{/*        placeholder="Enter Marketplace"*/}
                    {/*    */}{/*    />*/}
                    {/*    */}{/*</div>*/}

                    {/*    */}{/*<div>*/}
                    {/*    */}{/*    <label htmlFor="oms id" className="block text-sm font-medium text-gray-700">OMS ID *</label>*/}
                    {/*    */}{/*    <input*/}
                    {/*    */}{/*        type="number"*/}
                    {/*    */}{/*        id="name"*/}
                    {/*    */}{/*        name="oms_channel_id"*/}
                    {/*    */}{/*        value={formChannelData.oms_channel_id}*/}
                    {/*    */}{/*        onChange={handleChannelInputChange}*/}
                    {/*    */}{/*        // onChange={(e) => handleInputChange(e.target.name, e.target.value)}*/}
                    {/*    */}{/*        required*/}
                    {/*    */}{/*        className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"*/}
                    {/*    */}{/*        placeholder="Enter OMS ID"*/}
                    {/*    */}{/*    />*/}
                    {/*    */}{/*</div>*/}
                    {/*</div>*/}

                    {/*<div className="items-center my-4">*/}
                    {/*    <label htmlFor="company-select" className="block text-sm font-medium text-gray-700">Select Company</label>*/}
                    {/*    <select required id="company-select" value={formChannelData.selectedCompany} className="mt-1 p-2 border-2 border-gray-300 w-full rounded-lg cursor-pointer" onChange={handleChannelCompanyChange}>*/}

                    {/*        <option value="">Select a Company</option>*/}
                    {/*        {companies.map((company) => (*/}
                    {/*            <option value={company.name} key={company.name}>*/}
                    {/*                {company.name}*/}
                    {/*            </option>*/}
                    {/*        ))}*/}
                    {/*    </select>*/}
                    {/*</div>*/}


                    <div className="items-center w-full mb-4">
                        <label htmlFor="company-select" className="block text-sm font-medium text-gray-700">Select Company</label>
                        <select required id="company-select" value={formChannelData.selectedCompany} className="mt-1 p-2 border-2 border-gray-300 w-full rounded-lg cursor-pointer" onChange={handleChannelCompanyChange}>

                            <option value="">Select a Company</option>
                            {companies.map((company) => (
                                <option value={company.name} key={company.name}>
                                    {company.name}
                                </option>
                            ))}
                        </select>
                    </div>


                    {/* Select Channel Section */}
                    {/*<div className="items-center w-full mb-4">*/}
                    {/*    <label htmlFor="company-select" className="block text-sm font-medium text-gray-700">Select Platform</label>*/}

                    {/*    <select*/}
                    {/*        required*/}
                    {/*        id="platform-select"*/}
                    {/*        value={formChannelData.selectedChannel}*/}
                    {/*        className="mt-1 p-2 border-2 border-gray-300 w-full rounded-lg cursor-pointer"*/}
                    {/*        onChange={handleChannelChannelsChange} // Ensure this function is correctly handling the changes*/}
                    {/*    >*/}
                    {/*        <option value="">Select a Platform</option>*/}
                    {/*        {channels.map((channel) => (*/}
                    {/*            <option value={channel.name} key={channel.name}>*/}
                    {/*                {channel.label}*/}
                    {/*            </option>*/}
                    {/*        ))}*/}
                    {/*    </select>*/}
                    {/*</div>*/}

                    <div className="items-center w-full mb-4">
                        <label htmlFor="platform-select" className="block text-sm font-medium text-gray-700">Select Platform</label>
                        <select
                            required
                            id="platform-select"
                            value={formChannelData.selectedChannel}
                            className="mt-1 p-2 border-2 border-gray-300 w-full rounded-lg cursor-pointer"
                            onChange={handleChannelChannelsChange} // Ensure this function is correctly handling the changes
                        >
                            <option value="">Select a Platform</option>
                            {channels.map((channel) => (
                                <option value={channel.name} key={channel.name}>
                                    {channel.label}
                                </option>
                            ))}
                        </select>
                    </div>



                    <div className="items-center w-full mb-4">
                        <div className="flex items-center justify-between">
                            <label htmlFor="account-select" className="block text-sm font-medium text-gray-700">Select Account</label>

                            <button onClick={openAccountFormDialog} className="bg-gray-500 hover:bg-brand-400 text-white font-bold py-1 px-2 rounded">
                                Add Account
                            </button>
                        </div>

                        <select
                            required
                            id="account-select"
                            value={formChannelData.selectedAccount}
                            className="mt-1 p-2 border-2 border-gray-300 w-full rounded-lg cursor-pointer"
                            onChange={handleChannelAccountChange}
                        >
                            <option value="">Select an Account</option>
                            {accounts.map((account) => (
                                <option value={account.email} key={account.email}>
                                    {account.email}
                                </option>
                            ))}
                        </select>
                    </div>



                    {/*<div className="items-center w-full mb-4">*/}
                    {/*    <label htmlFor="account-select" className="block text-sm font-medium text-gray-700">Select Account</label>*/}
                    {/*    <select*/}
                    {/*        required*/}
                    {/*        id="account-select"*/}
                    {/*        value={formChannelData.selectedAccount}*/}
                    {/*        className="mt-1 p-2 border-2 border-gray-300 w-full rounded-lg cursor-pointer"*/}
                    {/*        onChange={handleChannelAccountChange}*/}
                    {/*    >*/}
                    {/*        <option value="">Select an Account</option>*/}
                    {/*        {accounts.map((account) => (*/}
                    {/*            <option value={account.email} key={account.email}>*/}
                    {/*                {account.email}*/}
                    {/*            </option>*/}
                    {/*        ))}*/}
                    {/*    </select>*/}
                    {/*</div>*/}

                    <div className="items-center my-4">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name *</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            required
                            value={formChannelData.name}
                            onChange={handleChannelInputChange}
                            //onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                            className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                            placeholder="Enter name"
                        />
                    </div>

                    {/* SKU Selection Section */}
                    {/*<div className="mb-4">*/}
                    {/*    <div className="flex items-center justify-between">*/}
                    {/*        <label className="block text-sm font-medium text-gray-700">Select SKUs</label>*/}

                    {/*        <button onClick={openSkuFormDialog} className="bg-gray-500 hover:bg-brand-400 text-white font-bold py-1 px-2 rounded">*/}
                    {/*            Add Sku*/}
                    {/*        </button>*/}
                    {/*    </div>*/}

                    {/*    <div className="mt-1 border-2 border-gray-300 w-full rounded-lg overflow-y-auto" style={{ maxHeight: '150px' }}>*/}
                    {/*        {skus.map((sku) => (*/}
                    {/*            <button*/}
                    {/*                type="button"*/}
                    {/*                key={sku.id}*/}
                    {/*                onClick={() => handleSkuClick(sku)}*/}
                    {/*                className={`p-2 w-full text-left ${formChannelData.selectedSkus.some(selectedSku => selectedSku.id === sku.id) ? 'bg-gray-200' : ''}`}*/}
                    {/*            >*/}
                    {/*                {sku.name}*/}
                    {/*            </button>*/}
                    {/*        ))}*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/* Selected SKUs Section */}
                    {/*<div className="mb-4">*/}
                    {/*    <label className="block text-sm font-medium text-gray-700 mb-3">Selected SKUs:</label>*/}

                    {/*    <div className="flex justify-between mx-[56px] my-3">*/}
                    {/*        <p className="block font-medium text-gray-700">SKU Name</p>*/}
                    {/*        <p className="block font-medium text-gray-700">Listing SKU</p>*/}
                    {/*    </div>*/}

                    {/*    <div className="flex flex-col gap-2">*/}
                    {/*        {formChannelData.selectedSkus.map((sku, index) => (*/}
                    {/*            <div key={index} className="flex justify-between items-center gap-2 p-2 border rounded">*/}
                    {/*                {sku.name}*/}
                    {/*                <input*/}
                    {/*                    type="text"*/}
                    {/*                    placeholder="SKU details..."*/}
                    {/*                    value={sku.listing_sku || ''}*/}
                    {/*                    onChange={(e) => {*/}
                    {/*                        // Create a new array with updated quantities*/}
                    {/*                        const updatedSkus = [...formChannelData.selectedSkus];*/}
                    {/*                        updatedSkus[index] = { ...sku, listing_sku: e.target.value };*/}
                    {/*                        setFormChannelData(prevFormSkuData => ({*/}
                    {/*                            ...prevFormSkuData,*/}
                    {/*                            selectedSkus: updatedSkus*/}
                    {/*                        }));*/}
                    {/*                    }}*/}
                    {/*                    className="w-1/3 border-gray-300 rounded-md shadow-sm bg-white p-3 border"*/}
                    {/*                />*/}
                    {/*            </div>*/}
                    {/*        ))}*/}
                    {/*    </div>*/}
                    {/*</div>*/}





                    {/* SKU Selection Section */}
                    <div className="mb-4">
                        <div>
                            <div className="flex items-center justify-between">
                                <label className="block text-sm font-medium text-gray-700">Select SKUs</label>

                                <button onClick={openSkuFormDialog} className="bg-gray-500 hover:bg-brand-400 text-white font-bold py-1 px-2 rounded">
                                    Add Sku
                                </button>
                            </div>

                            <input
                                type="text"
                                placeholder="Search SKUs..."
                                value={skuSearch}
                                onChange={e => setSkuSearch(e.target.value)}
                                className="mt-1 p-2 border-2 border-gray-300 w-full rounded-lg"
                            />
                        </div>

                        <div className="mt-1 border-2 border-gray-300 w-full rounded-lg overflow-y-auto" style={{ maxHeight: '150px' }}>
                            {filteredSkus.map(sku => (
                                <button
                                    type="button"
                                    key={sku.id}
                                    onClick={() => handleSkuClick(sku)}
                                    className={`p-2 w-full text-left ${formChannelData.selectedSkus.some(selectedSku => selectedSku.id === sku.id) ? 'bg-gray-200' : ''}`}
                                >
                                    {sku.name}
                                </button>
                            ))}
                        </div>
                    </div>

                    {/* Selected SKUs Section */}
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-3">Selected SKUs:</label>
                        <div className="flex justify-between mx-[56px] my-3">
                            <p className="block font-medium text-gray-700">SKU Name</p>
                            <p className="block font-medium text-gray-700">Listing SKU</p>
                        </div>

                        <div className="flex flex-col gap-2">
                            {formChannelData.selectedSkus.map((sku, index) => (
                                <div key={index} className="flex items-center justify-between my-2 bg-white p-2 rounded-lg shadow border relative">
                                    <span>{sku.name}</span>
                                    <input
                                        type="text"
                                        placeholder="Listing SKU"
                                        value={sku.listing_sku || ''}
                                        onChange={(e) => {
                                            const updatedSkus = formChannelData.selectedSkus.map(item =>
                                                item.id === sku.id ? { ...item, listing_sku: e.target.value } : item
                                            );
                                            setFormChannelData(prev => ({ ...prev, selectedSkus: updatedSkus }));
                                        }}
                                        className="ml-4 p-1 border border-gray-300 rounded"
                                    />
                                    <span onClick={() => removeSku(sku.id)} className="absolute right-0 top-[-8px] cursor-pointer text-red-500 hover:text-red-700 text-lg">
                                        <svg width="14px" height="14px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM15.75 12C15.75 12.4142 15.4142 12.75 15 12.75H9C8.58579 12.75 8.25 12.4142 8.25 12C8.25 11.5858 8.58579 11.25 9 11.25H15C15.4142 11.25 15.75 11.5858 15.75 12Z" fill="#ff0000">
                                            </path>
                                        </svg>
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>



                    <div className="flex justify-end mt-4">
                        <button
                            type="submit"
                            className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
                        >
                            Add Channel
                        </button>
                    </div>
                </form>
                {submitSuccess && <div>Your form has been submitted successfully!</div>}
                {submitError && <div>Error submitting form. Please try again.</div>}
            </div>
        </>
    );
};

export default ChannelSupplierDashboard;