import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter'
import React, { useState, useEffect, useContext, useCallback } from 'react';
import DataModal from '../../../../components/modal';
import AuthContext from '../../../../context/AuthContext';
import moment from 'moment'
import CustomTable from '../../../../components/customTable';
const AdminResumeDataGrid = () => {

    const columns = [
        { header: 'ID', key: 'id' },
        { header: 'Company ID', key: 'company_id' },
        { header: 'Name', key: 'name' },
        { header: 'Designation', key: 'designation' },
        { header: 'Notice Period Remaining', key: 'notice_period_remaining' },
        { header: 'Salary Expectations', key: 'salary_expectations' },
        { header: 'Joining Date', key: 'joining_date' },
    ];

  //  const columns = [
    
      
  //    { name: 'id', defaultFlex: 1, header: ' Id', type: 'number',},
  //    { name: 'company_id', defaultFlex: 1, header: 'Company Id', type: 'number',},

  //    { name: 'name', defaultFlex: 1, header: 'Name',type:'string'},
  //    { name: 'designation', defaultFlex: 1, header: 'Designation',type:'string'},
  //    { name: 'notice_period_remaining', defaultFlex: 1, header: 'Notice Period Remaining',type:'string'},
  //    { name: 'salary_expectations', defaultFlex: 1, header: 'Salary Expectations',type:'string'},
  //    {
  //      name: 'joining_date',
  //      header: 'Joining Date',
  //      defaultFlex: 1,
  //      filterEditorProps: (props, { index }) => {
  //          return {
  //              dateFormat: 'DD-MM-YYYY', // Updated date format
  //              cancelButton: false,
  //              highlightWeekends: false,
  //              placeholder: index == 1 ? 'Created date is before...' : 'Created date is after...'
  //          }
  //      },
  //      render: ({ value, cellProps }) => {
  //          return moment(value).format('DD-MM-YYYY') // Updated to match ShipmentDataGrid format
  //      }
  //  },
  
  //   // {name: 'pending', defaultFlex:1,header:'Pending', type:Number,render: ({ value }) => formatCurrency(value)},
  //];



  //      const defaultfilter = [
          
  //        { name: 'id', operator: 'gte', type: 'number' ,value: 0},
  //        { name: 'company_id', operator: 'gte', type: 'number' ,value: 0},
  //        { name: 'name', operator: 'startsWith', type: 'string' ,value: ''},
  //        { name: 'designation', operator: 'startsWith', type: 'string' ,value: ''},
  //        { name: 'notice_period_remaining', operator: 'startsWith', type: 'string' ,value: ''},
  //        { name: 'salary_expectations', operator: 'startsWith', type: 'string' ,value: ''},
  //           //{ name: 'sku_id', operator: 'gte', type: 'number' ,value: 0},
         
  //        //{ name: 'pending', operator: 'gte', type: 'number' ,value: 0},
  //        { name: 'joining_date', operator: 'before', type: 'date', value: '' },
  
  //];



    //const [filterValue, setFilterValue] = useState(defaultfilter);
    const [dataSource, setDataSource] = useState([]);
    const { authToken } = useContext(AuthContext);
    const [gridData, setGridData] = useState([]); // New state for actual data array

    const loadData = useCallback(() => {
        const newDataSource = () => {
            const limit = 50;
            const url = `${process.env.REACT_APP_BASE_API_URL}/candidate/complete_list/` + '?limit=' + limit;
            return fetch(url, {
                headers: {
                    'Authorization': `Token ${authToken}`
                }
            })
                .then(response => response.json())
                .then(data => {
                    const formattedData = data.map(item => ({
                        ...item,
                        order_date: moment(item.order_date).format('DD-MM-YYYY')
                    }));
                    setGridData(formattedData); // Update gridData with the actual data array
                    return { data: formattedData }; // Return the data for dataSource
                });
        }

        setDataSource(newDataSource)
    }, []);

    useEffect(() => {
        loadData();
    }, [loadData]); // Ensure loadData is stable and doesn't change unless necessary



    const gridStyle = { minHeight: 550 }


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);

    const onRowClick = useCallback((rowProps) => {
        setSelectedRowData(rowProps.data);
        setIsModalOpen(true);
    }, []);

    return (
        <div>
            <CustomTable data={gridData} columns={columns} onRowClick={() => { }} />

            {/*<ReactDataGrid*/}
            {/*    style={gridStyle}*/}
            {/*    idProperty="id"*/}
            {/*    pagination='local'*/}
            {/*    columns={columns}*/}
            {/*    dataSource={dataSource}*/}
            {/*    defaultLimit={50}*/}
                
            {/*    onRowClick={onRowClick}*/}
            {/*/>*/}

            {/*defaultFilterValue={defaultfilter}*/}
            {/*onFilterValueChange={setFilterValue}*/}

            {/*<DataModal*/}
            {/*    isOpen={isModalOpen}*/}
            {/*    onClose={() => setIsModalOpen(false)}*/}
            {/*    data={selectedRowData}*/}
            {/*    columns={columns}*/}
            {/*/>*/}
        </div>
    )
};


export default AdminResumeDataGrid;