import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import AuthContext from "context/AuthContext";
import * as XLSX from 'xlsx';
import excelTemplate from '../../../assets/documents/channel-bulk-upload.xlsx';
import { useToast } from '../../../components/toast';


const LocationSupplierDashboard = () => {

    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [submitError, setSubmitError] = useState(false);
    const { authToken } = useContext(AuthContext);
    const [companies, setCompanies] = useState([]);
    const [skus, setSkus] = useState([]);
    const [selectedVariants, setSelectedVariants] = useState([]);
    const [displayLimit, setDisplayLimit] = useState([]);
    const { toastSuccess, toastError } = useToast();


    const [formChannelData, setFormChannelData] = useState({
        name: '',
        aisle: '',
        bay: '',
        shelf: '',
        bin: '',
        level: '',
        capacity: '',
        capacity_unit: '',
        warehouse: [],
    });

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_API_URL}/company/`, {
            headers: {
                'Authorization': `Token ${authToken}`
            }
        })
            .then(response => response.json())
            .then(data => {
                setCompanies(data);
            })
            .catch(error => {
                console.error('Error fetching companies:', error);
            });
    }, [authToken]);

    const fetchSkusForCompany = (companyName) => {
        fetch(`${process.env.REACT_APP_BASE_API_URL}/sku/getSkusForCompany/?company_name=${encodeURIComponent(companyName)}`, {
            headers: {
                'Authorization': `Token ${authToken}`,
            }
        })
            .then(response => response.json())
            .then(data => {
                const skusWithLabel = data.map(sku => ({
                    ...sku,
                    label: sku.name // Adjust this based on your actual data structure
                }));
                setSkus(skusWithLabel);
            })
            .catch(error => {
                console.error('Error fetching SKUs:', error);
            });
    };

    const handleChannelCompanyChange = (event) => {
        const selectedCompany = event.target.value;
        setFormChannelData(prev => ({
            ...prev,
            selectedCompany: selectedCompany,
        }));
        fetchSkusForCompany(selectedCompany);
    };

    const handleChannelInputChange = (event) => {
        const { name, value } = event.target;
        setFormChannelData(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSkuClick = (clickedSku) => {
        const isAlreadySelected = formChannelData.selectedSkus.some(sku => sku.id === clickedSku.id);
    
        if (isAlreadySelected) {
            // Remove the SKU if it's already selected
            setFormChannelData(prev => ({
                ...prev,
                selectedSkus: prev.selectedSkus.filter(sku => sku.id !== clickedSku.id),
            }));
        } else {
            // Add the SKU, ensuring the 'id' is included
            setFormChannelData(prev => ({
                ...prev,
                selectedSkus: [...prev.selectedSkus, clickedSku],
            }));
        }
    };



    const handleChannelSubmit = async (event) => {
        event.preventDefault();
        let bodyFormData = new FormData();
    
        // Loop through formChannelData except 'selectedSkus' and append each to FormData
        Object.entries(formChannelData).forEach(([key, value]) => {
            if (key !== 'selectedSkus') {
                bodyFormData.append(key, value);
            } else {
                // Serialize 'selectedSkus' as a JSON string and append
                bodyFormData.append(key, JSON.stringify(value.map(sku => ({
                  ...sku,
                  // Ensure we are correctly handling potential undefined 'id's
                  id: sku.id || '' // Adjust this based on how you want to handle undefined ids
                }))));
            }
        });

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/channels/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${authToken}` // Ensure this matches what your API expects
                },
                body: bodyFormData,
            });

            //if (!response.ok) {
            //    const errorData = await response.json();
            //    throw new Error(`Submission failed: ${errorData.message}`); // More detailed error message
            //}
            //setSubmitSuccess(true);
            //setSubmitError(false);
            //setFormChannelData({
            //    name: '',
            //    platform: '',
            //    oms_channel_id: '',
            //    selectedCompany: '',
            //    selectedSkus: [],
            //});


            if (!response.ok) {
                const errorData = await response.json();
                if (errorData.errors && errorData.errors.length > 0) {
                    const errorMessage = errorData.errors.map(error => error.message).join(", ");
                    toastError(`Submission failed: ${errorMessage}`);
                } else {
                    // Fallback to a general error message if no specific errors are provided
                    toastError(`Submission failed: ${errorData.message || "Unknown error"}`);
                }
                setSubmitError(true);
            } else {
                toastSuccess("Channel submission successful!");
                setSubmitSuccess(true);
                setSubmitError(false);
                setFormChannelData({
                    name: '',
                    platform: '',
                    oms_channel_id: '',
                    selectedCompany: '',
                    selectedSkus: [],
                });
            }

        } catch (error) {
            toastError('Submission failed');
            setSubmitSuccess(false);
            setSubmitError(true);
        }
    };



    // Function to update additional information for a selected SKU
    const handleAdditionalInfoChange = (id, value) => {
        setFormChannelData(prev => ({
            ...prev,
            selectedSkus: prev.selectedSkus.map(sku =>
                sku.id === id ? { ...sku, additionalInfo: value } : sku
            ),
        }));
    };


    const handleShowMore = () => {
        setDisplayLimit(currentLimit => currentLimit + 10);
    };

    const handleDropdownClick = (e) => {
        e.stopPropagation(); // This prevents the onBlur event from firing when clicking inside the dropdown
    };




    const [isPopupOpen, setPopupOpen] = useState(false);
    const [step, setStep] = useState(1);
    const [selection, setSelection] = useState('');

    const openPopup = () => {
        setPopupOpen(true);
    };

    const closePopup = () => {
        setPopupOpen(false);
        setStep(1); // Reset the step to 1 when closing the modal.
    };



    const handleAddProductClick = (choice) => {
        setSelection(choice);
        setStep(2);
    };


    const [currentStep, setCurrentStep] = useState(1);
    const [stepsCompleted, setStepsCompleted] = useState([false, false]);

    const handleValidate = () => {
        setStepsCompleted({ ...stepsCompleted, [currentStep - 1]: true });
        if (currentStep < 2) setCurrentStep(currentStep + 1);
    };

    const handlePrevious = () => {
        setCurrentStep(currentStep - 1);
    };

    const isStepCompleted = (step) => {
        return stepsCompleted[step - 1];
    };



    const [items, setItems] = useState([]);
    const [isPopupModalOpen, setIsPopupModalOpen] = useState(false);
    const [csvFile, setCsvFile] = useState(null);

    //const fieldMappings = {
    //    // Example mapping: CSV header: Backend field name
    //    "PRODUCT NAME": "name",
    //    "PRODUCT DESCRIPTION": "description",
    //    "VARIANT CODE": "variant_code",
    //    "HSN CODE": "hsn",
    //    "ASIN CODE": "asin",
    //    "COUNTRY": "country",
    //};


    // Function to toggle the popup modal visibility
    const togglePopupModal = () => {
        setIsPopupModalOpen(!isPopupModalOpen);

    };

    return (
        <>
            <div>
                <h2 className="text-lg font-bold mb-5">Add Location</h2>


                <form id="catalogue_variant" onSubmit={handleChannelSubmit}>

                    <div className="items-center mb-4">
                        <label htmlFor="company-select" className="block text-sm font-medium text-gray-700">Select Warehouse</label>
                        <select required id="company-select" value={formChannelData.selectedCompany} className="mt-1 p-2 border-2 border-gray-300 w-full rounded-lg cursor-pointer" onChange={handleChannelCompanyChange}>

                            <option value="">Select a Company</option>
                            {companies.map((company) => (
                                <option value={company.name} key={company.name}>
                                    {company.name}
                                </option>
                            ))}
                        </select>
                    </div>


                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">

                        <div>
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name *</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                required
                                value={formChannelData.name}
                                onChange={handleChannelInputChange}
                                //onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                placeholder="Enter name"
                            />
                        </div>

                        <div>
                            <label htmlFor="aisle" className="block text-sm font-medium text-gray-700">Aisle *</label>
                            <input
                                type="text"
                                id="aisle"
                                name="aisle"
                                value={formChannelData.aisle}
                                onChange={handleChannelInputChange}
                                // onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                required
                                className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                placeholder="Enter Aisle"
                            />
                        </div>
                    </div>


                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                        <div>
                            <label htmlFor="bay" className="block text-sm font-medium text-gray-700">Bay *</label>
                            <input
                                type="text"
                                id="bay"
                                name="bay"
                                value={formChannelData.bay}
                                onChange={handleChannelInputChange}
                                // onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                required
                                className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                placeholder="Enter Bay"
                            />
                        </div>

                        <div>
                            <label htmlFor="shelf" className="block text-sm font-medium text-gray-700">Shelf *</label>
                            <input
                                type="text"
                                id="shelf"
                                name="shelf"
                                value={formChannelData.shelf}
                                onChange={handleChannelInputChange}
                                // onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                required
                                className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                placeholder="Enter Shelf"
                            />
                        </div>
                    </div>


                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                        <div>
                            <label htmlFor="bin" className="block text-sm font-medium text-gray-700">Bin *</label>
                            <input
                                type="text"
                                id="bin"
                                name="bin"
                                value={formChannelData.bin}
                                onChange={handleChannelInputChange}
                                // onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                required
                                className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                placeholder="Enter Bin"
                            />
                        </div>

                        <div>
                            <label htmlFor="level" className="block text-sm font-medium text-gray-700">Level *</label>
                            <input
                                type="text"
                                id="level"
                                name="level"
                                value={formChannelData.level}
                                onChange={handleChannelInputChange}
                                // onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                required
                                className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                placeholder="Enter Level"
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                        <div>
                            <label htmlFor="capacity" className="block text-sm font-medium text-gray-700">Capacity *</label>
                            <input
                                type="number"
                                id="capacity"
                                name="capacity"
                                value={formChannelData.capacity}
                                onChange={handleChannelInputChange}
                                // onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                required
                                className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                placeholder="Enter Capacity"
                            />
                        </div>

                        <div>
                            <label htmlFor="capacity_unit" className="block text-sm font-medium text-gray-700">Capacity Unit *</label>
                            <input
                                type="text"
                                id="capacity_unit"
                                name="capacity_unit"
                                value={formChannelData.capacity_unit}
                                onChange={handleChannelInputChange}
                                // onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                required
                                className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                                placeholder="Enter Capacity Unit"
                            />
                        </div>
                    </div>

                    {/* SKU Selection Section */}
                    {/*<div className="mb-4">*/}
                    {/*    <label className="block text-sm font-medium text-gray-700">Select SKUs</label>*/}
                    {/*    <div className="mt-1 border-2 border-gray-300 w-full rounded-lg overflow-y-auto" style={{ maxHeight: '150px' }}>*/}
                    {/*        {skus.map((sku) => (*/}
                    {/*            <button*/}
                    {/*                type="button"*/}
                    {/*                key={sku.id}*/}
                    {/*                onClick={() => handleSkuClick(sku)}*/}
                    {/*                className={`p-2 w-full text-left ${formChannelData.selectedSkus.some(selectedSku => selectedSku.id === sku.id) ? 'bg-gray-200' : ''}`}*/}
                    {/*            >*/}
                    {/*                {sku.name}*/}
                    {/*            </button>*/}
                    {/*        ))}*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/* Selected SKUs Section */}
                    {/*<div className="mb-4">*/}
                    {/*    <label className="block text-sm font-medium text-gray-700 mb-3">Selected SKUs:</label>*/}

                    {/*    <div className="flex justify-between mx-[56px] my-3">*/}
                    {/*        <p className="block font-medium text-gray-700">SKU Name</p>*/}
                    {/*        <p className="block font-medium text-gray-700">Listing SKU</p>*/}
                    {/*    </div>*/}

                    {/*    <div className="flex flex-col gap-2">*/}
                    {/*        {formChannelData.selectedSkus.map((sku, index) => (*/}
                    {/*            <div key={index} className="flex justify-between items-center gap-2 p-2 border rounded">*/}
                    {/*                {sku.name}*/}
                    {/*                <input*/}
                    {/*                    type="text"*/}
                    {/*                    placeholder="SKU details..."*/}
                    {/*                    value={sku.listing_sku || ''}*/}
                    {/*                    onChange={(e) => {*/}
                    {/*                        // Create a new array with updated quantities*/}
                    {/*                        const updatedSkus = [...formChannelData.selectedSkus];*/}
                    {/*                        updatedSkus[index] = { ...sku, listing_sku: e.target.value };*/}
                    {/*                        setFormChannelData(prevFormSkuData => ({*/}
                    {/*                            ...prevFormSkuData,*/}
                    {/*                            selectedSkus: updatedSkus*/}
                    {/*                        }));*/}
                    {/*                    }}*/}
                    {/*                    className="w-1/3 border-gray-300 rounded-md shadow-sm bg-white p-3 border"*/}
                    {/*                />*/}
                    {/*            </div>*/}
                    {/*        ))}*/}
                    {/*    </div>*/}
                    {/*</div>*/}



                    <div className="flex justify-end mt-4">
                        <button
                            type="submit"
                            className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
                        >
                            Add Location
                        </button>
                    </div>
                </form>
                {submitSuccess && <div>Your form has been submitted successfully!</div>}
                {submitError && <div>Error submitting form. Please try again.</div>}
            </div>
        </>
    );
};

export default LocationSupplierDashboard;