
const BankDetails = () => {

    return (
        <>
            <div>
                <div className="bg-white overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6 border-b border-gray-200">
                        <h3 className="text-lg leading-6 font-medium text-gray-900"> Bank & Tax details </h3>
                    </div>
                    <div className="px-4 py-5 sm:p-6">
                        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">

                            {/* Bank details */}
                            <div className="bg-blue-50 p-4 rounded sm:col-span-2">
                                <h4 className="text-sm font-medium uppercase text-blue-700 mb-3">Bank details</h4>
                                <table class="min-w-full divide-y divide-gray-200">
                                    {/*<thead class="bg-gray-50">*/}
                                    {/*    <tr>*/}
                                    {/*        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">*/}
                                    {/*            Detail*/}
                                    {/*        </th>*/}
                                    {/*        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">*/}
                                    {/*            Information*/}
                                    {/*        </th>*/}
                                    {/*    </tr>*/}
                                    {/*</thead>*/}
                                    <tbody class="bg-white divide-y divide-gray-200">
                                        {/*<!-- Account Number -->*/}
                                        <tr>
                                            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                Account number:
                                            </td>
                                            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                9VAL031357955413
                                            </td>
                                        </tr>
                                        {/*<!-- Account Name -->*/}
                                        <tr>
                                            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                Account name:
                                            </td>
                                            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                Beyobo Store House
                                            </td>
                                        </tr>
                                        {/*<!-- IFSC -->*/}
                                        <tr>
                                            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                IFSC:
                                            </td>
                                            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                DBSS0IN0811
                                            </td>
                                        </tr>
                                        {/*<!-- Account Type -->*/}
                                        <tr>
                                            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                Account Type:
                                            </td>
                                            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                Current Account
                                            </td>
                                        </tr>
                                        {/*<!-- Bank Name -->*/}
                                        <tr>
                                            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                Bank name:
                                            </td>
                                            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                State Bank of America
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>


                            {/* Tax details */}
                            <div className="bg-blue-50 p-4 rounded sm:col-span-2">
                                <h4 className="text-sm font-medium uppercase text-blue-700 mb-3">Tax details</h4>
                                <table class="min-w-full divide-y divide-gray-200">
                                    <tbody class="bg-white divide-y divide-gray-200">
                                        {/*<!-- Indian GST Number -->*/}
                                        <tr>
                                            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">Indian GST number:</td>
                                            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">29AAFCV5265N1ZK</td>
                                        </tr>
                                        {/*<!-- GST State -->*/}
                                        <tr>
                                            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">GST State:</td>
                                            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">Delhi</td>
                                        </tr>
                                        {/*<!-- GST Certificate -->*/}
                                        <tr>
                                            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">GST Certificate:</td>
                                            <td class="px-6 py-4 whitespace-nowrap text-sm text-brand-500">Download</td>
                                        </tr>
                                        {/*<!-- Company Signature -->*/}
                                        <tr>
                                            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">Company Signature:</td>
                                            <td class="px-6 py-4 whitespace-nowrap text-sm text-brand-500">Download</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>

                        </div>

                        <div className="mt-6 flex gap-5">
                            <button className="inline-flex justify-center py-2 px-4 border border-brand-300 shadow-sm text-sm font-medium rounded-md text-blue-600 bg-white hover:text-white hover:bg-brand-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                                Edit
                            </button>

                            <button className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BankDetails;
