import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter'
import React, { useState, useEffect, useContext, useCallback } from 'react';
import DataModal from '../../../../components/modal';
import AuthContext from '../../../../context/AuthContext';
import moment from 'moment'
import CustomTable from '../../../../components/customTable';
const AdminFinAdDataGrid = () => {

    const columns = [
        { header: 'ID', key: 'id' },
        { header: 'Company ID', key: 'company_id' },
        { header: 'Channel ID', key: 'channel_id' },
        { header: 'AD Cost', key: 'adcost' },
        { header: 'AD Date', key: 'ad_month' },
        { header: 'SKU', key: 'sku' },
    ];

    //const columns = [

    //    { name: 'id', defaultFlex: 1, header: ' Id', type: 'number', },
    //    { name: 'company_id', defaultFlex: 1, header: 'Company Id', type: 'number', },
    //    { name: 'channel_id', defaultFlex: 1, header: 'Channel Id', type: 'number', },
    //    { name: 'adcost', defaultFlex: 1, header: ' Ad Cost', type: 'number', },
    //    {
    //        name: 'ad_month',
    //        header: 'Ad Date',
    //        defaultFlex: 1,
    //        filterEditorProps: (props, { index }) => {
    //            return {
    //                dateFormat: 'DD-MM-YYYY', // Updated date format
    //                cancelButton: false,
    //                highlightWeekends: false,
    //                placeholder: index == 1 ? 'Created date is before...' : 'Created date is after...'
    //            }
    //        },
    //        render: ({ value, cellProps }) => {
    //            return moment(value).format('DD-MM-YYYY') // Updated to match ShipmentDataGrid format
    //        }
    //    },
    //    { name: 'sku', defaultFlex: 1, header: 'SKU', type: 'string' },

    //    // {name: 'pending', defaultFlex:1,header:'Pending', type:Number,render: ({ value }) => formatCurrency(value)},
    //];



    //      const defaultfilter = [

    //        { name: 'id', operator: 'gte', type: 'number' ,value: 0},
    //        { name: 'company_id', operator: 'gte', type: 'number' ,value: 0},
    //        { name: 'channel_id', operator: 'gte', type: 'number' ,value: 0},
    //        { name: 'adcost', operator: 'gte', type: 'number' ,value: 0},
    //        { name: 'ad_month', operator: 'before', type: 'date', value: '' },
    //        { name: 'sku', operator: 'startsWith', type: 'string' ,value: ''},

    //           //{ name: 'sku_id', operator: 'gte', type: 'number' ,value: 0},

    //        //{ name: 'pending', operator: 'gte', type: 'number' ,value: 0},

    //];



    //const [filterValue, setFilterValue] = useState(defaultfilter);
    const [dataSource, setDataSource] = useState([]);
    const { authToken } = useContext(AuthContext);
    const [gridData, setGridData] = useState([]); // New state for actual data array

    const loadData = useCallback(() => {
        const newDataSource = () => {
            const limit = 50;
            const url = `${process.env.REACT_APP_BASE_API_URL}/advertisement/complete_list/` + '?limit=' + limit;
            return fetch(url, {
                headers: {
                    'Authorization': `Token ${authToken}`
                }
            })
                .then(response => response.json())
                .then(data => {
                    const formattedData = data.map(item => ({
                        ...item,
                        order_date: moment(item.order_date).format('DD-MM-YYYY')
                    }));
                    setGridData(formattedData); // Update gridData with the actual data array
                    return { data: formattedData }; // Return the data for dataSource
                });
        }

        setDataSource(newDataSource)
    }, []);

    useEffect(() => {
        loadData();
    }, [loadData]); // Ensure loadData is stable and doesn't change unless necessary



    const gridStyle = { minHeight: 550 }


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);

    const onRowClick = useCallback((rowProps) => {
        setSelectedRowData(rowProps.data);
        setIsModalOpen(true);
    }, []);

    return (
        <div>
            <CustomTable data={gridData} columns={columns} onRowClick={() => { }} />

            {/*<ReactDataGrid*/}
            {/*    style={gridStyle}*/}
            {/*    idProperty="id"*/}
            {/*    pagination='local'*/}
            {/*    columns={columns}*/}
            {/*    dataSource={dataSource}*/}
            {/*    defaultLimit={50}*/}
                
            {/*    onRowClick={onRowClick}*/}
            {/*/>*/}
            {/*defaultFilterValue={defaultfilter}*/}
            {/*onFilterValueChange={setFilterValue}*/}

            {/*<DataModal*/}
            {/*    isOpen={isModalOpen}*/}
            {/*    onClose={() => setIsModalOpen(false)}*/}
            {/*    data={selectedRowData}*/}
            {/*    columns={columns}*/}
            {/*/>*/}
        </div>
    )
};


export default AdminFinAdDataGrid;