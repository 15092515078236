import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../../context/AuthContext';

function VariantDrrComponent() {
    const [data, setData] = useState([]);
    const { authToken } = useContext(AuthContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/variant-drr/`, {
                    headers: {
                        'Authorization': `Token ${authToken}`,
                    },
                });
                if (!response.ok) throw new Error('Network response was not ok');
                const jsonData = await response.json();
                setData(jsonData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [authToken]);

    const filteredData = searchTerm
        ? data.filter(row =>
            Object.values(row).some(value =>
                value.toString().toLowerCase().includes(searchTerm.toLowerCase())))
        : data;

    const totalPages = Math.ceil(filteredData.length / rowsPerPage);
    const currentData = filteredData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

    const pageNumbersRange = () => {
        const rangeSize = 10;
        let start = Math.max(currentPage - rangeSize / 2, 1);
        let end = Math.min(start + rangeSize - 1, totalPages);

        if (end - start + 1 < rangeSize) {
            start = Math.max(end - rangeSize + 1, 1);
        }

        return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    };

    const downloadCSV = () => {
        const csvRows = [];
        const headers = data[0] ? Object.keys(data[0]) : [];
        csvRows.push(headers.join(','));

        for (const row of data) {
            const values = headers.map(header => {
                const escaped = ('' + row[header]).replace(/"/g, '\\"');
                return `"${escaped}"`;
            });
            csvRows.push(values.join(','));
        }

        const blob = new Blob([csvRows.join('\n')], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', 'variant_drr_data.csv');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    return (
        <div className="bg-white m-5 rounded-lg" style={{ padding: '20px' }}>
            <h2 className="text-2xl" style={{ fontWeight: 'bold', textAlign: 'center', marginBottom: '20px' }}>VARIANT DRR Details</h2>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                <div>
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{ padding: '10px', width: '200px' }}
                        className="border-2 w-1/2 rounded-lg"
                    />
                </div>

                <div className="flex gap-10">
                    <div>
                        <p>Rows per page:</p>
                        <select
                            value={rowsPerPage}
                            onChange={(e) => setRowsPerPage(Number(e.target.value))}
                            className="border-2 rounded-lg"
                            style={{ padding: '10px', marginLeft: '5px' }}
                        >
                            {[5, 10, 15, 20].map(size => <option key={size} value={size}>{size} rows</option>)}
                        </select>
                    </div>

                    <button
                        onClick={downloadCSV}
                        style={{ padding: '10px', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}
                    >
                        Download CSV
                    </button>
                </div>
            </div>

            <div style={{ overflowX: 'auto' }}>
                <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px', border: '1px solid #ccc' }}>
                    <thead>
                        <tr>
                            {data[0] && Object.keys(data[0]).map(key => (
                                <th key={key} style={{ padding: '8px', border: '1px solid #ddd', backgroundColor: '#f3f3f3' }}>{key}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {currentData.map((row, index) => (
                            <tr key={index}>
                                {Object.values(row).map((value, idx) => (
                                    <td key={idx} style={{ padding: '8px', border: '1px solid #ddd' }}>{value}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px', marginTop: '20px' }}>
                <button onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1} style={{ padding: '8px 16px', cursor: 'pointer', backgroundColor: '#007bff', color: 'white', border: 'none', borderRadius: '5px' }}>
                    Previous
                </button>
                {pageNumbersRange().map(pageNumber => (
                    <button key={pageNumber} onClick={() => setCurrentPage(pageNumber)} style={{ padding: '8px 12px', cursor: 'pointer', backgroundColor: currentPage === pageNumber ? '#0056b3' : '#007bff', color: 'white', border: 'none', borderRadius: '5px' }}>
                        {pageNumber}
                    </button>
                ))}
                <button onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages} style={{ padding: '8px 16px', cursor: 'pointer', backgroundColor: '#007bff', color: 'white', border: 'none', borderRadius: '5px' }}>
                    Next
                </button>
            </div>
        </div>
    );
}

export default VariantDrrComponent;
