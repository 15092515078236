import '@inovua/reactdatagrid-community/index.css'
import React, { useState, useEffect, useContext, useCallback } from 'react';
import AuthContext from '../../../../context/AuthContext';
import moment from 'moment'
import CustomTable from '../../../../components/customTable';

const AdminChannelDataGrid = () => {

    const columns = [
        { header: 'ID', key: 'id' },
        { header: 'Company ID', key: 'company_id' },
        { header: 'Name', key: 'name' },
        { header: 'Platform', key: 'platform' },
        { header: 'Credentials', key: 'credentials' },
        { header: 'OMS Channel ID', key: 'oms_channel_id' },
    ];

    const [dataSource, setDataSource] = useState([]);
    const { authToken } = useContext(AuthContext);
    const [gridData, setGridData] = useState([]); // New state for actual data array

    const loadData = useCallback(() => {
        const newDataSource = () => {
            const limit = 50;
            const url = `${process.env.REACT_APP_BASE_API_URL}/channels/complete_list/` + '?limit=' + limit;
            return fetch(url, {
                headers: {
                    'Authorization': `Token ${authToken}`
                }
            })
                .then(response => response.json())
                .then(data => {
                    const formattedData = data.map(item => ({
                        ...item,
                        order_date: moment(item.order_date).format('DD-MM-YYYY')
                    }));
                    setGridData(formattedData); // Update gridData with the actual data array
                    return { data: formattedData }; // Return the data for dataSource
                });
        }

        setDataSource(newDataSource)
    }, []);

    useEffect(() => {
        loadData();
    }, [loadData]); // Ensure loadData is stable and doesn't change unless necessary


    return (
        <div>
            <CustomTable data={gridData} columns={columns} onRowClick={() => { }} />
        </div>
    )
};


export default AdminChannelDataGrid;