import {
  MRT_GlobalFilterTextField,
  MRT_TableBodyCellValue,
  MRT_TablePagination,
  MRT_ToolbarAlertBanner,
  flexRender,
  useMaterialReactTable,
} from "material-react-table";
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

const data = [
  {
    sku: "97386188",
    style: "30272183",
    skuCode: "SLCTTOPS97386188",
    hsn: "62064000",
    brand: "StyleCast",
    vendorArticleNumber: "4520083",
    vendorArticleName: "Adults-Women Light Green Top",
    size: "L",
    colour: "Light Green",
    mrp: "1699",
  },
  {
    sku: "97386188",
    style: "30272183",
    skuCode: "SLCTTOPS97386188",
    hsn: "62064000",
    brand: "StyleCast",
    vendorArticleNumber: "4520083",
    vendorArticleName: "Adults-Women Light Green Top",
    size: "L",
    colour: "Light Green",
    mrp: "1699",
  },
  {
    sku: "97386188",
    style: "30272183",
    skuCode: "SLCTTOPS97386188",
    hsn: "62064000",
    brand: "StyleCast",
    vendorArticleNumber: "4520083",
    vendorArticleName: "Adults-Women Light Green Top",
    size: "L",
    colour: "Light Green",
    mrp: "1699",
  },
  {
    sku: "97386188",
    style: "30272183",
    skuCode: "SLCTTOPS97386188",
    hsn: "62064000",
    brand: "StyleCast",
    vendorArticleNumber: "4520083",
    vendorArticleName: "Adults-Women Light Green Top",
    size: "L",
    colour: "Light Green",
    mrp: "1699",
  },
];

const columns = [
  {
    accessorKey: "sku",
    header: "SKU Id",
  },
  {
    accessorKey: "style",
    header: "Style Id",
  },
  {
    accessorKey: "skuCode",
    header: "SKU Code",
  },
  {
    accessorKey: "hsn",
    header: "HSN Code",
  },
  {
    accessorKey: "brand",
    header: "Brand",
  },
  {
    accessorKey: "vendorArticleNumber",
    header: "Vendor Article Number",
  },
  {
    accessorKey: "vendorArticleName",
    header: "Vendor Article Name",
  },
  {
    accessorKey: "size",
    header: "Size",
  },
  {
    accessorKey: "colour",
    header: "Colour",
  },
  {
    accessorKey: "mrp",
    header: "MRP",
  },
];

const Supplier = () => {
  const table = useMaterialReactTable({
    columns,
    data,
    initialState: {
      pagination: { pageSize: 5, pageIndex: 0 },
      showGlobalFilter: true,
    },
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 15],
      variant: "outlined",
    },
    paginationDisplayMode: "pages",
  });

  return (
    <Stack sx={{ m: "2rem 0" }} style={{ width: "100%", marginLeft: 220 }}>
      {/* <Typography variant="h4">My Custom Headless Table</Typography> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <MRT_GlobalFilterTextField table={table} />
        <MRT_TablePagination table={table} />
      </Box>

      <TableContainer>
        <Table>
          <TableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableCell align="center" variant="head" key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.Header ??
                            header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {table.getRowModel().rows.map((row, rowIndex) => (
              <TableRow key={row.id} selected={row.getIsSelected()}>
                {row.getVisibleCells().map((cell, _columnIndex) => (
                  <TableCell align="center" variant="body" key={cell.id}>
                    {/* Use MRT's cell renderer that provides better logic than flexRender */}
                    <MRT_TableBodyCellValue
                      cell={cell}
                      table={table}
                      staticRowIndex={rowIndex} //just for batch row selection to work
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <MRT_ToolbarAlertBanner stackAlertBanner table={table} />
    </Stack>
  );
};

export default Supplier;
