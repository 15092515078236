import React, { createContext, useContext } from 'react';
import { toast, Bounce } from 'react-toastify';

const ToastContext = createContext();

export const useToast = () => useContext(ToastContext);

const toastOptions = {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
  transition: Bounce,
};

export const ToastProvider = ({ children }) => {
 

  const toastSuccess = (message, options = {}) => {
    toast.success('🦄 ' + message, { ...toastOptions, ...options });
  };

  const toastInfo = (message, options = {}) => {
    toast.info('🦄 ' + message, { ...toastOptions, ...options });
  };

  const toastError = (message, options = {}) => {
    toast.error('🦄 ' + message, { ...toastOptions, ...options });
  };

  return (
    <ToastContext.Provider value={{ toastSuccess,toastInfo,toastError}}>
      {children}
    </ToastContext.Provider>
  );
};
