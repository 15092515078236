import ReactApexChart from "react-apexcharts";

const SplineChart = (props) => {
  const { options, series } = props;
  return (
    <ReactApexChart
      options={options}
      series={series}
      type="area"
      height={219}
    />
  );
};

export default SplineChart;
