import React, { useState, useContext, useEffect } from 'react';
import AuthContext from "../../../../src/context/AuthContext";
import BeyoboNavbar from "../../../components/beyobonavbar";
import ShipmentSidenav from "../payments/shipmentsidenav";
import moment from 'moment';

const ShipmentLists = () => {
    const { authToken } = useContext(AuthContext);
    const [gridData, setGridData] = useState([]);
    const [isQRPopupOpen, setQRPopupOpen] = useState(false);
    const [selectedShipmentId, setSelectedShipmentId] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const url = `${process.env.REACT_APP_BASE_API_URL}/shipmentvariant/?limit=50`;
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Token ${authToken}`
                }
            });
            const data = await response.json();
            const formattedData = data.map(item => ({
                ...item,
                order_date: moment(item.order_date).format('DD-MM-YYYY')
            }));
            setGridData(formattedData);
        };
        fetchData();
    }, [authToken]);

    const handleQRClick = (shipmentId) => {
        setSelectedShipmentId(shipmentId);
        setQRPopupOpen(true);
    };

    const handleCloseQR = () => {
        setQRPopupOpen(false);
        setSelectedShipmentId(null);
    };

    const handlePrintQR = () => {
        // This function should handle printing the QR code
        // The implementation will depend on how you're generating/rendering the QR code
        console.log('Printing QR for Shipment ID:', selectedShipmentId);
        // Example: window.print(); // Simplest way to trigger print, but you might need to customize this
    };

    return (
        <>
            <BeyoboNavbar />
            <div className="mx-5">
                <div className="flex p-4">
                    <p className="mt-3 text-2xl font-bold">Shipment Lists</p>
                </div>
                <div className="overflow-x-auto mt-6">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Name
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {gridData.map((item) => (
                                <tr key={item.shipment_id}>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        {item.name}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <button onClick={() => handleQRClick(item.shipment_id)} className="text-white bg-blue-500 hover:bg-blue-700 font-medium rounded-lg text-sm px-5 py-2.5 mr-4">Get QR</button>
                                        <a href={`shipment-list/${item.shipment_id}`} className="text-white bg-green-500 hover:bg-green-700 font-medium rounded-lg text-sm px-5 py-2.5">View Details</a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {isQRPopupOpen && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                        <div className="relative bg-white p-10 rounded-lg shadow-lg">
                            <button onClick={handleCloseQR} className="absolute top-0 right-0 mt-2 mr-2 bg-transparent text-black font-semibold">
                                X
                            </button>
                            <h2 className="text-lg">QR Code for Shipment ID: {selectedShipmentId}</h2>
                            {/* Implement QR code rendering based on `selectedShipmentId` */}
                            <div className="flex justify-center mt-4">
                                <button onClick={handlePrintQR} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700">Print QR</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default ShipmentLists;
