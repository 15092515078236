import React, { useState, useEffect, useContext } from "react";
import DashboardBox from "../../../components/dashboardbox";
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { AuthContext } from "../../../context/AuthContext";
import SalesDrrComponent from "../supplier_dashboard/sales_drr";
import VariantDrrComponent from "./variant_drr";
import FinancialData from "./financial-data";

// Define SVG icons for each tab
const SvgIcons = {
  Suppliers: () => (
    <svg
      className="mr-2 h-6 w-6"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
    >
      <path d="M3 12h18M3 6h18M3 18h18" />
    </svg>
  ),
  XYZ: () => (
    <svg
      className="mr-2 h-6 w-6"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
    >
      <path d="M21 16V8a2 2 0 00-2-2h-4V4a2 2 0 00-2-2H3a2 2 0 00-2 2v12a2 2 0 002 2h16a2 2 0 002-2zM7.5 15.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM17.5 15.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
    </svg>
  ),
  Location: () => (
    <svg
      className="mr-2 h-6 w-6"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
    >
      <path d="M12 2C8.134 2 5 5.134 5 9c0 5 7 13 7 13s7-8 7-13c0-3.866-3.134-7-7-7z" />
      <circle cx="12" cy="9" r="3" />
    </svg>
  ),
  Dubai: () => (
    <svg
      className="mr-2 h-6 w-6"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
    >
      <path d="M12 2C8.134 2 5 5.134 5 9c0 1.5.5 3 1.5 4.5S9 16 9 16h6s1.5-1.5 2.5-2.5S19 10.5 19 9c0-3.866-3.134-7-7-7z" />
      <circle cx="12" cy="9" r="3" />
    </svg>
  ),
  India: () => (
    <svg
      className="mr-2 h-6 w-6"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
    >
      <path d="M12 2C8.134 2 5 5.134 5 9c0 2 .8 3.9 2.1 5.3C8.4 15.7 10 17 10 17h4s1.6-1.3 2.9-2.7C18.2 12.9 19 11 19 9c0-3.866-3.134-7-7-7z" />
      <circle cx="12" cy="9" r="3" />
    </svg>
  ),
  China: () => (
    <svg
      className="mr-2 h-6 w-6"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
    >
      <path d="M12 2C8.134 2 5 5.134 5 9c0 2.5 1 4.5 3 6s4 2.5 4 2.5h0s2-0.5 4-2.5 3-3.5 3-6c0-3.866-3.134-7-7-7z" />
      <circle cx="12" cy="9" r="3" />
    </svg>
  ),
  Placeholder: () => (
    <svg
      className="mr-2 h-6 w-6"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
    >
      <path d="M12 4v16m8-8H4" />
    </svg>
  ),
};

const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState("Suppliers");
  const [openSubTabs, setOpenSubTabs] = useState({
    Suppliers: true,
    Location: false,
  });
  const [selectedPlatform, setSelectedPlatform] = useState("Amazon"); // New state for tracking dropdown selection

  const [amazontimeframe, setAmazonTimeframe] = useState("monthly");
  const [amazondata, setAmazonData] = useState([]);
  const [flipkarttimeframe, setFlipkartTimeframe] = useState("monthly");
  const [flipkartdata, setFlipkartData] = useState([]);
  const { authToken } = useContext(AuthContext);
  const [topSellingSkus, setTopSellingSkus] = useState([]);
  const [leastSellingSkus, setLeastSellingSkus] = useState([]);

  const [selectedAmazonSku, setSelectedAmazonSku] = useState("");

  const [selectedFlipkartSku, setSelectedFlipkartSku] = useState("");

  const [amazonskus, setAmazonSkus] = useState([]);
  const [flipkartskus, setFlipkartSkus] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_API_URL}/amazonuniqueskus/`,
          {
            headers: {
              Authorization: `Token ${authToken}`,
            },
          }
        );
        if (!response.ok) throw new Error("Data fetch failed");
        const jsonData = await response.json();
        setAmazonSkus(jsonData);
        if (amazondata.length > 0) {
          setSelectedAmazonSku(jsonData[0]); // Automatically select the first SKU
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [authToken]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${
            process.env.REACT_APP_BASE_API_URL
          }/amazonorderanalysis/?amazontimeframe=${amazontimeframe}&amazonsku=${encodeURIComponent(
            selectedAmazonSku
          )}`,
          {
            headers: {
              Authorization: `Token ${authToken}`,
            },
          }
        );
        if (!response.ok) throw new Error("Data fetch failed");
        const jsonData = await response.json();
        setAmazonData(jsonData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [amazontimeframe, authToken, selectedAmazonSku]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_API_URL}/flipkartuniqueskus/`,
          {
            headers: {
              Authorization: `Token ${authToken}`,
            },
          }
        );
        if (!response.ok) throw new Error("Data fetch failed");
        const jsonData = await response.json();
        setFlipkartSkus(jsonData);
        if (flipkartdata.length > 0) {
          setSelectedFlipkartSku(jsonData[0]); // Automatically select the first SKU
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [authToken]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${
            process.env.REACT_APP_BASE_API_URL
          }/flipkartorderanalysis/?flipkarttimeframe=${flipkarttimeframe}&flipkartsku=${encodeURIComponent(
            selectedFlipkartSku
          )}`,
          {
            headers: {
              Authorization: `Token ${authToken}`,
            },
          }
        );
        if (!response.ok) throw new Error("Data fetch failed");
        const jsonData = await response.json();
        setFlipkartData(jsonData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [flipkarttimeframe, authToken, selectedFlipkartSku]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_API_URL}/amazonskuanalysis/`, {
      headers: {
        Authorization: `Token ${authToken}`,
      },
    }) // Adjust this URL based on your actual endpoint
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setTopSellingSkus(data.top_selling_skus);
        setLeastSellingSkus(data.least_selling_skus);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError(error.toString());
        setLoading(false);
      });
  }, [authToken]);

  const activeStyle = "bg-brand-300 text-white";
  const inactiveStyle = "text-gray-700 hover:bg-gray-100 hover:text-gray-900";

  const getButtonClasses = (tabName) => {
    return `w-full text-left py-2 px-4 rounded-lg font-semibold flex items-center ${
      activeTab === tabName ? activeStyle : inactiveStyle
    }`;
  };

  const toggleSubTabs = (tabName) => {
    setActiveTab(tabName); // Set the active tab to render its content
    // Also toggle visibility for sub-tabs
    setOpenSubTabs((prev) => ({
      ...prev,
      [tabName]: !prev[tabName],
    }));
  };

  const renderAmazonChart = () => {
    if (amazontimeframe === "monthly") {
      return (
        <BarChart
          width={1000}
          height={400}
          data={amazondata}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="period_start" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="total_amount" fill="#8884d8" />
        </BarChart>
      );
    } else {
      return (
        <LineChart
          width={1000}
          height={400}
          data={amazondata}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="period_start" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="total_amount" stroke="#8884d8" />
        </LineChart>
      );
    }
  };

  const renderFlipkartChart = () => {
    if (flipkarttimeframe === "monthly") {
      return (
        <BarChart
          width={1000}
          height={400}
          data={flipkartdata}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="period_start" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="total_amount" fill="#8884d8" />
        </BarChart>
      );
    } else {
      return (
        <LineChart
          width={1000}
          height={400}
          data={flipkartdata}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="period_start" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="total_amount" stroke="#8884d8" />
        </LineChart>
      );
    }
  };

  const SidebarButton = ({ tabName, hasChildren }) => (
    <div>
      <button
        onClick={() => toggleSubTabs(tabName)}
        className={getButtonClasses(tabName)}
      >
        {(SvgIcons[tabName] || SvgIcons.Placeholder)()}
        {tabName}
      </button>
      {hasChildren && openSubTabs[tabName] && (
        <div className="pl-6">
          {tabName === "Suppliers" ? <></> : null}

          {/*<SidebarButton tabName="Kingone" />*/}
          {/*<SidebarButton tabName="Kingsound" />*/}
          {/*<SidebarButton tabName="James" />*/}

          {/*tabName === 'Location' ? (*/}
          {/*<>*/}
          {/*    <SidebarButton tabName="Dubai" />*/}
          {/*    <SidebarButton tabName="India" />*/}
          {/*    <SidebarButton tabName="China" />*/}
          {/*</>*/}
          {/*) :*/}
        </div>
      )}
    </div>
  );

  const renderContent = () => {
    switch (activeTab) {
      case "Suppliers":
        return (
          <>
            {/* New Dropdown for selecting the platform */}
            <div className="mt-5">
              <select
                value={selectedPlatform}
                onChange={(e) => setSelectedPlatform(e.target.value)}
                className="rounded-md border-2 border-gray-500 px-4 py-2"
              >
                <option value="Amazon">Amazon</option>
                <option value="Flipkart">Flipkart</option>
                {/* Add more options here as needed */}
              </select>
            </div>

            {/* Conditional rendering based on selectedPlatform */}
            {selectedPlatform === "Amazon" && (
              <div>
                <h2 className="my-5 text-2xl font-black">
                  Amazon Order Totals Chart
                </h2>

                <div className="mb-10 flex justify-between">
                  <div>
                    <label
                      htmlFor="timeframeSelect"
                      className="mb-2 block text-sm font-medium text-gray-700"
                    >
                      Select Timeframe:
                    </label>
                    <select
                      value={amazontimeframe}
                      onChange={(e) => setAmazonTimeframe(e.target.value)}
                      className="rounded-md border border-gray-300 bg-white p-3 shadow-sm"
                    >
                      <option value="monthly">Monthly</option>
                      <option value="weekly">Weekly</option>
                    </select>
                  </div>

                  {/* SKU selection moved above the chart */}
                  <div>
                    <label
                      htmlFor="skuSelect"
                      className="mb-2 block text-sm font-medium text-gray-700"
                    >
                      Select SKU:
                    </label>
                    <select
                      id="skuSelect"
                      value={selectedAmazonSku}
                      onChange={(e) => setSelectedAmazonSku(e.target.value)}
                      className="rounded-md border border-gray-300 bg-white p-3 shadow-sm"
                    >
                      <option value="All">All</option> {/* Add this line */}
                      {amazonskus.map((sku) => (
                        <option key={sku} value={sku}>
                          {sku}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {/* Now rendering the chart */}
                {renderAmazonChart()}

                <br></br>

                <div className="mt-10 flex justify-around gap-10">
                  <div className="box w-1/2 rounded-lg bg-white p-5 shadow-md">
                    <h3 className="mb-4 text-center text-lg font-semibold">
                      Top 5 Selling SKUs on Amazon
                    </h3>
                    <div className="mb-4 flex justify-between font-medium">
                      <span>SKU Name</span>
                      <span>Total Sale</span>
                    </div>
                    <ul>
                      {topSellingSkus.map((sku, index) => (
                        <li
                          key={index}
                          className="flex justify-between border-b py-4"
                        >
                          <span className="mr-4">{sku.Sku}</span>
                          <span>₹{sku.total_sales.toFixed(2)}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="box w-1/2 rounded-lg bg-white p-5 shadow-md">
                    <h3 className="mb-4 text-center text-lg font-semibold">
                      Bottom 5 Selling SKUs on Amazon
                    </h3>
                    <div className="mb-4 flex justify-between font-medium">
                      <span>SKU Name</span>
                      <span>Total Sale</span>
                    </div>
                    <ul>
                      {leastSellingSkus.map((sku, index) => (
                        <li
                          key={index}
                          className="flex justify-between border-b py-4"
                        >
                          <span className="mr-4">{sku.Sku}</span>
                          <span>₹{sku.total_sales.toFixed(2)}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            )}

            {selectedPlatform === "Flipkart" && (
              <div>
                <h2 className="my-5 text-2xl font-black">
                  Flipkart Order Totals Chart
                </h2>

                <div className="mb-10 flex justify-between">
                  <div>
                    <label
                      htmlFor="timeframeSelect"
                      className="mb-2 block text-sm font-medium text-gray-700"
                    >
                      Select Timeframe:
                    </label>

                    <select
                      value={flipkarttimeframe}
                      onChange={(e) => setFlipkartTimeframe(e.target.value)}
                      className="rounded-md border border-gray-300 bg-white p-3 shadow-sm"
                    >
                      <option value="monthly">Monthly</option>
                      <option value="weekly">Weekly</option>
                    </select>
                  </div>

                  {/* SKU selection moved above the chart */}
                  <div>
                    <label
                      htmlFor="skuSelect"
                      className="mb-2 block text-sm font-medium text-gray-700"
                    >
                      Select SKU:
                    </label>

                    <select
                      id="skuSelect"
                      value={selectedFlipkartSku}
                      onChange={(e) => setSelectedFlipkartSku(e.target.value)}
                      className="rounded-md border border-gray-300 bg-white p-3 shadow-sm"
                    >
                      <option value="All">All</option> {/* Add this line */}
                      {flipkartskus.map((sku) => (
                        <option key={sku} value={sku}>
                          {sku}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {/* Now rendering the chart */}
                {renderFlipkartChart()}
              </div>
            )}
          </>
        );

      //case 'XYZ':
      //    return <div>Content for XYZ</div>;
      //case 'Location':
      //    return <div>Content for Location</div>;
      //case 'Dubai':
      //    return <div>Content for Dubai</div>;
      //case 'India':
      //    return <div>Content for India</div>;
      //case 'China':
      //    return <div>Content for China</div>;
      // Add more cases as needed
      default:
        return <div>Select a tab to view content</div>;
    }
  };

  return (
    <>
      <div className="my-5 flex">
        {/*<div className="flex px-5 gap-3 w-[25%] items-center text-lg font-semibold">*/}
        {/*    <img className="w-10 h-10 rounded-full object-cover" src="https://upload.wikimedia.org/wikipedia/commons/9/99/Elon_Musk_Colorado_2022_%28cropped2%29.jpg" alt="" />*/}
        {/*    <span>Elon Musk</span>*/}
        {/*</div>*/}

        <div className="w-[100%] self-center">
          <div class="flex w-full items-center overflow-hidden rounded-md border">
            <button type="submit" class="border-2 py-2 px-6">
              <svg
                class="h-7 w-7 text-gray-600"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
            </button>
            <input
              type="text"
              placeholder="Search your data..."
              className="w-full px-4 py-[10px]"
            />
          </div>
        </div>
      </div>

      <div className="flex w-full gap-5">
        <div className="flex h-fit w-[25%] flex-col space-y-5 rounded-lg p-4">
          {/* Suppliers Sidebar */}
          <div className="my-3 flex flex-col space-y-2 rounded-lg bg-white p-2 shadow">
            <SidebarButton tabName="Suppliers" hasChildren />
            <SidebarButton tabName="XYZ" />
          </div>
          {/* Location Sidebar */}
          <div className="my-3 flex flex-col space-y-2 rounded-lg bg-white p-2 shadow">
            <SidebarButton tabName="Location" hasChildren />
          </div>
        </div>

        <div className="my-3 h-fit flex-1 rounded-lg bg-white p-5">
          {renderContent()}
        </div>
      </div>

      <br></br>

      <SalesDrrComponent />
      <br></br>
      <VariantDrrComponent></VariantDrrComponent>
      <br></br>
      <br></br>
    </>
  );
};

export default AdminDashboard;

//<div className="w-full flex flex-wrap gap-5 mt-5">
//    <DashboardBox
//        imgSrc="https://sellercentral.jennifer-in.com/assets/images/svg/shipmentvalue.svg"
//        imgAlt="Total Order"
//        currency="3456"
//        description="Total Order"
//    />
//    <DashboardBox
//        imgSrc="https://sellercentral.jennifer-in.com/assets/images/svg/amazoncredit.svg"
//        imgAlt="Total Sales"
//        currency="$3.5 M"
//        description="Total Sales"

//    />
//    <DashboardBox
//        imgSrc="https://sellercentral.jennifer-in.com/assets/images/svg/amazoncredit.svg"
//        imgAlt="Total Shipments"
//        currency="1234"
//        description="Total Shipments"

//    />
//</div>

//<div className="flex justify-between items-center">
//    <p>Data for <b>Kingone</b> Supplier and <b>Dubai</b> region</p>
//    <div className="relative inline-block text-gray-700">
//        <select
//            className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
//        >
//            <option value="INR">Indian Rupee</option>
//            <option value="USD">US Dollar</option>
//            <option value="JPY">Japanese Yen</option>
//            <option value="CNY">Chinese RMB</option>
//            <option value="KRW">South Korean Won</option>
//        </select>
//        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
//            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
//        </div>
//    </div>

//    <div className="flex gap-4 items-center">
//        <div className="flex gap-2 items-center">
//            <label htmlFor="start-date" className="block text-sm font-medium text-gray-700">From</label>
//            <input
//                type="date"
//                id="start-date"
//                name="start-date"
//                className="mt-1 p-2 border-2 border-gray-300 rounded-lg cursor-pointer"
//            />
//        </div>
//        <div className="flex gap-2 items-center">
//            <label htmlFor="end-date" className="block text-sm font-medium text-gray-700">To</label>
//            <input
//                type="date"
//                id="end-date"
//                name="end-date"
//                className="mt-1 p-2 border-2 border-gray-300 rounded-lg cursor-pointer"
//            />
//        </div>
//    </div>
//</div>
