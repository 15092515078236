import useApi from "hooks/useApi";
import useApiPaths from "hooks/useApiPath";
import { useEffect, useState, useContext } from "react";
import CustomTable from "components/customTable";
import { useDialog } from "components/dialog";
import AuthContext from '../../../src/context/AuthContext';
import BeyoboNavbar from "../../components/beyobonavbar";
import { TabGroup, Tab } from "../../components/tabGroup"
import CustomSelect from "components/customSelect";
import { useParams } from 'react-router-dom';
import { useToast } from "components/toast";
import { useUserRole } from "context/UserRoleContext";

const CreateShipment = ({ orderId, shipmentCreated }) => {

    const [shipment, setShipment] = useState(null);
    const [creatingShipment, setCreatingShipment] = useState(false);
    const [shipmentVariants, setShipmentVariants] = useState([]);
    const [loading, setLoading] = useState(true);
    const [weight, setWeight] = useState(0);
    const [volumeWeight, setVolumeWeight] = useState(0);
    const [awb, setAwb] = useState('');
    const [shipperName, setShipperName] = useState('');
    const [pickupAddressId, setPickupAddressId] = useState(0);
    const api = useApi();
    const apiPaths = useApiPaths();
    const [addresses, setAddresses] = useState([]);
    const { openDialog, closeDialog } = useDialog();
    const { toastSuccess, toastInfo, toastError } = useToast();

    const handleVariantDispatchQuantityChange = (index, quantity) => {
        const updatedVariants = [...shipmentVariants];
        updatedVariants[index].dispatched_quantity = quantity >= 0 ? Number(quantity) : 0;
        setShipmentVariants(updatedVariants);
    };

    const createAddress = (companyId) => {
        openDialog(<CreateAddress companyId={companyId} addressCreated={() => {
            openDialog(<CreateShipment orderId={orderId} shipmentCreated={shipmentCreated} />, 'large', true)
        }} />, 'large', true)
        return;
    }


    useEffect(() => {
        // Start the async flow once the component mounts
        const initializeShipment = async () => {
            setLoading(true); // Start loading
            try {
                // Step 1: Create a draft shipment
                const s = await api.post(apiPaths.createDraftShipment, { order_id: orderId });
                setShipment(s);

                const a = await api.get(apiPaths.companyAddresses(s.company_id));

                if (a.length === 0) {
                    createAddress(s.company_id);
                    return;
                }

                setAddresses(a);
                // Step 2: Fetch variants using the newly created shipment ID
                // Ensure shipment.id is available by checking if s is truthy and has an id
                if (s && s.id) {
                    const variants = await api.get(apiPaths.shipmentVariants(s.id));
                    console.log(variants);
                    setShipmentVariants(variants);
                }
            } catch (error) {
                console.error('Error initializing shipment:', error);
                // Handle errors as needed
            } finally {
                setLoading(false); // End loading
            }
        };

        initializeShipment();
    }, [orderId]);

    const handleAddressChange = (option) => {
        setPickupAddressId(option.value)
    }

    const createShipmentSubmit = async () => {
        let alertMessage = ''

        if (awb.length <= 0) {
            alertMessage = 'AWB should be not be empty';
        }
        else if (shipperName.length <= 0) {
            alertMessage = 'Shipper Name should not be empty';
        }
        else if (pickupAddressId <= 0) {
            alertMessage = 'Pickup Address should not be empty';
        }
        if (alertMessage.length > 0) {
            alert(alertMessage);
            return;
        }


        setCreatingShipment(true);

        const ship = await api.post(apiPaths.createShipment, {
            shipmentid: shipment.id,
            weight: weight,
            volumeweight: volumeWeight,
            awb: awb,
            pickupAddressId: pickupAddressId,
            shippername: shipperName,
            shipmentvariants: shipmentVariants.map(variant => ({
                shipmentvariantid: variant.id,
                orderedquantity: variant.ordered_quantity,
                dispatchedquantity: variant.dispatched_quantity
            }))
        }).catch(e => { setCreatingShipment(false); });

        setCreatingShipment(false);

        if (ship.id > 0) {
            closeDialog();
            toastSuccess('Shipment Successfully Created');
            shipmentCreated();
        }
        else {
            toastError('Failed to Create Shipment')
        }
    }

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <div>

                <div className="py-6">

                    <br />


                    <div className="flex gap-5 mr-4 justify-end">
                        <strong className="w-20">Required Quantity</strong>
                        <strong className="w-20">Dispatched Quantity</strong>
                    </div>


                    {shipmentVariants.map((v, i) => (
                        <div className="flex items-center my-5 bg-gray-100 p-2 rounded-lg relative">
                            <span className="flex-grow truncate">{v?.variant?.name ?? ''}</span>

                            <span className="w-20 text-center border-2 border-gray-300 rounded focus:outline-none mx-2">{v?.ordered_quantity ?? 'Invalid'}</span>
                            <input
                                type="text"
                                className="w-20 h-8 text-center border-2 border-gray-300 rounded focus:outline-none mx-2"
                                min="0"
                                onChange={(e) => handleVariantDispatchQuantityChange(i, e.target.value)}
                                value={v.dispatched_quantity ?? 0}
                            />
                        </div>
                    ))}



                </div>

                <div>
                    <label className="block mb-2" htmlFor="name">AWB</label>
                    <input
                        type="text"
                        id="conversion_rate"
                        name="conversion_rate"
                        value={awb}
                        required
                        onChange={(e) => setAwb(e.target?.value?.trim() ?? '')}
                        className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                        placeholder="KBADKJBR"
                    />
                </div>

                <div>
                    <div className="flex justify-between mt-3">
                        <label className="block mb-2" htmlFor="address">Pickup Address:</label>
                        {
                            ((shipment?.company_id ?? 0) > 0) &&
                            < button onClick={() => { createAddress(shipment?.company_id ?? 0); }} className="px-3 py-1 bg-brand-500 text-white text-sm border-lg">ADD</button>
                        }
                    </div>

                    <CustomSelect
                        name="address"
                        onChange={(option) => handleAddressChange(option)}
                        options={addresses?.map(w => {
                            return {
                                value: w?.id ?? 0,
                                label: `${w?.full_name ?? ""}, ${w?.address_one ?? ''}, 
                                ${w?.address_two ?? ''}, ${w?.city ?? ''},${w?.state ?? ''},${w.pin_code}`,
                                customLabel: (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {`${w?.full_name ?? ""}, ${w?.address_one ?? ''}, 
                                ${w?.address_two ?? ''}, ${w?.city ?? ''},${w?.state ?? ''},${w.pin_code}`}
                                    </div>
                                )
                            }
                        })}
                        isSearchable={true}
                        className="block w-full mt-1"
                    />

                </div>

                <div>
                    <label className="block mb-2" htmlFor="name">Shipper Name</label>
                    <input
                        type="text"
                        id="conversion_rate"
                        name="conversion_rate"
                        value={shipperName}
                        required
                        onChange={(e) => setShipperName(e.target?.value?.trim() ?? '')}
                        className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                        placeholder="FirstFlight"
                    />
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-4">
                    <div>
                        <label className="block mb-2" htmlFor="name">Weight (in kgs) *</label>
                        <input
                            type="number"
                            id="weight_in_kgs"
                            name="weight_in_kgs"
                            value={weight}
                            onChange={(e) => {
                                const newValue = parseFloat(e.target.value); // Convert input value to float
                                if (newValue > 0) {
                                    setWeight(newValue); // Only update if value is positive
                                } else if (e.target.value === '') {
                                    setWeight(''); // Allow empty field for user to delete/correct their input
                                }
                                // Implicitly, if the value is 0 or negative, it doesn't update the state
                            }}
                            className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                            placeholder="1"
                        />
                    </div>

                    <div>
                        <label className="block mb-2" htmlFor="name">Volumetric Weight (in kgs) *</label>
                        <input
                            type="number"
                            id="volumetric_weight_in_kgs"
                            name="volumetric_weight_in_kgs"
                            className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                            placeholder="1"
                            value={volumeWeight}
                            onChange={(e) => {
                                const newValue = parseFloat(e.target.value); // Convert input value to float
                                if (newValue > 0) {
                                    setVolumeWeight(newValue); // Only update if value is positive
                                } else if (e.target.value === '') {
                                    setVolumeWeight(''); // Allow empty field for user to delete/correct their input
                                }
                                // Implicitly, if the value is 0 or negative, it doesn't update the state
                            }}
                        />
                    </div>
                </div>

                <div className="flex justify-end mt-4">
                    <button disabled={creatingShipment} onClick={() => { closeDialog() }} className="bg-gray-200 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-300 mr-2">
                        Cancel
                    </button>
                    {

                        <button disabled={creatingShipment} onClick={() => { createShipmentSubmit() }} className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700">
                            Submit
                        </button>
                    }
                </div>

            </div >

        </>
    )


}




const PrintBarcode = ({ orderId }) => {

    const api = useApi();
    const apiPaths = useApiPaths();
    const [orderItems, setOrderItems] = useState([]);
    const [printers, setPrinters] = useState([]);
    const [shipment, setShipment] = useState([]);
    const [printerId, setPrinterId] = useState(null);
    const [seletedItems, setSelectedItems] = useState([]);
    const { toastSuccess, toastInfo, toastError } = useToast();

    const fetchPrinters = async () => {
        await api.httpFetch(`http://127.0.0.1:5000/testprinters`, 'GET').then(r => {
            let ps = r?.printers ?? [];
            if (ps.length <= 0) {
                toastInfo('No Printer Found');
            }
            else {
                toastInfo(`${ps.length} Printers found`);
                setPrinters(ps);
            }
        });
    }

    const printLabels = async () => {
        if ((printerId ?? 0) <= 0) {
            toastError(`Printer Not Set`);
            return;
        }
        if (orderItems.length <= 0) {
            toastError('No item is selected to print');
            return;
        }
        toastInfo(`Printing ${orderItems.length} Items`);

        await api.httpFetch(`http://127.0.0.1:5000/printlabelstest`, 'POST', { printerId: printerId, orderId: orderId, items: orderItems }).then(r => {
            console.log(r);
        });
    }


    useEffect(() => {
        api.get(apiPaths.orderItems(orderId)).then(r => {
            setOrderItems(r);
        });
        api.get(apiPaths.orderShipment(orderId)).then(r => {
            setShipment(r);
        });
        fetchPrinters();
    }, [orderId])


    return (
        <>
            <TabGroup>
                <Tab label="Print">
                    <div>
                        <p>Select Printer</p>
                        <CustomSelect
                            name="warehouse"
                            isSearchable={true}
                            className="block w-full mt-3"
                            onChange={(option) => { setPrinterId(option.value) }}
                            options={printers?.map(w => {
                                return {
                                    value: w?.id ?? 0,
                                    label: `${w?.name ?? ""}`,
                                    customLabel: (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {`${w?.name ?? ""}`}
                                        </div>
                                    )
                                }
                            })}
                        />

                        <div className="flex justify-end">
                            <button onClick={() => { printLabels() }} className="bg-brand-300 hover:bg-brand-400 text-white font-bold py-2 px-4 rounded items-center mt-4">Print</button>
                        </div>
                    </div>
                </Tab>
                <Tab label="Items">
                    <CustomTable heading='Items' data={orderItems} columns={[
                        { header: 'Name', key: 'variant.name' },
                        { header: 'Code', key: 'code' }
                    ]} />
                </Tab>
            </TabGroup>


        </>
    )
}


const CreateAddress = ({ companyId, addressCreated }) => {
    const [countries, setCountries] = useState([]);
    const [fullName, setFullname] = useState('');
    const [addressOne, setAddressOne] = useState('');
    const [addressTwo, setAddressTwo] = useState('');
    const [state, setState] = useState('');
    const [pincode, setPincode] = useState('');
    const [city, setCity] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [countryId, setCountryId] = useState(0);
    const [loading, setLoading] = useState(true);
    const [creatingAddress, setCreatingAddress] = useState(false);
    const api = useApi();
    const apiPaths = useApiPaths();
    const { openDialog, closeDialog } = useDialog();
    const { toastSuccess, toastInfo, toastError } = useToast();

    useEffect(() => {
        // Start the async flow once the component mounts
        const getCountries = async () => {
            setLoading(true); // Start loading
            try {
                // Step 1: Create a draft shipment
                const c = await api.get(apiPaths.getCountries);
                setCountries(c);
            } catch (error) {
                console.error('Error initializing countries:', error);
                // Handle errors as needed
            } finally {
                setLoading(false); // End loading
            }
        };

        getCountries();
    }, []);


    const createAddress = () => {

        if (!fullName.trim()) {
            toastInfo('Please enter a full name.');
            return;
        }
        if (!phoneNumber.trim()) {
            toastInfo('Please enter a phone number.');
            return;
        }
        if (!email.trim() || !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
            toastInfo('Please enter a valid email address.');
            return;
        }
        if (!pincode.trim()) {
            toastInfo('Please enter a pincode.');
            return;
        }
        if (!state.trim()) {
            toastInfo('Please enter a state.');
            return;
        }
        if (!city.trim()) {
            toastInfo('Please enter a city.');
            return;
        }
        if (!addressOne.trim()) {
            toastInfo('Please enter the primary address.');
            return;
        }
        if (!countryId) {
            toastInfo('Please select a country.');
            return;
        }

        setCreatingAddress(true);


        const addressData = {
            fullName: fullName,
            phoneNumber: phoneNumber,
            email: email,
            pincode: pincode,
            state: state,
            city: city,
            addressOne: addressOne,
            addressTwo: addressTwo,
            countryId: countryId,
            companyId: companyId
        };

        api.post(apiPaths.createAddress, addressData).then(r => {
            setCreatingAddress(false);
            if (r.id > 0) {
                toastSuccess(`Address Successfully Created!!! Id: ${r?.id ?? 0}`)
                addressCreated()
            }
        }).catch(r => { setCreatingAddress(false); })
    }


    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <div>
                <div>
                    <label className="block mb-2" htmlFor="name">Full Name</label>
                    <input
                        type="text"
                        id="conversion_rate"
                        name="conversion_rate"
                        value={fullName}
                        required
                        onChange={(e) => setFullname(e.target?.value ?? '')}
                        className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                        placeholder="Parul Jain"
                    />
                </div>

                <div>
                    <label className="block mb-2" htmlFor="name">Address One</label>
                    <input
                        type="text"
                        id="conversion_rate"
                        name="conversion_rate"
                        value={addressOne}
                        required
                        onChange={(e) => setAddressOne(e.target?.value ?? '')}
                        className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                        placeholder=""
                    />
                </div>

                <div>
                    <label className="block mb-2" htmlFor="name">Address Two</label>
                    <input
                        type="text"
                        id="conversion_rate"
                        name="conversion_rate"
                        value={addressTwo}
                        required
                        onChange={(e) => setAddressTwo(e.target?.value ?? '')}
                        className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                        placeholder=""
                    />
                </div>

                <div>
                    <label className="block mb-2" htmlFor="name">City</label>
                    <input
                        type="text"
                        id="conversion_rate"
                        name="conversion_rate"
                        value={city}
                        required
                        onChange={(e) => setCity(e.target?.value ?? '')}
                        className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                        placeholder="Delhi"
                    />
                </div>

                <div>
                    <label className="block mb-2" htmlFor="name">State</label>
                    <input
                        type="text"
                        id="conversion_rate"
                        name="conversion_rate"
                        value={state}
                        required
                        onChange={(e) => setState(e.target?.value ?? '')}
                        className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                        placeholder="Assam"
                    />
                </div>

                <label htmlFor="country">Country</label>
                <CustomSelect
                    name="country"
                    onChange={(option) => { setCountryId(option.value) }}
                    options={countries.map(w => {
                        return {
                            value: w?.id ?? 0,
                            label: `${w?.name ?? ''}`,
                            customLabel: (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {`${w?.name ?? ""}`}
                                </div>
                            )
                        }
                    })}
                    isSearchable={true}
                    className="block w-full mt-1"
                />

                <div>
                    <label className="block mb-2" htmlFor="name">Pincode</label>
                    <input
                        type="text"
                        id="conversion_rate"
                        name="conversion_rate"
                        value={pincode}
                        required
                        onChange={(e) => setPincode(e.target?.value?.trim() ?? '')}
                        className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                        placeholder="487631"
                    />
                </div>

                <div>
                    <label className="block mb-2" htmlFor="name">Email</label>
                    <input
                        type="text"
                        id="conversion_rate"
                        name="conversion_rate"
                        value={email}
                        required
                        onChange={(e) => setEmail(e.target?.value?.trim() ?? '')}
                        className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                        placeholder="parul@gmail.com"
                    />
                </div>

                <div>
                    <label className="block mb-2" htmlFor="name">Phone Number(with country code)</label>
                    <input
                        type="text"
                        id="conversion_rate"
                        name="conversion_rate"
                        value={phoneNumber}
                        required
                        onChange={(e) => setPhoneNumber(e.target?.value?.trim() ?? '')}
                        className="w-full border-gray-300 rounded-md shadow-sm bg-white p-3 border"
                        placeholder="+91-9425482356"
                    />
                </div>


                <div className="flex justify-end mt-4">
                    <button disabled={creatingAddress} onClick={() => { closeDialog() }} className="bg-gray-200 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-300 mr-2">
                        Cancel
                    </button>
                    {

                        <button disabled={creatingAddress} onClick={() => { createAddress() }} className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700">
                            Submit
                        </button>
                    }
                </div>

            </div>

        </>
    )
}

const FinalizeShipment = ({ orderId, shipmentFinalized }) => {

    const [shipment, setShipment] = useState(null);
    const [creatingShipment, setCreatingShipment] = useState(false);
    const [shipmentVariants, setShipmentVariants] = useState([]);
    const [loading, setLoading] = useState(true);
    const api = useApi();
    const apiPaths = useApiPaths();
    const { openDialog, closeDialog } = useDialog();
    const { toastSuccess, toastInfo, toastError } = useToast();

    const handleVariantReceivedQuantityChange = (index, quantity) => {
        const updatedVariants = [...shipmentVariants];
        updatedVariants[index].received_quantity = quantity >= 0 ? Number(quantity) : 0;
        setShipmentVariants(updatedVariants);
    };


    useEffect(() => {
        // Start the async flow once the component mounts
        const initializeShipment = async () => {
            setLoading(true); // Start loading
            try {
                // Step 1: Create a draft shipment
                const s = await api.get(apiPaths.orderShipment(orderId));
                setShipment(s);

                if (s && s.id) {
                    const variants = await api.get(apiPaths.shipmentVariants(s.id));
                    setShipmentVariants(variants);
                }
            } catch (error) {
                console.error('Error initializing shipment:', error);
                // Handle errors as needed
            } finally {
                setLoading(false); // End loading
            }
        };

        initializeShipment();
    }, [orderId]);

    const finalizeShipmentSubmit = async () => {
        setCreatingShipment(true);

        const ship = await api.post(apiPaths.finalizeShipment, {
            shipmentid: shipment.id,
            shipmentvariants: shipmentVariants.map(variant => ({
                shipmentvariantid: variant.id,
                receivedquantity: variant.received_quantity
            }))
        }).catch(e => { setCreatingShipment(false); });

        setCreatingShipment(false);

        if (ship.id > 0) {
            closeDialog();
            toastSuccess('Shipment Successfully Finalized');
            shipmentFinalized();
        }
        else {
            toastError('Failed to Finalize Shipment')
        }
    }

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <div>

                <div className="py-6">

                    <br />


                    <div className="flex gap-5 mr-4 justify-end">
                        <strong className="w-20">Dispatched Quantity</strong>
                        <strong className="w-20">Received Quantity</strong>
                    </div>


                    {shipmentVariants.map((v, i) => (
                        <div className="flex items-center my-5 bg-gray-100 p-2 rounded-lg relative">
                            <span className="flex-grow truncate">{v?.variant?.name ?? ''}</span>

                            <span className="w-20 text-center border-2 border-gray-300 rounded focus:outline-none mx-2">{v?.dispatched_quantity ?? 'Invalid'}</span>
                            <input
                                type="text"
                                className="w-20 h-8 text-center border-2 border-gray-300 rounded focus:outline-none mx-2"
                                min="0"
                                onChange={(e) => handleVariantReceivedQuantityChange(i, e.target.value)}
                                value={v.received_quantity ?? 0}
                            />
                        </div>
                    ))}



                </div>

                <div className="flex justify-end mt-4">
                    <button disabled={creatingShipment} onClick={() => { closeDialog() }} className="bg-gray-200 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-300 mr-2">
                        Cancel
                    </button>
                    {

                        <button disabled={creatingShipment} onClick={() => { finalizeShipmentSubmit() }} className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700">
                            Submit
                        </button>
                    }
                </div>

            </div>

        </>
    )
}

const OrderDetail = () => {

    const { orderId } = useParams();
    const { openDialog, closeDialog } = useDialog();
    const [refetchTrigger, setRefetchTrigger] = useState(0);
    const api = useApi()
    const apiPaths = useApiPaths();
    const [order, setOrder] = useState(null);
    const [orderSkus, setOrderSkus] = useState([]);
    const [orderItems, setOrderItems] = useState([]);
    const [shipment, setShipment] = useState(null);
    const [shipmentVariants, setShipmentVariants] = useState([]);
    const { getRole, getRoleList, updateRole } = useUserRole();

    useEffect(() => {
        api.get(apiPaths.orderDetail(orderId))
            .then(r => {
                setOrder(r);
            })

        api.get(apiPaths.orderSkus(orderId)).then(r => {
            setOrderSkus(r);
            
        })

        //onClick={() => { api.blobFetch(apiPaths.downloadItem, 'item.pdf', { itemId: item.id }) }}
        api.get(apiPaths.orderItems(orderId)).then(r => {
            r.forEach(item => {
                item.downloadButton = <button onClick={() => {console.log('hello');  api.blobFetch(apiPaths.downloadItem, 'item.pdf', { itemId: item.id }) }}
                 className="px-3 py-1 rounded-lg text-brand-300 border-2 border-brand-300">Label</button>
            });
            setOrderItems(r);
        })

        const orderShipment = async () => {

            const s = await api.get(apiPaths.orderShipment(orderId))
            setShipment(s);
            if (s && s.id) {
                const variants = await api.get(apiPaths.shipmentVariants(s.id));
                setShipmentVariants(variants);
            }
        };

        orderShipment();


    }, [orderId, refetchTrigger]);

    const deliveryAddress = order?.delivery_address;


    const orderSkusColumns = [
        { header: 'Name', key: 'sku.name' },
        { header: 'Code', key: 'sku.sku_code' },
        { header: 'Quantity', key: 'quantity' },
    ];

    const shipmentVariantColumns = [
        { header: 'Name', key: 'variant.name' },
        { header: 'Ordered Qty', key: 'ordered_quantity' },
        { header: 'Dispatched Qty', key: 'dispatched_quantity' },
        { header: 'Received Qty', key: 'received_quantity' },
    ];

    const orderItemColumns = [
        { header: 'Name', key: 'variant.name' },
        { header: 'Code', key: 'code' },
        {header: 'Label',key: 'downloadButton'}
    ];


    return (
        <>

            <BeyoboNavbar></BeyoboNavbar>
            <TabGroup>
                <Tab label="Orders">
                    <div className="mx-10 my-5" style={{ display: 'flex', justifyContent: 'end' }}>
                        {
                            (((shipment?.id ?? 0) <= 0) || ((shipment?.state ?? '') === 'draft')) &&
                            <button
                                onClick={() => { openDialog(<CreateShipment orderId={orderId} shipmentCreated={() => { setRefetchTrigger(p => p + 1); }} />, 'large', true) }}
                                className="bg-brand-300 hover:bg-brand-400 text-white font-bold py-2 px-4 rounded flex items-center mt-4">
                                + Create Shipment
                            </button>
                        }
                        {/* <button
                            onClick={() => { openDialog(<CreateAddress addressCreated={()=>{}}/>, 'large', true) }}
                            className="bg-brand-300 hover:bg-brand-400 text-white font-bold py-2 px-4 rounded flex items-center mt-4">
                            + Create Address
                        </button> */}
                    </div>


                    <div className="container mx-auto p-4 relative my-3"> {/* Add relative positioning here */}
                        {/* Button group moved here and positioned absolutely */}
                        <div className="bg-white shadow-md rounded-lg p-6 mb-4">
                            <h2 className="text-lg font-semibold mb-4">Order Details</h2>
                            <div className="grid grid-cols-2 gap-4 mb-4">
                                <p><strong>Order Date:</strong> {order?.order_date ?? ''}</p>
                                {/* <p><strong>Order Status:</strong> {order?.order_status ?? ''}</p> */}
                                <p><strong>Order Type:</strong> {order?.type ?? ''}</p>
                                <p><strong>Delivery Address:</strong> {deliveryAddress?.address_one ?? ''}, {deliveryAddress?.address_two ?? ''},
                                    {deliveryAddress?.city ?? ''}, {deliveryAddress?.state ?? ''}, {deliveryAddress?.state ?? ''},
                                    {deliveryAddress?.country?.name ?? ''}, PIN - {deliveryAddress?.pin_code ?? ''},
                                    Ph: {deliveryAddress?.phone_number ?? ''}, Email: {deliveryAddress?.email ?? ''}</p>
                            </div>
                        </div>
                    </div>

                    <CustomTable heading='Order Skus' data={orderSkus} columns={orderSkusColumns} />
                    <br></br>
                    <br></br>
                </Tab>


                <Tab label="Shipments">
                    <div className="mx-10 my-5 gap-5" style={{ display: 'flex', justifyContent: 'end' }}>
                        {
                            ((shipment?.id ?? 0) > 0) &&
                            <button
                                onClick={() => { api.blobFetch(apiPaths.downloadShipment, 'shipment.pdf', { shipmentId: shipment.id }) }}
                                className="bg-brand-300 hover:bg-brand-400 text-white font-bold py-2 px-4 rounded flex items-center mt-4">
                                Label
                            </button>
                        }

                        {
                            (((shipment?.id ?? 0) <= 0) || ((shipment?.state ?? '') === 'draft')) &&
                            <button
                                onClick={() => { openDialog(<CreateShipment orderId={orderId} shipmentCreated={() => { setRefetchTrigger(p => p + 1); }} />, 'large', true) }}
                                className="bg-brand-300 hover:bg-brand-400 text-white font-bold py-2 px-4 rounded flex items-center mt-4">
                                + Create Shipment
                            </button>

                        }
                        {
                            (getRole() === "manager" && (shipment?.state ?? '') === 'inTransit')
                            &&
                            <button
                                onClick={() => { openDialog(<FinalizeShipment orderId={orderId} shipmentFinalized={() => { setRefetchTrigger(p => p + 1); }} />, 'large', true) }}
                                className="bg-brand-300 hover:bg-brand-400 text-white font-bold py-2 px-4 rounded flex items-center mt-4">
                                + Finalize Shipment
                            </button>
                        }
                    </div>


                    <div className="container mx-auto p-4 relative my-3"> {/* Add relative positioning here */}
                        {/* Button group moved here and positioned absolutely */}
                        <div className="bg-white shadow-md rounded-lg p-6 mb-4">
                            <h2 className="text-lg font-semibold mb-4">Shipment Details</h2>
                            <div className="grid grid-cols-2 gap-4 mb-4">
                                <p><strong>Dispatch Date:</strong> {shipment?.dispatched_date ?? ''} </p>
                                <p><strong>Received Date:</strong> {shipment?.received_date ?? ''} </p>
                                <p><strong>Shipment Status:</strong>{shipment?.state ?? ''}</p>
                                <p><strong>Weight (in kgs):</strong>{shipment?.weight_in_kgs ?? ''}</p>
                                <p><strong>Volumetric Weight (in kgs):</strong>{shipment?.volumetric_weight_in_kgs ?? ''}</p>

                                <p><strong>Delivery Address:</strong> {shipment?.delivery_address?.address_one ?? ''}, {shipment?.delivery_address?.address_two ?? ''},
                                    {shipment?.delivery_address?.city ?? ''}, {shipment?.delivery_address?.state ?? ''}, {shipment?.delivery_address?.state ?? ''},
                                    {shipment?.delivery_address?.country?.name ?? ''}, PIN - {shipment?.delivery_address?.pin_code ?? ''},
                                    Ph: {shipment?.delivery_address?.phone_number ?? ''}, Email: {shipment?.delivery_address?.email ?? ''}</p>

                                <p><strong>Pickup Address:</strong> {shipment?.pickup_address?.address_one ?? ''}, {shipment?.pickup_address?.address_two ?? ''},
                                    {shipment?.pickup_address?.city ?? ''}, {shipment?.pickup_address?.state ?? ''}, {shipment?.pickup_address?.state ?? ''},
                                    {shipment?.pickup_address?.country?.name ?? ''}, PIN - {shipment?.pickup_address?.pin_code ?? ''},
                                    Ph: {shipment?.pickup_address?.phone_number ?? ''}, Email: {shipment?.pickup_address?.email ?? ''}</p>

                                <p><strong>Last Scanned:</strong> {shipment?.lastScanned ?? ''} </p>

                                <p><strong>Warehouse:</strong> {shipment?.warehouse?.name ?? ''} </p>

                            </div>
                        </div>
                    </div>

                    <CustomTable heading='Shipment Variants' data={shipmentVariants} columns={shipmentVariantColumns} />
                    <br></br>
                    <br></br>
                </Tab>


                <Tab label="Items">
                    {/* <div className="mx-10 my-5" style={{ display: 'flex', justifyContent: 'end' }}>
                        <button
                            onClick={() => { openDialog(<PrintBarcode orderId={orderId} orderCreated={() => { setRefetchTrigger(p => p + 1); }} />, 'large', true) }}
                            className="bg-brand-300 hover:bg-brand-400 text-white font-bold py-2 px-4 rounded flex items-center">
                            Print Barcode
                        </button>
                    </div> */}


                    <CustomTable heading='Order Items' data={orderItems} columns={orderItemColumns} />
                    <br></br>
                    <br></br>

                </Tab>
            </TabGroup>



        </>
    );
};

export default OrderDetail;

