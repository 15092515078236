import React, { useState } from 'react';

const DashboardBox = ({ imgSrc, imgAlt, currency, description, options }) => {
    // State to track the selected option
    const [selectedOption, setSelectedOption] = useState('');

    // Handle change in select option
    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    return (
        <div className="bg-white shadow rounded-lg p-6 w-1/4 flex justify-between">
            <div className="flex gap-5">
                <img src={imgSrc} alt={imgAlt} className="w-12 h-12" />
                <div>
                    <p className="text-xl text-black">{currency}</p>
                    <p className="text-sm text-gray-400">{description}</p>
                </div>
            </div>

            {/* Render select dropdown only if options are provided */}
            {options && (
                <div className="mt-2">
                    <select
                        value={selectedOption}
                        onChange={handleSelectChange}
                        className="form-select block w-full px-3 py-2 text-base font-normal text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                    >
                        {options.map((option, index) => (
                            <option key={index} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>
            )}
        </div>
    );
};

export default DashboardBox;
