import PropTypes from "prop-types";
import { useState } from "react";
import "./FrameComponent.css";
import usePath from "hooks/usePath";
import { useNavigate } from "react-router-dom";

const FrameComponent = ({ changeTab, className = "" }) => {
  const [activeTab, setActiveTab] = useState("admin");
  const path = usePath();
  let navigate = useNavigate();

  const updateTab = (tab) => {
    setActiveTab(tab);
    changeTab(tab);
  };

  return (
    <div className={`frame-group ${className}`}>
      <div className="development-container-wrapper">
        <button
          className={
            activeTab == "admin"
              ? "development-container"
              : "development-container-not-selected"
          }
          onClick={() => updateTab("admin")}
        >
          <div className="rectangle3" />
          <img
            className={"development-container-child"}
            alt=""
            src={activeTab == "admin" ? "/group-29.svg" : "/group-29-black.svg"}
          />
          <div
            className="dashboard"
            style={{ color: activeTab == "admin" ? "white" : "#54575B" }}
          >
            Dashboard
          </div>
        </button>
      </div>
      <div className="development-container-wrapper">
        <button
          className={
            activeTab == "orders"
              ? "development-container"
              : "development-container-not-selected"
          }
          onClick={() => updateTab("orders")}
        >
          <div className="rectangle3" />
          <img
            className="development-container-child"
            alt=""
            src={
              activeTab == "orders" ? "/group-34.svg" : "/group-34-black.svg"
            }
          />
          <div
            className="dashboard"
            style={{ color: activeTab == "orders" ? "white" : "#54575B" }}
          >
            Orders
          </div>
        </button>
      </div>
      <div className="development-container-wrapper">
        <button
          className={
            activeTab == "operation"
              ? "development-container"
              : "development-container-not-selected"
          }
          onClick={() => updateTab("operation")}
        >
          <div className="rectangle3" />
          <img
            className="development-container-child"
            alt=""
            src={
              activeTab == "operation" ? "/group-39.svg" : "/group-39-black.svg"
            }
          />
          <div
            className="dashboard"
            style={{ color: activeTab == "operation" ? "white" : "#54575B" }}
          >
            Operational Panel
          </div>
        </button>
      </div>
      <div className="development-container-wrapper">
        <button
          className={
            activeTab == "mis"
              ? "development-container"
              : "development-container-not-selected"
          }
          onClick={() => updateTab("mis")}
        >
          <div className="rectangle3" />
          <img
            className="development-container-child"
            alt=""
            src={activeTab == "mis" ? "/group-36.svg" : "/group-36-black.svg"}
          />
          <div
            className="dashboard"
            style={{ color: activeTab == "mis" ? "white" : "#54575B" }}
          >
            MIS
          </div>
        </button>
      </div>
      <div className="development-container-wrapper">
        <button
          className={
            activeTab == "hsn"
              ? "development-container"
              : "development-container-not-selected"
          }
          onClick={() => updateTab("hsn")}
        >
          <div className="rectangle3" />
          <img
            className="development-container-child"
            alt=""
            src={activeTab == "hsn" ? "/group-41.svg" : "/group-41-black.svg"}
          />
          <div
            className="dashboard"
            style={{ color: activeTab == "hsn" ? "white" : "#54575B" }}
          >
            HSN
          </div>
        </button>
      </div>

      <div className="development-container-wrapper">
        <button
          className={
            activeTab == "supplier"
              ? "development-container"
              : "development-container-not-selected"
          }
          onClick={() => updateTab("supplier")}
        >
          <div className="rectangle3" />
          <img
            className="development-container-child"
            alt=""
            src={
              activeTab == "supplier" ? "/group-29.svg" : "/group-29-black.svg"
            }
          />
          <div
            className="dashboard"
            style={{ color: activeTab == "supplier" ? "white" : "#54575B" }}
          >
            Supplier
          </div>
        </button>
      </div>
      <img className="path-32-icon" loading="lazy" alt="" src="/path-32.svg" />
    </div>
  );
};

FrameComponent.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent;
