// CustomTabGroup.js
import React, { useState } from 'react';

const TabGroup = ({ children }) => {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    return (
        <>
            <div className="flex border-b mt-5 justify-center">
                {React.Children.map(children, (child, index) => (
                    <button
                        key={index}
                        className={`py-2 px-4 text-lg font-medium ${activeTab === index ? 'border-b-2 border-indigo-500 text-indigo-600' : 'text-gray-500 hover:text-gray-600'}`}
                        onClick={() => handleTabClick(index)}
                    >
                        {child.props.label}
                    </button>
                ))}
            </div>
            <div className="pt-4">
                {React.Children.map(children, (child, index) => (
                    activeTab === index ? child.props.children : null
                ))}
            </div>
        </>
    );
};

// CustomTab.js (No changes, but included for completeness)
const Tab = ({ children }) => {
    return <>{children}</>;
};

export { TabGroup, Tab };