import React, { useState, useEffect, useContext, useRef } from 'react';

function Step1({ formData, handleFormDataChange, nextStep, }) {
    const [passwordError, setPasswordError] = useState('');

    const handlePasswordChange = (e) => {
        handleFormDataChange(e.target.name, e.target.value);
        // Reset password error state when the user modifies the password
        setPasswordError('');
    };

    const handleConfirmPasswordChange = (e) => {
        // Here, instead of using a local state for confirmPassword,
        // update the parent state directly.
        handleFormDataChange('user_confirmPassword', e.target.value);

        // Set or reset password error based on matching
        setPasswordError(e.target.value === formData.user_password ? '' : 'Passwords do not match.');
    };

    return (
        <form onSubmit={(e) => e.preventDefault()}>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                    Email address *
                </label>
                <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="user_email"
                    name="user_email"
                    type="email"
                    placeholder="Email Address"
                    value={formData.user_email} // Bind input value to state
                    onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}
                    required
                />
            </div>
            <div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                        Name *
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="user_name"
                        name="user_name"
                        type="text"
                        value={formData.user_name} // Bind input value to state
                        onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}
                        placeholder="Name"
                        required
                    />
                </div>

                <div className="mb-6">
                    <label htmlFor="user_number" className="block text-gray-700 text-sm font-bold mb-2">
                        Phone Number*
                    </label>
                    <input
                        type="text"
                        id="user_number"
                        name="user_number"
                        // Controlled component
                        // Update state on change
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Phone Number"
                        value={formData.user_number} // Bind input value to state
                        onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}
                        required
                    />
                </div>

                {/* Password Input */}
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="user_password">
                        Password*
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="user_password"
                        name="user_password"
                        type="password"
                        placeholder="Password"
                        value={formData.user_password}
                        onChange={handlePasswordChange}
                        required
                    />
                </div>

                {/* Confirm Password Input */}
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="confirm_password">
                        Confirm Password*
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="confirm_password"
                        name="user_confirmPassword" // This name should match the formData state key for consistency, but it's handled by the function, not by the name directly.
                        type="password"
                        placeholder="Confirm Password"
                        value={formData.user_confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        required
                    />
                    {passwordError && <p className="text-red-500 text-xs italic">{passwordError}</p>}
                </div>

                <button type="button" onClick={nextStep} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                    Next
                </button>
            </div>
        </form >
    );
}

export default Step1;
