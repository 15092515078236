function Step2({ formData, handleFormDataChange, nextStep, prevStep }) {
    return (
        <form onSubmit={(e) => e.preventDefault()}>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="user_company_name">
                    Company Name*
                </label>
                <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="user_company_name"
                    name="user_company_name"
                    type="text"
                    placeholder="Company Name"
                    value={formData.user_company_name}
                    onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}
                    required
                />
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="user_company_number">
                    Company Phone Number*
                </label>
                <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="user_company_number"
                    name="user_company_number"
                    type="tel"
                    placeholder="Company Phone Number"
                    value={formData.user_company_number}
                    onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}
                    required
                />
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="user_company_country">
                    Country*
                </label>
                <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="user_company_country"
                    name="user_company_country"
                    type="text"
                    placeholder="Country"
                    value={formData.user_company_country}
                    onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}
                    required
                />
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="user_company_address">
                    Company Address*
                </label>
                <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="user_company_address"
                    name="user_company_address"
                    type="text"
                    placeholder="Company Address"
                    value={formData.user_company_address}
                    onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}
                    required
                />
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="user_company_total_skus">
                    No. of SKUs*
                </label>
                <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="user_company_total_skus"
                    name="user_company_total_skus"
                    type="number"
                    placeholder="Total SKUs"
                    value={formData.user_company_total_skus}
                    onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}
                    required
                />
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="user_company_previous_gmv">
                    Last Year GMV (USD)*
                </label>
                <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="user_company_previous_gmv"
                    name="user_company_previous_gmv"
                    type="number"
                    placeholder="Previous Year GMV"
                    value={formData.user_company_previous_gmv}
                    onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}
                    required
                />
            </div>

            <div>
                <button type="button" onClick={prevStep} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                    Back
                </button>
                <button type="button" onClick={nextStep} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                    Next
                </button>
            </div>
        </form>
    );
}

export default Step2;
