

import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter'
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter'
import DateFilter from '@inovua/reactdatagrid-community/DateFilter'
import moment from 'moment'
import Button from '@inovua/reactdatagrid-community/packages/Button'
import BeyoboNavbar from "../beyobonavbar";
import React, { useState, useEffect, useContext, useCallback } from 'react';
import CatalogProductContext from '../../context/CatalogProductContext';
import { AuthContext } from '../../context/AuthContext';
import DataModal from '../modal'
import CustomTable from '../customTable'
const ChannelDataGrid = () => {




  //  const columns = [


  //    //{ name: 'sku_id', defaultFlex: 1, header: 'Sku Id', type: 'number', filterEditor: NumberFilter },
  //    { name: 'name', defaultFlex: 1, header: 'Channel Name',type:'string'},
  //    { name: 'platform', defaultFlex: 1, header: 'Platform',type:'string'}



  //   // {name: 'pending', defaultFlex:1,header:'Pending', type:Number,render: ({ value }) => formatCurrency(value)},
  //];

    const columns = [
        { header: 'Channel Name', key: 'name' },
        { header: 'Platform', key: 'platform' },
    ];


        const defaultfilter = [
          
          //{ name: 'sku_id', operator: 'gte', type: 'number' ,value: 0},
          { name: 'name', operator: 'contains', type: 'number' ,value: ''},
          { name: 'platform', operator: 'contains', type: 'number' ,value: ''}
         
          //{ name: 'pending', operator: 'gte', type: 'number' ,value: 0},
  
      ];



    const [filterValue, setFilterValue] = useState(defaultfilter);
    const [dataSource, setDataSource] = useState([]);
    const { authToken } = useContext(AuthContext);

    const [gridData, setGridData] = useState([]); // New state for actual data array

    const loadData = useCallback(() => {
        const newDataSource = () => {
            const limit = 50;
            const url = `${process.env.REACT_APP_BASE_API_URL}/channels/getcompanychannels/` + '?limit=' + limit;
            return fetch(url, {
                headers: {
                    'Authorization': `Token ${authToken}`
                }
            })
                .then(response => response.json())
                .then(data => {
                    const formattedData = data.map(item => ({
                        ...item,
                        order_date: moment(item.order_date).format('DD-MM-YYYY')
                    }));
                    setGridData(formattedData); // Update gridData with the actual data array
                    return { data: formattedData }; // Return the data for dataSource
                });
        };

        setDataSource(newDataSource); // Keep as it was, setting a function
    }, [authToken]);

    useEffect(() => {
        loadData();
    }, [loadData]); // Ensure loadData is stable and doesn't change unless necessary



    const gridStyle = { minHeight: 550 }


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);

    const onRowClick = useCallback((rowProps) => {
        setSelectedRowData(rowProps.data);
        setIsModalOpen(true);
    }, []);

    return (
        <div>
            <CustomTable data={gridData} columns={columns} onRowClick={() => { }} />


            {/* <Button onClick={() => loadData()} style={{marginRight: 10}}>
            Load async data
          </Button>
          <Button
            disabled={Array.isArray(dataSource)}
            onClick={() => setDataSource([])}
          >Clear data</Button> */}
            {/* <div style={{ height: 80 }} >Current filterValue: {filterValue ? <code>{JSON.stringify(filterValue, null, 2)}</code>: 'none'}.</div> */}
            {/*<ReactDataGrid*/}
            {/*    style={gridStyle}*/}
            {/*    idProperty="id"*/}
            {/*    pagination='local'*/}
            {/*    columns={columns}*/}
            {/*    dataSource={dataSource}*/}
            {/*    defaultLimit={50}*/}
            {/*    defaultFilterValue={defaultfilter}*/}
            {/*    onFilterValueChange={setFilterValue}*/}
            {/*    onRowClick={onRowClick}*/}
            {/*/>*/}

            {/*<DataModal*/}
            {/*    isOpen={isModalOpen}*/}
            {/*    onClose={() => setIsModalOpen(false)}*/}
            {/*    data={selectedRowData}*/}
            {/*    columns={columns}*/}
            {/*/>*/}
        </div>
    )
};

export default ChannelDataGrid;