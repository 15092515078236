import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useParams } from "react-router-dom";

import RtlLayout from "layouts/rtl";
//import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import OnboardingLayout from "./layouts/onboarding";
import OnboardingThanks from "./views/onboarding/onboardingthanks";
import NoShipments from "./views/admin/shipments";
import BeyoboDashboard from "./views/admin/dashboard";
import PaymentScreen from "./views/admin/payments";
import Catalogue from "./views/admin/catalogue";
import Order from "./views/admin/orders";
import { AuthProvider } from '../src/context/AuthContext';
import { UserProvider } from '../src/context/UserContext';
import { UserRoleProvider } from '../src/context/UserRoleContext';
import { CatalogProductProvider } from '../src/context/CatalogProductContext';
import { ChannelProvider } from "../src/context/ChannelsContext";
import { OrderProvider } from '../src/context/OrderContext';
import { CoreUserProvider } from '../src/context/CoreUserContext';
import { VariantProvider } from '../src/context/VariantContext';
import AdminProfile from "./views/admin/profile-new";
import SignIn from "./views/auth/SignIn";
import ExampleParentComponent from "views/manage/testing";
import SupplierDashboard from "./views/admin/supplier_dashboard";
import AdminDashboard from "./views/admin/supplier_dashboard/admin-dashboard";
import TestingSupplierDashboard from "./views/admin/supplier_dashboard/testing-supplier-dashboard";
import NewSupplierDashboard from "./views/admin/supplier_dashboard/recreating-supplier-dashboard";
import Website from "./views/auth/website";
import ShipmentLists from "./views/admin/shipments/shipment-list-qr";
import ShipmentDetails from "../src/views/admin/shipments/shipment-list-details"; // Ensure this import path is correct
import OrderDetail from "shared/order/orderDetail";
import Orders from "views/manage/orders";
import SalesDrrComponent from "views/admin/supplier_dashboard/sales_drr";
import { DialogProvider, DialogComponent } from "components/dialog";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { ToastProvider } from "components/toast";
import ManagerRole from "./views/admin/supplier_dashboard/adminRole";
import RequireRole from "./components copy/calendar/MiniCalendar";

const App = () => {
    return (
        <ToastProvider>
            <DialogProvider>
                <AuthProvider>
                    <UserProvider>
                        <UserRoleProvider>
                            <CatalogProductProvider>
                                <OrderProvider>
                                    <ChannelProvider>
                                        <CoreUserProvider>
                                            <VariantProvider>
                                                <Routes>
                                                    <Route path="auth/*" element={<Navigate to="/auth/sign-in" replace />} />
                                                    <Route path="auth/sign-in" element={<SignIn />} />
                                                    <Route path="admin/*" element={<BeyoboDashboard />} />
                                                    <Route path="admin/shipments" element={<NoShipments />} />
                                                    <Route path="admin/shipment-list" element={<ShipmentLists />} />
                                                    <Route path="admin/shipment-list/:shipmentId" element={<ShipmentDetailsWrapper />} /> {/* New route for ShipmentDetails */}
                                                    <Route path="admin/dashboard" element={<BeyoboDashboard />} />
                                                    <Route path="admin/payments" element={<PaymentScreen />} />
                                                    <Route path="admin/catalogue" element={<Catalogue />} />
                                                    <Route path="admin/orders" element={<Order />} />
                                                    <Route path="admin/profile" element={<AdminProfile />} />
                                                    <Route path="auth/onboarding/*" element={<OnboardingLayout />} />
                                                    <Route path="onboarding/thanks" element={<OnboardingThanks />} />
                                                    <Route path="rtl/*" element={<RtlLayout />} />
                                                    <Route path="/" element={<Navigate to="/auth/sign-in" replace />} />
                                                    <Route path="/website" element={<Website></Website>} />
                                                    <Route path="admin/catalogue/*" element={<Catalogue />} />
                                                    {/*<Route path="admin/supplier/*" element={<SupplierDashboard />} />*/}
                                                    {/*<Route path="admin/supplier/dashboard" element={<TestingSupplierDashboard />} />*/}
                                                    {/*<Route path="admin/supplier/testing" element={<NewSupplierDashboard />} />*/}
                                                    {/*<Route path="admin/admin-dashboard" element={<AdminDashboard />} />*/}
                                                    <Route path="/order/:orderId" element={<OrderDetail />} />
                                                    <Route path="manage/test" element={<ExampleParentComponent />} />
                                                    <Route path="manage/managerrole" element={<ManagerRole />} />
                                                    {/*<Route path="/manage/managerrole" element={*/}
                                                    {/*    <RequireRole allowedRoles={['manager']}>*/}
                                                    {/*        <ManagerRole />*/}
                                                    {/*    </RequireRole>*/}
                                                    {/*} />*/}
                                                    <Route path="manage/purchaseorders" element={<Orders />} />
                                                </Routes>
                                                <ToastContainer />
                                                <DialogComponent />
                                            </VariantProvider>
                                        </CoreUserProvider>
                                    </ChannelProvider>
                                </OrderProvider>
                            </CatalogProductProvider>
                        </UserRoleProvider>
                    </UserProvider>
                </AuthProvider>
            </DialogProvider>
        </ToastProvider>
    );
};

// Wrapper component to pass the shipmentId parameter to the ShipmentDetails component
const ShipmentDetailsWrapper = () => {
    const { shipmentId } = useParams();
    return <ShipmentDetails shipmentId={shipmentId} />;
};

export default App;
