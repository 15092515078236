import React, { createContext, useState, useEffect, useContext } from 'react';
import { AuthContext } from './AuthContext'; // or wherever your AuthContext is
// import {UserContext} from './UserContext';
// Create Context Object
export const VariantContext = createContext();

// Create a provider for components to consume and subscribe to changes
export const VariantProvider = (props) => {
    const [shipmentVariants, setShipmentVariants] = useState([]);


    // Fetch data from the backend
    const { authToken } = useContext(AuthContext);
    // const {user} =useContext(UserContext);
    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_API_URL}/variantname/`, {
            headers: {
                'Authorization': `Token ${authToken}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                const variantsWithLabel = data.map(variant => ({
                    ...variant,
                    isChecked: false, // Existing property
                    label: variant.name // Add label property
                }));
                setShipmentVariants(variantsWithLabel);
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });
    }, [authToken]);

    return (
        <VariantContext.Provider value={{ shipmentVariants, setShipmentVariants}}>
            {props.children}
        </VariantContext.Provider>
    );

};

export default VariantContext;
