import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link, Routes, Route, Navigate } from "react-router-dom";
import routes from "routes.js";
import DashboardBox from '../../components/dashboardbox';
import { AuthContext } from '../../../src/context/AuthContext';
import { useNavigate } from 'react-router-dom';
import Step1 from '../../components/step1';
import Step2 from '../../components/step2';
import Step3 from '../../components/step3';
export default function Auth() {
    const [currentStep, setCurrentStep] = useState(1);
    const [formData, setFormData] = useState({

        user_name: '',
        user_number: '',
        user_password: '',
        user_confirmPassword: '',
        user_company_name: '',
        user_company_number: '',
        user_company_country: '',
        user_company_address: '',
        user_company_total_skus: '',
        user_company_previous_gmv: '',
        user_company_business_license: '',
        //user_partner_eor: '',
        //user_partner_ior: '',
        //user_partner_psp: '',
        //user_partner_logistics: '',
    });

    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const [password, setPassword] = useState('');
    //const [currentPage, setCurrentPage] = useState(1);
    //const [itemsPerPage] = useState(10);
    const navigate = useNavigate();
    const dashboardNavigate = () => {
        navigate('/admin/dashboard')
    }

            //const passwordRef = useRef(null);
        //const confirmPasswordRef = useRef(null);
        //const [password, setPassword] = useState('');
        const [confirmPassword, setConfirmPassword] = useState('');
        const [showError, setShowError] = useState(false);
    // const [passwordError, setPasswordError] = useState('');

    //     // This is a new handler for the password field to update its state
    //     const handlePasswordChange = (e) => {
    //         setPassword(e.target.value);
    //     };

    //     const handleConfirmPasswordChange = (e) => {
    //         const confirmPasswordValue = e.target.value;
    //         setConfirmPassword(confirmPasswordValue);

    //         // Perform the validation check here
    //         if (confirmPasswordValue.length > 0 && password !== confirmPasswordValue) {
    //             setShowError(true);
    //         } else {
    //             setShowError(false);
    //         }
    //     };


    const [passwordError, setPasswordError] = useState('');

    const handlePasswordChange = (e) => {

       handleFormDataChange('user_password',e.target.value);
       setPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        // Here, instead of using a local state for confirmPassword,
        // update the parent state directly.
        handleFormDataChange('user_confirmPassword', e.target.value);

        // Set or reset password error based on matching
        setPasswordError(e.target.value === formData.user_password ? '' : 'Passwords do not match.');
    };

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent the default form submission action

        let bodyFormData = new FormData();

        for (let key in formData) {
            bodyFormData.append(key, formData[key]);
        }
        bodyFormData.append('user_password', password);
        bodyFormData.append('user_company_business_license',file);

        const endpoint = `${process.env.REACT_APP_BASE_API_URL}/registeruser/`;

        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    // Headers as needed. For FormData, Content-Type is usually auto-set
                },
                body: bodyFormData,
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            // Assuming the registration is successful
            setShowSuccessModal(true);

            // Wait for a few seconds to show the success modal, then redirect
            setTimeout(() => {
                window.location.href = '/auth/sign-in'; // Redirects to the sign-in page
            }, 8000); // Adjust timing as needed

        } catch (error) {
            console.error('Error:', error);
            // Handle errors, e.g., showing an error message
        }
    };



    const { authToken } = useContext(AuthContext);
    const [products, setProducts] = useState([]);


    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_API_URL}/product/`, {
            headers: {
                'Authorization': `Token ${authToken}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setProducts(data); // Assuming the API returns an array of products
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });
    }, []); // Empty dependency array means this effect runs once on mount

    // No HTML or JSX return statement here

    const handleFormDataChange = (name, value) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value,
        }));

        // Reset password error when the password is changed
        if (name === 'user_password') {
            setPasswordError('');
        }
    };

    const updateFormData = (event) => {
        const { name, value } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };


    const nextStep = () => {
        if (currentStep < 3) {
            setCurrentStep(currentStep + 1);
        }
    };

    const prevStep = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
        }
    };

    // State to store the selected file
    const [file, setFile] = useState(null);
    // State to store the preview URL of the file
    const [previewUrl, setPreviewUrl] = useState('');

    // Handler for file changes
    const handleFileChange = (event) => {
        const file = event.target.files[0]; // Get the first file
        if (file) {
            setFile(file);
            setPreviewUrl(URL.createObjectURL(file)); // Generate a preview URL
        }
    };


    const renderStepComponent = () => {
        switch (currentStep) {
            case 1:
               return <>
                <form onSubmit={(e) => e.preventDefault()}>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                    Email address *
                </label>
                <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="user_email"
                    name="user_email"
                    type="email"
                    placeholder="Email Address"
                    value={formData.user_email} // Bind input value to state
                    onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}
                    required
                />
            </div>
            <div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                        Name *
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="user_name"
                        name="user_name"
                        type="text"
                        value={formData.user_name} // Bind input value to state
                        onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}
                        placeholder="Name"
                        required
                    />
                </div>

                <div className="mb-6">
                    <label htmlFor="user_number" className="block text-gray-700 text-sm font-bold mb-2">
                        Phone Number*
                    </label>
                    <input
                        type="text"
                        id="user_number"
                        name="user_number"
                        // Controlled component
                        // Update state on change
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Phone Number"
                        value={formData.user_number} // Bind input value to state
                        onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}
                        //required
                    />
                </div>

                {/* Password Input */}
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="user_password">
                        Password*
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="user_password"
                        name="user_password"
                        type="password"
                        placeholder="Password"
                        value={formData.user_password}
                        onChange={handlePasswordChange}
                        required
                    />
                </div>

                {/* Confirm Password Input */}
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="confirm_password">
                        Confirm Password*
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="confirm_password"
                        name="user_confirmPassword" // This name should match the formData state key for consistency, but it's handled by the function, not by the name directly.
                        type="password"
                        placeholder="Confirm Password"
                        value={formData.user_confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        required
                    />
                    {passwordError && <p className="text-red-500 text-xs italic">{passwordError}</p>}
                </div>

                <button type="button" onClick={nextStep} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                    Next
                </button>
            </div>
        </form >
            </>
            case 2:
                return <>        <form onSubmit={(e) => e.preventDefault()}>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="user_company_name">
                            Company Name*
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="user_company_name"
                            name="user_company_name"
                            type="text"
                            placeholder="Company Name"
                            value={formData.user_company_name}
                            onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}
                            required
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="user_company_number">
                            Company Phone Number*
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="user_company_number"
                            name="user_company_number"
                            type="tel"
                            placeholder="Company Phone Number"
                            value={formData.user_company_number}
                            onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}
                            //required
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="user_company_country">
                            Country*
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="user_company_country"
                            name="user_company_country"
                            type="text"
                            placeholder="Country"
                            value={formData.user_company_country}
                            onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}
                            required
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="user_company_address">
                            Company Address*
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="user_company_address"
                            name="user_company_address"
                            type="text"
                            placeholder="Company Address"
                            value={formData.user_company_address}
                            onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}
                            //required
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="user_company_total_skus">
                            No. of SKUs*
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="user_company_total_skus"
                            name="user_company_total_skus"
                            type="number"
                            placeholder="Total SKUs"
                            value={formData.user_company_total_skus}
                            onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}
                            //required
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="user_company_previous_gmv">
                            Last Year GMV (USD)*
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="user_company_previous_gmv"
                            name="user_company_previous_gmv"
                            type="number"
                            placeholder="Previous Year GMV"
                            value={formData.user_company_previous_gmv}
                            onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}
                            //required
                        />
                    </div>

                    <div className="my-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                            Upload business licenses *
                        </label>
                        <div className="flex justify-center px-6 pt-5 pb-6 border-2 border-blue-500 border-dotted rounded-lg">
                            <div className="space-y-1 text-center">
                                {/* Display preview if there's a file */}
                                {previewUrl && (
                                    <img src={previewUrl} alt="Preview" className="mx-auto mb-3" style={{ maxWidth: '200px', maxHeight: '200px' }} />
                                )}
                                <div className="flex text-sm text-gray-600">
                                    <label htmlFor="file-upload" className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                        <span>Upload a file</span>
                                        <input
                                            id="file-upload"
                                            name="user_company_business_license"
                                            type="file"
                                            className="sr-only"
                                            onChange={handleFileChange}
                                            accept="image/png, image/jpeg, image/gif"
                                        />
                                    </label>
                                    <p className="pl-1">or drag and drop</p>
                                </div>
                                <p className="text-xs text-gray-500">
                                    PNG, JPG, GIF up to 10MB
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex gap-5">
                        <button type="button" onClick={prevStep} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                            Back
                        </button>
                        <button onClick={handleSubmit} type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                            Sign Up
                        </button>
                    </div>
                </form></>
                
                /*<Step2 formData={formData} handleFormDataChange={handleFormDataChange} nextStep={nextStep} prevStep={prevStep} />*/;
            //case 3:
            //    // If handling the success modal directly within Step3, no need for showSuccessModal prop.
            //    // If using a callback to trigger a modal from the parent, pass a callback function:
            //    return <Step3 formData={formData} handleFormDataChange={handleFormDataChange} prevStep={prevStep} handleSubmit={handleSubmit} onSubmitSuccess={() => setShowSuccessModal(true)} navigate={navigate} />;
            default:
               return <Step1 formData={formData} handleFormDataChange={handleFormDataChange} nextStep={nextStep} />;
        }
    };




    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.layout === "/onboarding") {
                return (
                    <Route path={`/${prop.path}`} element={prop.component} key={key} />
                );
            } else {
                return null;
            }
        });
    };

    document.documentElement.dir = "ltr";
    return (
        <>

            <main className={`mx-auto min-h-screen bg-white`}>
                <div className="relative flex flex-col">
                    <div className="flex w-full flex-col justify-start lg:pt-12 pt-5 md:max-w-[50%] lg:pt-0 xl:px-0">
                        <div className="mt-0 w-max lg:pt-10 px-10">
                            <img className="h-auto w-[108px]" src={'https://media.licdn.com/dms/image/D4D0BAQFqFAUkiXkfEA/company-logo_200_200/0/1690444337691?e=2147483647&v=beta&t=EyyY89lbCTDKr0dgfgPUhBn6e7QLeg2Mq8X-58aJtEo'} alt="beyobo logo" />
                            <h4 className="mb-2 mt-7 text-2xl font-bold text-navy-700 dark:text-white">
                                Welcome to Beyobo
                            </h4>
                            <p className="text-gray-400 text-[14px]">Please fill your details to sign up</p>
                        </div>
                        {/*, 'Partner Details'*/}

                        {/* Step indicators */}
                        <div className="flex justify-around items-center p-4">
                            <div className="flex items-center w-full justify-around mt-3 lg:px-10">
                                {['Personal Details', 'Business Details'].map((label, index) => {
                                    const step = index + 1;
                                    const isCurrent = currentStep === step;
                                    const isCompleted = currentStep > step;
                                    return (
                                        <React.Fragment key={label}>
                                            <div className="flex gap-3">
                                                {/* Step Circle */}
                                                <div className={`flex items-center ${index !== 0 ? 'flex-grow' : ''}`}>
                                                    {index !== 0 && (
                                                        // This is the connecting line
                                                        <div className={`h-1 ${isCompleted ? 'bg-green-500' : 'bg-gray-200'} transition-all duration-500 ease-in-out`}></div>
                                                    )}
                                                    <div className={`flex items-center justify-center h-8 w-8 rounded-full ${isCurrent || isCompleted ? 'bg-green-500' : 'bg-gray-200'} text-white`}>
                                                        {isCompleted ? (
                                                            // Check icon when step is completed
                                                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                                                        ) : (
                                                            // Step number when not completed
                                                            <span>{step}</span>
                                                        )}
                                                    </div>
                                                </div>
                                                {/* Step Label */}
                                                <div className={`text-sm font-medium self-center ${isCurrent ? 'text-green-700' : 'text-gray-500'}`} style={{ margin: '0px' }}>
                                                    {label}
                                                </div>
                                            </div>

                                        </React.Fragment>
                                    );
                                })}
                            </div>
                        </div>

                        <div className="mx-auto h-fit w-full items-center">
                            <div className="flex flex-col items-center justify-center p-2">
                                <div className="w-full max-w-md">
                                    {renderStepComponent()}
                                </div>
                            </div>
                        </div>

                        <Routes>
                            {getRoutes(routes)}
                            <Route
                                path="/"
                                element={<Navigate to="/onboarding/screen" replace />}
                            />
                        </Routes>
                    </div>
                    <div className="absolute right-0 hidden h-full min-h-screen md:block w-[50%]">
                        <div
                            className="absolute flex h-full w-full items-end justify-center bg-cover bg-center"
                            style={{ backgroundImage: `url(https://sellercentral.jennifer-in.com/assets/images/loginleftbg.jpg)` }}
                        />
                    </div>
                </div>
            </main>

            {
                showSuccessModal && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"> {/* Backdrop */}
                        <div className="bg-white rounded-lg p-6 shadow-lg max-w-md mx-auto"> {/* Modal Box */}
                            <div className="text-center">
                                <svg className="mx-auto mb-4 w-16 h-16 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor"> {/* Success Icon */}
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                </svg>
                                <h2 className="text-xl font-semibold text-gray-800">Registration Successful!</h2> {/* Title */}
                                <p className="text-gray-600">Your account has been created successfully.</p> {/* Message */}
                            </div>
                            <div className="mt-4 flex justify-center">
                                <button onClick={() => setShowSuccessModal(false)} className="inline-flex items-center px-4 py-2 bg-green-600 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-green-700 active:bg-green-900 focus:outline-none focus:border-green-900 focus:ring focus:ring-green-300 disabled:opacity-25 transition">Close</button> {/* Close Button */}
                            </div>
                        </div>
                    </div>
                )
            }


        </>
    );
}




    // Fetch data from the backend
    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const response = await fetch('http://127.0.0.1:8000/product/?format=api');
    //             const data = await response.json();
    //             setProducts(data);
    //         } catch (error) {
    //             console.error('Error fetching products:', error);
    //         }
    //     };

    //     fetchData();
    // }, []);

//{/* <div className="mt-4">
//                <label className="block text-gray-700 text-sm font-bold mb-2">
//                    Upload business license *
//                </label>
//                <div className="flex justify-center px-6 pt-5 pb-6 border-2 border-blue-500 border-dotted rounded-lg">
//                    <div className="space-y-1 text-center">
//                        <svg
//                            className="mx-auto h-12 w-12 text-gray-400"
//                            stroke="currentColor"
//                            fill="none"
//                            viewBox="0 0 48 48"
//                            aria-hidden="true"
//                            xmlns="http://www.w3.org/2000/svg"
//                        >
//                            <path
//                                d="M28 8H12a4 4 0 00-4 4v24a4 4 0 004 4h16m8-36v32a8 8 0 008 8H8a8 8 0 01-8-8V12a8 8 0 018-8h24a8 8 0 018 8z"
//                                strokeWidth={2}
//                                strokeLinecap="round"
//                                strokeLinejoin="round"
//                            />
//                        </svg>
//                        <div className="flex text-sm text-gray-600">
//                            <label htmlFor="file-upload" className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
//                                <span>Upload a file</span>
//                                <input
//                                    id="file-upload"
//                                    name="file-upload"
//                                    type="file"
//                                    className="sr-only"
//                                />
//                            </label>
//                            <p className="pl-1">or drag and drop</p>
//                        </div>
//                        <p className="text-xs text-gray-500">
//                            PNG, JPG, GIF up to 10MB
//                        </p>
//                    </div>
//                </div>
//            </div>
// */}