import React, { useCallback, useState } from 'react'
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter'

const Notification = ({ data, columns, onClose }) => {
    const notificationStyle = {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)', // Centering the notification
        width: '50%',
        maxHeight: '80vh',
        overflow: 'auto',
        border: '1px solid #007bff',
        padding: '20px',
        backgroundColor: '#f8f9fa',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        zIndex: 10000,
        transition: 'opacity 0.5s',
        opacity: 1,
    };


    const closeButtonStyle = {
        position: 'absolute',
        top: '10px',
        right: '10px',
        cursor: 'pointer',
        fontSize: '1rem',
        color: '#fff',
        background: '#dc3545',
        border: 'none',
        borderRadius: '50%',
        width: '30px',
        height: '30px',
        textAlign: 'center',
        lineHeight: '30px',
        textDecoration: 'none',
    };
    const firstColumnStyle = {
        width: '30%',
        padding: '8px',
        border: '1px solid #ddd',
        fontWeight: 'bold',
        backgroundColor: '#f0f0f0',
    };

    const secondColumnStyle = {
        width: '30%',
        padding: '8px',
        border: '1px solid #ddd',
    };

    // Finding headers from columns array
    const headers = columns.reduce((acc, column) => {
        if (data[column.name] !== undefined) { // ensure the data contains the key
            acc[column.header] = data[column.name];
        }
        return acc;
    }, {});

    return (
        <div style={notificationStyle}>
            <button style={closeButtonStyle} onClick={onClose}>&times;</button>
            <table>
                <tbody>
                    {Object.entries(headers).map(([key, value]) => (
                        <tr key={key}>
                            <td style={firstColumnStyle}>{key}</td>
                            <td style={secondColumnStyle}>{value}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

const MultipleDataGrid = () => {
    const [notification, setNotification] = useState(null);

    const showNotification = (data) => {
        setNotification(data);
    };

    const closeNotification = () => {
        setNotification(null);
    };

    const onRowDoubleClick = useCallback((rowProps) => {
        showNotification(rowProps.data);
    }, []);

    const onRowClick = useCallback((rowProps) => {
        showNotification(rowProps.data);
    }, []);

    const mulcolumns = [
        { name: 'id', header: 'Id', defaultVisible: false, defaultWidth: 50, type: 'number' },
        { name: 'sku', defaultFlex: 1, header: 'SKU' },
        { name: 'net_payout', header: 'Net Payout/PC', defaultFlex: 1, type: 'number', filterEditor: NumberFilter },
        { name: 'return_cost', header: 'Return Cost/PC', defaultFlex: 1, type: 'number', filterEditor: NumberFilter },
        { name: 'enso_commission', header: 'Enso Commission', defaultFlex: 1, type: 'number', filterEditor: NumberFilter },
        { name: 'tax', header: 'Tax', defaultFlex: 1, type: 'number', filterEditor: NumberFilter },
        { name: 'tcs_tds', header: 'TCS/TDS', defaultFlex: 1, type: 'number', filterEditor: NumberFilter },
        { name: 'ad_cost', header: 'Total Ad Cost', defaultFlex: 1, type: 'number', filterEditor: NumberFilter },
        { name: 'tac_pc', header: 'TAC/PC', defaultFlex: 1, type: 'number', filterEditor: NumberFilter },
        { name: 'firstmile_cost', header: 'First Mile Cost', defaultFlex: 1, type: 'number', filterEditor: NumberFilter },
        { name: 'orderprocessing_cost', header: 'Order Processing Cost', defaultFlex: 1, type: 'number', filterEditor: NumberFilter },
        { name: 'netpayout_pc', header: 'Net Payout/PC', defaultFlex: 1, type: 'number', filterEditor: NumberFilter },
        { name: 'old_payment', header: 'Old Payment', defaultFlex: 1, type: 'number', filterEditor: NumberFilter },
        { name: 'due', header: 'Due', defaultFlex: 1, type: 'number', filterEditor: NumberFilter },
        { name: 'totalunits_sold', header: 'Total Units Sold', defaultFlex: 1, type: 'number', filterEditor: NumberFilter },

    ]

    const defaultfilterValue = [
        { name: 'sku', operator: 'startsWith', type: 'string', value: '' },
        { name: 'net_payout', operator: 'gte', type: 'number', },
        { name: 'return_cost', operator: 'gte', type: 'number', },
        { name: 'enso_commission', operator: 'gte', type: 'number', },
        { name: 'tax', operator: 'gte', type: 'number', },
        { name: 'tcs_tds', operator: 'gte', type: 'number', },
        { name: 'ad_cost', operator: 'gte', type: 'number', },
        { name: 'tac_pc', operator: 'gte', type: 'number', },
        { name: 'firstmile_cost', operator: 'gte', type: 'number', },
        { name: 'orderprocessing_cost', operator: 'gte', type: 'number', },
        { name: 'netpayout_pc', operator: 'gte', type: 'number', },
        { name: 'old_payment', operator: 'gte', type: 'number', },
        { name: 'due', operator: 'gte', type: 'number', },
        { name: 'totalunits_sold', operator: 'gte', type: 'number', },

    ];

    const muldataSource = [
        { sku: '131242ADF3124', net_payout: 14424, return_cost: 34141, enso_commission: 35234, tax: 1241, tcs_tds: 12412, ad_cost: 1517421, tac_pc: 1735171, firstmile_cost: 12421215, orderprocessing_cost: 41241441, netpayout_pc: 5135135, old_payment: 141241, due: 41241, totalunits_sold: 66951 },
        { sku: '231242ADF3124', net_payout: 14424, return_cost: 34141, enso_commission: 35234, tax: 1241, tcs_tds: 12412, ad_cost: 1517421, tac_pc: 1735171, firstmile_cost: 12421215, orderprocessing_cost: 41241441, netpayout_pc: 5135135, old_payment: 141241, due: 41241, totalunits_sold: 66951 },
        { sku: '331242ADF3124', net_payout: 14424, return_cost: 34141, enso_commission: 35234, tax: 1241, tcs_tds: 12412, ad_cost: 1517421, tac_pc: 1735171, firstmile_cost: 12421215, orderprocessing_cost: 41241441, netpayout_pc: 5135135, old_payment: 141241, due: 41241, totalunits_sold: 66951 },
        { sku: '431242ADF3124', net_payout: 14424, return_cost: 34141, enso_commission: 35234, tax: 1241, tcs_tds: 12412, ad_cost: 1517421, tac_pc: 1735171, firstmile_cost: 12421215, orderprocessing_cost: 41241441, netpayout_pc: 5135135, old_payment: 141241, due: 41241, totalunits_sold: 66951 },
        { sku: '531242ADF3124', net_payout: 14424, return_cost: 34141, enso_commission: 35234, tax: 1241, tcs_tds: 12412, ad_cost: 1517421, tac_pc: 1735171, firstmile_cost: 12421215, orderprocessing_cost: 41241441, netpayout_pc: 5135135, old_payment: 141241, due: 41241, totalunits_sold: 66951 },
        { sku: '631242ADF3124', net_payout: 14424, return_cost: 34141, enso_commission: 35234, tax: 1241, tcs_tds: 12412, ad_cost: 1517421, tac_pc: 1735171, firstmile_cost: 12421215, orderprocessing_cost: 41241441, netpayout_pc: 5135135, old_payment: 141241, due: 41241, totalunits_sold: 66951 },
    ]

    const gridStyle = { minHeight: 550,  }

    return <>
        {notification && (
            <Notification
                data={notification}
                columns={mulcolumns}
                onClose={closeNotification}
            />
        )}
        <ReactDataGrid
            // ...props for your data grid...
            idProperty="id"
            columns={mulcolumns}
            style={gridStyle}
            pagination
            defaultFilterValue={defaultfilterValue}
            dataSource={muldataSource}
            onRowClick={onRowClick}
            onRowDoubleClick={onRowDoubleClick}
        />
    </>;
};

export default MultipleDataGrid;