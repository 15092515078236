
const MyProfile = () => {

    return (
        <>
            <div>
                <div className="bg-white overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6 border-b border-gray-200">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">My Profile</h3>
                    </div>
                    <div className="px-4 py-5 sm:p-6">
                        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">

                            {/* Personal details section */}
                            <div className="bg-blue-50 p-4 rounded">
                                <h4 className="text-sm font-medium text-blue-700 mb-3">Personal details</h4>
                                <div className="mb-3">
                                    <label className="block text-sm font-medium text-gray-700">Name:</label>
                                    <p className="mt-1 text-sm text-gray-900">Varun Mahurkar</p>
                                </div>
                                {/* Email ID */}
                                <div className="mb-3">
                                    <label className="block text-sm font-medium text-gray-700">Email ID:</label>
                                    <p className="mt-1 text-sm text-gray-900">workingwithvarun@gmail.com</p>
                                </div>
                                {/* Mobile number */}
                                <div className="mb-3">
                                    <label className="block text-sm font-medium text-gray-700">Mobile number:</label>
                                    <p className="mt-1 text-sm text-gray-900">7804044158</p>
                                </div>
                                {/* Social media ID */}
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Social media ID:</label>
                                    <p className="mt-1 text-sm text-gray-900">-</p>
                                </div>
                            </div>

                            {/* Business & Partner details section */}
                            <div className="bg-blue-50 p-4 rounded">
                                <h4 className="text-sm font-medium text-blue-700 mb-3">Business & Partner details</h4>
                                {/* Company name */}
                                <div className="mb-3">
                                    <label className="block text-sm font-medium text-gray-700">Company name:</label>
                                    <p className="mt-1 text-sm text-gray-900">MNC</p>
                                </div>
                                {/* Company phone number */}
                                <div className="mb-3">
                                    <label className="block text-sm font-medium text-gray-700">Company phone number:</label>
                                    <p className="mt-1 text-sm text-gray-900">-</p>
                                </div>
                                {/* Country */}
                                <div className="mb-3">
                                    <label className="block text-sm font-medium text-gray-700">Country:</label>
                                    <p className="mt-1 text-sm text-gray-900">India</p>
                                </div>
                                {/* Company address */}
                                <div className="mb-3">
                                    <label className="block text-sm font-medium text-gray-700">Company address:</label>
                                    <p className="mt-1 text-sm text-gray-900">Gwalior</p>
                                </div>
                                {/* Business license */}
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Business license:</label>
                                    <p className="mt-1 text-sm text-gray-900">Download license</p>
                                </div>
                            </div>

                            {/* Amazon Seller Central details */}
                            <div className="bg-blue-50 p-4 rounded sm:col-span-2">
                                <h4 className="text-sm font-medium text-blue-700 mb-3">Amazon Seller Central details</h4>
                                {/* Marketplace */}
                                <div className="mb-3">
                                    <label className="block text-sm font-medium text-gray-700">Marketplace:</label>
                                    <p className="mt-1 text-sm text-gray-900">AMAZON</p>
                                </div>
                                {/* Store name */}
                                <div className="mb-3">
                                    <label className="block text-sm font-medium text-gray-700">Store name:</label>
                                    <p className="mt-1 text-sm text-gray-900">Beyobo Store House</p>
                                </div>
                                {/* Amazon account opening date */}
                                <div className="mb-3">
                                    <label className="block text-sm font-medium text-gray-700">Amazon account opening date:</label>
                                    <p className="mt-1 text-sm text-gray-900">10-12-2023</p>
                                </div>
                                {/* Marketplace seller ID */}
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Marketplace seller ID:</label>
                                    <p className="mt-1 text-sm text-gray-900">FBA126YT5</p>
                                </div>
                            </div>

                        </div>

                        <div className="mt-6 flex gap-5">
                            <button className="inline-flex justify-center py-2 px-4 border border-brand-300 shadow-sm text-sm font-medium rounded-md text-blue-600 bg-white hover:text-white hover:bg-brand-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                                Edit
                            </button>

                            <button className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MyProfile;
