

import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter'
import moment from 'moment'
import React, { useState, useEffect, useContext, useCallback } from 'react';
import CustomTable from '../../../../components/customTable';
import DataModal from '../../../../components/modal';
import AuthContext from '../../../../context/AuthContext';

const AdminShipmentVariantDataGrid = () => {


    //const columns = [
    //    { name: 'id', defaultFlex: 1, header: 'Id', type: 'number',  },
    //    { name: 'company__id', defaultFlex: 1, header: 'Company Id', type: 'number',},
    //    { name: 'shipment_id', defaultFlex: 1, header: 'Shipment Id', type: 'number', },
    //    { name: 'variant_id', defaultFlex: 1, header: 'Variant Id', type: 'number', },
    //    { name: 'ordered_quantity', header: 'Ordered Quantity', defaultFlex: 1, type: 'number',  },
    //    { name: 'dispatched_quantity', header: 'Dispatched Quantity', defaultFlex: 1, type: 'number', },
    //    { name: 'received_quantity', header: 'Received Quantity', defaultFlex: 1, type: 'number', },
    //    { name: 'freight_per_pc', header: 'Freight Per Pc', defaultFlex: 1, type: 'number', },      
    //];


    const columns = [
        { header: 'ID', key: 'id' },
        { header: 'Company ID', key: 'company_id' },
        { header: 'Shipment ID', key: 'shipment_id' },
        { header: 'Variant ID', key: 'variant_id' },
        { header: 'Ordered Quantity', key: 'ordered_quantity' },
        { header: 'Dispatched Quantity', key: 'dispatched_quantity' },
        { header: 'Received Quantity', key: 'received_quantity' },
        { header: 'Freight Per PC', key: 'freight_per_pc' },
    ];


    //const defaultfilter = [
    //    { name: 'id', operator: 'gte', type: 'number', value: 0 },
    //    { name: 'company_id', operator: 'gte', type: 'number', value: 0 },
    //    { name: 'shipment_id', operator: 'gte', type: 'number', value: 0 },
    //    { name: 'variant_id', operator: 'gte', type: 'number', value: 0 },
    //    //{ name: 'sku_id', operator: 'gte', type: 'number' ,value: 0},

    //    //{ name: 'variant_id', operator: 'gte', type: 'number' ,value: 0},
    //    { name: 'ordered_quantity', operator: 'gte', type: 'number', value: 0 },
    //    { name: 'dispatched_quantity', operator: 'gte', type: 'number', value: 0 },
    //    { name: 'received_quantity', operator: 'gte', type: 'number', value: 0 },

        
    //    { name: 'freight_per_pc', operator: 'gte', type: 'number', value: 0 },

    //];



    //const [filterValue, setFilterValue] = useState(defaultfilter);
    const [dataSource, setDataSource] = useState([]);
    const { authToken } = useContext(AuthContext);

    const [gridData, setGridData] = useState([]); // New state for actual data array

    const loadData = useCallback(() => {
        const newDataSource = () => {
            const limit = 50;
            const url = `${process.env.REACT_APP_BASE_API_URL}/adminshipmentvariant/complete_list` + '?limit=' + limit;
            return fetch(url, {
                headers: {
                    'Authorization': `Token ${authToken}`
                }
            })
                .then(response => response.json())
                .then(data => {
                    const formattedData = data.map(item => ({
                        ...item,
                        order_date: moment(item.order_date).format('DD-MM-YYYY')
                    }));
                    setGridData(formattedData); // Update gridData with the actual data array
                    return { data: formattedData }; // Return the data for dataSource
                });
        };

        setDataSource(newDataSource); // Keep as it was, setting a function
    }, [authToken]);


    //const exportCSV = () => {
    //    if (!Array.isArray(gridData)) {
    //        console.error('Grid data is not an array');
    //        return;
    //    }

    //    // Process the data to format it for CSV
    //    const rows = gridData.map((data) =>
    //        columns.map((col) => {
    //            // Handle any necessary formatting per column
    //            let cellData = data[col.name];
    //            if (col.render) {
    //                // If there's a custom render, use it for CSV data
    //                const rendered = col.render({ value: cellData });
    //                if (React.isValidElement(rendered)) {
    //                    // If rendered content is a React element, extract text content
    //                    cellData = rendered.props.children;
    //                } else {
    //                    cellData = rendered;
    //                }
    //            }
    //            return `"${cellData?.toString().replace(/"/g, '""') || ''}"`; // Escape quotes
    //        }).join(',')
    //    );

    //    const header = columns.map((col) => `"${col.header.replace(/"/g, '""')}"`).join(',');
    //    const csvContent = [header].concat(rows).join('\n');

    //    // Create a Blob for the CSV content
    //    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    //    const url = URL.createObjectURL(blob);

    //    // Create a temporary link to trigger the download
    //    const link = document.createElement('a');
    //    link.href = url;
    //    link.download = 'shipment-grid-data.csv';
    //    document.body.appendChild(link);
    //    link.click();
    //    document.body.removeChild(link);
    //};


    useEffect(() => {
        loadData();
    }, [loadData]); // Ensure loadData is stable and doesn't change unless necessary


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);

    const onRowClick = useCallback((rowProps) => {
        setSelectedRowData(rowProps.data);
        setIsModalOpen(true);
    }, []);

    const gridStyle = { minHeight: 550 }


    const [selected, setSelected] = useState(null);

    const onSelectionChange = useCallback(({ selected: selectedMap, data }) => {
        const newSelected = Object.keys(selectedMap).map(id => id * 1)

        setSelected(newSelected);
    }, [])

    return (
        <div>
            <CustomTable data={gridData} columns={columns} onRowClick={() => { }} />

            {/*<div className="right-4 z-50 my-3">*/}
            {/*    <button onClick={exportCSV}*/}
            {/*        className="bg-brand-300 hover:bg-brand-400 text-white font-bold py-2 px-4 rounded flex items-center mt-4"*/}
            {/*    >*/}
            {/*        Download CSV*/}
            {/*    </button>*/}
            {/*</div>*/}

            {/*<ReactDataGrid*/}
            {/*    style={gridStyle}*/}
            {/*    idProperty="id"*/}
            {/*    pagination='local'*/}
            {/*    columns={columns}*/}
            {/*    dataSource={dataSource}*/}
            {/*    defaultLimit={50}*/}
                
            {/*    onRowClick={onRowClick} // Add this line to handle row clicks*/}
            {/*/>*/}

            {/*defaultFilterValue={defaultfilter}*/}
            {/*onFilterValueChange={setFilterValue}*/}

            {/*<DataModal*/}
            {/*    isOpen={isModalOpen}*/}
            {/*    onClose={() => setIsModalOpen(false)}*/}
            {/*    data={selectedRowData}*/}
            {/*    columns={columns}*/}
            {/*/>*/}
        </div>
    )
};


export default AdminShipmentVariantDataGrid;